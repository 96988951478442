'use strict';

import { register, IMenuItem, ANGULAR_EVENT, arrayToDictionary, isPromise } from '@systemorph/web';
import { normalizeComponentName } from '../../utils';
import { IColumnDisplay } from './columns/columns.api';
import { TreeUtils } from '../../utils/treeUtils';
import { IColumnDef, IGridConstants, IRowCol } from 'angular-ui-grid';
import { extend, toJson, fromJson } from 'angular';
import { IDataGridService, IDataGridColumnDef, IDataGridOptions, IDataGridApi, IExpandedChildrenState, IDataGridSelection, IDataGridColumn, IDataGridRow, IDataGridRowCol, IDataGridScope, IDataGridState, IDataGridEntity, IDataGridCellScope } from './dataGrid.api';
import { DATA_GRID_EVENT } from './dataGrid.events';
import { dataGridColumnTypeConfig } from './dataGridColumnConfig';
import { DATA_GRID_COLUMN_DISPLAY, DATA_GRID_COLUMN_TYPE } from './dataGrid.constants';
import {camelCase} from 'lodash';
import {IPromise} from 'angular';

// registering extra modules from ui-grid into main systemorph module
register.module([
    "ui.grid.pinning",
    "ui.grid.exporter",
    "ui.grid.moveColumns",
    "ui.grid.saveState"
]);

register.directive("datagrid", (dataGridService: IDataGridService) => {
    return {
        replace: true,
        restrict: 'E',
        bindToController: {
            entities: '=',
            columnDefs: '=?',
            datagridOptions: '=?'
        },
        template: require('./dataGrid.html'),
        controller: DataGridController,
        controllerAs: 'dataGrid',
        link: function () {
            dataGridService.setTemplates();
        }
    }
});

// additional horizontal space to account (paddings + borders) to calculate cell width required to display the longest value
const cellPaddings = 16;

export class DataGridController {
    // bindings
    entities: any[];
    columnDefs: IDataGridColumnDef[];
    datagridOptions: IDataGridOptions;
    // ---

    options: IDataGridOptions;
    gridIsReady: boolean;
    // the name of the container to hold the group headers,
    // needed to render the headers only in pinned columns container in case there are pinned columns (column type = RowHeader)
    groupHeaderColContainerName: string;
    // use fixed or auto height for long and short data sets accordingly
    fixedHeight: boolean;
    menus: IMenuItem[];
    gridApiPromise: ng.IPromise<IDataGridApi>;

    private shouldSaveState: boolean;
    // maximum width length per column, needed to calculate column width
    private maxWidthPerColumn: { [key: string]: number };

    private expandedChildrenState: IExpandedChildrenState;
    private currentRowIndex: number;
    private currentCellColDef: IColumnDef;
    private gridApiDeffered: ng.IDeferred<IDataGridApi>;
    private gridApi: IDataGridApi;

    // min and max values for calculated width
    private minColumnWidth = 80;
    private maxColumnWidth = 300;

    // row number threshold to enable virtualization
    private virtualizationThreshold = 50;

    private selection: IDataGridSelection = {};
    private selectedColumn: IDataGridColumn;
    private selectedRow: IDataGridRow;
    private selectedCell: IDataGridRowCol;

    private lastSelectedColumn: IDataGridColumn;
    private selectedColumns: IDataGridColumn[] = [];
    private selectedRows: IDataGridRow[] = [];

    constructor(private $scope: IDataGridScope,
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
        private $document: ng.IDocumentService,
        private $rootScope: ng.IRootScopeService,
        private $window: ng.IWindowService,
        private $element: ng.IAugmentedJQuery,
        private dataGridService: IDataGridService,
        private uiGridConstants: IGridConstants,
        private $injector: ng.auto.IInjectorService) {
        this.gridApiDeffered = this.$q.defer();
        this.gridApiPromise = this.gridApiDeffered.promise;

        this.setOptions();
        this.setColumnDefs();
        this.setGridData()
            .then(() => {
                this.shouldSaveState = !!this.options.gridId;

                // expanded children names restored from the state on page reload by parent colDef name
                this.expandedChildrenState = {};

                var oldValues: any[];

                $scope.$watchGroup([() => this.datagridOptions, () => this.columnDefs, () => this.entities, () => this.datagridOptions.menus], (newValues) => {
                    const optionsChanged = oldValues && newValues[0] !== oldValues[0] || oldValues && newValues[3] !== oldValues[3];
                    const columnDefsChanged = oldValues && newValues[1] !== oldValues[1];
                    const entitiesChanged = oldValues && newValues[2] !== oldValues[2];

                    if (optionsChanged) this.setOptions();
                    if (optionsChanged || columnDefsChanged) this.setColumnDefs();
                    if (optionsChanged || columnDefsChanged || entitiesChanged) {
                        this.setGridData()
                            .then(() => {
                                // restore cell selection after entities update
                                // todo: use grid state instead
                                this.$timeout(() => {
                                    this.setCalculatedColumnWidths();
                                    if (this.currentRowIndex && this.options.data[this.currentRowIndex]) {
                                        this.gridApiPromise.then(gridApi => {
                                            gridApi.cellNav.scrollToFocus(this.options.data[this.currentRowIndex], this.currentCellColDef);
                                        });
                                    }

                                    $scope.$emit(DATA_GRID_EVENT.dataChanged, $scope, this);

                                    // adapt to the new height when fixedHeight changes
                                    this.adjustGridToChangedHeight();
                                });
                            });
                    }

                    if (columnDefsChanged) this.resetColumnSelection();
                    if (entitiesChanged) this.resetRowSelection();

                    if (columnDefsChanged || entitiesChanged) {
                        this.resetCellSelection();
                        this.triggerSelectionChangedEvent();
                    }

                    oldValues = newValues.slice(0);
                });

                $scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
                    dataGridService.restoreTemplates();
                });

                $scope.$emit(DATA_GRID_EVENT.onInitialized, $scope, this);
            });
    }

    setGridHeight(height: number) {
        this.$element.find('.grid').height(height);
        return this.adjustGridToChangedHeight();
    }

    private adjustGridToChangedHeight() {
        return this.gridApiPromise.then(gridApi => {
            // $('.report-grid .ui-grid-viewport').trigger('scroll');
            // gridApi.core.refreshRows(); // refresh visible rows

            gridApi.core.handleWindowResize(); // set new dimensions

            for (let i in gridApi.grid.renderContainers) {
                const container = gridApi.grid.renderContainers[i];
                const vertScrollLength = container.getVerticalScrollLength();
                const vertScrollPercentage = container.prevScrollTop / vertScrollLength;
                container.adjustScrollVertical(container.prevScrollTop, vertScrollPercentage, true);
            }
        });
    }

    private setOptions() {
        this.options = extend({}, this.defaultOptions, this.datagridOptions || {});
        this.options.virtualizationThreshold = Math.max(this.virtualizationThreshold, this.options.fixedHeightThreshold);

        // todo: change the platform's dropDownMenuItem directive so that it works without this timeout workaround
        this.$timeout(() => {
            this.menus = [];

            this.$timeout(() => {
                this.menus = this.options.menus;
            });
        });
    }

    private setColumnDefs() {
        this.options.columnDefs = this.getColumnDefs();
        this.initColumnsHierarchy(this.options.columnDefs);
    }

    protected getGridStateStorageKey = () => `grid-state-${ this.options.gridId }`;

    protected saveGridState() {
        const gridState = this.gridApi.saveState.save();
        this.saveExpandedColumnsToState(gridState);
        localStorage.setItem(this.getGridStateStorageKey(), toJson(gridState));
    }

    protected restoreGridState() {
        const gridStateString = localStorage.getItem(this.getGridStateStorageKey());
        if (gridStateString) {
            try {
                const gridState = <IDataGridState> fromJson(gridStateString);
                this.gridApi.saveState.restore(this.$scope, gridState);
                return gridState;
            }
            catch (e) {
                console.log(`Failed to restore grid state (${ this.options.gridId })`, e);
            }
        }
    }

    private saveExpandedColumnsToState(gridState: IDataGridState) {
        gridState.columns.forEach(c => {
            const colDef = this.options.columnDefs.filter(cd => cd.name === c.name)[0];
            if (colDef.children) {
                c.expandedChildren = colDef.expandedChildren.map(ec => ec.name);
            }
        });
    }

    private setGridData() {
        this.options.data = this.processEntities(this.entities);
        return this.formatEntities();
    }

    toggleExpand(row: IDataGridRow) {
        row.entity.isExpanded = !row.entity.isExpanded;
        this.refreshHierarchicalRowsVisibility();
        this.setFixedHeight();
        this.gridApi.core.handleWindowResize();
    }

    refreshHierarchicalRowsVisibility() {
        var reason = "expand";

        this.gridApi.grid.rows.forEach((r: IDataGridRow) => {
            var groupingEntity = r.entity;

            if (!groupingEntity.parent) {
                r.clearThisRowInvisible(reason);
            } else {
                var isParentExpanded = true;
                while (groupingEntity.parent) {
                    isParentExpanded = isParentExpanded && groupingEntity.parent.isExpanded;
                    groupingEntity = groupingEntity.parent;
                }

                if (isParentExpanded) {
                    r.clearThisRowInvisible(reason);
                } else {
                    r.setThisRowInvisible(reason);
                }
            }
        });
    }

    toggleColumnExpanded(colDef: IDataGridColumnDef) {
        colDef.isExpanded = !colDef.isExpanded;
        colDef.expandedChildren = colDef.isExpanded ? colDef.children : [];

        this.setChildColumnsVisibility(colDef, colDef.isExpanded);
        // this.gridApi.grid.queueGridRefresh().then(() => {
        //     this.saveGridState();
        // });
    }

    // Calculates the column width based on the content. This applies to columns having no colDef.width specified
    // and overrides the default ui-grid's auto-width calculation, which stretches the column to occupy all available space.
    // On top of that, applies the same width to the column groups configured in options.equalWidthColumns
    private setCalculatedColumnWidths() {
        // individual columns
        this.gridApi.grid.getOnlyDataColumns().forEach(c => {
            if (!c.colDef.width && !c.hasUserWidth) {
                const maxWidth = c.colDef.maxWidth !== undefined ? c.colDef.maxWidth : this.maxColumnWidth;
                const minWidth = c.colDef.minWidth !== undefined ? c.colDef.minWidth : this.minColumnWidth;
                const width = this.maxWidthPerColumn[c.name] ? Math.ceil(this.maxWidthPerColumn[c.name]) + cellPaddings : 0;
                c.width = (minWidth ? Math.max(maxWidth ? Math.min(width, maxWidth) : width, minWidth) : width);
                // c.hasCustomWidth = true; // built-in ui-grid's flag, needed to prevent applying ui-grid's auto-width(stretching) on refresh
            }
        });

        // groups of columns with equal width - applying the biggest width to all columns of the group
        if (this.options.equalWidthColumns) {
            const colsByName: { [key: string]: IDataGridColumn } =
                arrayToDictionary(this.gridApi.grid.getOnlyDataColumns(), c => c.name);

            this.options.equalWidthColumns.forEach(g => {
                // excluding columns having auto-width (colDef.width = *) and columns resized by user
                const groupColumns = g.map(colName => colsByName[colName]).filter(c => c && c.colDef.width !== '*' && !c.hasUserWidth);
                const maxWidth = Math.max(...groupColumns.map(c => c.width));
                groupColumns.forEach(c => c.width = maxWidth);
            });
        }
    }

    protected formatEntities() {
        this.maxWidthPerColumn = {};

        const cellDataPromises: ng.IPromise<any>[] = [];

        this.options.data.forEach(rowEntity => {
            var res = this.formatEntity(rowEntity);
            if (res && isPromise(res))
                cellDataPromises.push(res);
        });

        return this.$q.all(cellDataPromises);
    }

    protected formatEntity(rowEntity: IDataGridEntity): ng.IPromise<any> {
        if (rowEntity.isGroupHeader) return;

        rowEntity.cellDataStore = {};
        rowEntity.cellConfig = {};
        rowEntity.displayValues = {};

        const promises: ng.IPromise<any>[] = [];

        this.options.columnDefs.forEach(colDef => {
            var cellDataResult = colDef.columnDisplay.getCellData(rowEntity.entity);

            if (isPromise(cellDataResult)) {
                promises.push((<ng.IPromise<any>> cellDataResult)
                    .then((cellData: any) => this.processCellData(rowEntity, colDef, cellData)));
            }
            else {
                this.processCellData(rowEntity, colDef, cellDataResult);
            }
        });

        return this.$q.all(promises);
    }

    protected processCellData(rowEntity: IDataGridEntity, colDef: IDataGridColumnDef, cellData: any) {
        rowEntity.cellDataStore[colDef.name] = cellData;

        // cell params
        rowEntity.cellConfig[colDef.name] = colDef.columnDisplay.getCellParams(rowEntity.entity, cellData);

        // display values
        const displayValue = rowEntity.displayValues[colDef.name] = colDef.columnDisplay.getDisplayValue(rowEntity.entity, cellData);
        const width = colDef.columnDisplay.getDisplayWidth(displayValue, cellData, rowEntity.hierarchyLevel);

        if (width && (!this.maxWidthPerColumn[colDef.name] || width > this.maxWidthPerColumn[colDef.name]))
            this.maxWidthPerColumn[colDef.name] = width;
    }

    updateRow(index: number, newRow: any) {
        var rowEntity = this.options.data[index];
        rowEntity.entity = newRow;
        rowEntity.classes = this.getRowClasses(newRow, rowEntity.isFirstInGroup).join(' ') || null;
        return this.formatEntity(rowEntity);
    }

    public refreshDisplayValues() {
        return this.gridApiPromise.then(gridApi => {
            return this.formatEntities().then(() => {
                this.setCalculatedColumnWidths();
                return gridApi.grid.queueGridRefresh(); // applying new column widths
            });
        });
    }

    protected onGridApiReady(api: IDataGridApi) {
        this.gridApiDeffered.resolve(api);

        this.gridApi = api;

        // recalculate width when comparison columns change
        this.gridApi.grid.registerDataChangeCallback(() => this.setCalculatedColumnWidths(), [this.uiGridConstants.dataChange.COLUMN]);

        api.cellNav.on.navigate(this.$scope, (newRowCol: IRowCol, oldRowCol: IRowCol) => {
            this.currentRowIndex = this.options.data.indexOf(newRowCol.row.entity);
            this.currentCellColDef = newRowCol.col.colDef;
        });

        this.$scope.$watch(() => api.grid.renderContainers.left && api.grid.renderContainers.left.renderedColumns.length, (newValue, oldValue) => {
            this.groupHeaderColContainerName = newValue > 0 ? "left" : "body";
        });

        this.setCalculatedColumnWidths();

        // if (this.shouldSaveState) {
        //     const gridState = this.restoreGridState();
        //     if (gridState) {
        //         gridState.columns.forEach(c => {
        //             if (c.expandedChildren) this.expandedChildrenState[c.name] = c.expandedChildren;
        //         });
        //     }
        //
        //     // setting expandable columns properties based on restored state
        //     this.options.columnDefs
        //         .filter(colDef => colDef.isExpandable)
        //         .forEach(colDef => {
        //             // todo: remove collapsed children from array instead of reassigning the whole array
        //             colDef.expandedChildren = this.expandedChildrenState[colDef.name]
        //                 ? colDef.children.filter(c => this.expandedChildrenState[colDef.name].indexOf(c.name) !== -1)
        //                 : colDef.children;
        //             colDef.isExpanded = colDef.expandedChildren.length > 0;
        //         });
        //
        //     // api.colMovable.on.columnPositionChanged(this.$scope, () => this.saveGridState());
        // }

        // api.colResizable.on.columnSizeChanged(this.$scope, (colDef, deltaChange) => {
        //     api.grid.getColumn(colDef.name).hasUserWidth = true;
        //     this.saveGridState();
        // });

        api.core.on.columnVisibilityChanged(this.$scope, (col: IDataGridColumn) => {
            const colDef = col.colDef;
            if (colDef.children) {
                this.setChildColumnsVisibility(colDef, colDef.visible && colDef.isExpanded);
            }
            if (colDef.parent) {
                this.setParentColumnExpanded(colDef.parent);
            }
            // if (this.shouldSaveState) {
            //     // queueGridRefresh is required for column visibility to be applied internally,
            //     // where the column state is copied from
            //     api.grid.queueGridRefresh().then(() => {
            //         this.saveGridState();
            //     });
            // }
        });

        api.selection.on.rowSelectionChanged(this.$scope, (row, event) => this.onRowSelectionChanged(event));
        api.selection.on.rowSelectionChangedBatch(this.$scope, (rows, event) => {
            if (rows.filter(r => r.isSelected).length > 0)
                this.onRowSelectionChanged(event);
        });

        api.cellNav.on.navigate(this.$scope, (newRowCol: IDataGridRowCol, oldRowCol: IDataGridRowCol) => {
            if (!newRowCol.col.isRowHeader) {
                this.selectedCell = newRowCol;
                this.resetColumnSelection();
                this.resetRowSelection();
                this.triggerSelectionChangedEvent();
            }
        });

        api.core.on.rowsRendered(this.$scope, () => {
            this.setFixedHeight(); // update fixed height on filters change
        });

        this.refreshHierarchicalRowsVisibility();

        this.setFixedHeight();

        // this.fixedHeight = api.grid.getVisibleRowCount() >= this.fixedHeightThreshold;

        // api.core.on.rowsVisibleChanged(this.$scope, row => {
        //     this.fixedHeight = api.grid.getVisibleRowCount() >= this.fixedHeightThreshold;
        //     $(window).trigger('resize');
        // });

        // this.$document.mousedown(event => {
        //     if (!$(event.target).is('.data-grid .grid *'))
        //         this.$timeout(() => this.unselectColumnsAndRows());
        // });

        // prevent showing grid before it is completely initialized - things like calculated column width
        // or hierarchical rows visibility can be set only after grid api is available
        this.gridIsReady = true;
        this.$scope.$emit(DATA_GRID_EVENT.onGridIsReady, this.$scope, this);
    }

    private onRowSelectionChanged(event: JQueryEventObject) {
        this.selectedRows = this.gridApi.selection.getSelectedGridRows();
        this.selectedRow = this.selectedRows[0];
        this.resetColumnSelection();
        this.resetCellSelection();
        this.triggerSelectionChangedEvent(event);
    }

    setFixedHeight() {
        const fixedHeight = this.gridApi.grid.rows.filter(r => r.visible).length >= this.options.fixedHeightThreshold;
        if (fixedHeight)
            this.$element.find('.grid').removeClass('auto-height');
        else
            this.$element.find('.grid').addClass('auto-height');
    }

    /**
     * Creates a set of IDataGridEntity to be used by ui-grid.
     * In addition to original entities the result set is extended with the group header rows.
     * @param entities original set of of entities
     * @returns {IDataGridEntity[]}
     */
    protected processEntities(entities: any[]) {
        var gridData: IDataGridEntity[] = [];
        var lastGroupName: { [key: number]: string } = {};

        entities.forEach((entity, index) => {
            var isFirstInGroup = false;

            if (this.options.groupDefinitions) {
                // add group headers
                this.options.groupDefinitions.forEach(g => {
                    const groupName = g.getGroupName(entity);
                    if (groupName && groupName !== lastGroupName[g.level]) {
                        isFirstInGroup = true;
                        gridData.push({
                            isGroupHeader: true,
                            groupName,
                            groupLevel: g.level,
                            columnSpan: g.columnSpan,
                        });
                        lastGroupName[g.level] = groupName;
                        Object.keys(lastGroupName).forEach((level: any) => {
                            if (level > g.level) lastGroupName[level] = undefined;
                        });
                    }
                });
            }

            // add entity
            gridData.push({
                index,
                isGroupHeader: false,
                entity,
                isFirstInGroup,
                classes: this.getRowClasses(entity, isFirstInGroup).join(' ') || null
            });
        });

        if (this.options.isHierarchicalGrid && !(this.options.groupDefinitions && this.options.groupDefinitions.length > 0))
            this.initRowsHierarchy(gridData);

        this.fixedHeight = gridData.length >= this.options.fixedHeightThreshold;

        return gridData;
    }

    protected getRowClasses(entity: any, isFirstInGroup: boolean) {
        const classes: string[] = [];

        if (isFirstInGroup) classes.push('first-in-group');

        if (this.options.getRowClasses) {
            const userClasses = this.options.getRowClasses(entity);
            if (userClasses && userClasses.length > 0) classes.push(...userClasses);
        }

        return classes;
    }

    protected initRowsHierarchy(gridData: IDataGridEntity[]) {
        const dataTree = TreeUtils.getTreeFromArray<IDataGridEntity, IDataGridEntity>(gridData,
            x => this.options.getHierarchicalEntityId(x.entity),
            x => this.options.getHierarchicalEntityParentId(x.entity),
            (data, children, level): IDataGridEntity | null => {
                const newNode = {
                    ...data,
                    hierarchyLevel: level,
                    isExpandable: children.length > 0,
                    isExpanded: this.options.isHierarchicalEntityExpandedByDefault ? this.options.isHierarchicalEntityExpandedByDefault(data.entity) : true,
                    children: children
                }

                children.forEach(c => {
                    c.parent = newNode;
                });

                return newNode;
            },
            id => this.options.getMissingHierarchicalEntity(id),
            null);

        gridData.splice(0, gridData.length, ...TreeUtils.flattenTreeNodes(dataTree, d => d.children))
    }

    private setParentColumnExpanded(colDef: IDataGridColumnDef) {
        colDef.expandedChildren = colDef.children.filter(c => c.visible);
        colDef.isExpanded = colDef.expandedChildren.length > 0;
    }

    private setChildColumnsVisibility(colDef: IDataGridColumnDef, visible: boolean) {
        colDef.children.forEach(c => {
            c.visible = visible && colDef.expandedChildren.indexOf(c) !== -1;
            if (c.children) this.setChildColumnsVisibility(c, visible && c.isExpanded);
        });
    }

    private initColumnsHierarchy(colDefs: IDataGridColumnDef[]) {
        const colDefsByName: { [key: string]: IDataGridColumnDef } = arrayToDictionary(colDefs, colDef => colDef.name);

        colDefs.forEach(colDef => {
            const parent = colDefsByName[colDef.parentName];
            if (parent) {
                parent.isExpandable = true;
                parent.isExpanded = true;
                if (!parent.children) {
                    parent.children = [];
                    parent.expandedChildren = [];
                }
                parent.children.push(colDef);
                parent.expandedChildren.push(colDef);
                colDef.parent = parent;
            }
        });

        var sortKey = 0;
        var menuSortKey = 0;

        const setLevels = (colDefs: IDataGridColumnDef[], level: number = 0) => {
            colDefs.forEach(c => {
                c.level = level;
                // showing parents above children in columns menu
                c.menuSortKey = menuSortKey++;
                if (c.children) setLevels(c.children, level + 1);
                // showing parents after children in the grid
                c.sortKey = sortKey++;
            });
        };

        setLevels(colDefs.filter(c => !c.parent));

        // todo: cleanup if we're sure this is not needed, so far - keeping the original columns order
        // colDefs.sort((a, b) => a.sortKey - b.sortKey);
    }

    protected getColumnDisplay(colDef: IDataGridColumnDef) {
        const columnTypeConfig = colDef.columnType ? dataGridColumnTypeConfig[camelCase(colDef.columnType)] : null;

        const columnDisplayName = colDef.columnDisplayName ? normalizeComponentName(colDef.columnDisplayName, "ColumnDisplay")
            : (columnTypeConfig ? columnTypeConfig.defaultColumnDisplay : DATA_GRID_COLUMN_DISPLAY.base);

        const locals: any = {
            colDef,
            dataGridOptions: this.options,
            dataGrid: this
        };

        if (this.options.getColumnDisplayLocals)
            extend(locals, this.options.getColumnDisplayLocals());

        return this.$injector.instantiate<IColumnDisplay>(this.$injector.get<Function>(columnDisplayName), locals);
    }

    protected getColumnDefs() {
        return this.columnDefs
            .map((colDef, index) => {
                const columnTypeConfig = colDef.columnType ? dataGridColumnTypeConfig[camelCase(colDef.columnType)] : null;
                const columnDisplay = this.getColumnDisplay(colDef);
                const template = columnDisplay.getTemplate();

                const defaults = <IDataGridColumnDef> {
                    allowCellFocus: false,
                    enableCellEdit: false,
                    cellEditableCondition: (cellScope: IDataGridCellScope) => cellScope.row.entity.cellConfig[cellScope.col.name].isEditable,
                    editableCellTemplate: `<div datagrid-editor-container></div>`,
                    displayName: colDef.displayName,
                    enableColumnResizing: true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    enableSorting: false,
                    width: colDef.autoWidth ? '*' : colDef.width,
                    minWidth: colDef.columnType == DATA_GRID_COLUMN_TYPE.rowNumber ? 20 : undefined,
                    cellTemplate: `<div class="ui-grid-cell-contents" title="TOOLTIP">${ template }</div>`,
                    columnDisplay,
                    columnTypeConfig,
                    index
                };

                return extend(defaults, colDef);
            });
    }

    private defaultOptions = <IDataGridOptions> {
        enablePinning: true,
        enableSorting: false,
        enablePaging: false,
        enableFiltering: false,
        enableFullRowSelection: false,
        enableSelectAll: false,
        enableGridMenu: true,
        enableRowSelection: false,
        enableRowHeaderSelection: false,
        // state saving options
        saveWidths: true,
        saveOrder: true,
        saveScroll: false,
        saveFocus: false,
        saveVisible: true,
        saveSort: false,
        saveFilter: false,
        savePinning: true,
        saveGrouping: false,
        saveGroupingExpandedStates: false,
        saveTreeView: false,
        saveSelection: false,
        data: null,
        columnDefs: null,
        rowHeight: 27,
        multiSelect: false,
        modifierKeysToMultiSelect: true,
        onRegisterApi: ((api: IDataGridApi) => {
            setTimeout(() => {
                this.onGridApiReady(api);
                if (!this.fixedHeight) {
                    // todo: should be investigated, probably can be fixed by styles, see https://github.com/angular-ui/ui-grid/issues/1926
                    var $viewport = $('.ui-grid-render-container');
                    ['touchstart', 'touchmove', 'touchend', 'keydown', 'wheel', 'mousewheel', 'DomMouseScroll', 'MozMousePixelScroll'].forEach((eventName: any) => {
                        $viewport.unbind(eventName);
                    });
                }
            })
        }),
        // this is a default row template extended with the group header rows
        // the header row renders a number of empty cells required by column span to expand the parent element to a proper width
        rowTemplate: `
        <div ng-if="row.entity.isGroupHeader && colContainer.name === row.grid.appScope.dataGrid.groupHeaderColContainerName" class="group-header-row level-1" data-row-index="{{row.entity.index}}">
            <div class="ui-grid-cell group-header-name">{{row.entity.groupName}}</div>
            <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns | limitTo: row.entity.columnSpan track by col.uid"
                 ui-grid-one-bind-id-grid="rowRenderIndex + '-' + col.uid + '-cell'"
                 class="ui-grid-cell"
                 ui-grid-cell>
            </div>
        </div>
        <div ng-if="!row.entity.isGroupHeader" ng-class="row.entity.classes" data-row-index="{{row.entity.index}}">
            <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
                 ui-grid-one-bind-id-grid="rowRenderIndex + '-' + col.uid + '-cell'"
                 class="ui-grid-cell" ng-class="[row.entity.cellConfig[col.name].classes, {'ui-grid-row-header-cell': col.isRowHeader}]"
                 role="{{col.isRowHeader ? 'rowheader' : 'gridcell'}}"
                 ui-grid-cell>
            </div>
        </div>
        `,
        // extended with column selection (ng-class + ng-click)
        headerTemplate: `
            <div role="rowgroup" class="ui-grid-header">
                <div class="ui-grid-top-panel">
                    <div class="ui-grid-header-viewport">
                        <div class="ui-grid-header-canvas">
                            <div class="ui-grid-header-cell-wrapper" ng-style="colContainer.headerCellWrapperStyle()">
                                <div role="row" class="ui-grid-header-cell-row">
                                    <div class="ui-grid-header-cell ui-grid-clearfix"
                                         ng-class="{ 'selected': col.isSelected }"
                                         ng-mousedown="grid.appScope.dataGrid.onColumnHeaderClicked($event, col)"
                                         ng-repeat="col in colContainer.renderedColumns track by col.uid" ui-grid-header-cell
                                         col="col" render-index="$index"
                                         data-test-data-grid-column-index="{{col.colDef.index}}"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`,
        getPropertyValue: (entity, colDef) => entity && entity[colDef.name],
        setPropertyValue: (entity, colDef, value) => entity[colDef.name] = value,
        getExportName: () => this.$q.when('Report'),
        getColumnExpandButtonTooltip: (colDef: IDataGridColumnDef) => {
            const columnNames = (colDef.isExpanded ? colDef.expandedChildren : colDef.children).map(c => c.displayName);
            const columnNamesString = columnNames.length > 1
                ? columnNames.slice(0, columnNames.length - 1).join(', ') + ' and ' + columnNames[columnNames.length - 1]
                : columnNames[0];
            return (colDef.isExpanded ? 'Hide ' : 'Show ') + columnNamesString;
        },
        exportProvider: 'dataGridExportProvider',
        exporterMenuCsv: false,
        exporterMenuPdf: false,
        fixedHeightThreshold: 23
    };

    onColumnHeaderClicked(event: any, column: IDataGridColumn) {
        if (!this.options.enableColumnSelection || ((!this.options.multiSelect || !column.colDef.isSelectable) && (event.shiftKey || event.ctrlKey)))
            return;

        if (column.colDef.isSelectable) {
            let isSelected = column.isSelected;
            if (event.shiftKey) {
                let fromCol = this.selectedColumns.length > 0 ? this.gridApi.grid.columns.indexOf(this.lastSelectedColumn) : 0;
                let toCol = this.gridApi.grid.columns.indexOf(column);

                if (fromCol > toCol) [fromCol, toCol] = [toCol, fromCol];

                for (let i = fromCol; i <= toCol; i++) {
                    const col = this.gridApi.grid.columns[i];
                    if (col.colDef.isSelectable) col.isSelected = true;
                }
            }
            else {
                if (!event.ctrlKey) {
                    if (this.selectedColumns.length > 1) isSelected = false;
                    this.resetColumnSelection();
                }

                column.isSelected = !isSelected;

                if (column.isSelected) this.lastSelectedColumn = column;
            }
        }
        else {
            this.resetColumnSelection();
        }

        this.selectedColumns = this.gridApi.grid.columns.filter(c => c.isSelected);
        this.selectedColumn = this.selectedColumns[0];

        this.resetRowSelection();
        this.resetCellSelection();
        this.triggerSelectionChangedEvent();
    }

    private resetColumnSelection() {
        this.selectedColumns.forEach(c => c.isSelected = false);
        this.selectedColumns = [];
        this.selectedColumn = null;
    }

    private resetRowSelection() {
        this.gridApi.selection.clearSelectedRows();
        this.selectedRows = [];
        this.selectedRow = null;
    }

    private resetCellSelection() {
        this.gridApi.grid.cellNav.clearFocus();
        this.gridApi.grid.cellNav.lastRowCol = null;
        this.selectedCell = null;
    }

    private triggerSelectionChangedEvent(event?: JQueryEventObject) {
        const newSelection: IDataGridSelection = {
            selectedColumn: this.selectedColumn,
            selectedColumns: this.selectedColumns,
            selectedRow: this.selectedRow,
            selectedRows: this.selectedRows,
            selectedCell: this.selectedCell
        };

        this.$scope.$emit(DATA_GRID_EVENT.selectionChanged, newSelection, this.selection, event);

        this.selection = newSelection;
    }
}