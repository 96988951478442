'use strict';

import { register } from "@systemorph/web";
import * as angular from "angular";
import { TreeItemController } from '@systemorph/ui-web/app/components/tree/treeDirective';

register.decorator("treeDirective", ($delegate: any, $compile: angular.ICompileService) => {

    const directive = $delegate[0];
    directive.template = `
            <div class="tree" ng-class="{multiselect: tree.multiSelect}">
                <tree-with-disabled-item item="item" ng-repeat="item in tree.items"></tree-with-disabled-item>
            </div>
        `;

    return $delegate;
});

register.directive("treeWithDisabledItem", ($compile: ng.ICompileService) => {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        link: (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
            const template = `
            <div class="tree-item" ng-class="[treeItem.item.class, {selectable: treeItem.item.isSelectable !== false, selected: treeItem.item.isSelected}]">
                <div class="tree-item-name" ng-click="treeItem.onClick()" ng-attr-style="padding-left:{{treeItem.marginLeft}}px;">
                    <i ng-if="::treeItem.item.children.length" 
                       ng-click="treeItem.toggleExpanded();$event.stopPropagation()"
                       class="fa" 
                       ng-class="{'fa-caret-right': !treeItem.item.isExpanded, 'fa-caret-down': treeItem.item.isExpanded}"></i> 
                    <input type="checkbox" ng-disabled="!(treeItem.item.isSelectable == undefined || treeItem.item.isSelectable)"
                           id="treeItem.item.data.id" 
                           ng-if="::(tree.multiSelect)" 
                           ng-model="treeItem.item.isSelected" 
                           ng-change="treeItem.onSelect()"
                           ng-click="$event.stopPropagation()" />
                    <span ng-class="{'disabled': !(treeItem.item.isSelectable == undefined || treeItem.item.isSelectable)}">{{treeItem.item.name}}</span>
                </div>
                <div ng-if="treeItem.item.children.length && treeItem.item.isExpanded">
                    <tree-with-disabled-item item="::childItem" level="::treeItem.level + 1" ng-repeat="childItem in treeItem.item.children"></tree-with-disabled-item>
                </div>
            </div>`;
            var newElement = angular.element(template);
            $compile(newElement)(scope);
            element.replaceWith(newElement);
        },
        bindToController: {
            item: '=',
            level: '=?'
        },
        controller: TreeItemController,
        controllerAs: 'treeItem'
    }
});
