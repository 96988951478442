'use strict';

import { register, setPageTitle, STATE } from "@systemorph/web";
import { IScope } from "angular";

register.state({
    name: STATE.home,
    parent: STATE.defaultLayout,
    template: require('./customHome.html'),
    url: '/',
    controller: /*@ngInject*/($scope: IScope) => {
        setPageTitle($scope, "Business Status Overview");
    },
    suppressReloadMessage: true,
}, { useAsOtherwise: true })