'use strict';

import { register, IMenuItemScope } from "@systemorph/web";
import { FormEntityRegistry } from "@systemorph/form-entity-web";
import { COMPARISON_TYPE } from '../reportGrid.constants';
import { IReportGridService } from '../reportGrid.api';
import { REPORT_GRID_EVENT } from '../reportGrid.events';
import { IAugmentedJQuery, IAttributes, IRootScopeService, IQService } from 'angular';
import { IReportService } from '../../../report';
import { ReportGridController } from '../reportGridDirective';
import { IBasicFormEntity } from '../../formEntity';

interface IComparisonMenuScope extends IMenuItemScope {
    c: ComparisonMenuController;
}

register.directive('comparisonTypeReportGridMenuItem', () => {
    return {
        replace: true,
        restrict: 'E',
        require: '?^^reportGrid',
        transclude: true,
        template: `
            <div>
                <button type="button" class="btn btn-primary btn-inline btn-inline-static" ng-click="c.showMenu()" uib-tooltip="Select comparison type" tooltip-placement="top-right" tooltip-append-to-body="true">
                    <i class="fa fa-columns"></i>
                </button>
                <div ui-grid-menu menu-items="c.menuItems" class="comparison-type-menu"></div>
            </div>
            `,
        controller: ComparisonMenuController,
        controllerAs: 'c',
        link($scope: IComparisonMenuScope, $element: IAugmentedJQuery, $attrs: IAttributes, reportGrid: ReportGridController) {
            $scope.c.init(reportGrid);
        }
    };
});

class ComparisonMenuController {
    private menuItems: any[];
    private reportGrid: ReportGridController;

    constructor(private $scope: IComparisonMenuScope,
        private $rootScope: IRootScopeService,
        private $q: IQService,
        private reportService: IReportService,
        private formEntityRegistry: FormEntityRegistry,
        private reportGridService: IReportGridService) {
    }

    init(reportGrid: ReportGridController) {
        this.reportGrid = reportGrid;

        this.getMenus().then(menuItems => {
            this.menuItems = menuItems;
        });
    }

    showMenu() {
        this.$rootScope.$broadcast('hide-menu');
        this.$scope.$broadcast('show-menu');
    }

    private getMenus() {
        return this.$q.all([
            this.formEntityRegistry.getFilterObject(),
            this.formEntityRegistry.getFormEntity()
        ]).then(([filterObject, formEntity]) => {
            return this.reportGridService.getComparisonMenus(filterObject).then(menus => {
                if (!this.reportGrid) { // legacy reports
                    menus = menus.filter(m => m.id == COMPARISON_TYPE.previousYear ||
                        m.id == COMPARISON_TYPE.previousYearAllQuarters ||
                        m.id == COMPARISON_TYPE.noComparison);
                }

                var currentComparisonType = (formEntity as IBasicFormEntity).comparisonType || "None";
                return menus.map(m => {
                    return {
                        title: m.label,
                        action: () => this.selectComparison((formEntity as IBasicFormEntity), m.id),
                        active: () => currentComparisonType == m.id,
                        icon: currentComparisonType == m.id ? "fa fa-check" : null
                    };
                });
            });
        });
    }

    private selectComparison(formEntity: IBasicFormEntity, type: string) {
        formEntity.comparisonType = type;
        this.$rootScope.$broadcast(REPORT_GRID_EVENT.comparisonTypeSelected, type);
    }
}
