'use strict';

import { register, IMessageService, IMenuItemScope } from "@systemorph/web";
import { IDataGridExportService, DataGridController } from "@systemorph/ui-web";
import { FormEntityRegistry } from "@systemorph/form-entity-web";

interface IExportReportInBenchmarkFormatToCsvDataGridMenuItemScope extends IMenuItemScope {
    export(): void;
}

register.directive('exportReportInBenchmarkFormatToCsvDataGridMenuItem', (dataGridExportService: IDataGridExportService, $q: ng.IQService) => {
    return {
        replace: true,
        restrict: 'E',
        require: '^^datagrid',
        transclude: true,
        template: `<button ng-click="export()" target="_self" type="button" class="btn btn-warning btn-inline btn-inline-static" uib-tooltip="Export report in benchmark format to csv" tooltip-placement="top-right" tooltip-append-to-body="true">
                    <span class="fa fa-file-excel-o"></span>
                  </button>`,
        controller: ($scope: IExportReportInBenchmarkFormatToCsvDataGridMenuItemScope, messageService:IMessageService,
            formEntityRegistry: FormEntityRegistry,
            uiGridExporterService: any,//todo get it from dataGridExportService
            $http: ng.IHttpService) => {
            $scope.export = () => {
                return formEntityRegistry.getFilterObject().then(filterObject => {

                    return $http.get<{ fileName: string, content: string }>('/api/ifrsReportExport/csvBenchmarkFormat', { params: filterObject }).then(result => {

                        uiGridExporterService.downloadFile(result.data.fileName, result.data.content, ",", false);

                    }).catch(error => messageService.alertError(error["data"]));
                });
            }
        },
        link($scope: IExportReportInBenchmarkFormatToCsvDataGridMenuItemScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, dataGrid: DataGridController) {
        }
    };
});
