"use strict";

import { register } from '@systemorph/web';
import { IProcessModel } from "../businessProcess.api";
import { getProcessModelName } from "../helpers/businessProcess.helpers";

export interface IProcessModelNameFilter {
    (value: IProcessModel): string;
}

register.filter('processModelName', () => ((value: IProcessModel) => getProcessModelName(value)));