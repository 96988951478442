import { IAutocompleteBoxItem } from "./autocompleteBox.api";
import { isObject } from "lodash";

export const convertToAutocmpleteBoxItems = (autocomplete: (IAutocompleteBoxItem | Object)[]): IAutocompleteBoxItem[] => {
    if (autocomplete && !autocomplete.every((item: any) => isObject(item) && item.value !== undefined)) {
        // i.e. Value property exists but it is uppercased
        if (autocomplete.every((item: any) => isObject(item))) {
            return autocomplete.map((item: any): IAutocompleteBoxItem => ({
                value: item.Value,
                displayName: item.DisplayName
            }));
        }
        else {
            // i.e. each item has no Value or value porperty
            return autocomplete.map((item: any): IAutocompleteBoxItem => {
                return { value: item, displayName: (item ? item.toString() : "") };
            });
        }
    }

    // original collection requires no transformation
    return <IAutocompleteBoxItem[]>autocomplete;
};