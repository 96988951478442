'use strict';

import { register } from "@systemorph/web";
import { 
    BaseAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyControllerAs,
    defaultAutocompleteFormEntityPropertyTempplate,
    FormEntityService,
    IFormEntityPropertyScope
} from "@systemorph/form-entity-web";
import { IAutocompleteBoxItem } from "@systemorph/ui-web";
import { IProcessDocumentationService } from "../../processDocumentation";
import { IPromise, IQService } from "angular";

register.directive('processDocumentationCategoryFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: ProcessDocumentationCategoryFormEntityPropertyController
    };
});

export class ProcessDocumentationCategoryFormEntityPropertyController extends BaseAutocompleteFormEntityPropertyController<string, string> {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                $q: IQService,
                processDocumentationService: IProcessDocumentationService) {
        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            const result: IAutocompleteBoxItem[] = [
                {
                    displayName: "All",
                    value: undefined
                },
                {
                    displayName: "Comments",
                    value: processDocumentationService.categories.comments
                },
                {
                    displayName: "Questions & Answers",
                    value: processDocumentationService.categories.qAndAs
                },
                {
                    displayName: "Supporting materials",
                    value: processDocumentationService.categories.supportingMaterials
                }
            ];
    
            return $q.when(result)
        };

        super($scope, formEntityService, getAutocompleteFunc, true);
    }

    protected getIndexFromAutocomplete(value: string, autocomplete: IAutocompleteBoxItem[]): number {
        return autocomplete.findIndex(x => x.value === value);
    }
}
