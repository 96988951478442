'use strict';

import { register } from "@systemorph/web";
import {
    IFormEntityPropertyScope,
    BaseAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyTempplate,
    defaultAutocompleteFormEntityPropertyControllerAs, FormEntityService
} from "@systemorph/form-entity-web";
import { IAutocompleteBoxItem } from "@systemorph/ui-web";
import { IPromise, IAugmentedJQuery } from 'angular';
import { isEmpty } from 'lodash';
import { IReportUtilsService, IPeriodModel } from '../../../reportingWeb.api';

register.directive('periodFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controller: PeriodFormEntityPropertyController,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs
    };
});

export abstract class BasePeriodFormEntityPropertyController extends BaseAutocompleteFormEntityPropertyController<string, string> {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                protected $element: IAugmentedJQuery,
                getAutocompleteFunc: () => IPromise<IAutocompleteBoxItem[]>,
                updateAutocompleteIfSomethingChanged: boolean) {
        super($scope, formEntityService, getAutocompleteFunc, updateAutocompleteIfSomethingChanged);
    }

    getIndexFromAutocomplete(value: string, autocompleteItems: IAutocompleteBoxItem[]): number {
        const index = autocompleteItems.findIndex(x => x.value === value);
        if (index === -1 && !isEmpty(autocompleteItems)) {
            return autocompleteItems.length - 1;
        }
        return index;
    }
}

class PeriodFormEntityPropertyController extends BasePeriodFormEntityPropertyController {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                $element: IAugmentedJQuery,
                reportUtilsService: IReportUtilsService) {
        const getAutocompleteFunc = () => {
            const year: number = $scope.entity['year'];
            const periodicity: string[] = $scope.typeLayout.editDirectiveParameters['periodicity'];
            return reportUtilsService.getPeriods(year, periodicity)
                .then(periods => periods.map(p => ({value: p.systemName, displayName: p.displayName})));
        };
        super($scope, formEntityService, $element, getAutocompleteFunc, true);
    }
}