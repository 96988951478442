'use strict';

import { filterCollection, ucFirst } from "./helpers";
import { DATA_GRID_COLUMN_TYPE } from "@systemorph/ui-web/app/components/dataGrid/dataGrid.constants";
import { IReportModelColumnNode, IReportModelSelection, IReportModelGroupNode, IReportModel, IReportModelRowDefinition, IReportModelColumnDefinition, IReportModelColumnGroup, IReportModelRow, INamedValue, IColumnNamedValue, IReportModelService } from './report.api';
import { ReportModelParser } from "./reportModelParser";
import { REPORT_MODEL_NODE_TYPE } from './report.constants';

export class ReportBindingContext {
    sliceDisplayName: string;
    allColumnNodes: IReportModelColumnNode[];
    columnNodes: IReportModelColumnNode[];
    args: any;
    comparisonType: string;
    comparisonLabel: string;
    isSelectedContext: boolean;
    isFirstComparisonContext: boolean;
    isLastComparisonContext: boolean;

    private selection: IReportModelSelection;
    selectedCell: ReportBindingContextCell;
    selectedColumn: ReportBindingContextColumn;
    selectedColumns: ReportBindingContextColumn[];
    selectedRow: ReportBindingContextRow;
    selectedRows: ReportBindingContextRow[];
    multipleSelection: boolean;
    selectionLabel: string;

    entity: any;
    value: any;
    column: ReportBindingContextColumn;
    row: ReportBindingContextRow;
    cell: ReportBindingContextCell;

    comparisonGroups: IReportModelGroupNode[];
    comparisonGroup: IReportModelGroupNode;

    // deprecated
    ReportModel: IReportModel;
    Args: any;
    ComparisonType: string;
    Row: any;
    RowDefinition: IReportModelRowDefinition;
    Column: IReportModelColumnDefinition;
    Group: IReportModelColumnGroup;
    Entity: any;
    GroupPath: IReportModelColumnGroup[];

    constructor(public reportModel: IReportModel, private parser: ReportModelParser) {
        this.allColumnNodes = parser.getColumnNodes();

        this.sliceDisplayName = reportModel.formEntity && reportModel.formEntity['sliceDisplayName'];
        this.args = reportModel.args;
        this.comparisonType = reportModel.ComparisonType;

        if (reportModel.ComparisonType) {
            this.comparisonGroups = parser.getComparisonGroups();
        }

        this.setComparison(null);

        // deprecated
        if (this.args) {
            this.Args = {};
            // backward compatibility for GLAD
            Object.keys(this.args).forEach(k => this.Args[ucFirst(k)] = this.args[k]);
        }
        this.ReportModel = this.reportModel;
        this.ComparisonType = this.comparisonType;
    }

    setSelection(selection: IReportModelSelection) {
        this.selection = selection;

        this.refreshSelection();

        return this;
    }

    private refreshSelection() {
        this.selectedRows = this.selectedColumns = [];
        this.selectedCell = this.selectedColumn = this.selectedRow = null;

        if (!this.selection) return;

        if (this.selection.selectedCell) {
            this.selectedCell = new ReportBindingContextCell(this.selection.selectedCell.columnNode, this.selection.selectedCell.row, this, this.parser);
        }
        else if (this.selection.selectedColumn) {
            this.selectedColumns = this.selection.selectedColumns.map(c => new ReportBindingContextColumn(c, this, this.parser));
            this.selectedColumn = this.selectedColumns.filter(c => c.columnNode === this.selection.selectedColumn)[0];

            if (this.comparisonGroup)
                this.isSelectedContext = this.comparisonGroup.path.every(g => g.SystemName === this.selection.selectedColumn.parent.path[g.Level].SystemName);

            this.selectionLabel = this.selectedColumn.columnNode.displayName;
            this.multipleSelection = this.selection.selectedColumns.length > 1;
        }
        else if (this.selection.selectedRow) {
            this.selectedRows = this.selection.selectedRows.map(r => new ReportBindingContextRow(r, this, this.comparisonGroup, this.parser));
            this.selectedRow = this.selectedRows.filter(r => r.reportModelRow == this.selection.selectedRow)[0];
            this.selectionLabel = this.selectedRow.rowDefinition.DisplayName;
            this.multipleSelection = this.selection.selectedRows.length > 1;
        }
    }

    setComparison(comparisonGroup: IReportModelGroupNode) {
        this.comparisonGroup = comparisonGroup;

        this.isFirstComparisonContext = this.comparisonGroups && this.comparisonGroups.length > 0 ? this.comparisonGroups.indexOf(comparisonGroup) === 0 : undefined;

        this.isLastComparisonContext = this.comparisonGroups && this.comparisonGroups.length > 0 ? this.comparisonGroups.indexOf(comparisonGroup) === this.comparisonGroups.length - 1 : undefined;

        this.columnNodes = comparisonGroup
            ? [...this.allColumnNodes.filter(c => c.column.ColumnType == DATA_GRID_COLUMN_TYPE.rowHeader),
                ...this.parser.collectNodes<IReportModelColumnNode>([comparisonGroup], n => n.type == REPORT_MODEL_NODE_TYPE.column)]
            : this.allColumnNodes;

        this.comparisonLabel = comparisonGroup
            ? [...comparisonGroup.path].reverse().map(g => g.DisplayName).join(' ')
            : null;

        this.refreshSelection();

        return this;
    }

    setCell(columnNode: IReportModelColumnNode, row: IReportModelRow, evalCellValue = true) {
        if (columnNode && !row || !columnNode && row)
            throw 'Failed to get the cell context - row or column arguments are missing.';

        // this.setComparison(this.parser.getComparisonGroupByColumnNode(columnNode));

        // todo: use cache/flyweights
        this.column = new ReportBindingContextColumn(columnNode, this, this.parser);
        this.row = new ReportBindingContextRow(row, this, this.parser.getComparisonGroupByColumnNode(columnNode), this.parser);
        this.cell = new ReportBindingContextCell(columnNode, row, this, this.parser);

        this.entity = this.parser.getEntity(row, columnNode.parent);
        if (evalCellValue) this.value = this.parser.getCellValue(columnNode, row);

        // deprecated
        this.Row = row.Row;
        this.RowDefinition = row.RowDefinition;
        this.Column = columnNode.column;
        this.Group = columnNode.parent && columnNode.parent.group;
        this.Entity = this.entity;
        this.GroupPath = columnNode.parent && columnNode.parent.path;
    }

    hasValue(val: any) {
        return val !== null && val !== undefined;
    }

    group(groupBy: string, valueSelector: (group: IReportModelColumnGroup) => string) {
        const groupSystemName = this.column.groupPath[0].SystemName;
        const regex = /\*\*\*([0-9]+)\*\*\*/;
        const match = regex.exec(groupSystemName);
        if (match != null){
            const index = match[1];
            return index === "0" ? "" : index; 
        }

        return "";
    }

    getColumnDefinition(arg: any) {
        const columnDefinition = typeof(arg) == 'string'
            ? this.reportModel.ColumnDefinitions.filter(c => c.SystemName === arg)[0]
            : this.reportModel.ColumnDefinitions[arg];

        if (!columnDefinition) throw `Column ${ arg } not found`;

        return columnDefinition;
    }

    getColumns(...args: any[]) {
        return this.parser.filterColumnNodes(this.columnNodes, args).map(columnNode => {
            return new ReportBindingContextColumn(columnNode, this, this.parser);
        });
    }

    getColumn(arg: any, groupPath?: any[]) {
        const nodes = groupPath
            ? this.columnNodes.filter(n => n.parent && n.parent.path.filter(g => !g.IsComparisonGroup).map(g => g.SystemName).every((g, i) => g == groupPath[i]))
            : this.columnNodes;

        const columnNode = typeof(arg) == 'string'
            ? nodes.filter(n => n.column.SystemName === arg)[0]
            : nodes[arg];

        if (!columnNode) throw `Column ${ arg } not found`;

        return new ReportBindingContextColumn(columnNode, this, this.parser);
    }

    getRows(...args: any[]) {
        return this.parser.filterRows(args).map(row => {
            return new ReportBindingContextRow(row, this, this.comparisonGroup, this.parser);
        });
    }

    getRow(arg: any) {
        const row = typeof(arg) == 'string'
            ? this.reportModel.Rows.filter(r => r.RowDefinition.SystemName === arg)[0]
            : this.reportModel.Rows[arg];

        if (!row) throw `Row ${ arg } not found`;

        return new ReportBindingContextRow(row, this, this.comparisonGroup, this.parser);
    }

    getCell(column: any, row: any) {
        throw 'Not implemented yet!';
    }
}

export class ReportBindingContextColumn {
    columnDefinition: IReportModelColumnDefinition;
    group: IReportModelColumnGroup;
    groupPath: IReportModelColumnGroup[];

    constructor(public columnNode: IReportModelColumnNode,
        private context: ReportBindingContext,
                private parser: ReportModelParser) {
        this.columnDefinition = columnNode.column;

        if (columnNode.parent) {
            this.group = columnNode.parent.group;
            this.groupPath = columnNode.parent.path;
        }
    }

    private filterRows(args: any[]) {
        if (!args.length) return this.context.reportModel.Rows;
        if (typeof(args[0]) == 'function') return this.context.reportModel.Rows.filter(args[0]);
        return filterCollection(this.context.reportModel.Rows, row => row.RowDefinition.SystemName, args);
    }

    getValues(...args: any[]): INamedValue[] {
        return this.filterRows(args).map(row => {
            return {
                value: this.parser.getCellValue(this.columnNode, row),
                systemName: row.RowDefinition.SystemName,
                displayName: row.RowDefinition.DisplayName
            };
        });
    }
}

export class ReportBindingContextRow {
    row: any;
    rowDefinition: IReportModelRowDefinition;
    entity: any;

    constructor(public reportModelRow: IReportModelRow,
        private context: ReportBindingContext,
        private comparisonGroup: IReportModelGroupNode,
                private parser: ReportModelParser) {
        this.row = reportModelRow.Row;
        this.rowDefinition = reportModelRow.RowDefinition;
        this.entity = comparisonGroup
            ? comparisonGroup.path.reduce((groupInstance, group) => groupInstance && groupInstance[group.SystemName], reportModelRow.Row)
            : reportModelRow.Row;
    }

    private filterColumnNodes(args: any[], dataColumnsOnly = false) {
        const columnNodes = dataColumnsOnly
            ? this.context.columnNodes.filter(c => c.column.ColumnType !== DATA_GRID_COLUMN_TYPE.rowHeader)
            : this.context.columnNodes;
        if (!args.length) return columnNodes;
        if (typeof(args[0]) == 'function') return columnNodes.filter(args[0]);
        return filterCollection(columnNodes, columnNode => columnNode.column.SystemName, args);
    }

    getValues(...args: any[]): IColumnNamedValue[] {
        return this.filterColumnNodes(args, true).map(columnNode => {
            return {
                value: this.parser.getCellValue(columnNode, this.reportModelRow),
                systemName: columnNode.systemName,
                displayName: columnNode.displayName,
                columnNode
            };
        });
    }
}

export class ReportBindingContextCell {
    columnDefinition: IReportModelColumnDefinition;
    row: any;
    rowDefinition: IReportModelRowDefinition;

    constructor(private columnNode: IReportModelColumnNode,
        private reportModelRow: IReportModelRow,
        private context: ReportBindingContext,
                private parser: ReportModelParser) {
        this.columnDefinition = columnNode.column;
        this.row = reportModelRow.Row;
        this.rowDefinition = reportModelRow.RowDefinition;
    }

    getValue() {
        return this.parser.getCellValue(this.columnNode, this.reportModelRow);
    }
}