'use strict';

import { IDataGridStateService, IDataGridStateColumn, IDataGridService } from './dataGrid.api';

import { arrayToDictionary, register } from '@systemorph/web';
import { DATA_GRID_EVENT } from './dataGrid.events';
import { IPromise } from 'angular';

// original versions of overwritten ui-grid templates, restored upon scope destroying
var originalViewportTemplate: string;
var originalHeaderCellTemplate: string;
var originalMenuTemplate: string;
var originalMenuItemTemplate: string;


class DataGridService implements IDataGridService {
    private http: ng.IHttpService;
    private templateCache: ng.ITemplateCacheService;
    private uiGridSaveStateService: IDataGridStateService;
    private originalTemplatesSaved = false;

    /*@ngInject*/
    constructor(http: ng.IHttpService, templateCache: ng.ITemplateCacheService, uiGridSaveStateService: IDataGridStateService) {
        this.http = http;
        this.templateCache = templateCache;
        this.uiGridSaveStateService = uiGridSaveStateService;

        this.enableColDefSaveWidthOption();
    }

    setTemplates(): void {
        if (!this.originalTemplatesSaved) {
            originalViewportTemplate = this.templateCache.get<string>('ui-grid/uiGridViewport');
            originalHeaderCellTemplate = this.templateCache.get<string>('ui-grid/uiGridHeaderCell');
            originalMenuTemplate = this.templateCache.get<string>('ui-grid/uiGridMenu');
            originalMenuItemTemplate = this.templateCache.get<string>('ui-grid/uiGridMenuItem');

            this.originalTemplatesSaved = true;
        }

        // restoring "track by $index" overwritten in platform
        this.templateCache.put('ui-grid/uiGridViewport',
            `<div role="rowgroup" class="ui-grid-viewport" ng-style="colContainer.getViewportStyle()">
                    <div class="ui-grid-canvas">
                        <div ng-repeat="(rowRenderIndex, row) in rowContainer.renderedRows track by $index" class="ui-grid-row" ng-style="Viewport.rowStyle(rowRenderIndex)">
                            <div role="row" ui-grid-row="row" row-render-index="rowRenderIndex"></div>
                        </div>
                    </div>
                </div>`
        );

        // extended with expand/collapse button, ng-bind-html for col.displayName
        this.templateCache.put('ui-grid/uiGridHeaderCell',
            `<div role="columnheader" ng-class="{'sortable': sortable, 'has-filter': grid.options.enableFiltering && col.enableFiltering, 'filter-applied': col.filters[0].term}" ui-grid-one-bind-aria-labelledby-grid="col.uid + '-header-text ' + col.uid + '-sortdir-text'" aria-sort="{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}">
                <div role="button" tabindex="0" ui-grid-one-bind-id-grid="col.uid + '-expand-button'" class="ui-grid-column-expand-button" ng-if="col.colDef.isExpandable" ng-click="grid.appScope.dataGrid.toggleColumnExpanded(col.colDef)" uib-tooltip-template="'dataGrid/columnExpandButtonTooltip.html'" tooltip-placement="auto" tooltip-append-to-body="true"><i class="fa" ng-class="{'fa-expand': !col.colDef.isExpanded, 'fa-compress': col.colDef.isExpanded}" aria-hidden="true"></i></div>
                <div role="button" tabindex="0" ui-grid-one-bind-id-grid="col.uid + '-filter-button'" class="ui-grid-column-filter-button" ng-if="grid.options.enableFiltering && col.enableFiltering"><datagrid-multiselect-filter></datagrid-multiselect-filter></div>
                <div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" title="TOOLTIP">
                    <span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + '-header-text'" ng-bind-html="col.colDef.displayNameHtml !== undefined ? col.colDef.displayNameHtml : col.colDef.displayName"></span> 
                </div>
            </div>`
        );

        // using template due to performance reason - this way the expression is evaluated only upon showing the tooltip,
        // otherwise it is evaluated on on every digest - e.g. even when you scroll the grid
        this.templateCache.put('dataGrid/columnExpandButtonTooltip.html', `<span>{{col.grid.appScope.dataGrid.options.getColumnExpandButtonTooltip(col.colDef)}}</span>`);

        // extended with custom columns ordering (showing parents above children)
        this.templateCache.put('ui-grid/uiGridMenu',
            `<div class="ui-grid-menu" ng-if="shown">
                <style ui-grid-style>{{dynamicStyles}}</style>
                <div class="ui-grid-menu-mid" ng-show="shownMid">
                    <div class="ui-grid-menu-inner">
                        <ul role="menu" class="ui-grid-menu-items">
                            <li ng-repeat="item in menuItems | orderBy: 'context.gridCol ? 301 + context.gridCol.colDef.menuSortKey : order'" role="menuitem" 
                                ui-grid-menu-item ui-grid-one-bind-id="'menuitem-'+$index" 
                                action="item.action" 
                                name="item.title" 
                                active="item.active" 
                                icon="item.icon" shown="item.shown" 
                                context="item.context" 
                                template-url="item.templateUrl" 
                                leave-open="item.leaveOpen" 
                                screen-reader-only="item.screenReaderOnly">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>`
        );

        // extended with child columns' padding
        this.templateCache.put('ui-grid/uiGridMenuItem',
            `<button type="button" class="ui-grid-menu-item" ng-attr-style="padding-left:{{8 + context.gridCol.colDef.level * 10}}px;" ng-click="itemAction($event, title)" ng-show="itemShown()" ng-class="{ 'ui-grid-menu-item-active': active(), 'ui-grid-sr-only': (!focus && screenReaderOnly) }" aria-pressed="{{active()}}" tabindex="0" ng-focus="focus=true" ng-blur="focus=false">
                    <i ng-class="icon" aria-hidden="true">&nbsp;</i> {{ name }}
            </button>`
        );
    }

    restoreTemplates(): void {
        this.templateCache.put('ui-grid/uiGridViewport', originalViewportTemplate);
        this.templateCache.put('ui-grid/uiGridHeaderCell', originalHeaderCellTemplate);
        this.templateCache.put('ui-grid/uiGridMenu', originalMenuTemplate);
        this.templateCache.put('ui-grid/uiGridMenuItem', originalMenuItemTemplate);
    }

    // this enables the colDef.saveWidth option, which is not available in ui-grid
    // todo: create reusable method-overwriting util
    enableColDefSaveWidthOption(): void {
        const saveColumnsBase = this.uiGridSaveStateService.saveColumns;
        this.uiGridSaveStateService.saveColumns = grid => {
            const savedCols = <IDataGridStateColumn[]>saveColumnsBase.call(this.uiGridSaveStateService, grid);
            const savedColsByName: { [key: string]: IDataGridStateColumn } = arrayToDictionary(savedCols, c => c.name);
            grid.getOnlyDataColumns().forEach(c => {
                if (c.colDef.saveWidth !== undefined)
                    savedColsByName[c.name].width = c.colDef.saveWidth ? c.width : undefined;
            });
            return savedCols;
        };

        const restoreColumnsBase = this.uiGridSaveStateService.restoreColumns;
        this.uiGridSaveStateService.restoreColumns = (grid, columnsState) => {
            columnsState.forEach(c => {
                const col = grid.getColumn(c.name);
                if (col && col.colDef.saveWidth === false) c.width = col.width;
            });
            restoreColumnsBase.call(this.uiGridSaveStateService, grid, columnsState);
            grid.getOnlyDataColumns().forEach(c => c.hasUserWidth = grid.options.saveWidths && c.colDef.saveWidth !== false && c.hasCustomWidth);
        };
    }
}

register.factory("dataGridService", ($http: ng.IHttpService, $templateCache: ng.ITemplateCacheService, uiGridSaveStateService: IDataGridStateService) => {
    return new DataGridService($http, $templateCache, uiGridSaveStateService);
});

register.decorator('uiGridRowDirective', ($delegate: any) => {
    const directive = $delegate[0];
    const originalCompileFn = directive.compile;
    directive.compile = () => {
        const res = originalCompileFn();
        const originalPostLinkFn = res.post;
        res.post = ($scope: ng.IScope, $elm: ng.IAugmentedJQuery, $attrs: ng.IAttributes, controllers: any) => {
            $scope.$watch('row', (n, o) => {
                if (n !== o) {
                    $scope.$broadcast(DATA_GRID_EVENT.rowChanged);
                }
            });
            originalPostLinkFn($scope, $elm, $attrs, controllers);
        };
        return res;
    };
    return $delegate;
});