'use strict';

import {register} from "@systemorph/web";
import {IScope, IAugmentedJQuery, IAttributes, IFormController, INgModelController} from 'angular';
import {Dictionary, assign, keys, isEmpty, uniq, values, flatten} from 'lodash';

interface IAsyncValidationErrorsScope extends IScope {
    asyncFormCtrl: AsyncFormController;
}

const PENDING_RESOLVED_EVENT = 'asyncForm.pendingResolved';

// this is an IFormController extension to provide support of asynchronous validators
register.directive('asyncForm', () => {
    return {
        require: 'form',
        bindToController: {
            asyncForm: '='
        },
        controller: AsyncFormController,
        controllerAs: 'asyncFormCtrl',
        link: function($scope: IAsyncValidationErrorsScope,
                       $element: IAugmentedJQuery,
                       $attrs: IAttributes,
                       formCtrl: IFormController) {
            $scope.asyncFormCtrl.link(formCtrl);
        }
    }
});

export class AsyncFormController {
    private asyncForm: AsyncFormController;
    private formCtrl: IFormController;

    // mutable errors object, unlike IFormController.$error, remains unchanged until async validation requests
    errors: Dictionary<Dictionary<boolean>>;

    constructor(private $scope: IAsyncValidationErrorsScope) {
    }

    link(formCtrl: IFormController) {
        this.asyncForm = this;
        this.formCtrl = formCtrl;

        // watching async validators
        this.$scope.$watch(() => formCtrl.$pending, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                if (!formCtrl.$pending) {
                    this.refreshErrors();
                    this.$scope.$emit(PENDING_RESOLVED_EVENT);
                }
            }
        });

        // watching sync validators
        this.$scope.$watch(() => formCtrl.$error, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                if (!formCtrl.$pending) {
                    this.refreshErrors();
                }
            }
        }, true);
    }

    // execute action when all async validation requests are completed
    onPendingResolved(handler: () => void) {
        if (!this.formCtrl.$pending) {
            throw 'The form is not in a pending state.';
        }

        const off = this.$scope.$on(PENDING_RESOLVED_EVENT, () => {
            handler();
            off();
        });
    }

    private refreshErrors() {
        const invalidControls: INgModelController[] = uniq(flatten(values(this.formCtrl.$error)));

        if (isEmpty(invalidControls)) {
            this.errors = null;
        }
        else {
            if (!this.errors) {
                this.errors = {};
            }

            const invalidControlNames = invalidControls.filter(c => c.$name).map(c => c.$name);

            keys(this.errors).forEach(k => {
                if (!invalidControlNames.includes(k)) {
                    delete this.errors[k];
                }
            });

            invalidControls
                .filter(c => c.$name)
                .forEach(c => {
                    if (!this.errors[c.$name]) {
                        this.errors[c.$name] = {}
                    }

                    const errors = this.errors[c.$name];

                    keys(errors).forEach(k => delete errors[k]);

                    assign(errors, c.$error);
                });
        }
    }
}

