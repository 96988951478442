'use strict';

import {register, DOM_EVENT, KEY} from "@systemorph/web";
import {BaseCellController} from "../../cells/baseCell";
import {IDataGridCellParams, IDataGridCellScope, IDataGridColumnDef} from "../../dataGrid.api";
import { RichTextColumnDisplay } from './richTextColumnDisplay';
import { IRichTextCellData } from '../columns.api';

register.directive("richTextCell", function($templateCache: ng.ITemplateCacheService) {
    const popoverTemplateUrl = "dataGrid/richTextCellPopover.html";
    $templateCache.put(popoverTemplateUrl, `
        <div ng-mousedown="$event.stopPropagation()" class="rich-text-container">
            <div rich-text-cell-viewer ng-if="!richText.cellData.isEditMode"></div>
            <div rich-text-cell-editor ng-if="richText.cellData.isEditMode"></div>
        </div>    
    `);

    return {
        restrict: "E",
        replace: true,
        template: `
            <span class="rich-text-cell"
                  ng-mouseenter="richText.cellData.mouseOver = true" 
                  ng-mouseleave="richText.cellData.mouseOver = false"
                  uib-popover-template="'${popoverTemplateUrl}'" 
                  popover-placement="auto top"
                  popover-trigger="none" 
                  popover-class="rich-text-cell-popover"
                  popover-is-open="richText.cellData.popoverIsOpen" 
                  popover-append-to-body="true">
                <i class="fa fa-pencil" 
                   ng-show="((richText.cellParams.isEditable || richText.cellData.text) && richText.cellData.mouseOver) || richText.cellData.popoverIsOpen" 
                   ng-mousedown="richText.onIconMousedown($event)"
                   ng-click="richText.openPopover()">
                </i>
                <span class="short-text" ng-bind-html="richText.cellData.shortText"></span>
            </span>
        `,
        controller: RichTextCellController,
        controllerAs: 'richText'
    };
});

export interface IRichTextCellScope extends IDataGridCellScope {
    richText: RichTextCellController;
}

export class RichTextCellController extends BaseCellController<RichTextColumnDisplay, IDataGridCellParams, IRichTextCellData, any, IDataGridColumnDef, IRichTextCellScope> {
    private document: ng.IDocumentService;
    private timeout: ng.ITimeoutService;

    constructor($scope: IRichTextCellScope, $document: ng.IDocumentService, $timeout: ng.ITimeoutService) {
        super($scope);
        this.document = $document;
        this.timeout = $timeout;
    }

    onIconMousedown(event: ng.IAngularEvent) {
        if (this.cellData.popoverIsOpen)
            event.stopPropagation();
    }

    openPopover() {
        this.cellData.isEditMode = !this.cellData.text;
        this.cellData.popoverIsOpen = true;

        const close = () => {
            this.timeout(() => {
                this.closePopover();
            });
        };

        this.document.on(DOM_EVENT.mousedown + ".richTextCell", () => close());
        this.document.on(DOM_EVENT.keydown + ".richTextCell", (event: JQueryKeyEventObject) => {
            if (event.keyCode == KEY.esc) { 
                close();
            }
        });
    }

    closePopover() {
        this.cellData.popoverIsOpen = false;

        this.document.off(DOM_EVENT.mousedown + ".richTextCell");
        this.document.off(DOM_EVENT.keydown + ".richTextCell");
    }

    openEditor() {
        this.cellData.isEditMode = true;
    }

    closeEditor() {
        this.cellData.isEditMode = false;
        if (!this.cellData.text) this.closePopover();
    }
}