'use strict';

import { register, IDimensionNameFilter, STATE} from "@systemorph/web";
import { IDimensionColumnDisplayValue } from './columns.api';
import { BaseColumnDisplay } from './baseColumnDisplay';
import { IDataGridColumnDef, IDataGridOptions } from '../dataGrid.api';

export class DimensionColumnDisplay extends BaseColumnDisplay {
    protected state: ng.ui.IStateService;
    protected filter: ng.IFilterService;

    /*@ngInject*/
    constructor(colDef: IDataGridColumnDef, dataGridOptions: IDataGridOptions, $state: ng.ui.IStateService, $filter: ng.IFilterService) {
        super(colDef, dataGridOptions);
        this.state = $state;
        this.filter = $filter;
    }

    getDisplayValue(entity: any, cellData: any): IDimensionColumnDisplayValue {
        const value = this.getValue(entity);

        if (!value) return;

        return {
            href: this.state.href(STATE.details, { entityId: value.Id }),
            click: () => { this.state.go(STATE.details, { entityId: value.Id })},
            displayName: this.filter<IDimensionNameFilter>('dimensionName')(value, this.colDef.formatString)
        };
    }

    getPdfExportValue(entity: any): any {
        const value = this.getValue(entity);
        return value && this.filter<IDimensionNameFilter>('dimensionName')(value, this.colDef.formatString);
    }

    getCsvExportValue(entity: any): any {
        const value = this.getValue(entity);
        return value && this.filter<IDimensionNameFilter>('dimensionName')(value, this.colDef.formatString);
        //return value.SystemName;
    }

    getDisplayWidth(displayValue: IDimensionColumnDisplayValue) {
        return displayValue && displayValue.displayName ? super.getTextWidth(displayValue.displayName) : 0;
    }

    getTemplate() {
        const displayValuePath = this.getDisplayValuePath();
        return `<span class="editable-dimension-wrapper"><a href="{{${displayValuePath}.href}}" ng-click="${displayValuePath}.click()" ng-bind="${displayValuePath}.displayName"></a></span>`;
    }

    getCellClasses(entity: any, cellData: any, isEditable: boolean) {
        const classes = super.getCellClasses(entity, cellData, isEditable);
        const dimensionClass = "dimension";
        return classes ? `${classes} ${dimensionClass}` : dimensionClass;
    }
}

register.factory("dimensionColumnDisplay", function() {
    return DimensionColumnDisplay;
});