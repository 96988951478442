'use strict';

import {IDataGridCellParams, IDataGridCellScope, IDataGridColumnDef} from "../dataGrid.api";
import { IColumnDisplay } from '../columns/columns.api';

export class BaseCellController<
    TColumnDisplay extends IColumnDisplay,
    TCellParams extends IDataGridCellParams,
    TCellData,
    TDisplayValue,
    TColDef extends IDataGridColumnDef,
    TCellScope extends IDataGridCellScope> {

    protected scope: TCellScope;

    constructor($scope: TCellScope) {
        this.scope = $scope;
    }

    get colDef() {
        return <TColDef>this.scope.col.colDef;
    }

    get displayValue() {
        return <TDisplayValue>this.scope.row.entity.displayValues[this.scope.col.colDef.name];
    }

    get cellData() {
        return <TCellData>this.scope.row.entity.cellDataStore[this.scope.col.colDef.name];
    }

    get cellParams() {
        return <TCellParams>this.scope.row.entity.cellConfig[this.scope.col.colDef.name];
    }

    get columnDisplay() {
        return <TColumnDisplay>this.scope.col.colDef.columnDisplay;
    }

    protected refreshDisplayValue() {
        this.scope.row.entity.displayValues[this.colDef.name] = this.columnDisplay.getDisplayValue(this.scope.row.entity.entity, this.scope.row.entity.cellDataStore[this.colDef.name]);
    }
}