'use strict';

import { register } from "@systemorph/web";

register.decorator('entityGridService', ($delegate: any) => {
    var origRefreshInner = $delegate.refreshInner;
    $delegate.refreshInner = (scope: any, expands: string[], odataSelectProperties: string[], args: any) => {
        if (scope.gridOptions) {
            origRefreshInner.apply($delegate, [scope, expands, odataSelectProperties, args]);
        }
    }
    return $delegate;
});
