'use strict';

import { IAutocompleteBoxItem } from "@systemorph/ui-web";
import { register, ANGULAR_EVENT } from '@systemorph/web';
import { AUDIT_EVENT } from './reportAudit.events';
import { extend } from 'angular';
import { IBranchAuditFormEntity, IBranchAuditProps } from './reportAudit.api';
import { AUDIT_TYPE } from './reportAudit.constants';
import { IReportUtilsService } from '../../reportingWeb.api';
import { IReportAuditScope, ReportAuditController } from './reportAuditDirective';

register.directive("branchAudit", () => {
    return {
        restrict: 'AE',
        replace: true,
        scope: true,
        template: `
        <div>
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <span class="label">Branch 1</span>
                    <autocomplete-box ng-model='branchAudit.auditBranch1'
                                      name="auditBranch1"
                                      autocomplete='branchAudit.auditBranch1Autocomplete'
                                      required
                                      on-change="branchAudit.validate()">
                    </autocomplete-box>
                </div>
                <div class="col-sm-6 col-md-6">
                    <span class="label">Branch 2</span>
                    <autocomplete-box ng-model='branchAudit.auditBranch2'
                                      name="auditBranch2"
                                      autocomplete='branchAudit.auditBranch2Autocomplete'
                                      required
                                      on-change="branchAudit.validate()">
                    </autocomplete-box>
                </div>
            </div>
            <div class="audit-validation" ng-show="branchAudit.isReady && auditForm.$invalid">
                <p>Select two different branches to compare</p>
            </div>
        </div>
        `,
        controller: BranchAuditController,
        controllerAs: 'branchAudit'
    }
});

class BranchAuditController {
    isReady: boolean;
    auditBranch1: string;
    auditBranch2: string;
    auditBranch1Autocomplete: IAutocompleteBoxItem[];
    auditBranch2Autocomplete: IAutocompleteBoxItem[];
    private audit: ReportAuditController;
    private formEntity: IBranchAuditFormEntity;
    private activeBranch: string;

    constructor(private $scope: IReportAuditScope,
        private $q: ng.IQService,
        private reportUtilsService: IReportUtilsService,
    ) {
        this.audit = this.$scope.audit;

        this.formEntity = <IBranchAuditFormEntity> this.audit.formEntity;

        this.audit.isResetEnabled = false;

        const branchesPromise = this.reportUtilsService.getBranches()
            .then(branches => {
                this.auditBranch1Autocomplete = this.auditBranch2Autocomplete = branches.map<IAutocompleteBoxItem>(branch => {
                    return {
                        value: branch,
                        displayName: branch
                    };
                });
            });

        const activeBranchInfoPromise = this.reportUtilsService.getActiveBranchInfo()
            .then((activeBranchInfo) => {
                this.activeBranch = activeBranchInfo.branchName;
            });

        this.$q.all([branchesPromise, activeBranchInfoPromise])
            .finally(() => {
                $scope.$emit(AUDIT_EVENT.childInitialized);
                this.isReady = true;
            });

        $scope.$on(AUDIT_EVENT.updateForm, () => this.updateForm());
        $scope.$on(AUDIT_EVENT.updateFormEntity, (event: ng.IAngularEvent, newValues: IBranchAuditProps) => this.updateFormEntity(newValues));
        $scope.$on(AUDIT_EVENT.reset, () => this.resetForm());
        $scope.$on(AUDIT_EVENT.disable, () => this.disable());

        $scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            if (this.formEntity.auditType === AUDIT_TYPE.branch)
                this.audit.previousProperties = ['auditBranch1', 'auditBranch2'];
        });
    }

    private updateForm() {
        this.auditBranch1 = this.formEntity.auditBranch1;
        this.auditBranch2 = this.formEntity.auditBranch2;
    }

    private resetForm() {
        this.auditBranch1 = this.activeBranch;
        this.auditBranch2 = null;
    }

    private updateFormEntity(newValues: IBranchAuditProps) {
        extend(newValues, {
            auditBranch1: this.auditBranch1,
            auditBranch2: this.auditBranch2
        });
    }

    private disable() {
        this.auditBranch1 = this.auditBranch2 = null;
    }

    private validate() {
        const isValid = this.auditBranch1 && this.auditBranch2 && this.auditBranch1 !== this.auditBranch2;
        this.$scope.auditForm['auditBranch1'].$setValidity('notSameBranch', isValid);
    }
}