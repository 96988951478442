'use strict';

import { register, ISystemorphEntity, USER_PIC_SIZE, STATE } from "@systemorph/web";

interface ISmallUserScope extends ng.IScope {
    url: string;
    imageSize: string;
    searchText: string;
    user: ISystemorphEntity;
}

register.directive("smallUser", () => {
    return {
        replace: true,
        restrict: 'E',
        scope: {
            user: "=",
            searchText: "="
        },
        template: `<div>
                        <user-image image-url="::user.ImageUrl" image-size="::imageSize" user-id="::user.Id" user-name="::user.DisplayName"></user-image>
                        <a href="{{::url}}" class="user-title" ng-bind-html="::(user.DisplayName | highlight:searchText)"></a>
                    </div>`,
        controller: ($scope: ISmallUserScope,  $state: ng.ui.IStateService) => {
            $scope.imageSize = USER_PIC_SIZE.small;
            $scope.url = $state.href(STATE.details, { entityId: $scope.user.Id });
        }
    }
});
