'use strict';

import {register} from "@systemorph/web";
import {IRichTextCellScope, RichTextCellController} from "./richTextCellDirective";

interface IRichTextViewerScope extends IRichTextCellScope {
    richTextViewer: RichTextCellViewerController;
}

register.directive("richTextCellViewer", function() {
    return {
        restrict: "A",
        scope: true,
        template: `
            <div class="rich-text-viewer">
                <div ng-bind-html="richText.cellData.text" class="rt-text"></div>
                <div class="rt-toolbar">
                    <button class="btn btn-primary btn-sm" ng-if="richText.cellParams.isEditable" ng-click="richText.openEditor()">Edit</button>
                </div>
            </div>`,
        link($scope: IRichTextViewerScope, element: ng.IAugmentedJQuery, attributes: ng.IAttributes) {
            $scope.richTextViewer.link(element, attributes);
        },
        controller: RichTextCellViewerController,
        controllerAs: 'richTextViewer'
    }
});

class RichTextCellViewerController {
    private scope: IRichTextViewerScope;
    private richText: RichTextCellController;
    private timeout: ng.ITimeoutService;

    constructor($scope: IRichTextViewerScope, $timeout: ng.ITimeoutService) {
        this.scope = $scope;
        this.richText = $scope.richText;
        this.timeout = $timeout;
    }

    link(element: ng.IAugmentedJQuery, attributes: ng.IAttributes) {
        element.keydown(event => {
            if (event.keyCode == 27) { // ESC
                this.timeout(() => {
                    this.richText.closeEditor();
                })
            }
        });
    }
}