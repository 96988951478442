'use strict';

import { register } from "@systemorph/web";
import * as angular from "angular";
import { TreeUtils } from '../../utils/treeUtils';
import { ITreeItemModel } from './tree.api';

interface ITreeScope extends ng.IScope {
    tree: TreeController;
}

interface ITreeItemScope extends ITreeScope {
    treeItem: TreeItemController;
}

register.directive("tree", () => {
        return {
        restrict: 'AE',
        replace: true,
        scope: true,
        template: `
            <div class="tree" ng-class="{multiselect: tree.multiSelect}">
                <tree-item item="item" ng-repeat="item in tree.items"></tree-item>
            </div>
        `,
        bindToController: {
            items: '=',
            multiSelect: '=?',
            selectAll: '=?',
            expandSelected: '=?',
            onSelect: '&?',
        },
        controller: TreeController,
        controllerAs: 'tree',
    }
});

export class TreeController {
    // bindings
    items: ITreeItemModel[];
    onSelect: any;
    multiSelect: boolean;
    selectAll: boolean; // select/unselect children along with parent
    expandSelected: boolean; // expand tree down to selected nodes initially, true by default

    selectedItem: ITreeItemModel;

    constructor(private $scope: ITreeScope) {
        if (!this.items) throw 'No tree items provided';

        var selectedItems = TreeUtils.flattenTreeNodes(this.items, i => i.children, i => i.isSelected);

        if (!this.multiSelect && selectedItems.length > 1) throw 'Multiple selected tree items are not supported in single selection mode';

        if (!this.multiSelect && selectedItems.length) this.selectedItem = selectedItems[0];

        if (this.expandSelected !== false)
            selectedItems.forEach(i => this.expandTreeToSelectedItem(i));
    }

    private expandTreeToSelectedItem(item: ITreeItemModel) {
        while(item = item.parent)
            item.isExpanded = true;
    }
}

register.directive("treeItem", ($compile: ng.ICompileService) => {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        link: (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
            const template = `
            <div class="tree-item" ng-class="[treeItem.item.class, {selectable: treeItem.item.isSelectable !== false, selected: treeItem.item.isSelected}]">
                <div class="tree-item-name" ng-click="treeItem.onClick()" ng-attr-style="padding-left:{{treeItem.marginLeft}}px;">
                    <i ng-if="::treeItem.item.children.length" 
                       ng-click="treeItem.toggleExpanded();$event.stopPropagation()"
                       class="fa" 
                       ng-class="{'fa-caret-right': !treeItem.item.isExpanded, 'fa-caret-down': treeItem.item.isExpanded}"></i> 
                    <input type="checkbox" 
                           ng-if="::(tree.multiSelect && treeItem.item.isSelectable != false)" 
                           ng-model="treeItem.item.isSelected" 
                           ng-change="treeItem.onSelect()"
                           ng-click="$event.stopPropagation()" />
                    <span>{{treeItem.item.name}}</span>
                </div>
                <div ng-if="treeItem.item.children.length && treeItem.item.isExpanded">
                    <tree-item item="::childItem" level="::treeItem.level + 1" ng-repeat="childItem in treeItem.item.children"></tree-item>
                </div>
            </div>`;
            var newElement = angular.element(template);
            $compile(newElement)(scope);
            element.replaceWith(newElement);
        },
        bindToController: {
            item: '=',
            level: '=?'
        },
        controller: TreeItemController,
        controllerAs: 'treeItem'
    }
});

export class TreeItemController {
    item: ITreeItemModel;
    level: number;

    tree: TreeController;
    marginLeft: number;

    constructor($scope: ITreeItemScope) {
        this.tree = $scope.tree;

        if (!this.level)
            this.level = 0;

        this.marginLeft = this.level * 15;
    }

    toggleExpanded() {
        this.item.isExpanded = !this.item.isExpanded;
    }

    onClick() {
        if (this.item.isSelectable !== false) {
            if (this.tree.multiSelect || !this.item.isSelected) {
                this.item.isSelected = !this.item.isSelected;
                this.onSelect();
            }
        }
        else {
            this.toggleExpanded();
        }
    }

    onSelect() {
        if (this.tree.multiSelect) {
            if (this.item.isSelected) {
                if (this.tree.selectAll && this.item.children)
                    TreeUtils.flattenTreeNodes(this.item.children, x => x.children).forEach(i => i.isSelected = true);
            }
            else {
                if (this.tree.selectAll && this.item.children)
                    TreeUtils.flattenTreeNodes(this.item.children, x => x.children).forEach(i => i.isSelected = false);
            }
        }
        else {
            if (this.tree.selectedItem && this.tree.selectedItem !== this.item)
                this.tree.selectedItem.isSelected = false;
        }

        if (this.tree.onSelect)
            this.tree.onSelect({item: this.item});
    }
}
