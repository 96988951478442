'use strict';

import {register} from "@systemorph/web";
import {IDataGridColumnDef, IDataGridOptions} from "../dataGrid.api";
import { BaseColumnDisplay } from './baseColumnDisplay';
import { DATA_GRID_EDITOR } from '../dataGrid.constants';

export class EnumColumnDisplay extends BaseColumnDisplay {
    /*@ngInject*/
    constructor(colDef: IDataGridColumnDef, dataGridOptions: IDataGridOptions) {
        super(colDef, dataGridOptions);
    }

    getDisplayValue(entity: any, cellData: any): string {
        const value = this.getValue(entity);
        const editorParameters: any = this.getEditorParameters(entity, cellData, DATA_GRID_EDITOR.dropdown);
        const item: any = editorParameters.items.filter((i: any) => i.value === value)[0];
        return item ? item['displayName'] : null;
    }

    getEditorParameters(entity: any, cellData: any, actualEditor: string): any {
        const editorParameters: any = super.getEditorParameters(entity, cellData, actualEditor) || {};
        const columnParameters: any = this.getColumnParameters(entity, cellData);
        if (!columnParameters || !columnParameters.enumItems) throw `No items provided for enum column (${this.colDef.name})`;
        editorParameters.items = columnParameters.enumItems;
        return editorParameters;
    }

    getDisplayWidth(displayValue: string) {
        return displayValue ? super.getTextWidth(displayValue) : 0;
    }

    getCellClasses(entity: any, cellData: any, isEditable: boolean) {
        const baseClasses = super.getCellClasses(entity, cellData, isEditable);
        const myClasses = "enum";
        return baseClasses ? `${baseClasses} ${myClasses}` : myClasses;
    }
}

register.factory("enumColumnDisplay", function() {
    return EnumColumnDisplay;
});