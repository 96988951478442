'use strict';

import { register, IMenuItem } from '@systemorph/web';

register.directive('staticFileLinkForMenu', () => {
    return {
        replace: true,
        restrict: 'E',
        template: require('./staticFileLinkForMenu.html'),
        transclude: true,
        controllerAs: 'ctrl',
        bindToController: {
            menuItem: "=",
            staticFileUrl: "=",
            highlightedText: "=?"
        },
        controller: StaticFileLinkForMenuController
    };
});

class StaticFileLinkForMenuController {
    //binded 
    menuItem: IMenuItem;
    staticFileUrl: string;
    highlightedText: string | null;

    constructor() {  }
}