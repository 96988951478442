'use strict';

import {IFilteredListItemProperty} from './filteredList.api';

export class FilteredListItemProperty implements IFilteredListItemProperty {
    constructor(public systemName: string,
                public displayName: string,
                public highlightSelector: string) {
    }

    getSearchText(item: any) {
        return [item[this.systemName]];
    }
}