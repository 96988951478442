'use strict';

import { 
    register, IMenuItemScope, ANGULAR_EVENT, 
    DOM_EVENT, stripHtml, PERSISTANCE_EVENT, COMMON_EVENT 
} from "@systemorph/web";
import { IBusinessProcessService, INewlyMergedDataModel } from '../businessProcess.api';
import { SERVER_EVENTS } from '../businessProcess.constants';
import { blockUI, ITimeoutService, IAugmentedJQuery, IDocumentService } from "angular";

interface INewDataMenuItemScope extends IMenuItemScope {
    newData: NewDataController;
}

register.directive('newDataMenuItem', () => {
    return {
        restrict: 'E',
        replace: true,
        template: `
            <div click-outside="newData.close()">
                <div class="new-data-button" ng-click="newData.toggleIsOpen()">New Data <i class="fa fa-sort-desc"></i></div>
                <div class="new-data-list" ng-if="newData.isOpen">
                    <h3>New data available</h3>
                    <div class="new-data-item" ng-repeat="item in newData.newData.items">{{item.displayName}} (Rev. {{item.revision}})</div>
                    <div class="new-data-actions" ng-show="newData.newData.canMerge"><button ng-click="newData.merge()" class="btn btn-primary">Merge</button></div>
                </div>
            </div>
        `,
        controller: NewDataController,
        controllerAs: 'newData',
        link: function (scope: INewDataMenuItemScope, element: IAugmentedJQuery) {
            scope.newData.menuItemElement = element.closest('.unv-topbar-item');
            scope.newData.setVisibility();
        }
    };
});

var isVisibleState = false;

class NewDataController {
    isOpen: boolean;
    menuItemElement: JQuery;

    private newData: INewlyMergedDataModel;

    constructor($scope: INewDataMenuItemScope,
                private businessProcessService: IBusinessProcessService,
                private blockUI: blockUI.IBlockUIService,
                $document: IDocumentService,
                private $timeout: ITimeoutService) {

        var onKeydown = (event: any) => {
            if (event.keyCode === 27) this.close();
        };

        $document.keydown(onKeydown);

        $scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            $document.off(DOM_EVENT.keydown, onKeydown);
        });

        const eventsToRefresh = [
            SERVER_EVENTS.processFinished,
            PERSISTANCE_EVENT.backedNotifiedDataVersionWasChanged,
            COMMON_EVENT.dataVersionChanged,
            COMMON_EVENT.persistenceContextChanged,
            COMMON_EVENT.commitDataToServer
        ];
        eventsToRefresh.forEach(eventName => {
            $scope.$on(eventName, () => {
                this.refresh();
            });
        });

        this.refresh();
    }

    toggleIsOpen() {
        this.isOpen = !this.isOpen;
    }

    close() {
        this.isOpen = false;
        this.$timeout();
    }

    merge() {
        this.close();
        this.block();
        var sourceRevision = this.newData.items[0].revision;
        this.businessProcessService.mergeNewData(sourceRevision)
            .finally(() => {
                this.unblock();
            });
    }

    setVisibility(isVisible: boolean = isVisibleState) {
        if (isVisible) this.menuItemElement.show();
        else this.menuItemElement.hide();
        isVisibleState = isVisible;
    }

    private refresh() {
        this.block();
        this.businessProcessService.getNewlyMergedData()
            .then(newData => {
                if (newData.items)
                    newData.items.forEach(i => i.displayName = stripHtml(i.displayName));
                this.newData = newData;
                this.setVisibility(newData && newData.items && newData.items.length > 0);
            })
            .finally(() => {
                this.unblock();
            })
    }

    private block() {
        this.blockUI.start('Loading...');
    }

    private unblock() {
        this.blockUI.stop();
    }
}
