'use strict';

import {register} from '@systemorph/web';
import {identity, flatten} from 'lodash';
import {IPromise, IQService, IScope, ITimeoutService} from 'angular';
import {IFilteredListItem, IFilteredListItemProperty} from './filteredList.api';
import {ISearchBoxContextItem} from '../searchBox/searchBox.api';
import {escapeRegExp} from '../../utils';

interface IFilteredListScope extends IScope {
    listCtrl: FilteredListController
}

register.directive('filteredList', () => {
    return {
        replace: true,
        scope: true,
        transclude: true,
        bindToController: {
            items: '=',
            filteredItems: '=',
            itemProperties: '=',
            onChange: '&',
            onToggleCollapsed: '&'
        },
        template: `
            <div class="position-registry-entry-positions"
                 ng-class="{'is-min-mode': listCtrl.isBriefLayout(),
                            'is-full-mode': listCtrl.isFullLayout(),
                            'is-filtering': listCtrl.isFilteringInProgress
                            }">
                <div class="sm-filter-search__ctr">
                    <search-box ng-model="listCtrl.searchText"
                                ng-model-options="{debounce: 250}"
                                context="listCtrl.searchContext"
                                context-items="listCtrl.searchContextItems"
                                is-case-sensitive="listCtrl.searchCaseSensitive"
                                placeholder="Filter..."
                                ng-change="listCtrl.onSearchTextChange()"
                                on-options-change="listCtrl.onSearchOptionsChange(context, isCaseSensitive)"></search-box>

                    <div class="sm-editor__layout">
                        <span class="sm-editor__layout-label">Expand/Collapse</span>
                        <i class="material-icons sm-editor__layout-icon"
                           ng-click="listCtrl.collapseAll()"
                           ng-class="{'sm-editor__layout-icon--active': listCtrl.isAllCollapsed()}"
                           title="Collapse all">storage</i>
                        <i class="material-icons sm-editor__layout-icon"
                           ng-click="listCtrl.expandAll()"
                           ng-class="{'sm-editor__layout-icon--active': listCtrl.isAllExpanded()}"
                           title="Expand all">dns</i>
                    </div>
                </div>

                <ng-transclude></ng-transclude>

                <div ng-if="listCtrl.filteredItems.length == 0">Nothing to show</div>
            </div>
        `,
        controller: FilteredListController,
        controllerAs: 'listCtrl'
    }
});

class FilteredListController {
    searchText: string;
    searchCaseSensitive: false;
    searchContextItems: ISearchBoxContextItem[];
    searchContext: string[];
    layout: string;
    isFilteringInProgress: boolean;

    // binding
    items: IFilteredListItem[];
    filteredItems: IFilteredListItem[];
    itemProperties: IFilteredListItemProperty[];
    onChange: (locals: {searchText: string, searchContext: string[], searchCaseSensitive: boolean})
        => IPromise<void> | void;
    onToggleCollapsed: (locals: {isCollapsed: boolean}) => void;

    constructor(private $scope: IFilteredListScope,
                private $q: IQService,
                private $timeout: ITimeoutService) {
        this.$scope.$watchCollection(() => this.items, () => {
            this.refreshFilteredItems();
        });

        this.searchContextItems = this.itemProperties.map(p => {
            return {
                systemName: p.systemName,
                displayName: p.displayName
            };
        });

        this.searchContext = this.searchContextItems.map(i => i.systemName);
    }

    isAllCollapsed = () => this.items.every(i => i.isCollapsed);
    isAllExpanded = () => this.items.every(i => !i.isCollapsed);

    collapseAll() {
        this.setAllCollapsed();
    }

    expandAll() {
        this.setAllCollapsed(false);
    }

    setAllCollapsed(isCollapsed = true) {
        this.items.filter(i => i.isCollapsible !== false)
            .forEach(i => i.isCollapsed = isCollapsed);

        if (this.onToggleCollapsed)
            this.onToggleCollapsed({isCollapsed});
    }

    onSearchTextChange() {
        this.refreshFilteredItems();
    }

    onSearchOptionsChange(context: string[], isCaseSensitive: boolean) {
        this.refreshFilteredItems();
    }

    private refreshFilteredItems() {
        this.isFilteringInProgress = true;

        // timeout is needed to digest isFiltering property before doing the actual filtering
        this.$timeout(() => {
            this.filteredItems = this.items.filter(item => {
                return this.isItemMatch(item) || item.isFilterable === false;
            });

            if (this.onChange) {
                this.$q.when(this.onChange({searchText: this.searchText, searchContext: this.searchContext, searchCaseSensitive: this.searchCaseSensitive}))
                    .finally(() => {
                        this.isFilteringInProgress = false;
                    });
            }
        });
    }

    private isItemMatch(item: any) {
        if (!this.searchText)
            return true;

        const textArrays = this.itemProperties
            .filter(p => this.searchContext.indexOf(p.systemName) !== -1)
            .map(p => p.getSearchText(item)).filter(identity);

        const texts = flatten(textArrays);

        const regexp = this.getSearchTextRegExp();

        return texts.some(text => regexp.test(text));
    }

    private getSearchTextRegExp() {
        return RegExp(escapeRegExp(this.searchText), !this.searchCaseSensitive ? 'i' : undefined);
    }
}