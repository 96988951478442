'use strict';
import * as angular from 'angular';
import { IGridConstants } from 'angular-ui-grid';
import IProvideService = angular.auto.IProvideService;

angular.module('systemorph')
    .config(/*@ngInject*/ ($provide: IProvideService) => {
        $provide.decorator('uiGridEditService', function ($delegate: any, uiGridConstants: IGridConstants) {
            const baseMethod = $delegate.isStartEditKey;
            $delegate.isStartEditKey = (e: JQueryKeyEventObject) => {
                if (e.keyCode !== uiGridConstants.keymap.F2 && e.keyCode >= uiGridConstants.keymap.F1 && e.keyCode <= uiGridConstants.keymap.F12)
                    return false;
                return baseMethod(e);

            };
            return $delegate;
        });
    });
