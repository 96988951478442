'use strict';

import { register } from "@systemorph/web";

register.directive("processDocumentationAnnotations", () => {
    return {
        replace: true,
        restrict: 'E',
        scope: true,
        controllerAs: "ctrl",
        bindToController: {
            entityId: "="
        },
        template: require('./processDocumentationAnnotations.html'),
        controller: ProcessDocumentationAnnotationsController
    }
});

class ProcessDocumentationAnnotationsController {
    commentsExpanded: boolean;
    commentsInitialized: boolean;
    attachmentsExpanded: boolean;
    attachmentsInitialized: boolean;

    //bindings
    entityId: string;

    constructor(private $scope: ng.IScope) {
    }

    toggleComments() {
        this.commentsExpanded = !this.commentsExpanded;
        if (this.commentsExpanded)
            this.attachmentsExpanded = false;
        this.commentsInitialized = true;
    }

    toggleAttachments() {
        this.attachmentsExpanded = !this.attachmentsExpanded;
        if (this.attachmentsExpanded)
            this.commentsExpanded = false;
        this.attachmentsInitialized = true;
    }
}