'use strict';

import { 
    register, getOrAddCachedValue, 
    IDataSourceService, IPersisterProviderService, 
    ISystemorphEntity, IPersister, 
    COMMON_EVENT, arrayToDictionary, PERSISTANCE_EVENT 
} from "@systemorph/web";
import { 
    IProcessModel, IProcessOverviewModel, IProcessStateModel, 
    IProcessHistoryModel, IProcessPromotableModel, ICurrentProcessModel, 
    INewlyMergedDataModel, IActiveProcessInfo, IBusinessProcessService 
} from './businessProcess.api';
import { ACTIVITY_TYPE, DEFAULT_BRANCH_INFO, SERVER_EVENTS, CURRENT_PROCESS_STATE } from './businessProcess.constants';
import { Dictionary, isEmpty, uniqBy } from 'lodash';
import {IPromise, ICacheObject, IHttpService, ICacheFactoryService, IScope, auto, cookies, IQService} from "angular";
import {FORM_ENTITY_EVENT, FormEntityProvider, FormEntityRegistry} from "@systemorph/form-entity-web";
import {BUSINESS_PROCESSES_EVENT} from "./businessProcess.events";

export class BusinessProcessService implements IBusinessProcessService {
    private cache: ICacheObject;
    private processStatesCache: Dictionary<IPromise<IProcessStateModel[]>> = {};
    private activeProcessCache: ICacheObject;
    private currentBranch: string;

    /*@ngInject*/
    constructor(private $http: IHttpService,
                $cacheFactory: ICacheFactoryService,
                private $rootScope: IScope,
                private $q: IQService,
                private $cookies: cookies.ICookiesService,
                private formEntityRegistry: FormEntityRegistry,
                private persisterProviderService: IPersisterProviderService,
                private dataSourceService: IDataSourceService) {

        this.cache = $cacheFactory("BusinessProcessService");
        this.activeProcessCache = $cacheFactory("BusinessProcessServiceActiveProcess");

        const eventNamesToKillTheCache = [
            COMMON_EVENT.commitDataToServer,
            COMMON_EVENT.persistenceContextChanged,
            COMMON_EVENT.dataVersionChanged,
            SERVER_EVENTS.processFinished,
            PERSISTANCE_EVENT.backedNotifiedDataVersionWasChanged
        ];

        eventNamesToKillTheCache.forEach(eventName => {
            this.$rootScope.$on(eventName, () => {
                this.cache.removeAll();
                this.processStatesCache = {};
            });
        });

        this.$rootScope.$on(SERVER_EVENTS.processFinished, () => {
            this.getActiveProcessInfo(true)
                .then(info => {
                    // switching to the head revision in case explicit revision was checked out previously
                    // in workbench, otherwise user will stay on a published changeset
                    if (!info.isMaxRevisionProjector)
                        this.dataSourceService.setDataVersion({ branch: info.branchName });

                    if (info.process && info.process.state === CURRENT_PROCESS_STATE.finished)
                        this.dataSourceService.setDataVersion({ branch: DEFAULT_BRANCH_INFO.name });
                });
        });

        this.$rootScope.$on(BUSINESS_PROCESSES_EVENT.currentProcessChanged, (event, waitPromises: IPromise<any>[]) => {
            if (this.formEntityRegistry.isSet()) {
                const promise = this.formEntityRegistry.get()
                    .then(entry => {
                        return this.getActiveProcessInfo(true)
                            .then(activeProcessInfo => {
                                if (activeProcessInfo.process) {
                                    const newValues = this.getFormEntityValues(activeProcessInfo.process);

                                    if (!isEmpty(newValues)) {
                                        return entry.formEntityController.setValues(newValues, false);
                                    }
                                }
                            });
                    });

                waitPromises.push(promise);
            }
        });

        this.$rootScope.$on(FORM_ENTITY_EVENT.rendering, (e, scope: string, provider: FormEntityProvider, oldProvider: FormEntityProvider, waitPromises: IPromise<any>[]) => {
            if (!scope && !oldProvider) {
                const promise = this.getActiveProcessInfo()
                    .then(activeProcessInfo => {
                        if (activeProcessInfo.process) {
                            const newValues = this.getFormEntityValues(activeProcessInfo.process);

                            if (!isEmpty(newValues)) {
                                provider.setValues(newValues);
                            }
                        }
                    });

                waitPromises.push(promise);
            }
        });
    }

    protected getFormEntityValues(process: ICurrentProcessModel) {
        const values: Dictionary<any> = {};

        if (process.year) {
            values['year'] = process.year;
        }

        if (process.period) {
            values['period'] = `${process.periodicity}_${process.period}`;
        }

        return values;
    }

    getProcess(processId: string, withSubModels: boolean = true): IPromise<IProcessModel> {
        return this.$http.get<IProcessModel>("/api/businessProcess/process", { params: { processId, withSubModels } })
            .then(result => {
                return result.data;
            }).catch<IProcessModel>(() => {
                throw `Could not retrieve process id ${ processId || "null" }`;
            });
    }

    getProcesses(skip: number, take: number, isCompleted?: boolean): IPromise<IProcessOverviewModel> {
        return this.$http.get<IProcessOverviewModel>("/api/businessProcess/processes", { params: { skip: skip, take: take, isCompleted: isCompleted } })
            .then(result => {
                result.data.processModels = result.data.processModels || [];
                result.data.totalModelsCount = result.data.totalModelsCount || 0;

                return result.data;
            }).catch<IProcessOverviewModel>(() => {
                throw "Could not retrieve processes";
            });
    }

    getProcessStates(processTrackerId: string) {
        if (!this.processStatesCache[processTrackerId]) {
            this.processStatesCache[processTrackerId] = this.$http.get<IProcessStateModel[]>("/api/businessProcess/ProcessStates", { params: { processTrackerId } })
                .then(result => result.data);
        }
        return this.processStatesCache[processTrackerId];
    }

    getProcessStateCachedAndCalculateCounts(masterProcessName: string, processModel: IProcessModel): IPromise<IProcessStateModel[]> {
        return this.getProcessEntity(masterProcessName).then((processEntity: ISystemorphEntity) => {
            let statesEntities: ISystemorphEntity[] = processModel.label === "SI"
                ? processEntity["SignatureStates"]
                : processModel.label === "GI"
                    ? processEntity["GroupStates"]
                : processEntity["States"];

            return this.getSortedStates(statesEntities, processModel);
        });
    }

    getAllStates(masterProcessName: string, processModel: IProcessModel): IPromise<IProcessStateModel[]> {
        return this.getProcessEntity(masterProcessName).then((processEntity: ISystemorphEntity) => {
            let statesEntities: ISystemorphEntity[] = [...processEntity["States"], ...processEntity["GroupStates"], ...processEntity["SignatureStates"]];

            return this.getSortedStates(statesEntities, processModel);
        });
    }

    private getSortedStates(statesEntities: ISystemorphEntity[], processModel: IProcessModel) {
        statesEntities = uniqBy(statesEntities, "SystemName");

        statesEntities =
            statesEntities.sort(
                (s1: ISystemorphEntity, s2: ISystemorphEntity) => s1["Order"] -
                s2["Order"]);

        const counts = this.calculateProcessCount(processModel);

        return statesEntities.map((e: ISystemorphEntity): IProcessStateModel => {
            return {
                systemName: e.SystemName,
                displayName: e.DisplayName,
                count: counts[e.SystemName] || 0
            }
        });
    }

    private calculateProcessCount(processModel: IProcessModel, startVal?: Dictionary<number>): Dictionary<number> {
        const ret: Dictionary<number> = startVal || {};

        if (processModel.subProcessModels) {
            processModel.subProcessModels.forEach((subProcess: IProcessModel) => {
                this.calculateProcessCount(subProcess, ret);
            });
        } else {
            if (processModel.currentStateSystemName in ret) {
                ret[processModel.currentStateSystemName]++;
            } else {
                ret[processModel.currentStateSystemName] = 1;
            }
        }

        return ret;
    }

    private getProcessEntity(processName: string): IPromise<ISystemorphEntity> {
        return getOrAddCachedValue(this.cache, `processEntity_${ processName }`, () => this.persisterProviderService.getPersister().then((persister: IPersister) => {
            return persister
                .query("ProcessDefinitions")
                .include("SignatureStates")
                .include("GroupStates")
                .include("States")
                .filter(`it.SystemName == '${ processName }'`)
                .toArray(true)
                .then((processes: ISystemorphEntity[]) => {
                    return processes[0];
                });
        }));
    }

    getProcessTrackerHistory(masterProcessName: string, process: IProcessModel): IPromise<IProcessHistoryModel> {
        return this.$http.get<IProcessHistoryModel>("/api/businessProcess/processTrackerHistory", { params: { processTrackerId: process.processTrackerId } })
            .then(result => {
                // result.data.processStates = result.data.processStates || [];
                result.data.historyItems = result.data.historyItems || [];

                return this.getProcessStateCachedAndCalculateCounts(masterProcessName, process)
                    .then(states => {
                        const processStateDictionary = arrayToDictionary(states, state => state.systemName);
                        result.data.historyItems.forEach(h => {
                            if (h.toStateSystemName) {
                                h.toState = processStateDictionary[h.toStateSystemName];
                            }
                            if (h.fromStateSystemName) {
                                h.fromState = processStateDictionary[h.fromStateSystemName];
                            }
                        });
                        return result.data;
                    });
            }).catch<IProcessHistoryModel>(() => {
                throw "Could not retrieve process tracker history";
            });
    }

    getProcessTrackerPromote(processTrackerId: string, processName: string): IPromise<IProcessPromotableModel[]> {
        return this.$http.get<IProcessPromotableModel[]>("/api/businessProcess/processTrackerPromotable", { params: { processTrackerId: processTrackerId, processName: processName } })
            .then(result => {
                return result.data;
            }).catch<IProcessPromotableModel[]>(() => {
                throw "Could not retrieve promotable trackers.";
            });
    }

    getChangeableTracker(processTrackerId: string, fromStateSystemName: string, toStateSystemName: string): IPromise<IProcessModel> {
        const params = { processTrackerId, fromStateSystemName, toStateSystemName };

        return this.$http.get<IProcessModel>("/api/businessProcess/changeableTracker", { params: params })
            .then(result => {
                return result.data;
            }).catch<IProcessModel>(() => {
                throw "Could not load changeable trackers";
            });
    }   

    startMotion(process: IProcessModel, processName: string, promoteModel: IProcessPromotableModel): void {
        const params = {
            displayName: `${ processName } / ${ process.displayName } &mdash; ${ promoteModel.displayName }`,
            systemName: `${ promoteModel.fromSystemName }_to_${ promoteModel.targetStateSystemName }`,
            processName: processName,
            processTrackerId: process.processTrackerId,
            fromStateSystemName: promoteModel.fromSystemName,
            toStateSystemName: promoteModel.targetStateSystemName,
            category: ACTIVITY_TYPE.statusChange
        }
        this.$http.post<boolean>("/api/businessProcess/startPromote", params);
    }

    getProcessDataTypes(process: IProcessModel) {
        const flatProcesses = this.flattenProcess(process);
        const dict = arrayToDictionary(flatProcesses.filter(p => isEmpty(p.subProcessModels)), t => t.systemName);
        return Object.keys(dict).map(systemName => {
            return {
                systemName,
                displayName: dict[systemName].displayName
            }
        })
    }

    applyFiltering(process: IProcessModel, stateFilter?: string) { // what is this?
        const flatProcesses = this.flattenProcess(process);
        flatProcesses.forEach(p => p.subProcessModelsFiltered = null);
        const filteredDataProcesses = flatProcesses
            .filter(p => isEmpty(p.subProcessModels) && (!stateFilter || p.currentStateSystemName === stateFilter));
        const addToParent = (p: IProcessModel) => {
            if (p.parent) {
                if (!p.parent.subProcessModelsFiltered)
                    p.parent.subProcessModelsFiltered = [];
                if (p.parent.subProcessModelsFiltered.indexOf(p) === -1)
                    p.parent.subProcessModelsFiltered.push(p);
                addToParent(p.parent);
            }
        };
        filteredDataProcesses.forEach(p => addToParent(p));
    }

    flattenProcess(process: IProcessModel, result: IProcessModel[] = []) {
        result.push(process);
        if (process.subProcessModels) {
            process.subProcessModels.filter(p => isEmpty(p.subProcessModels)).forEach(p => {
                p.parent = process;
                this.flattenProcess(p, result);
            });
            process.subProcessModels.filter(p => !isEmpty(p.subProcessModels)).forEach(p => {
                p.parent = process;
                this.flattenProcess(p, result);
            });
        }
        return result;
    }

    getCurrentProcesses(): angular.IPromise<ICurrentProcessModel[]> {
        return this.$http.get<ICurrentProcessModel[]>("/api/businessProcess/currentProcesses")
            .then(result => result.data);
    }

    getNewlyMergedData(): angular.IPromise<INewlyMergedDataModel> {
        return this.$http.get<INewlyMergedDataModel>("/api/businessProcess/newlyMergedData")
            .then(result => result.data);
    }

    getActiveProcessInfo(noCache = false) {
        const dataSource = this.dataSourceService.getDataSource();
        const branch = dataSource && dataSource.dataVersion && dataSource.dataVersion.branch;
        if (noCache || this.currentBranch !== branch) this.activeProcessCache.removeAll();
        this.currentBranch = branch;
        return this.$http.get<IActiveProcessInfo>("/api/businessProcess/activeProcessInfo", { cache: this.activeProcessCache })
            .then(result => result.data);
    }

    mergeNewData(sourceRevision: number) {
        return this.getActiveProcessInfo().then(branchInfo => {
            const targetRevision = branchInfo.revision;

            const params = {
                displayName: `Merge New Data`,
                systemName: `MergeNewData-${ sourceRevision }-to-${ targetRevision }`,
                sourceRevision: sourceRevision,
                targetRevision: targetRevision,
                persistenceContext: this.dataSourceService.getDataSource().persistenceContext,
                category: ACTIVITY_TYPE.statusChange
            }
            this.$http.post<boolean>("/api/businessProcess/startMerge", params);
        });
    }

    setActiveProcess(branch: string) {
        this.setDataVersion({ branch });

        const waitPromises: IPromise<any>[] = [];

        this.$rootScope.$broadcast(BUSINESS_PROCESSES_EVENT.currentProcessChanged, waitPromises);

        return this.$q.all(waitPromises).then(() => {
            this.$rootScope.$broadcast(COMMON_EVENT.dataVersionChanged);
        });
    }

    // platform method without firing the event immediately
    private setDataVersion(dataVersion: any) {
        this.$cookies.put('data-Version', JSON.stringify(dataVersion), { path: "/", expires: "Thu, 31 Dec 2037 23:59:59 GMT"  });
    }
}

register.factory('BusinessProcessService', () => BusinessProcessService)
        .service('businessProcessService',  /*@ngInject*/($injector: auto.IInjectorService, BusinessProcessService: Function) => {
            return $injector.instantiate(BusinessProcessService);
        });