'use strict';

import { register, IPropertyOfEntityScope } from '@systemorph/web';

register.directive('prettyBooleanProperty', () => {
    return {
        replace: true,
        restrict: 'E',
        scope: true,
        template: `<i class="pretty-boolean-property" ng-class="::ctrl.cssClass"></a>`,
        controller: Controller,
        controllerAs: 'ctrl'
    }
});

class Controller {
    cssClass: string;
    constructor($scope: IPropertyOfEntityScope) {
        const value: boolean | null = $scope.entity[$scope.propertyLayout.systemName];
        if (value != null) {
            this.cssClass = value
                ? 'glyphicon glyphicon-ok text-success'
                : 'glyphicon glyphicon-remove text-danger';
        }
    }
}