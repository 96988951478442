'use strict';

import * as uiGrid from "angular-ui-grid"
import * as angular from "angular";
import { register, IPersisterProviderService, ILayoutService, ISystemorphEntity } from "@systemorph/web";
import { DropdownDataGridEditorController, IDropdownDataGridEditorScope }
    from "./dropdownDataGridEditor";
import { IDimensionDataGridEditorParameters } from './editor.api';

register.directive("dimensionDataGridEditor", function () {
    return {
        require: 'dimensionDataGridEditor',
        link(scope: IDropdownDataGridEditorScope, element: ng.IAugmentedJQuery, attributes: ng.IAttributes) {
            scope.dropdownEditor.link(element, attributes);
        },
        controller: DimensionDataGridEditorController,
        controllerAs: 'dropdownEditor'
    }
});

export class DimensionDataGridEditorController extends DropdownDataGridEditorController {
    protected persisterProviderService: IPersisterProviderService;
    protected layoutService: ILayoutService;

    constructor($scope: IDropdownDataGridEditorScope, $q: angular.IQService, $document: angular.IDocumentService, $compile: angular.ICompileService, uiGridEditConstants: uiGrid.IGridEditConstants, persisterProviderService: IPersisterProviderService, layoutService: ILayoutService) {
        super($scope, $q, $document, $compile, uiGridEditConstants);
        this.persisterProviderService = persisterProviderService;
        this.layoutService = layoutService;
    }

    protected getItems() {
        const typeName = (<IDimensionDataGridEditorParameters> this.editorParameters).typeName
            || (this.container.columnParameters && this.container.columnParameters.typeName);
        if (!typeName) throw 'No type name provided for dimensionDataGridEditor';

        return this.layoutService.layout(typeName, null).$promise
            .then(typeLayout => {
                return this.persisterProviderService.getPersister()
                    .then(persister => {
                        return persister.query(typeLayout.collectionName).toArray(true)
                            .then(entities => {
                                return entities.map((entity: ISystemorphEntity) => {
                                    return {
                                        value: entity,
                                        displayName: entity.DisplayName
                                    };
                                })
                            });
                    });
            });
    }

    protected getIsMatchPredicate() {
        return (a: ISystemorphEntity, b: ISystemorphEntity) => a.SystemName === b.SystemName;
    }
}