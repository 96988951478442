'use strict';

import {register, IMenuItemScope, IDataSourceService, ANGULAR_EVENT, COMMON_EVENT, DOM_EVENT} from "@systemorph/web";
import IBlockUIService = ng.blockUI.IBlockUIService;
import { IReportUtilsService } from '../reportingWeb.api';

let currentLabel: string = null;
let isChangingBranch = false;

register.directive('branchSelectorMenuItem', () => {
    return {
        restrict: 'E',
        replace: true,
        template: `
            <div click-outside="branchSelector.close()">
                <div class="active-branch" ng-click="branchSelector.toggleIsOpen()">{{branchSelector.label}} <i class="fa fa-sort-desc"></i></div>
                <div class="branches" ng-if="branchSelector.isOpen">
                    <div ng-show="branchSelector.isLoading" class="loading">Loading...</div>
                    <div ng-if="!branchSelector.isLoading">
                        <div ng-show="!branchSelector.branches.length" class="no-processes">No branches</div>
                        <div class="branch" 
                            ng-repeat="branch in branchSelector.branches" 
                            ng-class="{active: branchSelector.isActiveBranch(branch), 'default-branch': branchSelector.isDefaultBranch(branch)}" 
                            ng-click="branchSelector.setActiveBranch(branch)">{{branch}}</div>
                    </div>
                </div>
            </div>
        `,
        controller: BranchSelectorController,
        controllerAs: 'branchSelector'
    };
});

const defaultBranchName = 'Default';

class BranchSelectorController {
    branches: string[];
    label: string;
    isOpen: boolean;
    isLoading: boolean;

    activeBranch: string;

    constructor(private $scope: IMenuItemScope,
                private dataSourceService: IDataSourceService,
                private blockUI: IBlockUIService,
                private $document: ng.IDocumentService,
                private $cookies: any,
                private $location: ng.ILocationService,
                private $rootScope: ng.IRootScopeService,
                private $timeout: ng.ITimeoutService,
                private reportUtilsService: IReportUtilsService) {

        this.refreshActiveBranch()
            .then(() => {
                if (isChangingBranch)
                    this.unblock();
            });


        var onKeydown = (event: any) => {
            if (event.keyCode === 27) this.close();
        };

        $document.keydown(onKeydown);

        $scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            $document.off(DOM_EVENT.keydown, onKeydown);
            currentLabel = this.label;
        });
    }

    toggleIsOpen() {
        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            this.isLoading = true;
            this.loadBranches()
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }

    close() {
        this.isOpen = false;
        this.$timeout();
    }

    setActiveBranch(branch: string) {
        if (!this.isActiveBranch(branch)) {
            isChangingBranch = true;
            this.block();
            this.label = branch;
            this.close();
            // here we have to change the branch name in cookies, navigate to the home page and only then fire the event
            this.setDataVersion({branch});
            // this.$location.path('/');
            // timeout is needed to let the navigation occur before firing the event, otherwise the details page may throw an exception of missing entity
            this.$timeout(() => {
                this.$rootScope.$broadcast( COMMON_EVENT.dataVersionChanged);
            });
            // no need to refresh here since dataSourceService.setDataVersion initiates re-instantiating of this directive
        }
    }

    isActiveBranch(branch: string) {
        return branch === this.activeBranch;
    }

    isDefaultBranch(branch: string) {
        return branch === defaultBranchName;
    }

    // platform method without firing the event immediately
    private setDataVersion(dataVersion: any) {
        this.$cookies.put('data-Version', JSON.stringify(dataVersion), { path: "/", expires: "Thu, 31 Dec 2037 23:59:59 GMT"  });
    }

    private refreshActiveBranch() {
        return this.reportUtilsService.getActiveBranchInfo()
            .then(activeBranchInfo => {
                this.activeBranch = activeBranchInfo.isHeadRevision ? activeBranchInfo.branchName : null;
                this.label = activeBranchInfo.isHeadRevision
                    ? activeBranchInfo.branchName
                    : `${activeBranchInfo.branchName}, rev. ${activeBranchInfo.revision}`;
            });
    }

    private loadBranches() {
        return this.reportUtilsService.getBranches()
            .then(branches => {
                this.branches = branches;
            });
    }

    private block() {
        this.blockUI.start('Loading...');
    }

    private unblock() {
        this.blockUI.stop();
    }

}
