'use strict';

import { register } from "@systemorph/web";
import { IBusinessProcessService, IProcessModel, IProcessStateModel} from "../businessProcess.api";
import { BUSINESS_PROCESS_EVENT } from './businessProcessDetails.events';
import { IScope, ITimeoutService } from "angular";
import { IStateParamsService, IStateService } from "angular-ui-router";

register.directive("hierarchicalBusinessProcessDetails", () => {
    return {
        replace: true,
        scope: true,
        template: require('./hierarchicalBusinessProcessDetails.html'),
        controllerAs: "ctrl",
        controller: HierarchicalBusinessProcessController,
        bindToController: {
            processModel: "=",
            masterProcessName: "=",
            disablePromotion: "="
        }
    }
});

class HierarchicalBusinessProcessController {
    //bindned
    processModel: IProcessModel;
    masterProcessName: string; 
    disablePromotion: boolean;

    processStates: IProcessStateModel[];
    stateFilter: string;

    constructor(private $scope: IScope,
                private $state: IStateService,
                $stateParams: IStateParamsService,
                private $timeout: ITimeoutService,
                private businessProcessService: IBusinessProcessService) {

        this.stateFilter = $stateParams['state'] || 'all';

        this.businessProcessService.getAllStates(this.masterProcessName, this.processModel)
            .then(processStates => {
                this.processStates = [...processStates];
                this.processStates.splice(0, 0, <any>{ systemName: 'all', displayName: "All"});
            });

        this.applyFiltering();
    }

    setStateFilter(state: IProcessStateModel): void {
        this.stateFilter = state.systemName;
        this.$state.go(this.$state.current.name, { state: state.systemName }, { notify: false });
        this.applyFiltering();
        // using timeout to let filtered out items go away on digest so that they don't react on this event
        this.$timeout(() => {
            this.$scope.$broadcast(BUSINESS_PROCESS_EVENT.stateFilterUpdated);
        });
    }

    private applyFiltering() {
        this.businessProcessService.applyFiltering(this.processModel, this.stateFilter !== 'all' ? this.stateFilter : undefined);
    }
}