'use strict';

import { register, IPropertyOfEntityScope, IWorkFlowService, IDataSourceService, IDataSource, ISystemorphEntity, ENTITY_EVENT } from "@systemorph/web";

import "ng-tags-input";
import { IUtilsService } from "@systemorph/ui-web";
import { IPromise, IAngularEvent, IHttpService } from "angular";

register.module("ngTagsInput");

interface IEditTagsScope extends IPropertyOfEntityScope {
}

interface ITag {
    text: string;
}

register.directive("editTagsProperty", () => {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        template: `
            <div>
                <tags-input ng-model="editTags.tags" min-length="2" max-length="50">
                    <auto-complete source="editTags.getAutocomplete($query)" min-length="1"></auto-complete>
                </tags-input>
            </div>`,
        controller: EditTagsController,
        controllerAs: 'editTags'
    }
});

class EditTagsController {
    tags: ITag[];
    private getAllTagsPromise: IPromise<ITag[]>;

    constructor($scope: IEditTagsScope, $http: IHttpService, workFlowService: IWorkFlowService, dataSourceService: IDataSourceService, private utilsService: IUtilsService) {
        this.tags = $scope.entity[$scope.propertyLayout.systemName].map((t: ISystemorphEntity): ITag => {
            return {
                text: t.SystemName
            }
        });

        var dataSource: IDataSource = dataSourceService.getDataSourceFromScope($scope);

        $scope.$on(ENTITY_EVENT.preparePreSavePromises, (e: IAngularEvent, preSavePromises: (IPromise<any> | (() => IPromise<any>))[]) => {
            var promiseFunc: () => IPromise<any> = () => {
                return $http.post<any>(`/api/ProcessDocumentation/UpdateTags`,
                    { entityId: $scope.entity.Id, tags: this.tags.map(t => t.text) },
                    { headers: workFlowService.getHeaders(dataSourceService.getHeaders(dataSource)) })
                    .then((response: { data: string }) => {
                        return response.data;
                    });
            };

            preSavePromises.push(promiseFunc);
        });
    }

    getAutocomplete(query: string) {
        return this.getAllTags()
            .then(tags => tags.filter(t => t.text.indexOf(query) !== -1));
    }

    private getAllTags() {
        if (!this.getAllTagsPromise) {
            this.getAllTagsPromise = this.utilsService.getTypeQuery("ProcessDocumentationTag")
                .then(query => {
                    return query
                        .toArray(true)
                        .then(entities => {
                            return entities.map(e => {
                                return {
                                    text: e.DisplayName
                                }
                            })
                        });
                });
        }
        return this.getAllTagsPromise;
    }
}