'use strict';

import { register } from "@systemorph/web";
import {
    IFormEntityPropertyScope, FormEntityService,
    defaultAutocompleteFormEntityPropertyControllerAs,
    defaultAutocompleteFormEntityPropertyTempplate,
    BaseIndependentDimensionAutocompleteFormEntityPropertyController
} from '@systemorph/form-entity-web';
import { IAutocompleteBoxItem } from "@systemorph/ui-web";
import { InterfaceVersionFileSelectService } from '../interfaceFile';
import { IPromise } from "angular";

register.directive('fileVersionFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: FileVersionFormEntityPropertyController
    };
});

class FileVersionFormEntityPropertyController extends BaseIndependentDimensionAutocompleteFormEntityPropertyController {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                interfaceVersionFileSelectService: InterfaceVersionFileSelectService) {

        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {

            const provider = this.$scope.entity.__provider;

            const queryModel = provider.getFilterObject();
            queryModel.interface = provider.name;
            delete(queryModel.version);

            return interfaceVersionFileSelectService.getFileVersions(provider.name, queryModel).then((versions: number[]) => {
                return versions.map(x => ({ value: x, displayName: x.toString() }));
            });
        };

        super($scope, formEntityService, getAutocompleteFunc, true);
    }
}