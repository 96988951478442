'use strict';

import { register, IDataSourceService, STATE } from "@systemorph/web";
import {IBusinessProcessService, IProcessModel} from "../businessProcess.api";
import { BUSINESS_PROCESS_STATE } from '../businessProcess.constants';
import { ILocationService } from "angular";
import { IStateService } from "angular-ui-router";

register.directive("processListItem", () => {
    return {
        replace: true,
        restrict: 'E',
        template: require('./processListItem.html'),
        bindToController: {
            process: "="
        },
        scope: true,
        controllerAs: "processCtrl",
        controller: ProcessListItemController
    }
});

class ProcessListItemController {
    process: IProcessModel;

    constructor(private $state: IStateService, 
                private $location: ILocationService,
                private businessProcessService: IBusinessProcessService) {
    }

    openDetails() {
        if (this.process.isFinished || !this.process.processBranchName) {
            //state.go has issue with browser hsitory
            //https://github.com/ionic-team/ionic/issues/1287
            var href = this.$state.href(BUSINESS_PROCESS_STATE.businessProcessDetails, { processId: this.process.businessProcessId, processName: this.process.systemName });
            this.$location.url(href);       
        } else {
            this.businessProcessService.setActiveProcess(this.process.processBranchName)
                .then(() => {
                    this.$state.go(STATE.home);
                });
        }
    }
}