'use strict';

import { register, IDateTimeService, getOrAddCachedValue } from "@systemorph/web";
import { IReleaseVersion, IReleaseVersionService} from "./releaseNotes.api";
import { IPromise, IHttpService, ICacheFactoryService, ICacheObject } from 'angular';

export class ReleaseVersionService implements IReleaseVersionService {
    private currentReleaseVersionUrl = '/dist/release.txt';
    private cache: ICacheObject;

    constructor(private $http: IHttpService,
                private $cacheFactory: ICacheFactoryService,
                private dateTimeService: IDateTimeService) {
        this.cache = this.$cacheFactory("releaseVersionService");
    }

    getCurrentReleaseVersion = (): IPromise<IReleaseVersion | null> => {
        return getOrAddCachedValue(this.cache, 'currentReleaseVersion', () => {
            return this.$http.get(this.currentReleaseVersionUrl)
                .then((response: { data: string }) => {
                    const ret = this.parseReleaseText(response.data);
                    if (!ret.buildNumber)
                        return null;
                    return ret;
                })
                .catch(() => {
                    return <IReleaseVersion>null;
                })
        })
    }

    private parseReleaseText = (data: string): IReleaseVersion => {
        const result: IReleaseVersion = {
            date: this.dateTimeService.now(),
            release: "Latest",
            buildNumber: "",
            commit: "",
            owner: ""
        };

        var strArr = data.split(/\r?\n/);
        strArr.forEach((s: string) => {
            var keyValueStr = s.split(/:(.+)/, 2);
            if (keyValueStr.length == 2) {
                var keyStr: string = <string>keyValueStr[0].trim();
                var valStr: string = <string>keyValueStr[1].trim();
                switch (keyStr) {
                case 'BuildNumber':
                    result.buildNumber = valStr;
                    break;
                case 'ReleaseName':
                    result.release = valStr;
                    break;
                case 'Commit':
                    result.commit = valStr;
                    break;
                case 'Requested':
                    result.owner = valStr;
                    break;
                case 'ReleaseTime':
                    result.date = this.dateTimeService.parse(valStr);
                    break;
                default:
                }
            }
        });

        return result;
    }
}

register.service('releaseVersionService', ReleaseVersionService);
