'use strict';
import { IScope, IPromise } from 'angular';
import { IReportingNodeService, IReportingNodeTreeModel } from "../../reportingWeb.api";
import { ITreeItemModel, TreeUtils } from "@systemorph/ui-web";
import { register } from "@systemorph/web";

register.directive('restrictNodes', () => ({
    restrict: 'E',
    scope: true,
    replace: true,
    template: require('./restrictNodes.html'),
    controllerAs: "ctrl",
    controller: RestrictNodesController
}));

interface Restrictions {
    restrictToNodeNames: string[];
    hierarchy: string;
}

//TODO after this is merged to master, pay attention to the index.ts file in same directory. It must export this file.
export class RestrictNodesController {
    treeItems: ITreeItemModel[];
    errorMessage: string;
    allExpanded: boolean;
    allSelected: boolean;

    private treeItemsFlatten: ITreeItemModel[];

    restrictions: Restrictions;
    /*@ngInject*/
    constructor($scope: IScope, protected reportingNodeService: IReportingNodeService) {
        this.restrictions = $scope.entity;

        this.refreshTreeItems()
            .then(() => {
                this.setAllSelected();
            });
    }

    private refreshTreeItems(): IPromise<void> {
        var restrictNodes: string[] = this.restrictions.restrictToNodeNames;
        return this.reportingNodeService.getReportingNodeTreesCached(this.restrictions.hierarchy)
            .then(reportingNodeTrees => {
                this.treeItems = TreeUtils.getTreeFromAnotherTree(reportingNodeTrees,
                    r => r.children,
                    (r, children) => {
                        const newNode: ITreeItemModel = { 
                            children: children, 
                            isSelected: restrictNodes == null || restrictNodes.indexOf(r.systemName) !== -1,
                            ...this.getTreeItemFromReportingNode(r) 
                        };
                        children.forEach(c => {
                            c.parent = newNode;
                        });
                        return newNode;
                    });

                if (this.treeItems.length == 1) {
                    this.treeItems[0].isExpanded = true;
                }

                this.treeItemsFlatten = TreeUtils.flattenTreeNodes(this.treeItems, i => i.children);
            });
    }

    protected getTreeItemFromReportingNode(reportingNode: IReportingNodeTreeModel): ITreeItemModel {
        return {
            name: this.reportingNodeService.reportingNodeToString(reportingNode),
            data: reportingNode
        };
    }

    onItemSelected(): void {
        this.errorMessage = null;
        this.setAllSelected();
        this.updateRestrictions();
    }

    expandAll(expand = true): void {
        this.treeItemsFlatten.forEach(i => i.isExpanded = expand);
        this.allExpanded = expand;
        this.updateRestrictions();
    }

    selectAll(): void {
        this.errorMessage = null;
        this.treeItemsFlatten.forEach(i => i.isSelected = this.allSelected);
        this.updateRestrictions();
    }

    protected setAllSelected(): void {
        this.allSelected = this.treeItemsFlatten.every(i => i.isSelected);
        this.updateRestrictions();
    }

    protected updateRestrictions(): () => void {
        var allItems = TreeUtils.flattenTreeNodes(this.treeItems, i => i.children);
        var selectedItems = allItems.filter(i => i.isSelected);
        if (!selectedItems.length) {
            this.errorMessage = "Select reporting nodes from the list";
            return;
        }

        this.restrictions.restrictToNodeNames = allItems.length == selectedItems.length ? null : selectedItems.map(n => n.data.systemName);
    }
}