'use strict';

import { register } from "@systemorph/web";
import { ISelectableProcessModel, IProcessModel } from "../businessProcess.api";

register.directive("parentProcessTracker", () => {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        template: require('./parentProcessTracker.html'),
        bindToController: {
            process: '=',
            isSelectable: '=',
            masterProcessName: '=',
            disablePromotion: '=',
            selectChanged: '&?',
            expandChanged: '&?'
        },
        controllerAs: "ctrl",
        controller: ParentProcessTrackerController
    }
});

class ParentProcessTrackerController {
    //bindings
    process: ISelectableProcessModel;
    masterProcessName: string;
    disablePromotion: boolean;
    isSelectable: boolean;
    isExpanded: boolean;
    selectChanged: (args: {process: ISelectableProcessModel}) => void;
    expandChanged: () => void;

    constructor() {
    }

    toggleExpand() {
        this.process.isExpanded = !this.process.isExpanded;
        if (this.expandChanged) {
            this.expandChanged();
        }
    }

    onSelectChanged(process: IProcessModel) {
        if (this.selectChanged) {
            this.selectChanged({ process });
        }
    }
}