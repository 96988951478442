'use strict';

import { register, USER_PIC_SIZE } from "@systemorph/web";
import { IBusinessProcessService, ISelectableProcessModel, IProcessHistoryModel } from "../businessProcess.api";
import { ITemplateCacheService } from "angular";

const templateKey = require.resolve('./processTrackerHistoryPopover.html');
register.directive("childProcessTracker", ($templateCache: ITemplateCacheService) => {
    $templateCache.put(templateKey, require('./processTrackerHistoryPopover.html'));

    return {
        restrict: 'E',
        replace: true,
        scope: true,
        template: require('./childProcessTracker.html'),
        bindToController: {
            process: '=',
            masterProcessName: '=',
            disablePromotion: '=',
            sign: '=',
            isSelectable: '=',
            externalSelectChanged: '&?selectChanged'
        },
        controllerAs: "ctrl",
        controller: ChildProcessTrackerController
    }
});

class ChildProcessTrackerController {

    //bindings
    process: ISelectableProcessModel;
    masterProcessName: string;
    disablePromotion: boolean;
    sign: string;
    isSelectable: boolean;
    externalSelectChanged: (args: {process: ISelectableProcessModel}) => void;

    //locals
    isHistoryOpen: boolean;
    historyModel: IProcessHistoryModel;
    userImageSize: string;
    popoverTemplateUrl = templateKey;

    constructor(private businessProcessService: IBusinessProcessService) {
        this.userImageSize = USER_PIC_SIZE.small;
        this.isHistoryOpen = false;
        this.historyModel = null;
    }

    openHistory() {
        this.historyModel = null;
        this.isHistoryOpen = true;
        this.businessProcessService.getProcessTrackerHistory(this.masterProcessName, this.process).then((historyModel: IProcessHistoryModel) => {
            this.historyModel = historyModel;
        });
    }

    toggleIsSelected() {
        if (this.isSelectable) {
            this.process.isSelected = !this.process.isSelected;
            this.onSelectChanged();
        }
    }

    onSelectChanged() {
        if (this.externalSelectChanged) {
            this.externalSelectChanged({process: this.process});
        }
    }
}