'use strict';
import * as moment from "moment";
import { register, ANGULAR_EVENT } from '@systemorph/web';
import { IAutocompleteBoxItem } from '@systemorph/ui-web';
import { equals, extend, element } from 'angular';
import { ReportAuditController, IReportAuditScope } from './reportAuditDirective';
import { ITimeAuditFormEntity, ITimeAuditProps } from './reportAudit.api';
import { AUDIT_EVENT } from './reportAudit.events';
import { AUDIT_TYPE, AUDIT_TIME } from './reportAudit.constants';

register.directive("timeAudit", () => {
    return {
        restrict: 'AE',
        replace: true,
        scope: true,
        template: `
        <div>
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <span class="label">From</span>
                    <autocomplete-box ng-model='timeAudit.auditFrom' name="auditFrom" autocomplete='timeAudit.auditFromAutocomplete' on-change="timeAudit.onFromChanged()"></autocomplete-box>
                    <div class="relative-position" ng-if="timeAudit.isFromCustomEnabled">
                        <div class='input-group'>
                            <input type='text'
                                   class="form-control"
                                   ng-model="timeAudit.auditFromCustom"
                                   format="{{timeAudit.dateFormat}}"
                                   name="auditFromCustom"
                                   date-time
                                   view="date"/>
                            <span class="input-group-btn">
                                <button type="button" ng-click="timeAudit.openFromCustom()" class="btn btn-default">
                                    <i class="glyphicon glyphicon-calendar"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6">
                    <span class="label">To</span>
                    <autocomplete-box ng-model='timeAudit.auditTo' name="auditTo" autocomplete='timeAudit.auditToAutocomplete' on-change="timeAudit.onToChanged()"></autocomplete-box>
                    <div class="relative-position" ng-if="timeAudit.isToCustomEnabled">
                        <div class='input-group'>
                            <input type='text'
                                   class="form-control"
                                   ng-model="timeAudit.auditToCustom"
                                   format="{{timeAudit.dateFormat}}"
                                   name="auditToCustom"
                                   date-time
                                   view="date"/>
                            <span class="input-group-btn">
                                <button type="button" ng-click="timeAudit.openToCustom()" class="btn btn-default">
                                    <i class="glyphicon glyphicon-calendar"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="audit-validation" ng-show="auditForm.$invalid">
                <p>Incorrect date range</p>
            </div>
        </div>
        `,
        controller: TimeAuditController,
        controllerAs: 'timeAudit'
    }
});

const defaultAuditFrom = AUDIT_TIME.dayAgo;
const defaultAuditTo = AUDIT_TIME.now;

class TimeAuditController {
    audit: ReportAuditController;
    formEntity: ITimeAuditFormEntity;

    auditFromAutocomplete: IAutocompleteBoxItem[];
    auditFrom: string;
    auditFromCustom: Date;
    auditToAutocomplete: IAutocompleteBoxItem[];
    auditTo: string;
    auditToCustom: Date;
    isFromCustomEnabled: boolean;
    isToCustomEnabled: boolean;

    dateFormat = 'MMM d, y H:mm';

    constructor(private $scope: IReportAuditScope,
                private $element: ng.IAugmentedJQuery,
                ) {

        this.audit = this.$scope.audit;
        this.formEntity = <ITimeAuditFormEntity>this.audit.formEntity;

        this.auditFromAutocomplete = this.formEntity.auditFromAutocomplete.map<IAutocompleteBoxItem>(item => {
            return {
                value: item.SystemName,
                displayName: item.DisplayName
            };
        });

        this.auditToAutocomplete = this.formEntity.auditToAutocomplete.map<IAutocompleteBoxItem>(item => {
            return {
                value: item.SystemName,
                displayName: item.DisplayName
            };
        });

        this.$scope.$watchGroup([() => this.auditFrom, () => this.auditTo, () => this.auditFromCustom, () => this.auditToCustom], (newValues, oldValues) => {
            if (equals(newValues, oldValues)) return;

            const customSettingsApplied = this.formEntity.auditType && (this.formEntity.auditFrom !== defaultAuditFrom || this.formEntity.auditTo !== defaultAuditTo);

            this.audit.isResetEnabled = this.auditFrom !== defaultAuditFrom || this.auditTo !== defaultAuditTo || customSettingsApplied;
            this.audit.shouldApplyOnReset = customSettingsApplied;

            this.validate();
        });

        $scope.$on(AUDIT_EVENT.updateForm, () => this.updateForm());
        $scope.$on(AUDIT_EVENT.updateFormEntity, (event: ng.IAngularEvent, newValues: ITimeAuditProps) => this.updateFormEntity(newValues));
        $scope.$on(AUDIT_EVENT.reset, () => this.resetForm());
        $scope.$on(AUDIT_EVENT.disable, () => this.disable());

        $scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            if (this.formEntity.auditType === AUDIT_TYPE.time)
                this.audit.previousProperties = ['auditFrom', 'auditTo', 'auditFromCustom', 'auditToCustom'];
        });

        $scope.$emit(AUDIT_EVENT.childInitialized);
    }

    private updateForm() {
        this.auditFrom = this.formEntity.auditFrom;
        this.auditFromCustom = this.formEntity.auditFromCustom;
        this.auditTo = this.formEntity.auditTo;
        this.auditToCustom = this.formEntity.auditToCustom;
        this.isFromCustomEnabled = this.auditFrom === AUDIT_TIME.custom;
        this.isToCustomEnabled = this.auditTo === AUDIT_TIME.custom;
    }

    private resetForm() {
        this.auditFrom = defaultAuditFrom;
        this.auditTo = defaultAuditTo;
        this.auditFromCustom = null;
        this.auditToCustom = null;
        this.isFromCustomEnabled = false;
        this.isToCustomEnabled = false;
    }

    private disable() {
        this.auditFrom = this.auditFromCustom = this.auditTo = this.auditToCustom = null;
    }

    private validate() {
        const from = this.auditFrom === AUDIT_TIME.custom ? moment(this.auditFromCustom) : this.getMoment(this.auditFrom);
        const to = this.auditTo === AUDIT_TIME.custom ? moment(this.auditToCustom) : this.getMoment(this.auditTo);
        this.$scope.auditForm['auditFrom'].$setValidity('dateRange', from.isBefore(to));
    }

    private updateFormEntity(newValues: ITimeAuditProps) {
        extend(newValues, {
            auditFrom: this.auditFrom,
            auditFromCustom: this.auditFromCustom,
            auditTo: this.auditTo,
            auditToCustom: this.auditToCustom
        });
    }

    onFromChanged() {
        this.isFromCustomEnabled = this.auditFrom === AUDIT_TIME.custom;
        this.auditFromCustom = this.isFromCustomEnabled ? new Date() : null;
    }

    onToChanged() {
        this.isToCustomEnabled = this.auditTo === AUDIT_TIME.custom;
        this.auditToCustom = this.isToCustomEnabled ? new Date() : null;
    }

    openFromCustom() {
        setTimeout(() => {
            element(this.$element.find('input[name=auditFromCustom]')[0]).focus();
            element(this.$element.find('input[name=auditFromCustom]')[0]).trigger('click');
        }, 0);
    }

    openToCustom() {
        setTimeout(() => {
            element(this.$element.find('input[name=auditToCustom]')[0]).focus();
            element(this.$element.find('input[name=auditToCustom]')[0]).trigger('click');
        }, 0);
    }

    private getMoment(value: string) {
        if (value === AUDIT_TIME.now) return moment();
        if (value === AUDIT_TIME.dayAgo) return moment().add(-1, 'd');
        if (value === AUDIT_TIME.twoDaysAgo) return moment().add(-2, 'd');
    }
}