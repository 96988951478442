'use strict';

import { register } from '@systemorph/web';
import * as angular from 'angular';

register.directive('notEmpty', () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope: angular.IScope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ngModelController: angular.INgModelController) {
            ngModelController.$validators['notEmpty'] = (modelValue: any, viewValue: string) => {
                return !!(viewValue && viewValue.trim());
            };
        }
    };
});