'use strict';

import * as angular from "angular";
import {
    IPersisterProviderService, ICreateEntityService, ILayoutService,
    IMessageService, COMMON_EVENT, IEntityCreationArgs, IEntityAccessRightsService,
    IConfirmationOptions, IConfirmationService, IEntityService,  
    ISystemorphEntity, ITypeLayout, IPersister, IPropertyLayout, PROPERTY_TYPE_META, DETAILS_MODE, ANGULAR_EVENT, ENTITY_EVENT,
    arrayToDictionary, getOrAddCachedValue, PERSISTANCE_EVENT
} from "@systemorph/web";
import {FORM_ENTITY, FormEntityRegistry} from "@systemorph/form-entity-web";
import { TreeUtils } from "@systemorph/ui-web";
import { 
    IReportHierarchyItem, REPORT_STATE, IReportingNodeTreeModel,
    IReportHierarchyService, IReportingNodeService
} from "@systemorph/reporting-web";
import { Dictionary, groupBy } from 'lodash';
import { IProcessDocumentationExportModel, IProcessDocumentationService, IProcessDocumentationItem, IProcessDocumentationFormEntity, IProcessDocumentationQAndA, IProcessDocumentationChangeStatusOption, IProcessDocumentationItemGrouping } from './processDocumentation.api';


export class ProcessDocumentationService implements IProcessDocumentationService {
    private cache: ng.ICacheObject;
    private replyDisplayContext: string;
    private reportDisplayContext: string;
    private overviewDisplayContext: string;

    /*@ngInject*/
    constructor(protected $http: ng.IHttpService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private $rootScope: ng.IScope,
                private $uibModal: ng.ui.bootstrap.IModalService,
                $cacheFactory: ng.ICacheFactoryService,
                protected formEntityRegistry: FormEntityRegistry,
                private persisterProviderService: IPersisterProviderService,
                private createEntityService: ICreateEntityService,
                private messageService: IMessageService,
                private layoutService: ILayoutService,
                private entityAccessRightsService: IEntityAccessRightsService,
                private confirmationService: IConfirmationService,
                private entityService: IEntityService,
                private reportHierarchyService: IReportHierarchyService,
                private blockUI: ng.blockUI.IBlockUIService,
                private reportingNodeService: IReportingNodeService) {

        this.cache = $cacheFactory("processDocumentation");
        this.replyDisplayContext = "ReplyDisplayContext";
        this.reportDisplayContext = "ReportDisplayContext";
        this.overviewDisplayContext = "OverviewDisplayContext";

        this.$rootScope.$on(COMMON_EVENT.commitDataToServer, (e: ng.IAngularEvent) => {
            this.cache.removeAll();
        });
        this.$rootScope.$on(COMMON_EVENT.persistenceContextChanged, (e: ng.IAngularEvent) => {
            this.cache.removeAll();
        });
        this.$rootScope.$on(COMMON_EVENT.dataVersionChanged, (e: ng.IAngularEvent) => {
            this.cache.removeAll();
        });
        this.$rootScope.$on(PERSISTANCE_EVENT.backedNotifiedDataVersionWasChanged, (e: ng.IAngularEvent) => {
            this.cache.removeAll();
        });
    }
    
    categories = {
        comments: "comments",
        qAndAs: "qAndAs",
        supportingMaterials: "supportingMaterials"
    }

    getProcessDocumentationItems(typeName: string, reportType?: string, skip?: number, take?: number): ng.IPromise<IProcessDocumentationItem[]> {
        return this.$q.all([this.formEntityRegistry.getFormEntity(), this.formEntityRegistry.getFilterObjectMainPropertiesOnly()]).then((formEntityResults: any[]) => {
            const formEntity: IProcessDocumentationFormEntity = formEntityResults[0];
            const filterObject: any = formEntityResults[1];

            const cacheKeyValues: any[] = [typeName, reportType, skip, take].concat(Object.keys(filterObject).map((k: string) => filterObject[k] || '_'));

            const cacheKey = cacheKeyValues.map(v => v || '').join("_");
            const displayContext = !!reportType ? this.reportDisplayContext : this.overviewDisplayContext;

            return getOrAddCachedValue(this.cache, cacheKey, () => {
                return this.$q.all([
                    this.layoutService.layout(typeName, displayContext).$promise,
                        this.persisterProviderService.getPersister(),
                        this.reportHierarchyService.getReportsHierarchy()
                    ])
                    .then((results: any[]) => {
                        const layout: ITypeLayout = results[0];
                        const persister: IPersister = results[1];
                        const reports: IReportHierarchyItem[] = results[2];
                        const reportsDictionary: Dictionary<IReportHierarchyItem> = arrayToDictionary(TreeUtils.flattenTreeNodes(reports, r => r.children).filter(x => x.isReportDefinition), r => r.reportType);

                        const expandProperties = layout.propertyLayouts
                                                .filter(p => (p.propertyTypeMeta === PROPERTY_TYPE_META.dimension ||
                                                              p.propertyTypeMeta === PROPERTY_TYPE_META.listDimension) && p.isVisible)
                                                .map(p => p.systemName);

                        let query = persister.query(layout.collectionName);

                        if (reportType) {
                            query = query.filter(`it.ReportType == '${reportType}'`);
                        }
                        expandProperties.forEach(p => {
                            query = query.include(p);
                        });

                        //NOTE sort is done client side because jaydata loose order-operators ordering. 
                        // I.e. statement .orderBy("it.p1").orderBy("it.p2") can be transformed by jaydata as .../$orderby=p2,p1 or .../$orderby=p1,p2 (sequence is not derimined)
                        layout.propertyLayouts
                            .filter((pl: IPropertyLayout, i: number) => pl.sortPriority !== null && pl.isSortable)
                            .sort((pl1: IPropertyLayout, pl2: IPropertyLayout) => pl2.sortPriority - pl1.sortPriority)
                            .forEach((pl: IPropertyLayout, i: number) => {
                                const propertyTypeMeta = pl.propertyTypeMeta;

                                const orderFunc: string = propertyTypeMeta === PROPERTY_TYPE_META.primitive
                                    ? "it." + pl.systemName
                                    : propertyTypeMeta === PROPERTY_TYPE_META.dimension || propertyTypeMeta === PROPERTY_TYPE_META.fact
                                        ? "it." + pl.systemName + "." + (pl.sortingSubProperty || "DisplayName")
                                        : null;

                                if (!orderFunc)
                                    throw new Error("Sorting by the given property type meta is not possible : " + propertyTypeMeta);

                                query = pl.isDecending
                                    ? query.orderByDescending(orderFunc)
                                    : query.orderBy(orderFunc);
                            });

                        query = query.withQueryContext("pdAggregated", filterObject);
                        if (skip) {
                            query = query.skip(skip);
                        }
                        if (take) {
                            query = query.skip(take);
                        }

                        return this.$q.all([
                                <angular.IPromise<IProcessDocumentationItem[]>>query.toArray(true),
                                this.reportingNodeService.getReportingNodeTreesCached(formEntity.hierarchy)
                            ])
                            .then(([items, reportingNodeTrees]) => {
                                const reportingNodeTreeDictionary: Dictionary<IReportingNodeTreeModel> = arrayToDictionary(TreeUtils.flattenTreeNodes(reportingNodeTrees, r => r.children), r => r.id);

                                return items.length === 0
                                    ? this.$q.when([])
                                    : this.$http.post<string>(`/api/ProcessDocumentation/ReportingNodesByResultsKey`, { resultsKeyIds: items.map(x => x.ResultsKey_S)}, {}).then(
                                        result => {
                                            const resultsKeyDictionary: Dictionary<string> = JSON.parse(result.data);

                                            items.forEach(p => {
                                                p.reportingNode = reportingNodeTreeDictionary[resultsKeyDictionary[p.ResultsKey_S]];
                                                if (p.ReportType) {
                                                    p.report = reportsDictionary[p.ReportType];
                                                    const reportStateParams: any = {
                                                        reportType: p.ReportType,
                                                        reportingNode: p.reportingNode.systemName
                                                    };
                                                    p.reportUrl = this.$state.href(REPORT_STATE.report, reportStateParams);
                                                }
                                            });

                                            return items;

                                        });
                            });
                    });
            });
        });
    }

    canCreate(typeName: string, reportType?: string): ng.IPromise<boolean> {
        const displayContext = !!reportType ? this.reportDisplayContext : this.overviewDisplayContext;

        return this.$q.all([this.getEditableResultsKeyId(), this.layoutService.layout(typeName, displayContext).$promise, this.reportHierarchyService.getEditableReportsHierarchy()])
            .then(([resultsKeyId, layout, editableReports]) => {
                const reportsDictionary: Dictionary<IReportHierarchyItem> = arrayToDictionary(TreeUtils.flattenTreeNodes(editableReports, r => r.children).filter(x => x.isReportDefinition), r => r.reportType);

                return layout.isCreatable && resultsKeyId != null && (!reportType || !!reportsDictionary[reportType]);
            });
    }

    isResponseEmpty(item: IProcessDocumentationQAndA): boolean {
        return !item.Answer || !$(item.Answer).text().replace(/\s/g, "").length && item.Answer.match(/<img\W/g) == null;
    }

    canAnswerQuestion(item: IProcessDocumentationQAndA, responderId: string): boolean {
        return this.canEdit(item) && (!item.RespondedById || item.RespondedById === responderId);
    }

    canEdit(item: IProcessDocumentationItem): boolean {
        return this.entityAccessRightsService.canEdit(item);
    }

    canDelete(item: IProcessDocumentationItem): boolean {
        return this.entityAccessRightsService.canDelete(item);
    }
    
    delete(item: IProcessDocumentationItem): ng.IPromise<any> {
        const options: IConfirmationOptions = {
            title: "<p>You are about to delete a record. Do you want to continue?</p>",
            template: "<div></div>",
            okLabel: '<span class="glyphicon glyphicon-remove-circle"></span> Yes, delete it',
            cancelLabel: 'No, keep it',
            okCssClass: 'btn-danger'
        };

        return this.confirmationService.open(options).then((result: any) => {
            this.blockUI.start("Deleting...");
            this.entityService.deleteEntity(item.Id).then(() => {
                this.$rootScope.$broadcast(COMMON_EVENT.commitDataToServer);
                this.blockUI.stop();
            });
        });
    }

    changeStatus(id: string, newStatus: string): ng.IPromise<any> {
        return this.$http.post<string>(`/api/ProcessDocumentation/ChangeStatus`, { id: id, newStatus: newStatus }, {});
    }

    getChangeStatusOptions(id: string): ng.IPromise<IProcessDocumentationChangeStatusOption[]> {
        return this.$http.get<IProcessDocumentationChangeStatusOption[]>(`/api/ProcessDocumentation/ChangeStatusOptions`, { params: { id: id} })
            .then((response: { data: IProcessDocumentationChangeStatusOption[] }) => {
                return response.data;
            });
    }

    edit(item: IProcessDocumentationItem, typeName: string, reportType?: string): ng.IPromise<any> {
        const displayContext = !!reportType ? this.reportDisplayContext : this.overviewDisplayContext;

        return this.openEditDialog(item, typeName, displayContext);
    }

    answerQuestion(item: IProcessDocumentationQAndA, typeName: string, responderId: string, reportType?: string): ng.IPromise<any> {
        const originalValue: string = item.RespondedById;
        return this.openEditDialog(item, typeName, this.replyDisplayContext, (item: IProcessDocumentationQAndA) => {
            item.RespondedById = responderId;
        }, "Answer").catch(() => {
            item.RespondedById = originalValue;
        });
    }

    private openEditDialog(item: IProcessDocumentationItem, typeName: string, displayContext: string, changeItemTracked?: (item: IProcessDocumentationItem) => void, dialogTitle?: string): ng.IPromise<any> {
        this.blockUI.start("Loading...");

        return this.$q.all([this.layoutService.layout(typeName, displayContext).$promise, this.persisterProviderService.getPersister()]).then((results: any[]) => {
            const layout: ITypeLayout = results[0];
            const persister: IPersister = results[1];
            persister.purge();
            const entitySet: any = (<any>persister).getEntitySet(item.getType());
            entitySet.attach(item);
            if (changeItemTracked) {
                changeItemTracked(item);
            }

            this.blockUI.stop();

            const settings: ng.ui.bootstrap.IModalSettings = {
                template: `<div class="modal-content-inner">
                                <entity entity="ctrl.entity"
                                        type-layout="ctrl.typeLayout"
                                        mode="ctrl.mode"
                                        display-context="ctrl.displayContext"
                                        max-level="0"
                                        current-level="0"
                                        class="modal-entity"
                                        override-title="ctrl.dialogTitle"
                                        is-modal-content="true"
                                        should-set-page-title="false">
                                </entity>
                           </div>`,
                controller: EditEntityModalController,
                size: 'lg',
                windowClass: 'lg-modal',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    typeLayout: () => {
                        return layout;
                    },
                    mode: () => {
                        return DETAILS_MODE.edit;
                    },
                    displayContext: () => {
                        return displayContext;
                    },
                    dialogTitle: () => {
                        return dialogTitle || `Edit ${layout.displayName}`;
                    },
                    entity: () => {
                        return item;
                    }
                }
            }

            const modalInstance = this.$uibModal.open(settings);

            return modalInstance.result.finally(() => {
                entitySet.detach(item);
            });
        });
    }

    toggleGroupExpand(group: IProcessDocumentationItemGrouping): void {
        group.isCollapsed = !group.isCollapsed;
        this.refreshVisibility(group);
    }

    refreshVisibility(group: IProcessDocumentationItemGrouping): void {
        group.children.forEach(cg => {
            cg.isVisible = group.isVisible && !group.isCollapsed;
            this.refreshVisibility(cg);
        });
    }

    groupByProperty(typeName: string, propertyName: string, items: IProcessDocumentationItem[]): ng.IPromise<IProcessDocumentationItemGrouping[]> {
        return this.layoutService.layout(typeName, this.overviewDisplayContext).$promise.then(
            (layout: ITypeLayout) => {
                const propertyLayout: IPropertyLayout = layout.propertyLayouts.filter(pl => pl.systemName === propertyName)[0];
                switch (propertyName) {
                    case "ResultsKey":
                        return this.groupByReportingNode(items);
                    case "ReportType":
                        return this.groupByReportType(items);
                    default:
                }

                if (!propertyLayout)
                    return this.groupByNone(items);

                switch (propertyLayout.propertyTypeMeta) {
                    case PROPERTY_TYPE_META.primitive:
                        return this.groupByPrimitiveProperty(propertyName, "<em>None</em>", items);
                    case PROPERTY_TYPE_META.dimension:
                        return this.groupByEntityProperty(propertyName, "<em>None</em>", items);
                    case PROPERTY_TYPE_META.listDimension:
                        return this.groupByListEntityProperty(propertyName, "<em>No tag</em>", items);
                    default:
                        return this.groupByNone(items);
                }
            });
    }

    groupByNone(items: IProcessDocumentationItem[]): IProcessDocumentationItemGrouping[] {
        if (!items.length)
            return [];

        const group: IProcessDocumentationItemGrouping = {
            key: null,
            displayName: null,
            items: items,
            cssClass: "pd-no-group",
            isCollapsed: false,
            isVisible: true,
            children: []
        };

        return [group];
    }

    private groupByPrimitiveProperty(propertyName: string, emptyDisplayName: string, items: IProcessDocumentationItem[]): IProcessDocumentationItemGrouping[] {
        const groupsDictionary = items.reduce((result: Dictionary<IProcessDocumentationItemGrouping>, item: IProcessDocumentationItem) => {
            const key: string = (item[propertyName] || '').toString();

            if (key in result) {
                result[key].items.push(item);
            } else {
                const newGroup: IProcessDocumentationItemGrouping = {
                    key: key || "emptyKey",
                    displayName: key || emptyDisplayName,
                    items: [item],
                    cssClass: "pd-group non-hierarchical-pd-group",
                    isCollapsed: false,
                    isVisible: true,
                    children: []
                }
                result[key] = newGroup;
            }
            return result;
        }, <Dictionary<IProcessDocumentationItemGrouping>>{});

        return Object.keys(groupsDictionary).map(k => groupsDictionary[k]).sort((g1: IProcessDocumentationItemGrouping, g2: IProcessDocumentationItemGrouping) => {
            return g1.displayName.localeCompare(g2.displayName);
        });
    }

    private groupByEntityProperty(propertyName: string, emptyModel: string, items: IProcessDocumentationItem[]): IProcessDocumentationItemGrouping[] {
        const groupsDictionary = items.reduce((result: Dictionary<IProcessDocumentationItemGrouping>, item: IProcessDocumentationItem) => {
            const propertyValue: ISystemorphEntity = item[propertyName];
            const id = propertyValue ? propertyValue.Id : "";

            if (id in result) {
                result[id].items.push(item);
            } else {
                const newGroup: IProcessDocumentationItemGrouping = {
                    key: id || "emptyId",
                    displayName: (propertyValue || <any>{}).DisplayName || emptyModel,
                    items: [item],
                    cssClass: "pd-group non-hierarchical-pd-group",
                    isCollapsed: false,
                    isVisible: true,
                    children: []
                }
                result[id] = newGroup;
            }
            return result;
        }, <Dictionary<IProcessDocumentationItemGrouping>>{});

        return Object.keys(groupsDictionary).map(k => groupsDictionary[k]).sort((g1: IProcessDocumentationItemGrouping, g2: IProcessDocumentationItemGrouping) => {
            return g1.displayName.localeCompare(g2.displayName);
        });
    }

    private groupByReportingNode(items: IProcessDocumentationItem[]): ng.IPromise<IProcessDocumentationItemGrouping[]> {
        return this.formEntityRegistry.getFormEntity().then((formEntity: IProcessDocumentationFormEntity) => {
            return this.reportingNodeService.getReportingNodeTreesCached(formEntity.hierarchy)
                .then(reportiNodeTrees => {
                    
                    const pdItemsGrouped = groupBy(items, x => x.reportingNode.id);
                    const pdGroupingTree = TreeUtils.getTreeFromAnotherTree<IReportingNodeTreeModel, IProcessDocumentationItemGrouping>(reportiNodeTrees, 
                                                     r => r.children,
                                                     (reportingNode, children, level) => {
                                                         const pdItems = pdItemsGrouped[reportingNode.id];
                                                         if (!pdItems && children.length === 0 ) {
                                                             return null;
                                                         } 

                                                         return {
                                                            key: reportingNode,
                                                            displayName: this.reportingNodeService.reportingNodeToString(reportingNode),
                                                            items: pdItems,
                                                            cssClass: `pd-group level-${level}`,
                                                            isCollapsed: false,
                                                            isVisible: true,
                                                            children: children
                                                        }
                                                     });
                    return TreeUtils.flattenTreeNodes(pdGroupingTree, i => i.children);
                });
        });
    }

    private groupByListEntityProperty(propertyName: string, emptyDisplayName: string, items: IProcessDocumentationItem[]): IProcessDocumentationItemGrouping[] {
        const emptyKey: string = "__emptyKey";
        
        const groupsDictionary = items.reduce((result: Dictionary<IProcessDocumentationItemGrouping>, item: IProcessDocumentationItem) => {
            const entities: ISystemorphEntity[] = item[propertyName];

            if (!entities || !entities.length) {
                if (emptyKey in result) {
                    result[emptyKey].items.push(item);
                } else {
                    const newGroup: IProcessDocumentationItemGrouping = {
                        key: emptyKey,
                        displayName: emptyDisplayName,
                        items: [item],
                        cssClass: "pd-group non-hierarchical-pd-group",
                        isCollapsed: false,
                        isVisible: true,
                        children: []
                    }
                    result[emptyKey] = newGroup;
                }
            } else {
                result = entities.reduce((subResult: Dictionary<IProcessDocumentationItemGrouping>, entity: ISystemorphEntity) => {
                    const key = entity.Id;

                    if (key in subResult) {
                        subResult[key].items.push(item);
                    } else {
                        const newGroup: IProcessDocumentationItemGrouping = {
                            displayName: entity.DisplayName,
                            key: key,
                            items: [item],
                            cssClass: "pd-group non-hierarchical-pd-group",
                            isCollapsed: false,
                            isVisible: true,
                            children: []
                        }
                        subResult[key] = newGroup;
                    }
                    return subResult;
                }, result);
            }

            return result;
        }, <Dictionary<IProcessDocumentationItemGrouping>>{});

        return Object.keys(groupsDictionary).map(k => groupsDictionary[k]).sort((g1: IProcessDocumentationItemGrouping, g2: IProcessDocumentationItemGrouping) => {
            return g1.displayName.localeCompare(g2.displayName);
        });
    }

    private groupByReportType(items: IProcessDocumentationItem[]): ng.IPromise<IProcessDocumentationItemGrouping[]> {
        return this.reportHierarchyService.getReportsHierarchy().then((reports: IReportHierarchyItem[]) => {
            
            const pdItemsGrouped = groupBy(items.filter(i => !!i.report), x => x.report.id);
            const pdGroupingTree = TreeUtils.getTreeFromAnotherTree<IReportHierarchyItem, IProcessDocumentationItemGrouping>(reports,
                        x => x.children,
                        (report, children, level): IProcessDocumentationItemGrouping | null => {
                            const pdItems = pdItemsGrouped[report.id];
                            if (!pdItems && children.length === 0 ) {
                                return null;
                            }
                            return {
                                key: report,
                                displayName: report.name,
                                items: pdItems,
                                cssClass: `pd-group level-${level}`,
                                isCollapsed: false,
                                isVisible: true,
                                children: children
                            }
                        });

            const pdItemsWithoutReport = items.filter(i => !i.report);
            if (!pdItemsWithoutReport.length) 
                return TreeUtils.flattenTreeNodes(pdGroupingTree, i => i.children);

            // adding items with missing reports, but with ReprotType set.
            const pdItemsRawGroupedWithNullReport = groupBy(pdItemsWithoutReport, x => x.ReportType);
            const pdGroupingTreeForDetachedReports: IProcessDocumentationItemGrouping = {
                cssClass: "pd-group level-0",
                key: "__detachedReportGroup",
                displayName: "<em>Deleted or missing reports</em>",
                items: null,
                isCollapsed: false,
                isVisible: true,
                children: Object.keys(pdItemsRawGroupedWithNullReport)
                    .map(reprotType => ({
                        cssClass: "pd-group level-1",
                        key: reprotType,
                        displayName: reprotType,
                        items: pdItemsRawGroupedWithNullReport[reprotType],
                        isCollapsed: false,
                        isVisible: true,
                        children: []
                    }))
            } 
            return TreeUtils.flattenTreeNodes([...pdGroupingTree, pdGroupingTreeForDetachedReports], i => i.children);
        });
    }

    private searchablePropertyKey = "searchableProperty";

    search(text: string, typeName: string, items: IProcessDocumentationItem[]): ng.IPromise<IProcessDocumentationItem[]> {
        return this.$q.all([
            this.layoutService.layout(typeName, this.overviewDisplayContext).$promise,
            this.formEntityRegistry.getFormEntity()])
            .then(([layout, formEntity]) => {

                const restrictors: ((it: IProcessDocumentationItem) => boolean)[] = [];
                layout.propertyLayouts.forEach(pl => {
                    switch (pl.systemName) {
                        case "Deadline":
                            if (formEntity.deadlineFrom) {
                                restrictors.push(it => it[pl.systemName] >= formEntity.deadlineFrom);
                            }
                            if (formEntity.deadlineTo) {
                                restrictors.push(it => it[pl.systemName] <= formEntity.deadlineTo);
                            }
                            break;
                        case "Status":
                            if (formEntity.status && formEntity.status.Id) {
                                restrictors.push(it => it[pl.systemName].SystemName === formEntity.status.SystemName);
                            }
                            break;
                        case "Priority":
                            if (formEntity.priority && formEntity.priority.Id) {
                                restrictors.push(it => it[pl.systemName].SystemName === formEntity.priority.SystemName);
                            } 
                            break;
                        default:
                            break;
                    }
                });

                if (formEntity.tag && formEntity.tag.Id) {
                    restrictors.push(it => it.Tags.some((sub: ISystemorphEntity) => sub.SystemName === formEntity.tag.SystemName));
                }

                if (text && text.length) {
                    const textToLower = text.toLowerCase();
                    const searchPropertyFunctions: ((it: IProcessDocumentationItem) => string)[] = layout.propertyLayouts
                        .filter(pl => !!pl.displayDirectiveParameters[this.searchablePropertyKey])
                        .map(pl => {
                            if (pl.propertyTypeMeta === PROPERTY_TYPE_META.dimension) {
                                return (it: IProcessDocumentationItem) => (<ISystemorphEntity>(it[pl.systemName] || <any>{})).DisplayName;
                            } else {
                                return (it: IProcessDocumentationItem) => it[pl.systemName];
                            }
                        });

                    restrictors.push(x => searchPropertyFunctions.some(f => (f(x) || '').toLowerCase().indexOf(textToLower) !== -1));
                }

                return items.filter(x => {
                    return restrictors.every(r => r(x));
                });
            });
    }

    openProcessDocumentationCreationDialog(typeName: string, reportType?: string): ng.IPromise<ISystemorphEntity> {
        return this.getEditableResultsKeyId().then((resultsKeyId: string) => {
            if (!resultsKeyId) {
                this.messageService.alertError("Results key not found.");
                return null;
            }

            const displayContext = !!reportType ? this.reportDisplayContext : this.overviewDisplayContext;

            const createArgs: IEntityCreationArgs = {
                typeName: typeName,
                additionalPreChanges: (e: ISystemorphEntity) => {
                    e["ResultsKey_S"] = resultsKeyId;
                    e["ReportType"] = reportType;
                },
                displayContext: displayContext
            }

            return this.createEntityService.create(createArgs);
        });
    }

    export(models: IProcessDocumentationExportModel[]): ng.IPromise<any> {
        return this.formEntityRegistry.getFilterObject().then((filterObject: any) => {
            const data = {
                data: models,
                formEntity: filterObject,
                providerName: filterObject[FORM_ENTITY.providerKey]
            };

            return this.$http.post<any>(`/api/ProcessDocumentation/Export`, data, {responseType: 'arraybuffer'}).then(response => {
                const blob = new Blob([response.data], { type: response.headers('Content-Type') });
                const contentDispositionHeader = response.headers('Content-Disposition');
                const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                const fileName = result.replace(/"/g, '');

                if (window.navigator.msSaveOrOpenBlob) { // For IE:
                    navigator.msSaveBlob(blob, fileName);
                } else { // For other browsers:
                    const link: any = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                }
            });
        });
    }

    private getEditableResultsKeyId(): ng.IPromise<string> {
        return this.formEntityRegistry.getFilterObjectMainPropertiesOnly().then((filterObject: any) => {
            var cacheKey = "resultsKeyId" + Object.keys(filterObject).map((k: string) => `${k}:${filterObject[k] || ''}`).join("_");

            return getOrAddCachedValue(this.cache, cacheKey, () => {
                return this.$http.get<string>(`/api/ProcessDocumentation/EditableResultsKeyId`, { params: filterObject })
                    .then(response => {
                        return response.data;
                    });
            });
        });
    }
}

interface IEditEntityModalScope extends ng.IScope {
    ctrl: EditEntityModalController;
}

class EditEntityModalController {
    /*@ngInject*/
    constructor($scope: IEditEntityModalScope,
                $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
                public typeLayout: ITypeLayout,
                public mode: string,
                public displayContext: string,
                public dialogTitle: string,
                public entity: ISystemorphEntity) {

        $scope.ctrl = this;

        $scope.$on(ENTITY_EVENT.afterEntitySaving, (e: ng.IAngularEvent) => {
            $uibModalInstance.close(entity);
        });

        $scope.$on( ENTITY_EVENT.cancellingEntityEdit, (e: ng.IAngularEvent) => {
            $uibModalInstance.dismiss('cancel');
        });

        $scope.$on(ANGULAR_EVENT.stateChangeStart, (event: ng.IAngularEvent, toState: ng.ui.IState, toParams: ng.ui.IStateParamsService, fromState: ng.ui.IState, fromParams: ng.ui.IStateParamsService) => {
            $uibModalInstance.dismiss('cancel');
        });
    }
}

angular.module('systemorph')
    .factory('ProcessDocumentationService', () => ProcessDocumentationService)
    .service('processDocumentationService', /*@ngInject*/ ($injector: ng.auto.IInjectorService, ProcessDocumentationService: Function) => {
        return $injector.instantiate(ProcessDocumentationService);
    });