'use strict';
import {IScope, IPromise, IHttpService} from 'angular';
import {register} from "@systemorph/web";
import {map, includes, remove} from 'lodash';

register.directive('selectInterCompanyNode', () => ({
    restrict: 'E',
    scope: true,
    template:
        `
        <div class="inter-company-nodes">
             <div ng-repeat="item in InterCompanyNode.items" ng-click="item.onClick()" class="inter-company-node">
                       <input type="checkbox" 
                              ng-model="item.isSelected" 
                              ng-change="item.onSelect()"
                              ng-click="$event.stopPropagation()" />
                       <span class="name">{{item.name}}</span>
            </div>
        </div>
        `,
    controllerAs: "InterCompanyNode",
    controller: Controller
}));

interface DataNode {
    id: string;
    displayName: string;
    systemName: string;
}

interface ItemModel {
    name: string;
    data?: DataNode;
}

class Controller {
    items: ItemModel[];
    errorMessage: string;

    /*@ngInject*/
    constructor(private $scope: IScope, private $http: IHttpService) {
        const container: string[] = $scope.entity.interCompanyNodeIds = [];
        this.$http.get(`/api/intercompanyNode/interCompanyNodes`).then(result => result.data)
            .then((nodes: DataNode[]) => {
                this.items = map(nodes, node => {
                    return {
                        name: `${node.displayName} (${node.systemName})`,
                        data: node,
                        onSelect: () => {
                            if (includes(container, node.id)) {
                                remove(container, node.id)
                            } else {
                                container.push(node.id);
                            }
                        }
                    }
                });
            });
    }
}