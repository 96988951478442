'use strict';

import { register } from "@systemorph/web";
import { IAutocompleteBoxItem } from '@systemorph/ui-web';
import {
    BaseAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyTempplate,
    defaultAutocompleteFormEntityPropertyControllerAs,
    IFormEntityPropertyScope, FormEntityService
} from "@systemorph/form-entity-web";
import { IPromise } from "angular";
import { IReportUtilsService } from '../../../reportingWeb.api';

register.directive('yearFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: YearFormEntityPropertyController
    };
});

export abstract class BaseYearFormEntityPropertyController extends BaseAutocompleteFormEntityPropertyController<number, number> {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                getAutocompleteFunc: () => IPromise<IAutocompleteBoxItem[]>,
                updateAutocompleteIfSomethingChanged: boolean) {
        super($scope, formEntityService, getAutocompleteFunc, updateAutocompleteIfSomethingChanged);
    }

    protected getUrlOrOldValue(): number {
        return parseInt(this.entity.getStateParamsOrCurrentValue(this.propertyLayout));
    }

    protected getIndexFromAutocomplete(value: number, autocomplete: IAutocompleteBoxItem[]): number {
        return autocomplete.findIndex(x => x.value === value);
    }

    protected getDefaultNotFoundValue(): number {
        return NaN;
    }
}

class YearFormEntityPropertyController extends BaseYearFormEntityPropertyController {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                reportUtilsService: IReportUtilsService) {
        const getAutocompleteFunc = () => {
            const periodicity: string[] = $scope.typeLayout.editDirectiveParameters['periodicity'];
            return reportUtilsService.getYears(periodicity)
                .then(years => years.map(y => ({value: y, displayName: y.toString()})));
        };

        super($scope, formEntityService, getAutocompleteFunc, true);
    }
}
