'use strict';

import { register, STATE } from "@systemorph/web";
import { REPORT_STATE, ReportController } from "@systemorph/reporting-web";

export class BaloiseReportController extends ReportController {
    protected getOptions() {
        let options = super.getOptions();

        options.getExportName = () => {
            if (this.reportModel) {
                const { DisplayName, formEntity } = this.reportModel;
                return this.$q.when(`${DisplayName}_${formEntity.hierarchy}_${formEntity.reportingNode.systemName}`);
            }
            else {
                return options.getExportName();
            }
        }

        return options;
    }
}

register.state({
    name: REPORT_STATE.report,
    parent: STATE.defaultLayout,
    url: '/report/:reportType?menu&mode',
    template: require('@systemorph/reporting-web/app/report/pages/report.html'),
    controllerAs: 'report',
    controller: BaloiseReportController,
    suppressReloadMessage: true
});