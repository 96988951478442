'use strict';

import {ISystemorphEntity} from "@systemorph/web";
import {IStateParamsService} from 'angular-ui-router';

export interface IPosition extends ISystemorphEntity {
    PositionType: string;
    DisplayOrder: number;
    Formula: string;
    Applicability?: string;
    Type?: string;
    CacheBehavior?: string;
}

export interface IPositionRegistryEntryModel {
    positionName: string;
    positionType: string;
    positionDataType: string;
    editablePositionTypeName: string;
    editablePositionIsCreatable: boolean;
    editableDataPositionTypeName: string;
    editableDataPositionIsCreatable: boolean;

    editablePositionTypeWithApplicability: boolean;
    editablePositionTypeWithType: boolean;
    editablePositionTypeWithCacheBehavior: boolean;

    editableDataPositionTypeWithApplicability: boolean;
    editableDataPositionTypeWithType: boolean;
    editableDataPositionTypeWithCacheBehavior: boolean;
}

export interface IPositionModel {
    systemName: string;
    displayName: string;
    type: string;
    cacheBehavior: string;
    isEditable: boolean;
    isDeletable: boolean;
    formulas: IPositionModelFormula[];
}

export interface IPositionModelFormula {
    positionEntity?: IPosition;
    applicability: string;
    code: string;
    displayOrder: number;
    isDeletable: boolean;
    applicabilityHtml?: string;
    codeHtml?: string;
}

export interface IPositionRegistryEntryStateParams extends IStateParamsService {
    filterTerm: string;
    filterCaseSensitive: string;
    filterProperties: string;
    mode: string;
    page: string;
    pageSize: string;
}

export interface IPositionFilterOptions {
    term: string;
    caseSensitive: boolean;
    regExp?: RegExp;
    searchProperties: string[];
}

export interface IFilterSearchProperty {
    systemName: string;
    displayName?: string;
    getText: (position: IPositionModel) => string[];
}

export class SimpleFilterSearchProperty implements IFilterSearchProperty {
    systemName: string;
    displayName: string;

    constructor(systemName: string, displayName?: string) {
        this.systemName = systemName;
        this.displayName = displayName;
    }

    getText(position: IPositionModel) {
        return [(<any>position)[this.systemName]];
    }
}