'use strict';

import { extend, isString, IAngularEvent } from 'angular';
import { register, ANGULAR_EVENT, DOM_EVENT } from "@systemorph/web";
import {isNullOrUndefined, DataGridController, IDataGridSelection, DATA_GRID_EVENT, DATA_GRID_COLUMN_TYPE } from "@systemorph/ui-web";
import { lowerFirst, debounce } from 'lodash';
import { IReportGridOptions, IReportGridColumnDef, IReportGridScope, IReportGridDataGridScope, IReportGridCellData } from './reportGrid.api';
import {
    IReportModel, IReportModelSelection,
    IReportModelRow, IReportModelColumnNode,
    REPORT_MODEL_RELOAD,
    IReportChartService,
} from '../../report';
import { REPORT_GRID_EVENT } from "./reportGrid.events";

export const reportGridDDOProvider = () => {
    return {
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            reportModel: "=",
            reportGridOptions: "=?",
        },
        template: require('./reportGrid.html'),
        controller: ReportGridController,
        controllerAs: 'reportGrid',
    }
};

register.directive("reportGrid", reportGridDDOProvider);

export const minGridHeight = 100;

export class ReportGridController {
    // bindings
    reportModel: IReportModel;
    selection: IReportModelSelection;
    reportGridOptions: IReportGridOptions;
    // ---

    options: IReportGridOptions;
    columnDefs: IReportGridColumnDef[];
    rows: IReportModelRow[];
    dataGrid: DataGridController;

    private rowsHierarchy: { [key: string]: string };
    protected isHierarchicalReport: boolean;
    private enumPromises: { [key: string]: ng.IPromise<any> } = {};

    /*@ngInject*/
    constructor(private $scope: IReportGridScope,
        private $http: ng.IHttpService,
        private $q: ng.IQService,
        private $window: ng.IWindowService,
        private $timeout: ng.ITimeoutService,
        private $element: ng.IAugmentedJQuery,
        private reportChartService: IReportChartService) {

        this.$scope.$on(DATA_GRID_EVENT.onInitialized, (event: ng.IAngularEvent, scope: IReportGridDataGridScope, dataGrid: DataGridController) => {
            scope.reportGrid = this;
            this.dataGrid = dataGrid;
        });

        this.setupGrid();

        this.$scope.$watch(() => this.reportModel, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.setupGrid();
            }
        });

        $scope.$on(DATA_GRID_EVENT.selectionChanged, (event: IAngularEvent, selection: IDataGridSelection) => {
            const selectedColumns = selection.selectedColumns.map(c => (<IReportGridColumnDef> c.colDef).columnNode);
            const selectedRows = selection.selectedRows.map(r => r.entity.entity);
            this.selection = {
                selectedColumns,
                selectedColumn: selectedColumns[0],
                selectedRows,
                selectedRow: selectedRows[0],
                selectedCell: selection.selectedCell
                    ? { columnNode: (<IReportGridColumnDef> selection.selectedCell.col.colDef).columnNode, row: selection.selectedCell.row.entity.entity }
                    : undefined,
                dataGridSelection: selection
            };
            this.reportModel.context.setSelection(this.selection);
            $scope.$emit(REPORT_GRID_EVENT.selectionChanged, this.selection);
        });

        $scope.$on(DATA_GRID_EVENT.onInitialized, () => this.setGridHeight());
        $scope.$on(DATA_GRID_EVENT.dataChanged, () => this.setGridHeight());

        $(window).on(DOM_EVENT.resize + '.reportGrid', debounce(() => this.setGridHeight(), 250));

        this.$scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            $(window).off(DOM_EVENT.resize + '.reportGrid');
        });
    }

    private setGridHeight() {
        // timeouts are needed to let dataGrid menus render to get correct grid position before calculating the height
        // todo: change drop-down-menu-item to render menus immediately, which would let us use element-rendered directive
        this.$timeout(() => {
            this.$timeout(() => {
                const height = $(window).height() - this.$element.find('.grid').offset().top
                    - $('.body-wrapper-ctr').scrollTop() - 20;
                this.dataGrid.setGridHeight(Math.max(height, minGridHeight));
            });
        });
    }

    saveReportValue(row: IReportModelRow, columnNode: IReportModelColumnNode, properties: string[], reloadReportModel = REPORT_MODEL_RELOAD.full): ng.IPromise<any> {
        return this.options.saveReportValue ? this.options.saveReportValue(this.reportModel.ReportType, row, columnNode, properties, reloadReportModel) : this.$q.when();
    }

    private setupGrid() {
        this.initRowsHierarchy();
        this.setOptions();
        this.setColumnDefs();
        this.rows = this.reportModel.Rows;

        this.selection = {
            selectedColumns: [],
            selectedRows: []
        };
    }

    private initRowsHierarchy() {
        this.rowsHierarchy = {};

        this.reportModel.Rows.forEach(row => {
            if (row.RowDefinition && row.RowDefinition.Expand) {
                row.RowDefinition.Expand.forEach(child => {
                    this.rowsHierarchy[child] = row.RowDefinition.SystemName;
                })
            }
        });

        this.isHierarchicalReport = Object.keys(this.rowsHierarchy).length > 0;
    }

    private setColumnDefs() {
        this.columnDefs = this.reportModel.parser.getColumnNodes().map(n => this.getColumnDef(n));

        if (this.isHierarchicalReport) {
            const rowHeader = this.columnDefs.filter(colDef => colDef.columnNode.column.ColumnType === DATA_GRID_COLUMN_TYPE.rowHeader)[0];
            if (rowHeader && !rowHeader.columnDisplayName) {
                rowHeader.columnDisplayName = "hierarchicalColumnDisplay";
            }
        }
    }


    protected getColumnDef(columnNode: IReportModelColumnNode) {
        const colDef = <IReportGridColumnDef> {
            name: columnNode.systemName,
            columnType: columnNode.column.ColumnType,
            displayName: columnNode.displayName,
            displayNameHtml: columnNode.displayNameHtml,
            columnDisplayName: columnNode.column.Display || (columnNode.column.ColumnType === DATA_GRID_COLUMN_TYPE.number ? "reportNumberColumnDisplay" : null),
            pinnedLeft: columnNode.column.IsPinned || columnNode.column.ColumnType === DATA_GRID_COLUMN_TYPE.rowHeader,
            allowCellFocus: !columnNode.column.IsPinned
                && columnNode.column.ColumnType !== DATA_GRID_COLUMN_TYPE.rowHeader
                && columnNode.column.ColumnType !== DATA_GRID_COLUMN_TYPE.boolean,
            enableCellEdit: true,
            width: columnNode.column.Width || undefined,
            autoWidth: false,
            columnNode: columnNode,
            isSelectable: !(columnNode.column.IsPinned || columnNode.column.ColumnType === DATA_GRID_COLUMN_TYPE.rowHeader),
            formatString: columnNode.column.Format
        };

        return colDef;
    }

    private setOptions() {
        const pinnedColumns = this.reportModel.ColumnDefinitions.filter(c => c.IsPinned || c.ColumnType === DATA_GRID_COLUMN_TYPE.rowHeader).length;

        const enableRowSelection = this.reportChartService.reportModelHasRowCharts(this.reportModel);

        const defaultOptions: IReportGridOptions = {
            enableColumnSelection: this.reportChartService.reportModelHasColumnCharts(this.reportModel),
            enableRowSelection,
            enableRowHeaderSelection: enableRowSelection,
            getPropertyValue: (row: IReportModelRow, colDef: IReportGridColumnDef) => this.getPropertyValue(row, colDef),
            setPropertyValue: (row: IReportModelRow, colDef: IReportGridColumnDef, value: any) => this.setPropertyValue(row, colDef, value),
            getRowClasses: this.getRowClasses,
            getCellClasses: (row: IReportModelRow, colDef: IReportGridColumnDef, cellData: any) => this.getCellClasses(row, colDef),
            rowTemplate: `
            <div ng-if="row.entity.entity.RowDefinition.RowType === 'Title'" class="title-row" data-row-index="{{row.entity.index}}">
                <div class="ui-grid-cell title" ng-if="colContainer.name === row.grid.appScope.dataGrid.options.titleRowContainerName">{{row.entity.entity.Row}}</div>
                <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
                     ui-grid-one-bind-id-grid="rowRenderIndex + '-' + col.uid + '-cell'"
                     class="ui-grid-cell"
                     ui-grid-cell>
                </div>
            </div>
            <div ng-if="row.entity.entity.RowDefinition.RowType !== 'Title'" ng-class="row.entity.classes" data-row-index="{{row.entity.index}}">
                <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
                     ui-grid-one-bind-id-grid="rowRenderIndex + '-' + col.uid + '-cell'"
                     class="ui-grid-cell" ng-class="[row.entity.cellConfig[col.name].classes, {'ui-grid-row-header-cell': col.isRowHeader}]"
                     role="{{col.isRowHeader ? 'rowheader' : 'gridcell'}}"
                     ui-grid-cell>
                </div>
            </div>
            `,
            titleRowContainerName: pinnedColumns > 0 ? 'left' : 'body',
            getExportName: () => this.reportModel.DisplayName,
            exportProvider: 'reportGridExportProvider',
            exporterPdfCustomFormatter: (docDefinition: any) => {
                docDefinition.styles.titleRowStyle = { fontSize: 10, color: '#fff', bold: true, fillColor: '#808080' };
                return docDefinition;
            },
            saveValue: (row: IReportModelRow, colDef: IReportGridColumnDef) => {
                // do not reload the model in case of rich text columns to keep the viewer open
                const reloadModel = colDef.columnNode.column.ColumnType === DATA_GRID_COLUMN_TYPE.richText ? REPORT_MODEL_RELOAD.noReload
                    : this.reportModel.ReloadOnSave || REPORT_MODEL_RELOAD.full;
                return this.saveReportValue(row, colDef.columnNode, null, reloadModel)
                    .then(data => {
                        if (reloadModel === REPORT_MODEL_RELOAD.singleRow) {
                            const newRow = <IReportModelRow> data;
                            const index = this.reportModel.Rows.indexOf(row);
                            this.reportModel.Rows.splice(index, 1, newRow);
                            return this.dataGrid.updateRow(index, newRow);
                        }
                    });
            },
            getColumnDisplayLocals: () => {
                return { reportGrid: this };
            },
            showHeader: this.reportModel.ShowHeader,
            isEditableCell: (row: IReportModelRow, colDef: IReportGridColumnDef) => this.isEditableCell(row, colDef),
            getCellData: (row: IReportModelRow, colDef: IReportGridColumnDef) => this.getCellData(row, colDef),
            getEditor: (row: IReportModelRow, colDef: IReportGridColumnDef, cellData: IReportGridCellData) => cellData.editor,
            getColumnParameters: (row: IReportModelRow, colDef: IReportGridColumnDef, cellData: IReportGridCellData) => cellData.parameters,
            getEditorParameters: (row: IReportModelRow, colDef: IReportGridColumnDef, cellData: IReportGridCellData, actualEditor: string) => cellData.editorParameters,
            getValidators: (row: IReportModelRow, colDef: IReportGridColumnDef, cellData: IReportGridCellData) => cellData.validators,
            isHierarchicalGrid: this.isHierarchicalReport,
            getHierarchicalEntityId: (row: IReportModelRow) => row.RowDefinition.SystemName,
            getHierarchicalEntityParentId: (row: IReportModelRow) => this.rowsHierarchy[row.RowDefinition.SystemName],
            multiSelect: true
        };

        this.options = extend({}, defaultOptions, this.reportGridOptions || {});
    }

    protected getPropertyValue(row: IReportModelRow, colDef: IReportGridColumnDef) {
        return this.reportModel.parser.getCellValue(colDef.columnNode, row);
    }

    protected setPropertyValue(row: IReportModelRow, colDef: IReportGridColumnDef, value: any) {
        this.reportModel.parser.setCellValue(colDef.columnNode, row, value);
    }

    protected getRowClasses(row: IReportModelRow) {
        return row.RowDefinition && row.RowDefinition.Style ? [row.RowDefinition.Style] : null;
    }

    protected getCellClasses(row: IReportModelRow, colDef: IReportGridColumnDef) {
        if (colDef.columnNode.column.Style) {
            const classes = this.reportModel.parser.interpolateExpression(colDef.columnNode.column.Style, colDef.columnNode, row);
            return [classes];
        }
    }

    protected isEditableCell(row: IReportModelRow, colDef: IReportGridColumnDef) {
        if (isNullOrUndefined(colDef.columnNode.column.IsEditable)) return false;
        return this.reportModel.parser.evaluateExpression<boolean>(colDef.columnNode.column.IsEditable, colDef.columnNode, row);
    }

    private getCellData(row: IReportModelRow, colDef: IReportGridColumnDef) {
        const promises: ng.IPromise<any>[] = [];

        var validators: string[] = null;
        if (colDef.columnNode.column.Validators) {
            const validatorsString = this.reportModel.parser.interpolateExpression(colDef.columnNode.column.Validators, colDef.columnNode, row);
            if (validatorsString) validators = validatorsString.split(' ');
        }

        const parameters = this.parseParametersExpressions(colDef.columnNode.column.Parameters, row, colDef.columnNode);
        const editorParameters = this.parseParametersExpressions(colDef.columnNode.column.EditorParameters, row, colDef.columnNode);

        if (colDef.columnNode.column.ColumnType === DATA_GRID_COLUMN_TYPE.enum) {
            if (!parameters.typeName) throw `TypeName of enumeration is missing for "${ colDef.columnNode.column.SystemName }" column, use parameters to specify it`;

            if (!this.enumPromises[parameters.typeName])
                this.enumPromises[parameters.typeName] = this.getEnumItems(parameters.typeName);

            promises.push(this.enumPromises[parameters.typeName].then(enumItems => parameters.enumItems = enumItems));
        }

        const cellData = <IReportGridCellData> {
            parameters,
            editor: colDef.columnNode.column.Editor
                ? this.reportModel.parser.interpolateExpression(colDef.columnNode.column.Editor, colDef.columnNode, row)
                : null,
            editorParameters,
            validators,
        };

        return promises.length > 0 ? this.$q.all(promises).then(() => cellData) : cellData;
    }

    private parseParametersExpressions(parametersExpressionsDict: any, row: IReportModelRow, columnNode: IReportModelColumnNode) {
        var parameters: any = {};
        for (var k in parametersExpressionsDict) {
            const value = isString(parametersExpressionsDict[k])
                ? this.reportModel.parser.evaluateExpression(parametersExpressionsDict[k], columnNode, row)
                : parametersExpressionsDict[k];

            parameters[k] = value;

            // deprecated, backward compatibility
            parameters[lowerFirst(k)] = value;
        }
        return parameters;
    }

    private getEnumItems(typeName: string) {
        return this.$http.get<any[]>(`/api/ReportModel/EnumItems`, { params: { typeName } })
            .then(response => {
                return response.data;
            });
    }
}