'use strict';

import { register } from "@systemorph/web";


register.directive("pdTags", () => {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            model: "="
        },
        template: `<div class="pd-tag" ng-repeat="tag in model">
                      <span ng-if="$index !== 0">,</span>
                      <i ng-bind-html="::(tag.DisplayName)"></i>
                   </div>`
    }
});

register.directive("tagsProperty", () => {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        template: `<div><pd-tags model="::entity[propertyLayout.systemName]"></pd-tags></div>`
    }
});
