'use strict';

import {register, IPersisterProviderService, IDataSourceService} from "@systemorph/web";
import {FormEntityRegistry} from "@systemorph/form-entity-web";
import {BusinessProcessService, ICurrentProcessModel} from "@systemorph/business-processes-web";
import {IQService, cookies, IHttpService, ICacheFactoryService, IScope,} from 'angular';

export class IfrsBusinessProcessService extends BusinessProcessService {
    constructor($http: IHttpService,
                $cacheFactory: ICacheFactoryService,
                $rootScope: IScope,
                $q: IQService,
                $cookies: cookies.ICookiesService,
                formEntityRegistry: FormEntityRegistry,
                persisterProviderService: IPersisterProviderService,
                dataSourceService: IDataSourceService) {
        super($http, $cacheFactory, $rootScope, $q, $cookies, formEntityRegistry, persisterProviderService, dataSourceService);
    }

    protected getFormEntityValues(process: ICurrentProcessModel) {
        const values = super.getFormEntityValues(process);

        if (process.processSystemName) {
            values['businessProcess'] = process.processSystemName;
        }

        return values;
    }
}

register.decorator('BusinessProcessService', () => IfrsBusinessProcessService);