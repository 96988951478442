'use strict';

import { register, setPageTitle } from '@systemorph/web';
import { AC_APPLICATION_STAGE, ACCOUNT_STATE } from '@systemorph/access-control-web';
import { IScope } from 'angular';

class Controller {
    /*@ngInject*/
    constructor($scope: IScope) {
        setPageTitle($scope, "Not authenticated");
    }
}

register.state({
    name: ACCOUNT_STATE.notAuthenticated,
    parent: ACCOUNT_STATE.noAccessLayout,
    url: '/notAuthenticated',
    template: require('./baloiseNotAuthorized.html'),
    controller: Controller,
    controllerAs: 'ctrl',
    suppressReloadMessage: true,
    params: {
        ReturnUrl: null
    }
}, { applicationStage: AC_APPLICATION_STAGE.notAuthenticated, useAsOtherwise: true });