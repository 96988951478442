'use strict';

export const POSITION_EVENT = {
    positionFilterChanged: 'positionEditor.positionFilterChanged',
    savingNewPosition: 'positionEditor.savingPosition',
    newFormulaAdded: 'positionEditor.newFormulaAdded',
    formulaApplicabilityChanged: 'positionEditor.formulaApplicabilityChanged',
    savedNewPosition: 'positionEditor.savedNewPosition',
    formulaOrderChanged: 'positionEditor.formulaReordered',
    validateType: 'positionEditor.validateType'
};