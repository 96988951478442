import { register, IActivityService } from '@systemorph/web';
import { IScope } from "angular";

register.controller('importWithAutomaticBehaviourDialogController', (
        $scope: IScope,
        activityService: IActivityService,
        $controller: ng.IControllerService
    ) => {
        $scope.activity.directiveArgs.phaseArgs['importBehaviour'] = "ForceCommit";

        $controller('importDialogController', { $scope: $scope, activityService });
        $scope.importBehaviourPropertyInfo = '';
    }
);

register.decorator('importDialogDirective', ($delegate: any) => {
    $delegate[0].controller = 'importWithAutomaticBehaviourDialogController';
    return $delegate;
});

