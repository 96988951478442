'use strict';

import {register} from "@systemorph/web";
import {BaseColumnDisplay} from "../baseColumnDisplay";
import {IDataGridColumnDef, IDataGridOptions} from "../../dataGrid.api";
import { IRichTextCellData } from '../columns.api';


export class RichTextColumnDisplay extends BaseColumnDisplay {
    /*@ngInject*/
    constructor(colDef: IDataGridColumnDef, dataGridOptions: IDataGridOptions) {
        super(colDef, dataGridOptions);
    }

    getCellData(entity: any): IRichTextCellData {
        const cellData: IRichTextCellData = <any>{};
        this.updateText(cellData, entity);
        return cellData;
    }

    getTemplate() {
        return `<rich-text-cell></rich-text-cell>`;
    }

    updateText(cellData: any, entity: any) {
        const text = this.getValue(entity);
        cellData.shortText = text
            ? String(text)
                .replace(/<(br\s*\/?|\/div|\/p)>/igm, ' ')
                .replace(/<[^>]+>/gm, '')
            : null;
        cellData.text = text;
    }
}

register.factory("richTextColumnDisplay", () => RichTextColumnDisplay);
