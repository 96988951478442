'use strict';

import {register, IMenuService, ANGULAR_EVENT, COMMON_EVENT} from "@systemorph/web";
import { IChartConfiguration, IChartService } from '../charts.api';
import { CHART_EVENT } from '../charts.events';

const canvasElementId = 'right-panel-chart';
let previousCharts: IChartConfiguration[];

register.directive('chartsContextPanel', function() {
    return {
        replace: true,
        restrict: 'E',
        template: `
            <section class="unv-cpl-section unv-cpl-section-chart">
                <header class="unv-cpl-section-header">
                    <h2 class="unv-cpl-section-title">Charts</h2>
                </header>
                <div class="unv-cpl-section-content">
                    <div class="unv-cpl-section-content-inner">
                        <div class="unv-cpl-section-scroller">
                            <div class="unv-cpl-empty" ng-show="chart.noCharts">
                                <p>No charts available</p>
                            </div>
                            <div id="right-panel-chart-container">
                                <canvas id="${canvasElementId}" element-rendered></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        `,
        controller: ChartsContextPanelController,
        controllerAs: 'chart'
    }
});

class ChartsContextPanelController {
    private chart: Chart;

    noCharts: boolean;

    constructor(private $scope: ng.IScope,
                private menuService: IMenuService,
                private chartService: IChartService) {

        $scope.$on(COMMON_EVENT.elementRendered, ($event: ng.IAngularEvent, id: string) => {
            if (id == canvasElementId) {
                this.refresh();
            }
        });

        $scope.$on(CHART_EVENT.rightPanelChartsChanged, () => {
            this.refresh();
        });

        $scope.$on(ANGULAR_EVENT.scopeDestroy, () => this.destroy());
    }

    private refresh() {
        const charts = this.chartService.getRightPanelCharts();
        this.noCharts = !charts || !charts.length;

        if (previousCharts === charts)
            return;

        previousCharts = charts;

        this.destroy();

        if (charts && charts.length) {
            this.chart = this.chartService.renderChart(document.getElementById(canvasElementId), charts[0]);
            // if (!isResized) {
            //     $('.unv-context-panel-details').width(600);
            //     this.menuService.expandContextPanel();
            //     isResized = true;
            // }

            // needed for debugging purposes
            // const win: any = window;
            // win.chartDefinition = reportModel.ChartDefinition;
            // win.renderChart = () => this.reportChartService.renderChart(document.getElementById('report-chart'), reportModel);
        }
    }

    private destroy() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = undefined;
        }
    }
}