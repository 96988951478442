'use strict';

import {register} from "@systemorph/web";
import {IRichTextCellScope, RichTextCellController} from "./richTextCellDirective";

interface IRichTextEditorScope extends IRichTextCellScope {
    richTextEditor: RichTextCellEditorController;
}

register.directive("richTextCellEditor", function() {
    return {
        restrict: "A",
        scope: true,
        template: `
            <div class="rich-text-editor">
                <div text-angular
                     ng-model="richTextEditor.editValue"
                     ta-text-editor-setup='richTextEditor.textEditorSetup'
                     ta-toolbar="[['h1', 'h2', 'h3'], ['bold', 'italics', 'underline', 'strikeThrough', 'insertLink',], ['quote', 'ul', 'ol'], ['justifyLeft', 'justifyCenter', 'justifyRight'], ['redo', 'undo', 'clear', 'html']]">
                </div>
                <div class="rt-toolbar">
                    <button class="btn btn-cancel btn-danger btn-clear btn-sm" ng-click="richTextEditor.cancel()">Cancel</button>
                    <button class="btn btn-success btn-sm" ng-click="richTextEditor.save()">Save</button>
                </div>
            </div>`,
        link($scope: IRichTextEditorScope, element: ng.IAugmentedJQuery, attributes: ng.IAttributes) {
            $scope.richTextEditor.link(element, attributes);
        },
        controller: RichTextCellEditorController,
        controllerAs: 'richTextEditor'
    }
});

class RichTextCellEditorController {
    private scope: IRichTextEditorScope;
    private richText: RichTextCellController;
    private timeout: ng.ITimeoutService;

    editValue: string;

    constructor($scope: IRichTextEditorScope, $timeout: ng.ITimeoutService, textAngularManager: any) {
        this.scope = $scope;
        this.richText = $scope.richText;
        this.timeout = $timeout;
        this.editValue = this.richText.cellData.text;
        (<any>this).ta = textAngularManager;
    }

    link(element: ng.IAugmentedJQuery, attributes: ng.IAttributes) {
        // var editorScope: any = (<any>this).ta.retrieveEditor('dataGridRichTextCell').scope;
        // this.timeout(() => {
        //     this.timeout(function(){
        //         editorScope.displayElements.text.trigger('focus');
        //         editorScope.updateSelectedStyles();
        //     });
        // });

        element.keydown(event => {
            if (event.keyCode == 27) { // ESC
                this.timeout(() => {
                    this.richText.closeEditor();
                });
                event.stopPropagation();
            }
        });
    }

    textEditorSetup(element: any) {
        // the second timeout is needed is needed to let the cellNav focus the cell first
        // in case we're clicking on the unfocused cell
        setTimeout(() => {
            setTimeout(() => {
                element.trigger('focus').trigger('mouseup');
            });
        })
    }

    save() {
        const entity: any = this.scope.row.entity.entity;
        const colDef = this.richText.colDef;

        this.richText.columnDisplay.setValue(entity, this.editValue);

        if (this.scope.row.grid.appScope.dataGrid.options.saveValue)
            this.scope.row.grid.appScope.dataGrid.options.saveValue(this.scope.row.entity.entity, this.scope.col.colDef);

        this.richText.columnDisplay.updateText(this.richText.cellData, entity);

        this.richText.closeEditor();
    }

    cancel() {
        this.richText.closeEditor();
    }
}