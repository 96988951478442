'use strict';
import {register, ACTIVITY_EVENT, IActivityService} from "@systemorph/web";
import {IScope} from "angular";

register.directive('mirrorImportConfirmation', () => {
    return {
        restrict: 'E',
        template: '<activity-log-notification data="data"></activity-log-notification>',
        controller: (
            $scope: IScope,
            activityService: IActivityService
        ) => {
            $scope.data =
                $scope.activity.directiveArgs.phaseArgs.log.errors &&
                $scope.activity.directiveArgs.phaseArgs.log.errors.length
                    ? $scope.activity.directiveArgs.phaseArgs.log.errors
                    : $scope.activity.directiveArgs.phaseArgs.log.warnings;


            $scope.$emit(ACTIVITY_EVENT.addActivityActions, [
                {
                    id: 'ok',
                    order: 1,
                    click: () => {
                        activityService.runPhase({
                            activityId:
                            $scope.activity.directiveArgs.activityId,
                            masterContext:
                            $scope.activity.directiveArgs.masterContext,
                            phaseName:
                            $scope.activity.directiveArgs.phaseName,
                            phaseArgs:
                            $scope.activity.directiveArgs.phaseArgs
                        });
                    },
                    buttonCssClass: 'btn-success',
                    showCondition: () => true,
                    htmlContent:
                        '<span class="glyphicon glyphicon-ok"></span>&nbsp;Confirm',
                    tooltip: 'Continue'
                }
            ]);
        }
    };
});
