'use strict';

import { register, IMenuItemScope} from "@systemorph/web";
import { IReportStateParams } from "@systemorph/reporting-web";
import { FormEntityRegistry } from "@systemorph/form-entity-web";
import { ProcessDocumentationContextPanelController } from './processDocumentationContextPanelController';
import { IProcessDocumentationService } from '../processDocumentation.api';

register.directive("processDocumentationContextPanelSupportingMaterials", () => {
    return {
        replace: true,
        restrict: 'E',
        template: require('./processDocumentationContextPanelSupportingMaterials.html'),
        controllerAs: "ctrl",
        controller: ProcessDocumentationContextPanelSupportingMaterialsController
    }
});

export class ProcessDocumentationContextPanelSupportingMaterialsController extends ProcessDocumentationContextPanelController {

    constructor($scope: IMenuItemScope,
        $q: ng.IQService,
        $timeout: ng.ITimeoutService,
        $state: ng.ui.IStateService,
        $stateParams: IReportStateParams,
        formEntityRegistry: FormEntityRegistry,
        blockUI: ng.blockUI.IBlockUIService,
        processDocumentationService: IProcessDocumentationService) {

        super(
            $scope,
            $q,
            $timeout,
            $state,
            $stateParams,
            formEntityRegistry,
            blockUI,
            processDocumentationService,
            "ProcessDocumentationSupportingMaterial",
            processDocumentationService.categories.supportingMaterials);
    }
}