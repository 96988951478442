'use strict';

import { DeferredStore, IDataGridSelection, IPredicate } from "@systemorph/ui-web";
import {ReportModelParser} from "./reportModelParser";
import { IReportChartRules, IReportColumnChartRules, IReportChartConfiguration } from './charts/reportChart.api';
import { ReportBindingContext } from './reportBindingContext';
import { IReportGridValueCoordinates } from '../components';
import { ReportController } from "./pages";
import { IPromise } from 'angular';
import { IReportDefinition } from "../reportingWeb.api";

export interface IReportModel {
    ReportType: string;
    DisplayName?: string;
    IsNotAvailable?: boolean;
    Message?: string;
    Groups?: IReportModelColumnGroup[];
    ColumnDefinitions?: IReportModelColumnDefinition[];
    Rows?: IReportModelRow[];
    ComparisonType?: string;
    ShowHeader?: boolean;
    Display?: string;
    ReloadOnSave?: string;
    Layout?: number[];
    Menus?: string[];

    Chart?: IReportChartRules;

    // form entity and object filter used to load the report model, assigned at front-end
    formEntity?: any;
    args?: any;
    parser: ReportModelParser;
    context: ReportBindingContext;
}

export interface IReportModelColumnDefinition {
    SystemName?: string;
    DisplayName?: string;
    DisplayNameHtml?: string;
    Width?: number;
    ColumnType?: string;
    Display?: string;
    IsPinned?: boolean;
    IsGrouped?: boolean;
    Order: number;
    Chart?: IReportColumnChartRules;

    // the following properties support data-binding
    Style?: string;
    Parameters?: IReportModelColumnParameters;
    Format?: string;
    IsEditable?: string;
    Editor?: string;
    EditorParameters?: any;
    Validators?: string;
    Value?: string;
}

export interface IReportModelColumnParameters {
    TypeName?: string;
    Trend?: string;
}

export interface IReportModelColumnGroup {
    SystemName: string;
    DisplayName: string;
    DisplayNameHtml: string;
    // each group is repeated within every group of the higher level
    Level?: number;
    // when not empty - the group will be repeated inside specified parents only
    Parents?: string[];
    IsComparisonGroup?: boolean;
}

export interface IReportModelGroupNode extends IReportModelNode {
    path: IReportModelColumnGroup[],
    group: IReportModelColumnGroup;
    isLeafComparisonGroup: boolean;
}

export interface IReportModelColumnNode extends IReportModelNode {
    displayName: string;
    displayNameHtml: string;
    column: IReportModelColumnDefinition;
}

// todo: move children and leafsCount to groupNode
export interface IReportModelNode {
    type: string;
    systemName: string;
    parent: IReportModelGroupNode;
    children: IReportModelNode[];
    leafsCount: number;
    sortKeysByLevel: number[];
}

export const reportModelNodeType = {
    group: "Group",
    column: "Column"
};

export interface IReportModelRowDefinition {
    SystemName?: string;
    DisplayName?: string;
    Style?: string;
    RowType?: string;
    Format?: string;
    Expand?: string[];
    Chart?: IReportChartConfiguration;
}

export interface IReportModelRow {
    Row: any;
    RowDefinition: IReportModelRowDefinition;
}

export const reportModelRowTypes = {
    title: "Title",
};

export const reportModelReload = {
    full: "Full",
    singleRow: "SingleRow",
    noReload: "NoReload"
};

export const specialNumbers = {
    nan: "NaN",
    negativeInfinity: "-Infinity",
    infinity: "Infinity"
};

export interface IReportModelSelection {
    selectedColumn?: IReportModelColumnNode;
    selectedColumns?: IReportModelColumnNode[];
    selectedRow?: IReportModelRow;
    selectedRows?: IReportModelRow[];
    selectedCell?: IReportModelCell;
    dataGridSelection?: IDataGridSelection;
}

export interface IReportModelCell {
    columnNode: IReportModelColumnNode;
    row: IReportModelRow;
}

export interface IUnitsMultiplier {
    prefix: string;
    multiplier: number;
}

export interface IReportStateParams extends ng.ui.IStateParamsService {
    reportType: string;
    auditEnabled: boolean;
    auditFrom?: string;
    auditFromCustom?: string;
    auditTo?: string;
    auditToCustom?: string

}

export interface INamedValue {
    value: any;
    systemName: string;
    displayName: string;
}

export interface IColumnNamedValue extends INamedValue {
    columnNode: IReportModelColumnNode;
}

export interface IEnumItem {
    SystemName: string;
    DisplayName: string;
}


export interface IReportModelService {
    args: any; // form entity args
    getGroupTree(groups: IReportModelColumnGroup[]): IReportModelGroupNode[];
    getColumnTree(reportModel: IReportModel, groupFilter?: IPredicate<IReportModelColumnGroup>, columnFilter?: IPredicate<IReportModelColumnDefinition>): IReportModelNode[];
    collectNodes<T extends IReportModelNode>(tree: IReportModelNode[], filter?: IPredicate<IReportModelNode>): T[];
    areSameColumnInstances(c1: IReportModelColumnNode, c2: IReportModelColumnNode): boolean;
    filterRows(reportModel: IReportModel, args: any[]): IReportModelRow[];
    filterColumnNodes(columnNodes: IReportModelColumnNode[], args: any[]): IReportModelColumnNode[];
    getCellValue(reportModel: IReportModel, columnNode: IReportModelColumnNode, row: IReportModelRow): any;
    setCellValue(reportModel: IReportModel, columnNode: IReportModelColumnNode, row: IReportModelRow, value: any): void;
    evaluateExpression<T>(expression: string, context: ReportBindingContext, columnNode?: IReportModelColumnNode, row?: IReportModelRow, evalCellValue?: boolean, silent?: boolean, assignValue?: any): any;
    interpolateExpression(expression: string, context: ReportBindingContext, columnNode?: IReportModelColumnNode, row?: IReportModelRow): string;
    getValueCoordinates(row: IReportModelRow, columnNode: IReportModelColumnNode, properties?: string[]): IReportGridValueCoordinates;
    getEntity(row: IReportModelRow, groupNode?: IReportModelGroupNode): any;
    getComparisonGroupByColumnNode(columnNode: IReportModelColumnNode): IReportModelGroupNode;
}

export interface IReportService {
    isDesignMode: boolean;
    reportDefinition: IReportDefinition; // empty if not in a design mode
    reportControllerStore: DeferredStore<ReportController>;
    units: number;
    loadReportModel(): IPromise<IReportModel>;
    formatNumber(value: number, format?: string): string;
}

export interface IReportHierarchyItem {
    id: string;
    name: string;
    fullName: string;
    children?: IReportHierarchyItem[];
    reportType?: string;
    process?: string;
    isReportDefinition?: boolean;
    level: number;
}

export interface IReportHierarchyService {
    getReportsHierarchy(): ng.IPromise<IReportHierarchyItem[]>;
    getEditableReportsHierarchy(): ng.IPromise<IReportHierarchyItem[]>;
}