'use strict';

import {register} from '@systemorph/web'

// Temporary solution to enable onChange for ui-select (should be removed once ui-select is updated to the latest version)
// Usage: <ui-select ng-model="myModel" ui-select-on-close="onClose(item)">...</ui-select>
register.directive('uiSelectOnClose', function() {
    return {
        scope: false,
        require: 'uiSelect',
        link: function($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, $select: any) {
            const base = $select.close;
            $select.close = function() {
                base.call($select);
                $scope.$eval($attrs['uiSelectOnClose']);
            }
        }
    }
});