export const FORM_ENTITY_EVENT = {
    updated: "formEntityUpdated",
    replaced: "formEntityReplaced",
    initializeExternalProperties: "initializeExternalProperties",
    propertyInitialized: "formEntity.propertyInitialized",
    propertyDestroyed: "formEntity.propertyDestroyed",
    dependencyPropertyChanged: "dependencyPropertyChanged",

    providerChanged: 'formEntity.providerChanged',
    initialized: 'formEntity.initialized',
    added: 'formEntity.added',
    removed: 'formEntity.removed',
    refreshProperties: 'formEntity.refreshProperties',
    rendering: 'formEntity.rendering'
};