'use strict';
import { isArray, equals, isString, copy, merge } from 'angular';
import { DATA_GRID_COLUMN_TYPE, evalExpression, CHART_TYPE, transparentize, CHART_COLOR, CHART_AXIS_TYPE, IWaterfallChartConfiguration } from '@systemorph/ui-web';
import { upperFirst, uniq } from 'lodash';
import { ChartPoint } from 'chart.js';
import { register } from '@systemorph/web';
import {
    IReportChartService,
    IReportChartModel,
    IReportChartConfiguration,
    ILineReportChartConfiguration,
    IBarReportChartConfiguration,
    IReportChartRules
} from './reportChart.api';
import {ReportBindingContext, ReportBindingContextColumn, ReportBindingContextRow} from "../reportBindingContext";
import { IReportModelSelection, IReportService, IReportModel, IReportModelCell, IReportModelGroupNode, INamedValue } from '../report.api';

class ReportChartService implements IReportChartService {
    constructor(
                private reportService: IReportService) {
    }

    getReportModelCharts(reportModel: IReportModel, selection: IReportModelSelection, chartRules = reportModel.Chart) {
        let chartModels: IReportChartModel[];

        const selectionChart = this.getSelectionChartModel(reportModel, selection, chartRules);

        if (selectionChart) {
            chartModels = [selectionChart];
        }
        else {
            chartModels = this.getDefaultChartModels(reportModel, selection, chartRules);
        }

        return chartModels.map(model => {
            return this.processChartConfiguration(model.chartConfiguration, model.reportModel, model.isDefault, model.selection, model.cell);
        });
    }

    private getSelectionChartModel(reportModel: IReportModel, selection: IReportModelSelection, chartRules: IReportChartRules): IReportChartModel {
        if (selection.selectedColumn) {
            // todo: setup more than one chart if selected columns have their own chart configs
            if (selection.selectedColumn.column.Chart || (chartRules && chartRules.columnSelection)) {
                const columnChartRules = selection.selectedColumn.column.Chart;
                const chartConfiguration = columnChartRules && columnChartRules.columnSelection
                    ? columnChartRules.columnSelection : chartRules.columnSelection;
                return {
                    chartConfiguration,
                    reportModel,
                    selection
                };
            }
        }
        else if (selection.selectedRow) {
            // todo: setup more than one chart if selected rows have their own chart configs
            if (selection.selectedRow.RowDefinition.Chart || (chartRules && chartRules.rowSelection)) {
                const chartConfiguration = selection.selectedRow.RowDefinition.Chart
                    ? selection.selectedRow.RowDefinition.Chart : chartRules.rowSelection;
                return {
                    chartConfiguration,
                    reportModel,
                    selection
                };
            }
        }
        else if (selection.selectedCell && selection.selectedCell.columnNode.column.Chart) {
            const rules = selection.selectedCell.columnNode.column.Chart;

            let chartConfiguration: IReportChartConfiguration;

            if (rules.cellSelection !== undefined) chartConfiguration = rules.cellSelection;
            else if (rules.cellChart) {
                const cellData = selection.dataGridSelection.selectedCell.row.entity.cellDataStore[selection.selectedCell.columnNode.systemName];
                if (cellData.cellChartEnabled)
                    chartConfiguration = rules.cellChart;
            }

            if (chartConfiguration) {
                return {
                    chartConfiguration,
                    reportModel,
                    selection,
                    cell: {
                        row: selection.selectedCell.row,
                        columnNode: selection.selectedCell.columnNode
                    }
                };
            }
        }
    }

    private getDefaultChartModels(reportModel: IReportModel, selection: IReportModelSelection, chartRules: IReportChartRules) {
        let result: IReportChartModel[] = [];

        if (chartRules && chartRules.default) {
            if (chartRules.default.column !== undefined) {
                const columnNodes = reportModel.context.allColumnNodes.filter(c => c.column.ColumnType !== DATA_GRID_COLUMN_TYPE.rowHeader);
                const [defColumn, ...groupPath] = isArray(chartRules.default.column) ? chartRules.default.column : [chartRules.default.column];

                const nodes = groupPath.length > 0
                    ? columnNodes.filter(n => n.parent && equals(n.parent.path.map(g => g.SystemName), groupPath))
                    : columnNodes;

                const columnNode = isString(defColumn)
                    ? nodes.filter(n => n.column.SystemName === defColumn)[0]
                    : nodes[defColumn];

                if (!columnNode)
                    throw `Incorrect chart configuration, column ${ chartRules.default.column } not found.`;

                const chartConfiguration = columnNode.column.Chart && columnNode.column.Chart.columnSelection
                    ? columnNode.column.Chart.columnSelection : chartRules.columnSelection;

                if (!chartConfiguration)
                    throw `Incorrect chart configuration, column ${ chartRules.default.column } does not have a chart defined.`;

                result.push({
                    chartConfiguration,
                    reportModel,
                    selection: {
                        selectedColumn: columnNode,
                        selectedColumns: columnNodes.filter(c => reportModel.parser.areSameColumnInstances(c, columnNode)),
                        selectedRows: []
                    }
                });
            }
            if (chartRules.default.row !== undefined) {
                const row = reportModel.Rows.filter(r => r.RowDefinition.SystemName === chartRules.default.row)[0];

                if (!row)
                    throw `Incorrect chart configuration, row ${ chartRules.default.row } not found.`;

                const chartConfiguration = row.RowDefinition.Chart
                    ? row.RowDefinition.Chart : chartRules.rowSelection;

                if (!chartConfiguration)
                    throw `Incorrect chart configuration, row ${ chartRules.default.row } does not have a chart defined.`;

                result.push({
                    chartConfiguration,
                    reportModel,
                    selection: {
                        selectedRow: row,
                        selectedRows: [row],
                        selectedColumns: []
                    }
                });
            }
            if (chartRules.default.chart) {
                result.push({
                    chartConfiguration: chartRules.default.chart,
                    reportModel,
                    selection,
                    isDefault: true
                });
            }
        }

        return result;
    }

    private normalizeConfig(config: IReportChartConfiguration) {
        if (!config.options) config.options = {};

        // scales shortcuts
        if (!config.options.scales && (config.xAxis || config.yAxis)) {
            config.options.scales = {};

            if (config.xAxis) {
                config.options.scales.xAxes = [config.xAxis];
                config.xAxis = undefined;
            }

            if (config.yAxis) {
                config.options.scales.yAxes = [config.yAxis];
                config.yAxis = undefined;
            }
        }

        if (config.options.comparisonContextFilter) {
            config.options.comparisonContextFilter = evalExpression(`(${ config.options.comparisonContextFilter })`);
        }
        if (config.options.columnSelectionFilter) {
            config.options.columnSelectionFilter = evalExpression(`(${config.options.columnSelectionFilter})`);
        }
        if (config.options.rowSelectionFilter) {
            config.options.rowSelectionFilter = evalExpression(`(${config.options.rowSelectionFilter})`);
        }

        const formatterContext = {
            formatNumber: (value: number, format?: string) => this.reportService.formatNumber(value, format)
        };

        if (config.options.formatter) config.options.formatter = evalExpression<any>(`(${ config.options.formatter })`, formatterContext);
        if (config.options.axisFormatter) config.options.axisFormatter = evalExpression<any>(`(${ config.options.axisFormatter })`, formatterContext);
        if (config.data && config.data.datasets) {
            config.data.datasets.forEach(ds => {
                if (ds.formatter) ds.formatter = evalExpression<any>(`(${ ds.formatter })`, formatterContext);
            });
        }

        // todo: backwatd compat
        const conf: any = config;
        if (conf.absoluteValues) {
            conf.options.absoluteValues = conf.absoluteValues;
            delete conf.absoluteValues;
        }
        if (conf.showAbsoluteValues) {
            conf.options.showAbsoluteValues = conf.showAbsoluteValues;
            delete conf.showAbsoluteValues;
        }

        // data shortcut
        if (isString(conf.data) || isArray(conf.data)) {
            config.data = {
                datasets: [{
                    data: conf.data
                }]
            };
        }
    }

    // process custom chart configuration properties, other (native) properties are passed to the Chart.js as-is
    processChartConfiguration(config: IReportChartConfiguration,
        reportModel: IReportModel,
        isDefault: boolean,
        selection: IReportModelSelection,
        cell: IReportModelCell) {
        config = copy(config);

        this.normalizeConfig(config);

        if (!config.options.title) config.options.title = {};

        const cellColumnNode = cell ? cell.columnNode : null;
        const cellRow = cell ? cell.row : null;

        if (selection) reportModel.context.setSelection(selection);
        if (cell) reportModel.context.setCell(cellColumnNode, cellRow);

        if (config.options.format)
            config.options.format = reportModel.parser.interpolateExpression(config.options.format);
        if (!config.options.formatter)
            config.options.formatter = value => this.reportService.formatNumber(value, config.options.format);

        if (config.options.title.text) {
            config.options.title.text = reportModel.parser.interpolateExpression(<string>config.options.title.text);
        }
        else {
            let title: string;
            if (isDefault) title = reportModel.DisplayName;
            else if (cell) title = `${ cell.row.RowDefinition.DisplayName }, ${ cell.columnNode.displayName }`;
            // todo: if all selected columns are instances of the same column - use it's display name without comparison groups suffix
            else if (selection.selectedColumn) title = selection.selectedColumns.length > 1 ? reportModel.DisplayName : selection.selectedColumn.displayName;
            else if (selection.selectedRow) title = selection.selectedRows.length > 1 ? reportModel.DisplayName : selection.selectedRow.RowDefinition.DisplayName;
            else if (selection.selectedCell) title = `${ selection.selectedCell.row.RowDefinition.DisplayName }, ${ selection.selectedCell.columnNode.column.DisplayName }`;

            config.options.title.text = title;
        }

        if (config.options.title.text)
            config.options.title.display = true;

        if ([CHART_TYPE.bar, CHART_TYPE.horizontalBar, CHART_TYPE.waterfall, CHART_TYPE.horizontalWaterfall, CHART_TYPE.line].indexOf(config.type) !== -1) {
            if (!config.options.legend) config.options.legend = {};
            config.options.legend.isHorizontal = true;
        }

        const processFunc = `process${ upperFirst(config.type) }ChartConfiguration`;

        const me: any = this;

        if (!me[processFunc])
            throw `Unknown chart type: '${ config.type }.`;

        me[processFunc](config, reportModel, isDefault, selection, cell);

        return config;
    }

    private forEachComparisonAndSelection(config: IReportChartConfiguration, context: ReportBindingContext, selection: IReportModelSelection, handler: (comparisonGroup: IReportModelGroupNode, index: number) => void) {
        const isColumnSelection = selection.selectedColumns.length;
        const comparisonGroups: IReportModelGroupNode[] = context.comparisonType && !config.options.noComparison && !isColumnSelection ? context.comparisonGroups : [null];
        const selectionCopy = { ...selection };
        comparisonGroups.forEach((comparisonGroup, index) => {
            context.setComparison(comparisonGroup);

            if (config.options.comparisonContextFilter && comparisonGroup && !config.options.comparisonContextFilter(context, index, context.comparisonGroups))
                return;

            if (selection.selectedRows.length > 1) {
                this.forEachSelectedRow(config, context, selectionCopy, () => handler(comparisonGroup, index));
            }
            else if (selection.selectedColumns.length > 1) {
                this.forEachSelectedColumn(config, context, selectionCopy, () => handler(comparisonGroup, index));
            }
            else {
                handler(comparisonGroup, index);
            }
        });
    }

    private forEachSelectedRow(config: IReportChartConfiguration, context: ReportBindingContext, selection: IReportModelSelection, handler: () => void) {
        selection.selectedRows.forEach((row, index, rows) => {
            if (config.options.rowSelectionFilter && !config.options.rowSelectionFilter(row, index, rows)) return;
            selection.selectedRow = row;
            context.setSelection(selection);
            handler();
        });
    }

    private forEachSelectedColumn(config: IReportChartConfiguration, context: ReportBindingContext, selection: IReportModelSelection, handler: () => void) {
        selection.selectedColumns.forEach((column, index, columns) => {
            if (config.options.columnSelectionFilter && !config.options.columnSelectionFilter(column, index, columns)) return;
            selection.selectedColumn = column;
            context.setSelection(selection);
            handler();
        });
    }

    private processLineChartConfiguration(config: ILineReportChartConfiguration,
        reportModel: IReportModel,
        isDefault: boolean,
        selection: IReportModelSelection,
        cell: IReportModelCell) {
        const context = reportModel.context;

        if (config.data) {
            if (config.data.datasets) {
                const originalDatasets = config.data.datasets;
                config.data.datasets = [];

                this.forEachComparisonAndSelection(config, context, selection, (comparisonGroup, comparisonGroupIndex) => {
                    originalDatasets.forEach(originalDataset => {
                        const dataSet = copy(originalDataset);

                        if (dataSet.label) dataSet.label = reportModel.parser.interpolateExpression(dataSet.label);
                        else dataSet.label = [context.comparisonLabel, context.multipleSelection && context.selectionLabel].filter(x => x).join(', ')

                        if (isString(dataSet.data)) {
                            let result = this.evalExpression<any>(reportModel, config, <any>dataSet.data);

                            if (result instanceof ReportBindingContextColumn || result instanceof ReportBindingContextRow)
                                result = result.getValues();

                            if (isArray(result) && this.isNamedValuesArray(result)) {
                                dataSet.data = (<INamedValue[]> result).map(el => {
                                    return {
                                        x: el.displayName,
                                        y: el.value
                                    };
                                });
                            }
                            else {
                                dataSet.data = result;
                            }
                        }

                        if (dataSet.color) {
                            dataSet.backgroundColor = transparentize((<any> CHART_COLOR)[dataSet.color], 0.8);
                            dataSet.borderColor = (<any> CHART_COLOR)[dataSet.color];
                            delete dataSet.color;
                        }

                        config.data.datasets.push(dataSet);
                    });
                });
            }
        }

        // using full row/column by default for row/column selection
        if ((!config.data || !config.data.datasets) && (cell || selection.selectedColumn || selection.selectedRow)) {
            if (!config.data) config.data = {};

            config.data.datasets = [];

            if (cell) {
                config.data.datasets.push({
                    data: context.cell.getValue()
                });
            }
            else {
                this.forEachComparisonAndSelection(config, context, selection, (comparisonGroup, comparisonGroupIndex) => {
                    // todo: bug - comparisonLabel is re-assigned after calling getValues on selected row or column
                    const label = [context.comparisonLabel, context.multipleSelection && context.selectionLabel].filter(x => x).join(', ')

                    const selectionData = selection.selectedColumn
                        ? context.selectedColumn.getValues()
                        : context.selectedRow.getValues();

                    const data = selectionData.map(d => {
                        return {
                            x: d.displayName,
                            y: d.value
                        };
                    });

                    config.data.datasets.push({
                        label,
                        data
                    });
                });
            }
        }

        // setup line axes
        const datasets = config.data.datasets;
        const xIsCategory = datasets.some(ds => (<ChartPoint[]> ds.data).some(p => isString(p.x)));
        const yIsCategory = datasets.some(ds => (<ChartPoint[]> ds.data).some(p => isString(p.y)));

        if (config.data.datasets.length) {
            const data: ChartPoint[] = datasets[0].data;

            if (xIsCategory && !config.data.xLabels)
                config.data.xLabels = uniq(data.map(p => p.x || ''));

            if (yIsCategory && !config.data.yLabels)
                config.data.yLabels = uniq(data.map(p => p.y || ''));
        }

        const scales: any = {
            xAxes: [{
                type: xIsCategory ? CHART_AXIS_TYPE.category : CHART_AXIS_TYPE.linear,
                ticks: {
                    // maxTicksLimit: xIsCategory ? 10 : undefined
                }
            }],
            yAxes: [{
                type: yIsCategory ? CHART_AXIS_TYPE.category : CHART_AXIS_TYPE.linear,
                ticks: {
                    // maxTicksLimit: yIsCategory ? 10 : undefined
                }
            }]
        };

        if (config.options && config.options.scales) merge(scales, config.options.scales);

        config.options.scales = scales;

        return config;
    }

    private processBarChartConfiguration(config: IBarReportChartConfiguration,
        reportModel: IReportModel,
        isDefault: boolean,
        selection: IReportModelSelection,
        cell: IReportModelCell) {
        const context = reportModel.context;

        if (config.data) {
            if (isString(config.data.labels)) {
                config.data.labels = this.evalExpression<any[]>(reportModel, config, <any>config.data.labels);
            }

            if (config.data.datasets) {
                const originalDatasets = config.data.datasets;
                config.data.datasets = [];

                let contextIndex = 0;
                this.forEachComparisonAndSelection(config, context, selection, (comparisonGroup, comparisonGroupIndex) => {
                    originalDatasets.forEach(originalDataset => {
                        const dataSet = copy(originalDataset);

                        if (dataSet.label) dataSet.label = reportModel.parser.interpolateExpression(dataSet.label);
                        else dataSet.label = [context.comparisonLabel, context.multipleSelection && context.selectionLabel].filter(x => x).join(', ')

                        if (isString(dataSet.data)) {
                            let result = this.evalExpression<any>(reportModel, config, <any>dataSet.data);
                            let systemNames: string[];

                            if (result instanceof ReportBindingContextColumn || result instanceof ReportBindingContextRow)
                                result = result.getValues();

                            if (isArray(result) && this.isNamedValuesArray(result)) {
                                const values = (<INamedValue[]> result).map(el => el.value);
                                const displayNames = (<INamedValue[]> result).map(el => el.displayName);
                                systemNames = (<INamedValue[]> result).map(el => el.systemName);

                                dataSet.data = values;

                                if (contextIndex === 0 && !config.data.labels)
                                    config.data.labels = displayNames;
                            }
                            else {
                                dataSet.data = result;
                            }

                            if ((config.type === CHART_TYPE.horizontalWaterfall || config.type === CHART_TYPE.waterfall)
                                && contextIndex === 0 && systemNames)
                                this.convertWaterfallItems(<IWaterfallChartConfiguration> config, systemNames);
                        }

                        if (dataSet.color) {
                            dataSet.backgroundColor = transparentize((<any>CHART_COLOR)[dataSet.color], 0.8);
                            dataSet.borderColor = (<any>CHART_COLOR)[dataSet.color];
                            delete dataSet.color;
                        }

                        if (dataSet.stack)
                            dataSet.stack = `${ comparisonGroupIndex }-${ dataSet.stack }`;

                        config.data.datasets.push(dataSet);
                    });
                    contextIndex++;
                });
            }
        }

        // using full row/column by default for row/column selection
        if ((!config.data || !config.data.datasets) && (cell || selection.selectedColumn || selection.selectedRow)) {
            if (!config.data) config.data = {};

            config.data.datasets = [];

            if (cell) {
                const cellData: INamedValue[] = context.cell.getValue();
                if (!config.data.labels) config.data.labels = cellData.map(d => d.displayName);
                config.data.datasets.push({
                    data: cellData.map(d => d.value)
                });
            }
            else {
                let contextIndex = 0;
                this.forEachComparisonAndSelection(config, context, selection, (comparisonGroup, comparisonGroupIndex) => {
                    const label = [context.comparisonLabel, context.multipleSelection && context.selectionLabel].filter(x => x).join(', ')

                    const selectionData = selection.selectedColumn
                        ? context.selectedColumn.getValues()
                        : context.selectedRow.getValues();

                    if (contextIndex == 0) {
                        if (!config.data.labels) config.data.labels = selectionData.map(d => d.displayName);

                        if ((config.type === CHART_TYPE.horizontalWaterfall || config.type === CHART_TYPE.waterfall)) {
                            const systemNames = selectionData.map(d => d.systemName);
                            this.convertWaterfallItems(<IWaterfallChartConfiguration> config, systemNames);
                        }
                    }

                    config.data.datasets.push({
                        label,
                        data: selectionData.map(d => d.value)
                    });
                    contextIndex++;
                });
            }
        }

        return config;
    }

    private convertWaterfallItems(waterfallConfig: IWaterfallChartConfiguration, systemNames: string[]) {
        if (isArray(waterfallConfig.options.absoluteValues))
            this.convertSystemNamesToIndexes(waterfallConfig.options.absoluteValues, systemNames);
        if (waterfallConfig.options.showAbsoluteValues)
            this.convertSystemNamesToIndexes(waterfallConfig.options.showAbsoluteValues, systemNames);
    }

    private convertSystemNamesToIndexes(args: any[], systemNames: string[]) {
        args.forEach((v: any, i: number) => {
            if (isString(v)) {
                const index = systemNames.indexOf(v);
                // if (index === -1) throw `Element ${v} not found`;
                args[i] = index;
            }
        });
    }

    private processHorizontalBarChartConfiguration(config: IBarReportChartConfiguration,
        reportModel: IReportModel,
        isDefault: boolean,
        selection: IReportModelSelection,
        cell: IReportModelCell) {
        return this.processBarChartConfiguration(config, reportModel, isDefault, selection, cell);
    }

    private processWaterfallChartConfiguration(config: IBarReportChartConfiguration,
        reportModel: IReportModel,
        isDefault: boolean,
        selection: IReportModelSelection,
        cell: IReportModelCell) {

        if (!config.options.comparisonContextFilter)
            config.options.comparisonContextFilter = (context) => context.selectedColumn ? context.isSelectedContext : context.isLastComparisonContext;

        const selectionCopy = { ...selection };
        selectionCopy.selectedRows = selectionCopy.selectedColumns = [];

        return this.processBarChartConfiguration(config, reportModel, isDefault, selectionCopy, cell);
    }

    private processHorizontalWaterfallChartConfiguration(config: IBarReportChartConfiguration,
        reportModel: IReportModel,
        isDefault: boolean,
        selection: IReportModelSelection,
        cell: IReportModelCell) {
        if (!config.options.comparisonContextFilter)
            config.options.comparisonContextFilter = (context) => context.selectedColumn ? context.isSelectedContext : context.isLastComparisonContext;

        const selectionCopy = { ...selection };
        selectionCopy.selectedRows = selectionCopy.selectedColumns = [];

        return this.processBarChartConfiguration(config, reportModel, isDefault, selectionCopy, cell);
    }

    private processPieChartConfiguration(config: IBarReportChartConfiguration,
        reportModel: IReportModel,
        isDefault: boolean,
        selection: IReportModelSelection,
        cell: IReportModelCell) {
        return this.processBarChartConfiguration(config, reportModel, isDefault, selection, cell);
    }

    private isNamedValuesArray(array: any[]) {
        return array.some(el => el && (el.value !== undefined || el.displayName !== undefined || el.systemName !== undefined));
    }

    private evalExpression<T>(reportModel: IReportModel, config: IReportChartConfiguration, expr: string) {
        if (config.eval) expr = `${ config.eval } \n ${ expr }`;
        return reportModel.parser.evaluateExpression<T>(expr, null, null, false, false);
    }

    reportModelHasColumnCharts(reportModel: IReportModel) {
        const hasExplicitCharts = reportModel.ColumnDefinitions.some(c => c.Chart && !!c.Chart.columnSelection);
        const hasImplicitCharts = reportModel.Chart && reportModel.Chart.columnSelection ? true : false;
        return hasExplicitCharts || hasImplicitCharts;
    }

    reportModelHasRowCharts(reportModel: IReportModel) {
        const hasExplicitCharts = reportModel.Rows.some(r => r.RowDefinition && !!r.RowDefinition.Chart);
        const hasImplicitCharts = reportModel.Chart && reportModel.Chart.rowSelection ? true : false;
        return hasExplicitCharts || hasImplicitCharts;
    }
}

register.service("reportChartService", ReportChartService);