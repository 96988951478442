'use strict';

import * as angular from 'angular';
import { IStateService } from "angular-ui-router";

const excludeStates: any = {
    'accessObjectIsOwnerOfGroups': [],
    'accessObjectIsMemberOfGroups': [],
    'groupMembers': [],
    'groupOwners': [],
    'groupAssignmentLogs': [],
    'groupAssignmentLogsOfAccessObject': [],
    'accessReview': [],
    'currentUserAccessRequests': [],
    'accessRequestsApproval': [],

    'about': [],
    'modules': [],
    'domainservice': [],
    'pageNotFound': [],

    'auditByEntity': [],
    'auditByType': [],

    'dataModelTypesState': [],

    'baloiseOldReleaseNotesState': [],
    'releaseNotesState': [],

    'catalog': ['User', 'Group', 'Activity', 'GroupConflict', 'currentUserAccessRequests', 'accessRequestApprovals', 'ExportTemplate', 'TransactionRecordFile', 'AppendixRecordFile', 'ReconciliationRecordFile'],
    'details': []
};

export const isReplace = (state: any, params: any) => {
    let name = (state && state.self) ? state.self.name : state;
    return Object.keys(excludeStates).includes(name)
        && (excludeStates[name].length == 0 || excludeStates[name].includes(params['entityType']))
}

angular.module('systemorph')
    .decorator('$state', /*@ngInject*/($delegate: IStateService) => {
        const originalTransitionTo = $delegate.transitionTo;

        (<any>$delegate).transitionTo = (state: any, params?: {}, options?: any) => {
            const newOptions = (isReplace(state, params) ? options : { ...options, ...{ location: 'replace' } });
            return originalTransitionTo.apply($delegate, [state, params, newOptions]);
        }

    return $delegate;
});