'use strict';

import { register, IDataSourceService, IEntityService, IDataSource } from "@systemorph/web";

interface ILinkToFileEntityScope extends ng.IScope {
    id: string;
    url: string;
    name: string;
    searchText: string;
}

register.directive("linkToFileEntity", () => {
    return {
        replace: true,
        restrict: 'E',
        scope: {
            id: "=",
            name: "=",
            searchText: "="
        },
        template: `<a href="{{::url}}" ng-bind-html="::(name | highlight:searchText)" target="_self"></a>`,
        controller: ($scope: ILinkToFileEntityScope,
                        dataSourceService: IDataSourceService,
                        entityService: IEntityService,
                        $state: ng.ui.IStateService) => {

            var dataSource: IDataSource = dataSourceService.getDataSourceFromScope($scope);
            var fileDownloadStateName = entityService.fileDownloadStateName();

            $scope.url = $state.href(fileDownloadStateName, dataSourceService.extendWithUrlPrefixedDataSource({ id: $scope.id }, dataSource));
        }
    }
});
