'use strict';

import {register} from "@systemorph/web";
import {FORM_ENTITY_SELECTOR_TYPE} from './formEntity.constants';
import {IAngularEvent, IAugmentedJQuery, IScope} from 'angular';
import {FORM_ENTITY_EVENT} from './formEntity.events';

register.directive('formEntityRowMenuItem', () => {
    return {
        replace: true,
        restrict: 'E',
        template: `
            <div class="unv-topbar-group" ng-class="item.cssClass">
                <i ng-if="item.fontIconClass" ng-class="item.fontIconClass" ng-bind="item.fontIconContent"></i>
                <span ng-repeat="subItem in item.subItems" class="unv-topbar-item" ng-class="::subItem.cssClass">
                    <menu-item-renderer item="subItem" is-heading="false" level="level + 1"></menu-item-renderer>
                </span>
            </div>
        `,
        transclude: true
    };
});

register.directive('mainFormEntity', () => {
    return {
        template: `
            <div>
                <form-entity provider-selector="mainFormEntityCtrl.selector"
                             use-state-params="true">
                </form-entity>
            </div>
        `,
        controller: MainFormEntityController,
        controllerAs: 'mainFormEntityCtrl'
    };
});

const formEntityHiddenClass = 'form-entity--hidden';

class MainFormEntityController {
    selector = FORM_ENTITY_SELECTOR_TYPE.state;

    constructor($element: IAugmentedJQuery, $scope: IScope) {
        const headerElement = $element.parents("#unv_header");
        headerElement.addClass(formEntityHiddenClass);

        $scope.$on(FORM_ENTITY_EVENT.removed, (event: IAngularEvent, scope: string) => {
            if (!scope) {
                headerElement.addClass(formEntityHiddenClass);
            }
        });

        $scope.$on(FORM_ENTITY_EVENT.added, (event: IAngularEvent, scope: string) => {
            if (!scope) {
                headerElement.removeClass(formEntityHiddenClass);
            }
        });
    }
}