'use strict';

import { register, IPropertyOfEntityScope, COMMON_EVENT, ISystemorphEntity, ANGULAR_EVENT } from "@systemorph/web";
import { ITreeItemModel, TreeUtils } from "@systemorph/ui-web";
import {
    FORM_ENTITY_EVENT,
    IFormEntityDependencyPropertyChangedFeedback,
    FormEntityService, ValueDiff
} from "@systemorph/form-entity-web";
import { IBasicFormEntity } from '../reportingFormEntity.api';
import { IReportingNodeService, IReportingNodeTreeModel } from '../../../reportingWeb.api';
import { IPromise } from "angular";

interface IReportingNodeFormEntityPropertyScope extends IPropertyOfEntityScope {
    entity: IBasicFormEntity;
}

register.directive('reportingNodeFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: `
            <div class="reporting-node-form-entity-property tree-view-form-entity-property">
                <dropdown-tree items="reportingNodeProp.treeItems"
                               ng-if="reportingNodeProp.treeItems"
                               no-options-text="No reporting nodes available"
                               on-select="reportingNodeProp.onItemSelected(item)">
                </dropdown-tree>
            </div>
        `,
        controller: ReportingNodeFormEntityPropertyController,
        controllerAs: 'reportingNodeProp',
    };
});

const reportingNodeAutocompleteCacheKey = "__reportingNodeAutocompleteCache";

class ReportingNodeFormEntityPropertyController {
    treeItems: ITreeItemModel[];
    selectedItem: ITreeItemModel;

    constructor(private $scope: IReportingNodeFormEntityPropertyScope,
                private formEntityService: FormEntityService,
                private reportingNodeService: IReportingNodeService) {

        const dependencyHandlerOff = this.formEntityService.onDependencyPropertyChanged(this.$scope.propertyLayout, changes => {
            return this.refresh().then(diff => {
                const feedback: IFormEntityDependencyPropertyChangedFeedback = {
                    propertyLayout: this.$scope.propertyLayout,
                    newValue: diff.newValue,
                    oldValue: diff.oldValue
                };

                return feedback;
            });
        });

        // this is needed for the case we rerender RESOLVED form entity (means no need to propogate all the properties chain initialize)
        if ($scope.entity[reportingNodeAutocompleteCacheKey]) {
            const desiredSystemName: string = $scope.entity.getStateParamsOrCurrentValue($scope.propertyLayout);
            this.refreshTreeItems($scope.entity[reportingNodeAutocompleteCacheKey], desiredSystemName);
        }

        $scope.$emit(FORM_ENTITY_EVENT.propertyInitialized, $scope.propertyLayout, $scope.entity[$scope.propertyLayout.systemName]);

        this.$scope.$on(FORM_ENTITY_EVENT.refreshProperties, (event, properties: string[], waitPromises: IPromise<any>[]) => {
            if (properties.includes(this.$scope.propertyLayout.systemName)) {
                waitPromises.push(this.refresh());
            }
        });

        this.$scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            dependencyHandlerOff();
            this.$scope.$emit(FORM_ENTITY_EVENT.propertyDestroyed, this.$scope.propertyLayout, $scope.entity[$scope.propertyLayout.systemName]);
        });
    }

    private refresh() {
        const desiredSystemName: string = this.$scope.entity.getStateParamsOrCurrentValue(this.$scope.propertyLayout);

        return this.reportingNodeService.getReportingNodeTreesCached(this.$scope.entity["hierarchy"])
            .then(reportingNodeTrees => {
                const oldValue: any = this.$scope.entity[this.$scope.propertyLayout.systemName];
                this.refreshTreeItems(reportingNodeTrees, desiredSystemName);
                const newValue = this.selectedItem ? this.selectedItem.data : null;
                this.$scope.entity[reportingNodeAutocompleteCacheKey] = reportingNodeTrees;
                this.$scope.entity[this.$scope.propertyLayout.systemName] = newValue;

                return new ValueDiff<any>(newValue, oldValue);
            });
    }

    // NOTE: this trick is done mostly for the formEntityService.
    // Due to the fact that propertyLayout of the reportingNode has type Dimension, formEntityService must access Id, SystemName (Pascal-case) properties of the reportingNode
    // Robust way is to inherit this directive from BaseFormEntityPropertyController and in that controller implement a hook for 
    // getting url value and getting http.send value.
    // After this is done in this directive we can override this 2 methods and return proper values
    private convertToEntity(reportingNodeTree: IReportingNodeTreeModel): ISystemorphEntity {
        if (!reportingNodeTree)
            return null;

        return <any>{
            Id: reportingNodeTree.id,
            SystemName: reportingNodeTree.systemName,
            DisplayName: reportingNodeTree.displayName,
            ...reportingNodeTree
        }
    }

    onItemSelected(item: ITreeItemModel) {
        this.$scope.entity[this.$scope.propertyLayout.systemName] = item.data;
        this.$scope.$emit(COMMON_EVENT.propertyChanged, this.$scope.entity, this.$scope.propertyLayout, item.data);
    }

    private refreshTreeItems(reportingNodeTrees: IReportingNodeTreeModel[], selectedItemSystemName: string) {
        this.treeItems = this.getTreeItems(reportingNodeTrees, selectedItemSystemName); 
        const treeItemsFlat = TreeUtils.flattenTreeNodes(this.treeItems, i => i.children);

        //when tree is not empty and there is no selected node => first root is selected
        if (treeItemsFlat.length && treeItemsFlat.every(x => !x.isSelected)) {
            this.treeItems[0].isSelected = true;
        }

        this.selectedItem = treeItemsFlat.filter(x => x.isSelected)[0];
        //in case root is selected => it should be expanded
        if (this.selectedItem && !this.selectedItem.parent) {
            this.selectedItem.isExpanded = true;
        }
    }

    private getTreeItems(reportingNodes: IReportingNodeTreeModel[], selectedItemSystemName: string) {
        return TreeUtils.getTreeFromAnotherTree<IReportingNodeTreeModel, ITreeItemModel>(reportingNodes,
            x => x.children,
            (reportingNode, children, level): ITreeItemModel => {
                const newNode = {
                    name: this.reportingNodeService.reportingNodeToString(reportingNode),
                    data: this.convertToEntity(reportingNode),
                    isExpanded: (children || []).some(x => x.isSelected || x.isExpanded),
                    isSelected: reportingNode.systemName === selectedItemSystemName,
                    children: children
                }
                children.forEach(c => {
                    c.parent = newNode;
                });
                return newNode;
            });
    }
}