'use strict';

import { register } from "@systemorph/web";
import * as uiGrid from "angular-ui-grid"
import {
    IDataGridColumnDef, IDataGridEditorParameters, IDataGridColumnTypeConfig, IDataGridEditorScope, IDataGridOptions
} from "../dataGrid.api";
import { normalizeComponentName } from "../../../utils";
import { IAngularEvent } from 'angular';
import { kebabCase } from 'lodash';
import { IColumnDisplay } from '../columns/columns.api';
import { DATA_GRID_EDITOR } from '../dataGrid.constants';

export interface IDataGridEditorContainerScope extends IDataGridEditorScope {
    editorContainer: DataGridEditorContainerController;
}

register.directive("datagridEditorContainer", function () {
    return {
        link(scope: IDataGridEditorContainerScope, element: ng.IAugmentedJQuery) {
            scope.editorContainer.link(element);
        },
        controller: DataGridEditorContainerController,
        controllerAs: 'editorContainer'
    }
});

export class DataGridEditorContainerController {
    colDef: IDataGridColumnDef;
    columnDisplay: IColumnDisplay;
    columnTypeConfig: IDataGridColumnTypeConfig;
    entity: any;
    displayValues: any;
    options: IDataGridOptions;
    cellData: any;
    editor: string;
    columnParameters: any;
    editorParameters: IDataGridEditorParameters;

    constructor(private $scope: IDataGridEditorScope,
        private $compile: ng.ICompileService,
        private $timeout: ng.ITimeoutService,
        private uiGridConstants: uiGrid.IGridConstants,
        private uiGridEditConstants: uiGrid.IGridEditConstants) {
        this.colDef = $scope.col.colDef;
        this.columnDisplay = this.colDef.columnDisplay;
        this.columnTypeConfig = this.colDef.columnTypeConfig;
        this.entity = $scope.row.entity.entity;
        this.displayValues = $scope.row.entity.displayValues;
        this.options = $scope.grid.appScope.dataGrid.options;
        this.cellData = $scope.row.entity.cellDataStore[$scope.col.name];
        this.editor = this.columnDisplay.getEditor(this.entity, this.cellData);
        this.columnParameters = this.columnDisplay.getColumnParameters(this.entity, this.cellData) || <any> {};
        this.editorParameters = this.columnDisplay.getEditorParameters(this.entity, this.cellData, this.editor) || <any> {};
    }

    link(element: ng.IAugmentedJQuery) {
        this.$scope.$on(this.uiGridEditConstants.events.BEGIN_CELL_EDIT, (e: IAngularEvent, triggerEvent: JQueryKeyEventObject) => {
            if (triggerEvent.keyCode === this.uiGridConstants.keymap.DEL) {
                const value = this.columnDisplay.getValue(this.entity);
                if (value !== null && !this.editorParameters || !this.editorParameters.isRequired) this.save(null);
                this.$timeout(() => this.$scope.$emit(this.uiGridEditConstants.events.END_CELL_EDIT));
                return;
            }

            const editor = this.editor ? normalizeComponentName(this.editor, "DataGridEditor") : (this.columnTypeConfig ? this.columnTypeConfig.defaultEditor : DATA_GRID_EDITOR.basic);

            if (editor === DATA_GRID_EDITOR.noEditor) {
                this.$timeout(() => this.$scope.$emit(this.uiGridEditConstants.events.END_CELL_EDIT));
                return;
            }

            const template = `<div ${ kebabCase(editor) }></div>`;

            element.html(template);
            this.$compile(element.contents())(this.$scope);
        });
    }

    save(value: any) {
        this.columnDisplay.setValue(this.entity, value);

        // refresh display values
        this.displayValues[this.colDef.name] = this.columnDisplay.getDisplayValue(this.entity, this.cellData);

        if (this.options.saveValue)
            this.options.saveValue(this.entity, this.colDef);
    }

    cancel() {
        if (this.options.cancelEdit)
            this.options.cancelEdit();
    }
}