export const FORM_ENTITY = {
    isIndependentPropertyKey: "isIndependentProperty",
    isExternalPropertyKey: "isExternalProperty",
    skipInOdataQueryKey: "skipInOdataQuery",
    dependenciesKey: 'dependencies',
    mainParameterKey: "mainParameter",
    providerKey: "$formEntityProvider",
    deferredApplyKey: 'deferredApply'
};

export const FORM_ENTITY_SELECTOR_TYPE = {
    state: 'State',
    context: 'Context'
};