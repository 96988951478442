'use strict';
import { extend, IPromise, ITemplateCacheService } from 'angular';
import { register, IPropertyOfEntityScope, arrayToDictionary, COMMON_EVENT, ANGULAR_EVENT } from "@systemorph/web";
import {
    FORM_ENTITY_EVENT,
    IFormEntityDependencyPropertyChangedFeedback,
    IFormEntity,
    FormEntityRegistry,
    FormEntityService
} from '@systemorph/form-entity-web';
import { padStart, Dictionary } from 'lodash';
import { IReportArgsDimensionInstance, IReportArgsDimensionInstanceLazyResult, IFilterFormEntityDimensionModel } from '../reportingFormEntity.api';
import { IReportUtilsService, IFormEntityDimensionModel, FormEntityFilterStrategies } from '../../../reportingWeb.api';
import { IReportService } from "../../../report";

export interface IFilterFormEntityPropertyScope extends IPropertyOfEntityScope {
    entity: IFormEntity;
}

register.directive('filterFormEntityProperty', ($templateCache: ITemplateCacheService) => {
    const popoverTemplateUrl = require.resolve('./filterFormEntityPropertyPopover.html');
    $templateCache.put(popoverTemplateUrl, require('./filterFormEntityPropertyPopover.html'));

    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: `<div class="ui-select-bootstrap filter-popover-trigger">
                        <button type="button"
                                ng-keydown="ctrl.onKeydown($event)"
                                class="btn btn-default form-control ui-select-match"
                                tabindex="-1"
                                ng-class="{'active': ctrl.isOpen}"
                                popover-trigger="none"
                                popover-is-open="ctrl.isOpen"
                                prevent-enter-key
                                uib-popover-template="'${popoverTemplateUrl}'"
                                popover-placement="auto bottom-left"
                                ng-click="ctrl.open()">
                            <span ng-bind="ctrl.displayValue"></span><span class="caret"></span>
                        </button>
                    </div>`,
        controllerAs: "ctrl",
        controller: FilterFormEntityPropertyController
    };
});

register.filter('formEntityOptionName', () => {

    return (value: IReportArgsDimensionInstance) => {

        return (!value) ? '' :

            value.systemName === value.displayName || !value.systemName ? value.displayName : value.displayName + " <b>" + value.systemName + "</b>";

    };

});

export class FilterFormEntityPropertyController {
    options: IFilterFormEntityDimensionModel[];
    activeOption: IFilterFormEntityDimensionModel;
    optionsDictionary: Dictionary<IFilterFormEntityDimensionModel>;
    displayValue: string;
    searchText: string;
    isOpen: boolean;
    showLimitExceeded: boolean;
    selectedItemsLimit: number = 30;
    protected separator: string = ",";
    protected displaySeparator: string = ", ";

    constructor(protected $scope: IFilterFormEntityPropertyScope,
        protected $element: ng.IAugmentedJQuery,
        protected $timeout: ng.ITimeoutService,
        protected $q: ng.IQService,
        protected $http: ng.IHttpService,
        protected formEntityRegistry: FormEntityRegistry,
        protected formEntityService: FormEntityService,
        private reportService: IReportService,
        private reportUtilsService: IReportUtilsService) {

        this.$scope.entity[this.$scope.propertyLayout.systemName] = this.$scope.entity.getStateParamsOrCurrentValue(this.$scope.propertyLayout) || "";

        const dependencyHandlerOff = this.formEntityService.onDependencyPropertyChanged(this.$scope.propertyLayout, changes => {
            const oldValue: string = $scope.entity[$scope.propertyLayout.systemName];
            return this.loadDimensionsAutocompleteAndSetValue().then(() => {
                const newValue: string = $scope.entity[$scope.propertyLayout.systemName];

                const feedback: IFormEntityDependencyPropertyChangedFeedback = {
                    propertyLayout: $scope.propertyLayout,
                    newValue: newValue,
                    oldValue: oldValue
                };

                return feedback;
            });
        });

        $scope.$emit(FORM_ENTITY_EVENT.propertyInitialized, $scope.propertyLayout, $scope.entity[$scope.propertyLayout.systemName]);

        this.$scope.$on(FORM_ENTITY_EVENT.refreshProperties, (event, properties: string[], waitPromises: IPromise<any>[]) => {
            if (properties.includes(this.$scope.propertyLayout.systemName)) {
                waitPromises.push(this.loadDimensionsAutocompleteAndSetValue());
            }
        });

        this.$scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            dependencyHandlerOff();
            this.$scope.$emit(FORM_ENTITY_EVENT.propertyDestroyed, this.$scope.propertyLayout, this.getValue());
        });
    }

    private loadDimensionsAutocompleteAndSetValue(): ng.IPromise<any> {
        return this.reportUtilsService.getFilterDimensions(this.getQueryParams(null), this.reportService.reportDefinition)
            .then(autocomplete => {
                this.activeOption = null;

                this.options = autocomplete
                    .map((x: IFormEntityDimensionModel, index: number): IFilterFormEntityDimensionModel => {
                        return {
                            systemName: x.systemName,
                            shortName: x.shortName,
                            displayName: x.displayName,
                            parentDimension: x.parentDimension,
                            instances: null,
                            currentSelectedInstances: null,
                            initialIds: [], //check this property usages
                            firstLoadingPromise: null,
                            isFirstLoadingPromiseFinished: false,
                            searchingHint: null,
                            searchLoadingPromise: null,
                            isSearchLoadingPromiseFinished: false,
                            counter: "",
                            filterDimensionStrategy: x.filterDimensionStrategy,
                            hasSelected: false,
                            hasDeselected: false,
                            parent: null,
                            level: 0,
                            sortKey: padStart(index.toString(), autocomplete.length.toString().length, '0')
                        }
                    });
                this.optionsDictionary = arrayToDictionary(this.options, x => x.systemName);

                this.options.forEach(option => {
                    if (option.parentDimension)
                        option.parent = this.optionsDictionary[option.parentDimension];
                });

                this.options.forEach((option, index) => {
                    let parent = option.parent;
                    while (parent) {
                        option.level++;
                        option.sortKey = `${ parent.sortKey }_${ option.sortKey }`;
                        parent = parent.parent;
                    }
                });

                this.options.sort((a, b) => a.sortKey.localeCompare(b.sortKey));

                this.updateOptions(this.$scope.entity[this.$scope.propertyLayout.systemName]);

                this.afterOptionsUpdated();
            });
    }

    protected open(): void {
        this.isOpen = true;
        this.activate(this.activeOption || this.options[0]);
    }

    protected clickOutSide(): void {
        this.updateValue();
    }

    protected searchTextChanged(): void {
        if (this.activeOption) {
            if (this.activeOption.filterDimensionStrategy === FormEntityFilterStrategies[FormEntityFilterStrategies.LoadAll]) {
                this.updateCounter(this.activeOption);
            } else {
                if (this.activeOption.filterDimensionStrategy === FormEntityFilterStrategies[FormEntityFilterStrategies.Lazy]) {
                    var searchText = this.searchText;

                    var option = this.activeOption;
                    option.isSearchLoadingPromiseFinished = false;

                    this.activeOption.searchLoadingPromise = this.$http.get<IReportArgsDimensionInstanceLazyResult>('/api/reportUtils/autocompleteFilterDimensions', { params: this.getQueryParams({ dimensionName: this.activeOption.systemName, searchText: this.searchText, filterArgs: this.getValue(this.activeOption.systemName) }) }).then(result => {
                        if (searchText === this.searchText) {
                            //in case search was changed
                            option.isSearchLoadingPromiseFinished = true;

                            option.instances = option.instances.filter(x => x.isSelected);
                            var selectedNames = option.instances.map(x => x.systemName);
                            option.instances = option.instances.concat(result.data.instances.filter(x => selectedNames.indexOf(x.systemName) === -1));
                            result.data.instances.forEach(y => {
                                y.isSelected = false;
                            });
                            option.searchingHint = `Showing <strong>${ result.data.instances.length }</strong> items out of <strong>${ result.data.totalCount }</strong>`;

                            this.updateCounter(option);
                        }
                    });
                }
            }
        }
    }

    protected resetAll(): void {
        this.updateOptions("");
        this.searchTextChanged();
    }

    protected toggleSelected(option: IFilterFormEntityDimensionModel, instance: IReportArgsDimensionInstance): void {
        var isLimitExceeded = this.options
            .map(x => x.initialIds != null && x.initialIds.length || x.instances != null && x.instances.filter(y => y.isSelected).length)
            .reduce((x: number, result: number) => x + result, 0) >= this.selectedItemsLimit;

        if (isLimitExceeded && !instance.isSelected) {
            this.showLimitExceeded = true;
            return;
        }

        // due to click-outside relies on the event.target => element should not be detached
        // bu setting instance.isSelected = false, element is detached
        // first click-outside handler must be executed with attached element and only then element can be detached
        this.$timeout(() => {
            instance.isSelected = !instance.isSelected;
            this.updateCounter(option);
            (<any>this.$element.find('.popover-filter')[0]).focus({ preventScroll: true });
        })
    }

    protected closeInstancesLimitExceededMessage() {
        this.showLimitExceeded = false;
    }

    protected activate(option: IFilterFormEntityDimensionModel): void {
        this.activeOption = option;
        this.searchText = '';

        if (!option.isFirstLoadingPromiseFinished && !option.firstLoadingPromise) {
            if (this.activeOption.filterDimensionStrategy === FormEntityFilterStrategies[FormEntityFilterStrategies.LoadAll]) {
                this.activeOption.firstLoadingPromise = this.$http
                    .get<IReportArgsDimensionInstance[]>('/api/reportUtils/loadAllFilterDimensions', { params: this.getQueryParams({ dimensionName: option.systemName }) }).then(result => {
                        option.isFirstLoadingPromiseFinished = true;
                        option.isSearchLoadingPromiseFinished = true;

                        option.instances = result.data;
                        option.instances.forEach(y => {
                            y.isSelected = option.initialIds.indexOf(y.systemName) !== -1;
                        });
                        option.currentSelectedInstances = option.instances.filter(x => x.isSelected);
                        delete option.initialIds;

                        if (this.activeOption === option) {
                            this.searchTextChanged();
                        }
                    });
            } else {
                if (this.activeOption.filterDimensionStrategy === FormEntityFilterStrategies[FormEntityFilterStrategies.Lazy]) {
                    this.activeOption.firstLoadingPromise = this.$http.get<IReportArgsDimensionInstanceLazyResult>('/api/reportUtils/preSelectedFilterDimensions', { params: this.getQueryParams({ dimensionName: option.systemName, instances: option.initialIds }) }).then(result => {
                        option.isFirstLoadingPromiseFinished = true;

                        option.instances = result.data.instances;
                        option.instances.forEach(y => {
                            y.isSelected = true;
                        });
                        option.currentSelectedInstances = option.instances.filter(x => x.isSelected);
                        delete option.initialIds;

                        if (this.activeOption === option) {
                            this.searchTextChanged();
                        }
                    });
                }
            }
        } else {
            this.searchTextChanged();
        }
    }

    private getQueryParams(additionalParams: any): ng.IPromise<any> {
        var mainProperties = this.$scope.entity.__provider.getMainPropertyNames();
        var ret: any = this.$scope.entity.__provider.getFilterObject(mainProperties);
        return extend({}, ret, additionalParams);
    }

    protected onKeydown(evt: JQueryEventObject): void {
        if (this.isOpen) {
            switch (evt.keyCode) {
                case 27: //esc
                    evt.stopPropagation();
                    evt.preventDefault();
                    this.updateOptions(this.$scope.entity[this.$scope.propertyLayout.systemName]);
                    this.isOpen = false;
                    break;
                case 13: // enter
                case 9: // tab
                    evt.preventDefault();
                    evt.stopPropagation();
                    this.updateValue();
                    break;
            }
        }
    }

    protected getDisplayValue(): string {
        var values = this.options.filter(x => x.initialIds != null && x.initialIds.length !== 0 || x.instances != null && x.instances.some(y => y.isSelected)).map(x => `${ x.shortName || x.displayName }(${ x.instances != null ? x.instances.filter(y => y.isSelected).length : x.initialIds.length })`);
        return values.length === 0 ? "None" : values.join(this.displaySeparator);
    }

    protected getValue(skipDimensionName?: string): string {
        var values = this.options
            .filter(x => !skipDimensionName || x.systemName !== skipDimensionName)
            .filter(x => x.initialIds != null && x.initialIds.length !== 0 || x.instances != null && x.instances.some(y => y.isSelected))
            .map(x => `${ x.systemName }<${ (x.instances != null ? x.instances.filter(y => y.isSelected).map(y => y.systemName) : x.initialIds).map(y => encodeURIComponent(y)).join(this.separator) }>`);
        return values.length > 0 ? values.join(this.separator) : null;
    }

    protected updateOptions(value: string): void {
        value = value || "";
        this.options.forEach(x => {
            if (x.filterDimensionStrategy === FormEntityFilterStrategies[FormEntityFilterStrategies.Lazy] && x.currentSelectedInstances != null) {
                x.instances = x.currentSelectedInstances;
            }

            if (x.instances != null) {
                x.instances.forEach(y => {
                    y.isSelected = false;
                });
            } else {
                x.initialIds = [];
            }
        });

        var reg = new RegExp("(?:^|,)([^\\<]+)\\<([^\\>]*)\\>", "g");
        var match: RegExpExecArray;
        while (match = reg.exec(value)) {
            var dimensionName: string = match[1];
            var instances: string[] = match[2].split(this.separator).map(x => decodeURIComponent(x));

            if (dimensionName in this.optionsDictionary) {
                var option = this.optionsDictionary[dimensionName];

                if (option.instances != null) {
                    option.instances.forEach(y => {
                        y.isSelected = instances.indexOf(y.systemName) !== -1;
                    });
                } else {
                    if (option.initialIds != null) {
                        option.initialIds = instances;
                    }
                }
            }
        }

        this.options.forEach(x => {
            this.updateCounter(x);
        });
    }

    private updateCounter(option: IFilterFormEntityDimensionModel): void {
        if (option.instances) {
            var filteredInstances = searchFilterFunction(option.instances, this.searchText);
            var filteredSelectedInstancesLength = filteredInstances.filter(y => y.isSelected).length;
            option.hasSelected = filteredSelectedInstancesLength !== 0;
            option.hasDeselected = filteredSelectedInstancesLength !== filteredInstances.length;
        } else {
            option.hasSelected = false;
            option.hasDeselected = false;
        }

        var totalSelectedInstancesLength = option.instances != null ? option.instances.filter(y => y.isSelected).length : option.initialIds.length;
        option.counter = `${ totalSelectedInstancesLength !== 0 ? totalSelectedInstancesLength : "" }`;
    }

    protected updateValue(): void {
        this.isOpen = false;
        var oldValue = this.$scope.entity[this.$scope.propertyLayout.systemName];
        this.afterOptionsUpdated();
        var newValue = this.$scope.entity[this.$scope.propertyLayout.systemName];

        this.options.forEach(x => {
            if (x.instances != null) {
                x.currentSelectedInstances = x.instances.filter(x => x.isSelected);
            }
        });

        if (newValue !== oldValue) {
            this.$scope.$emit(COMMON_EVENT.propertyChanged, this.$scope.entity, this.$scope.propertyLayout, newValue);
        }
    }

    protected afterOptionsUpdated(): void {
        this.$scope.entity[this.$scope.propertyLayout.systemName] = this.getValue();
        this.displayValue = this.getDisplayValue();
    }
}

var searchFilterFunction = (items: IReportArgsDimensionInstance[], searchText: string) => {
    if (!searchText || !searchText.length) {
        return items;
    }

    var searchTestLower = searchText.toLowerCase();
    return items.filter(x => x.displayName.toLowerCase().indexOf(searchTestLower) !== -1 || x.systemName.toLowerCase().indexOf(searchTestLower) !== -1);
};

register.filter("filteredDimensions", () => {
    return searchFilterFunction;
});
