'use strict';

import { register } from "@systemorph/web";

register.directive("environmentInfo", () => {
    return {
        restrict: 'E',
        replace: true,
        template: `
            <div class="environment-info" ng-if="item.parameters['environmentName'] !== ''">
                <p>{{item.parameters['environmentName']}} environment</p>
            </div>
        `      
    };
})