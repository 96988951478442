'use strict';

import { register, IPropertyOfEntityScope } from '@systemorph/web';
import { FORM_ENTITY_SELECTOR_TYPE, FormEntityRegistry, FORM_ENTITY_EVENT, FormEntityProvider } from '@systemorph/form-entity-web';
import { IFileModel, IInterfaceModel } from '@systemorph/interfaces-web/app/interfaceFile/interfaceFileSelectService';
import { InterfaceVersionFileSelectService } from '../interfaceFile';
import { blockUI, IAngularEvent } from 'angular';
import { head, isEmpty, Dictionary } from 'lodash';

interface IInterfaceFileUserInput {
    interface: string;
    fileName: string;
    dataFormat: string;
}

interface IStorageFileModel extends IFileModel {
    isSelected: boolean;
    imported: boolean;
}

class Controller {
    blockName: string;

    activeInterface: IInterfaceModel;
    allInterfaces: IInterfaceModel[];

    readonly formEntitySelector = FORM_ENTITY_SELECTOR_TYPE.context; 
    readonly formEntityScope = `interfaceVersionScope`;
    readonly formEntityContext = `InterfaceContext`; 
    formEntityContextArgs: { interfaceName: string}; 

    allFiles: IStorageFileModel[];
    allSelected: boolean;

    activeDataFormat: string;

    constructor(private $scope: IPropertyOfEntityScope, 
                private blockUI: blockUI.IBlockUIService,
                private formEntityRegistry: FormEntityRegistry,
                private interfaceVersionFileSelectService: InterfaceVersionFileSelectService) {
        this.blockName = `InterfaceVersionFileSelectBlock_${$scope.entity.Id}`;

        this.block();
        this.interfaceVersionFileSelectService.getInterfaces().then(data => {
            this.allInterfaces = data;
            if (!isEmpty(data)) {
                this.selectInterface(head(data));
            }
        }).finally(() => {
            this.unblock();
        })

        const formEntityEvents = [
            FORM_ENTITY_EVENT.updated,
            FORM_ENTITY_EVENT.replaced,
            FORM_ENTITY_EVENT.added
        ];
        formEntityEvents.forEach(eventName => {
            this.$scope.$on(eventName, (event: IAngularEvent, formEntityScope: string) => {
                if (formEntityScope == this.formEntityScope) {
                    this.loadFiles();
                }
            });
        })
    }

    selectInterface(selectedInterface: IInterfaceModel) {
        if (this.activeInterface && this.activeInterface.name == selectedInterface.name) {
            return;
        }
        this.activeInterface = selectedInterface;

        if (isEmpty(this.activeInterface.formats)) {
            this.activeDataFormat = null;
        } else {
            this.activeDataFormat = head(this.activeInterface.formats);
        }

        this.setFormEntityContextArgs();
    }

    private setFormEntityContextArgs() {
        this.formEntityContextArgs = { interfaceName: this.activeInterface.formEntityProvider };
    }

    private loadFiles() {
        this.block();
        this.formEntityRegistry.getFilterObject(this.formEntityScope).then(queryModel => {
            queryModel.interface = this.activeInterface.name;
            return this.interfaceVersionFileSelectService.getFiles(this.activeInterface.name, queryModel).then(data => {
                this.allFiles = <IStorageFileModel[]>data;
                this.restoreDefaults();
            })
        }).catch(() => {
            this.allFiles = [];
            this.clearEntityValue();
        }).finally(() => {
            this.unblock();
        })
    }

    restoreDefaults() {
        this.allFiles.forEach((f: IStorageFileModel) => { f.isSelected = (f.imported == null || !f.imported); });
        this.setAllSelected();
        this.updateEntityValue();
    }

    selectFile() {
        this.updateEntityValue();
        this.setAllSelected();
    }

    protected setAllSelected(): void {
        this.allSelected = this.allFiles.every(i => i.isSelected);
    }

    selectAll(): void {
        this.allFiles.forEach(i => i.isSelected = this.allSelected);
        this.updateEntityValue();
    }

    onDataFormatChange() {
        this.updateEntityValue();
    }

    private clearEntityValue() {
        this.$scope.entity[this.$scope.propertyLayout.systemName] = null;
    }

    private updateEntityValue() {
        const userInput: IInterfaceFileUserInput[] = this.allFiles.filter((f: IStorageFileModel) => f.isSelected).map((f: IStorageFileModel) => <IInterfaceFileUserInput>{
            interface: this.activeInterface.name,
            fileName: f.name,
            dataFormat: this.activeDataFormat
        });

        this.$scope.entity[this.$scope.propertyLayout.systemName] = userInput;
    }

    private block() {
        this.blockUI.instances.get(this.blockName).start("Loading...")
    }

    private unblock() {
        this.blockUI.instances.get(this.blockName).stop()
    }
}

register.directive("interfaceVersionFileSelect", () => {
    return {
        replace: true,
        scope: true,
        template: require('./interfaceVersionFileSelect.html'),
        controllerAs: 'ctrl',
        controller: Controller
    }
})