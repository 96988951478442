'use strict';

import { register} from "@systemorph/web";
import { DimensionColumnDisplay} from "./dimensionColumnDisplay";
import {IDataGridColumnDef, IDataGridOptions} from "../dataGrid.api";

export class HierarchicalDimensionColumnDisplay extends DimensionColumnDisplay {
    /*@ngInject*/
    constructor(colDef: IDataGridColumnDef, dataGridOptions: IDataGridOptions, $state: ng.ui.IStateService, $filter: ng.IFilterService) {
        super(colDef, dataGridOptions, $state, $filter);
    }

    getTemplate(): string {
        const linkTemplate = super.getTemplate();
        const indent = this.colDef.indentationSize || 18;

        const displayValuePath = this.getDisplayValuePath();

        return `
            <div ng-show="${displayValuePath}" ng-attr-style="padding-left:{{row.entity.hierarchyLevel * ${indent}}}px;">
                <i ng-if="row.entity.isExpandable" class="fa no-selection" ng-class="{'fa-caret-right': !row.entity.isExpanded, 'fa-caret-down': row.entity.isExpanded}" ng-click="grid.appScope.dataGrid.toggleExpand(row)"></i>
                ${linkTemplate}
            </div>`;
    }

    getCsvExportValue(entity: any): any {
        const value = this.getValue(entity);
        return value ? value.SystemName : "";
    }
}

register.factory("hierarchicalDimensionColumnDisplay", function() {
    return HierarchicalDimensionColumnDisplay;
});