/// <reference path="../../../../Scripts/typings/pdfmake.d.ts"/>
'use strict';

import { register } from "@systemorph/web";
import * as pdfmake from 'pdfmake/build/pdfmake';
// import 'pdfmake/build/vfs_fonts';
import { IDataGrid, IDataGridColumn, IDataGridRow } from "../dataGrid.api";
import { dataGridExportType, dataGridVfs } from "./dataGridExport";
import IInjectorService = ng.auto.IInjectorService;
import * as angular from "angular";
import * as uiGrid from "angular-ui-grid"
import { IDataGridExportService, IDataGridExportProvider } from './export.api';


interface IUiGridExporterService {
    pdfExport(grid: uiGrid.IGrid, rowTypes: string, colTypes: string): ng.IPromise<any>;
    csvExport(grid: uiGrid.IGrid, rowTypes: string, colTypes: string): ng.IPromise<any>;

    loadAllDataIfNeeded(grid: uiGrid.IGrid, rowTypes: string, colTypes: string): ng.IPromise<any>;
    getColumnHeaders(grid: uiGrid.IGrid, colTypes: string): any;
    prepareAsPdf(grid: uiGrid.IGrid, exportColumnHeaders: any, exportData: any): any;
    isIE(): boolean;
    downloadPDF(exporterPdfFilename: string, docDefinition: any): void;

    formatAsCsv(exportColumnHeaders: any, exportData: any, exporterCsvColumnSeparator: string): any;
    downloadFile(exporterCsvFilename: any, csvContent: any, exporterCsvColumnSeparator: any, exporterOlderExcelCompatibility: any): void;
    formatFieldAsPdfString(field: any): any;
}

interface IUiGridExporterConstants {
    SELECTED: string;
    VISIBLE: string;
    ALL: string;
}

class DataGridExportService implements IDataGridExportService {
    private http: ng.IHttpService;
    private q: ng.IQService;
    private uiGridExporterService: IUiGridExporterService;
    private uiGridExporterConstants: IUiGridExporterConstants;
    private filter: ng.IFilterService;
    private injector: IInjectorService;
    private exportProvider: IDataGridExportProvider;

    /*@ngInject*/
    constructor(injector: IInjectorService, http: ng.IHttpService, q: ng.IQService, filter: ng.IFilterService, uiGridExporterService: IUiGridExporterService, uiGridExporterConstants: IUiGridExporterConstants) {
        this.http = http;
        this.q = q;
        this.uiGridExporterService = uiGridExporterService;
        this.uiGridExporterConstants = uiGridExporterConstants;
        this.overridePdfFieldFormatter();
        this.filter = filter;
        this.injector = injector;

        (<any> window).pdfMake = (<any> window).pdfMake || {}; (<any> window).pdfMake.vfs = dataGridVfs;
    }

    // overriding the ui-grids formatter to enable column spans and styling
    private overridePdfFieldFormatter() {
        const baseFormatter: any = this.uiGridExporterService.formatFieldAsPdfString;
        this.uiGridExporterService.formatFieldAsPdfString = function (field: any) {
            var value: any = baseFormatter(field);
            if (field.columnSpan || field.style) {
                if (typeof (value) === 'string') value = { text: value };
                value.colSpan = field.columnSpan;
                value.style = field.style;
            }
            return value;
        }
    }

    private reportingNodeName = "ReportingNode";

    exportToCsv(grid: IDataGrid, rowTypes: string, colTypes: string, fileName: string) {
        var options: any = grid.options;

        var exportProvider: IDataGridExportProvider = this.injector.instantiate<IDataGridExportProvider>(this.injector.get<Function>(grid.options.exportProvider), { grid, exportType: dataGridExportType.csv });

        options.exporterCsvFilename = fileName;
        //options.exporterCsvColumnSeparator = fileName;
        //options.exporterOlderExcelCompatibility = fileName;
        options.exporterCsvLinkElement = angular.element(document.querySelectorAll(".custom-csv-link-location"));

        grid.columns.forEach((c: any) => {
            const name = c.colDef.name || c.colDef.field;
            if (!name || name.replace(/ /g, '').length === 0) {
                c.colDef.exporterSuppressExport = true;
            }
        });
        //////////////////////////////////////////
        var self = this;

        return this.uiGridExporterService.loadAllDataIfNeeded(grid, rowTypes, colTypes).then(function () {
            var exportColumnHeaders = options.showHeader ? self.uiGridExporterService.getColumnHeaders(grid, colTypes) : [];
            var exportData = self.getData(grid, rowTypes, colTypes, dataGridExportType.csv, exportProvider);
            var csvContent = self.uiGridExporterService.formatAsCsv(exportColumnHeaders, exportData, options.exporterCsvColumnSeparator);

            self.uiGridExporterService.downloadFile(options.exporterCsvFilename, csvContent, options.exporterCsvColumnSeparator, options.exporterOlderExcelCompatibility);
        });
    }

    exportToPdf(grid: IDataGrid, rowTypes: string, colTypes: string, fileName: string) {
        var options = grid.options;

        var exportProvider: IDataGridExportProvider = this.injector.instantiate<IDataGridExportProvider>(this.injector.get<Function>(grid.options.exportProvider), { grid, exportType: dataGridExportType.pdf });

        options.exporterPdfDefaultStyle = { fontSize: 8 };
        options.exporterPdfTableStyle = { margin: [10, 10, 0, 30] };
        options.exporterPdfTableHeaderStyle = { fontSize: 10, bold: true, color: '#039' };

        var logo = exportProvider.getPdfLogo();

        var headerColumns: any[] = [];

        if (logo)
            headerColumns.push({
                // usually you would use a dataUri instead of the name for client-side printing
                // sampleImage.jpg however works inside playground so you can play with it
                image: logo,
                width: 100,
                margin: [8, 0, 0, 0]
            });

        headerColumns.push({ text: fileName, margin: [20, 2, 10, 2] });

        options.exporterPdfHeader = {
            style: 'headerStyle',
            columns: headerColumns
        };


        options.exporterPdfFooter = (currentPage: number, pageCount: number) => {
            return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
        };

        const originalPdfFormatter = options.exporterPdfCustomFormatter;

        options.exporterPdfCustomFormatter = (docDefinition: any) => {
            docDefinition.styles.headerStyle = { fontSize: 15, bold: true, margin: [50, 10, 50, 0], color: '#039' };
            docDefinition.styles.footerStyle = { fontSize: 8, color: '#666', margin: [50, 0, 0, 0] };
            docDefinition.styles.groupNameStyle = { fontSize: 10, color: '#fff', bold: true, fillColor: '#808080' };
            if (originalPdfFormatter) originalPdfFormatter(docDefinition);
            return docDefinition;
        };
        options.exporterPdfOrientation = 'landscape';
        options.exporterPdfPageSize = 'A4';
        options.exporterPdfMaxGridWidth = 700;
        options.exporterPdfFilename = fileName;

        grid.columns.forEach((c: any) => {
            const name = c.colDef.name || c.colDef.field;
            if (!name || name.replace(/ /g, '').length === 0) {
                c.colDef.exporterSuppressExport = true;
            }
        });

        ////////////////////////////////////////////////////////////////////////////////////////////////

        var self = this;

        return this.uiGridExporterService.loadAllDataIfNeeded(grid, rowTypes, colTypes).then(() => {
            var exportColumnHeaders = self.uiGridExporterService.getColumnHeaders(grid, colTypes);
            exportColumnHeaders.forEach((cd: { align: string, displayName: string, name: string, width: any }) => {
                if (cd.name !== this.reportingNodeName) {
                    cd.width = "*"
                }
            })


            var exportData = self.getData(grid, rowTypes, colTypes, dataGridExportType.pdf, exportProvider);

            var docDefinition = self.uiGridExporterService.prepareAsPdf(grid, exportColumnHeaders, exportData);

            if (self.uiGridExporterService.isIE() || navigator.appVersion.indexOf("Edge") !== -1) {
                self.uiGridExporterService.downloadPDF(options.exporterPdfFilename, docDefinition);
            } else {
                pdfmake.createPdf(docDefinition).open();
            }
        });
    }

    private getData(grid: IDataGrid, rowTypes: string, colTypes: string, exportType: string, exportProvider: IDataGridExportProvider) {
        var data: any[] = [];
        var rows: any;
        var columns: IDataGridColumn[];

        switch (rowTypes) {
            case this.uiGridExporterConstants.ALL:
                rows = grid.rows;
                break;
            case this.uiGridExporterConstants.VISIBLE:
                rows = grid.getVisibleRows();
                break;
            case this.uiGridExporterConstants.SELECTED:
                if (grid.api.selection) {
                    rows = grid.api.selection.getSelectedGridRows();
                } else {
                    //gridUtil.logError('selection feature must be enabled to allow selected rows to be exported');
                }
                break;
        }

        if (colTypes === this.uiGridExporterConstants.ALL) {
            columns = grid.columns;
        } else {
            var leftColumns = grid.renderContainers.left ? grid.renderContainers.left.visibleColumnCache.filter(function (column: any) { return column.visible; }) : [];
            var bodyColumns = grid.renderContainers.body ? grid.renderContainers.body.visibleColumnCache.filter(function (column: any) { return column.visible; }) : [];
            var rightColumns = grid.renderContainers.right ? grid.renderContainers.right.visibleColumnCache.filter(function (column: any) { return column.visible; }) : [];

            columns = leftColumns.concat(bodyColumns, rightColumns);
        }

        const isColumnVisible = (gridCol: IDataGridColumn) => (gridCol.visible || colTypes === this.uiGridExporterConstants.ALL) &&
            gridCol.colDef.exporterSuppressExport !== true &&
            grid.options.exporterSuppressColumns.indexOf(gridCol.name) === -1;

        var visibleColumns = columns.filter(c => isColumnVisible(c));

        // var exportProvider: IDataGridExportProvider = this.injector.instantiate<IDataGridExportProvider>(this.injector.get<Function>(grid.options.exportProvider), { grid, exportType });

        rows.forEach(function (row: IDataGridRow, index: number) {
            if (row.exporterEnableExporting !== false) {

                // todo: deprecated, should be removed after old reports are migrated to formula framework
                if (row.entity.isGroupHeader) {
                    data.push([{ value: row.entity.groupName, columnSpan: visibleColumns.length, style: 'groupNameStyle' }]);
                    return;
                }

                data.push(exportProvider.getExportedRow(row, visibleColumns));
            }
        });

        return data;
    }
}

register.factory("dataGridExportService", ($injector: IInjectorService, $http: ng.IHttpService, $q: ng.IQService, $filter: ng.IFilterService, uiGridExporterService: IUiGridExporterService, uiGridExporterConstants: IUiGridExporterConstants) => {
    return new DataGridExportService($injector, $http, $q, $filter, uiGridExporterService, uiGridExporterConstants);
});
