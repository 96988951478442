'use strict';

import { register } from "@systemorph/web";
import {
    IDataGridColumnDef,
    IDataGridCellParams,
    IDataGridOptions,
} from "../dataGrid.api";
import { getTextWidth } from "../../../utils";
import { IColumnDisplay } from './columns.api';
import { DATA_GRID_EDITOR, DATA_GRID_COLUMN_TYPE } from '../dataGrid.constants';

// needed to measure the text width
const defaultFont = "13px Lato";

// this is the base property display object that displays the un-formatted
// value this way - entity[colDef.name]
export class BaseColumnDisplay implements IColumnDisplay {
    protected colDef: IDataGridColumnDef;
    protected dataGridOptions: IDataGridOptions;

    /*@ngInject*/
    constructor(colDef: IDataGridColumnDef, dataGridOptions: IDataGridOptions) {
        this.colDef = colDef;
        this.dataGridOptions = dataGridOptions;
    }

    getValue(entity: any) {
        return this.dataGridOptions.getPropertyValue(entity, this.colDef);
    }

    setValue(entity: any, value: any) {
        this.dataGridOptions.setPropertyValue(entity, this.colDef, value);
    }

    getDisplayValue(entity: any, cellData: any) {
        return this.getValue(entity);
    }

    getCellParams(entity: any, cellData: any) {
        const isEditable = this.isEditableCell(entity, cellData);
        return <IDataGridCellParams> {
            isEditable,
            classes: this.getCellClasses(entity, cellData, isEditable)
        };
    }

    getCellData(entity: any) {
        return this.dataGridOptions.getCellData ? this.dataGridOptions.getCellData(entity, this.colDef) : null;
    }

    isEditableCell(entity: any, cellData: any) {
        return this.dataGridOptions.isEditableCell ? this.dataGridOptions.isEditableCell(entity, this.colDef, cellData) : false;
    }

    getColumnParameters(entity: any, cellData: any) {
        return this.dataGridOptions.getColumnParameters ? this.dataGridOptions.getColumnParameters(entity, this.colDef, cellData) : (this.colDef.parameters || null);
    }

    getEditor(entity: any, cellData: any) {
        return this.dataGridOptions.getEditor ? this.dataGridOptions.getEditor(entity, this.colDef, cellData) : (this.colDef.editor || DATA_GRID_EDITOR.basic);
    }

    getEditorParameters(entity: any, cellData: any, actualEditor: string) {
        return this.dataGridOptions.getEditorParameters ? this.dataGridOptions.getEditorParameters(entity, this.colDef, cellData, actualEditor) : (this.colDef.editorParameters || null);
    }

    getValidators(entity: any, cellData: any) {
        return this.dataGridOptions.getValidators ? this.dataGridOptions.getValidators(entity, this.colDef, cellData) : (this.colDef.validators || null);
    }

    getCellClasses(entity: any, cellData: any, isEditable: boolean) {
        const classes: string[] = [];
        if (isEditable) classes.push('is-editable');
        if (this.colDef.columnType == DATA_GRID_COLUMN_TYPE.rowHeader) classes.push('row-header');
        if (this.colDef.columnType == DATA_GRID_COLUMN_TYPE.rowNumber) classes.push('row-number');
        if (this.dataGridOptions.getCellClasses) {
            const customClasses = this.dataGridOptions.getCellClasses(entity, this.colDef, cellData);
            if (customClasses) classes.push(...customClasses);
        }
        return classes.length > 0 ? classes.join(' ') : null;
    }

    getPdfExportValue(entity: any, cellData: any) {
        return this.getDisplayValue(entity, cellData);
    }

    getCsvExportValue(entity: any, cellData: any) {
        return this.getDisplayValue(entity, cellData);
    }

    getDisplayWidth(displayValue: any, cellData: any, hierarchyLevel?: number): number {
        return this.getTextWidth(displayValue ? displayValue.toString() : 0);
    }

    getTemplate() {
        const displayValuePath = this.getDisplayValuePath();
        return `<span ng-bind-html="${ displayValuePath }"></span>`;
    }

    protected getDisplayValuePath() {
        return `row.entity.displayValues['${ this.colDef.name }']`;
    }

    protected getCellConfigPath() {
        return `row.entity.cellConfig['${ this.colDef.name }']`;
    }

    protected getCellDataPath() {
        return `row.entity.cellDataStore['${ this.colDef.name }']`;
    }

    protected getTextWidth(text: string, font = defaultFont) {
        return getTextWidth(text, font);
    }
}

register.factory("baseColumnDisplay", function () {
    return BaseColumnDisplay;
});