'use strict';

import { register } from "@systemorph/web";
import { IProcessModel} from "../businessProcess.api";

register.directive("simpleBusinessProcessDetails", () => {
    return {
        replace: true,
        scope: true,
        template: require('./simpleBusinessProcessDetails.html'),
        controllerAs: "ctrl",
        controller: SimpleBusinessProcessController,
        bindToController: {
            processModel: "=",
            masterProcessName: "=",
            disablePromotion: '='
        }
    }
});

class SimpleBusinessProcessController {
    //bindned
    processModel: IProcessModel;
    masterProcessName: string; 
    disablePromotion: boolean; 

    constructor() {
    }
}