'use strict';

import { register } from "@systemorph/web";
import { IScope } from "angular";

register.directive('baloiseReleaseNotes', () => {
    return {
        replace: true,
        template: require('./baloiseReleaseNotes.html'),
        transclude: true,
        controller: ($scope: IScope) => {}
    };
});