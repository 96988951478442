'use strict';

import * as angular from 'angular';
import { register } from '@systemorph/web';

register.directive("appendToBody", function($document: any, $window: any) {
    function getOffset(element: any) {
        var boundingClientRect = element[0].getBoundingClientRect();
        return {
            width: boundingClientRect.width || element.prop('offsetWidth'),
            height: boundingClientRect.height || element.prop('offsetHeight'),
            top: boundingClientRect.top + ($window.pageYOffset || $document[0].documentElement.scrollTop),
            left: boundingClientRect.left + ($window.pageXOffset || $document[0].documentElement.scrollLeft)
        };
    }

    return function link(scope: any, element: any, attrs: any) {
        scope.$watch(attrs.appendToBody, function(isOpen: boolean) {
            if (isOpen) {
                positionDropdown();
            } else {
                resetDropdown();
            }
        });

        // Move the dropdown back to its original location when the scope is destroyed. Otherwise
        // it might stick around when the user routes away or the select field is otherwise removed
        scope.$on('$destroy', function() {
            resetDropdown();
        });

        // Hold on to a reference to the .ui-select-container element for appendToBody support
        var placeholder: any = null,
            originalWidth: any = '';

        function positionDropdown() {
            // Remember the absolute position of the element
            var offset = getOffset(element);

            // Clone the element into a placeholder element to take its original place in the DOM
            placeholder = angular.element('<div class="append-to-body-placeholder"></div>');
            // placeholder[0].style.width = offset.width + 'px';
            // placeholder[0].style.height = offset.height + 'px';
            placeholder[0].style.display = 'none';
            element.after(placeholder);

            // Remember the original value of the element width inline style, so it can be restored
            // when the dropdown is closed
            originalWidth = element[0].style.width;

            // Now move the actual dropdown element to the end of the body
            $document.find('body').append(element);

            element[0].style.position = 'absolute';
            element[0].style.left = offset.left + 'px';
            element[0].style.top = offset.top + 'px';
            element[0].style.width = offset.width + 'px';
        }

        function resetDropdown() {
            if (placeholder === null) {
                // The dropdown has not actually been display yet, so there's nothing to reset
                return;
            }

            // Move the dropdown element back to its original location in the DOM
            placeholder.replaceWith(element);
            placeholder = null;

            element[0].style.position = '';
            element[0].style.left = '';
            element[0].style.top = '';
            element[0].style.width = originalWidth;
        }
    };
});