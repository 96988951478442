'use strict';

import { register } from "@systemorph/web";
import { IFileModel, IInterfaceModel } from '@systemorph/interfaces-web/app/interfaceFile/interfaceFileSelectService';
import { IPromise, IHttpService, IQService } from "angular";

interface IVersionFileModel extends IFileModel {
    version: number;
}

export class InterfaceVersionFileSelectService implements InterfaceVersionFileSelectService {

    private readonly baseUri = '/api/interfaceVersionFileSelect/';

    constructor(private $http: IHttpService, private $q: IQService) {
    }

    getFiles(interfaceName: string, queryModel: any): IPromise<IFileModel[]> {
        if (!interfaceName) {
            return this.$q.when([]);
        }

        return this.$http.get<IVersionFileModel[]>(`${this.baseUri}Files`, { params: { interfaceName, ...queryModel } }).then(result => {
            return (queryModel.version) ? result.data.filter((f: IVersionFileModel) => f.version == queryModel.version) : result.data;
        });
    }

    getFileVersions(interfaceName: string, queryModel: any): IPromise<number[]> {
        if (!interfaceName) {
            return this.$q.when([]);
        }

        return this.$http.get<number[]>(`${this.baseUri}FileVersions`, { params: { interfaceName, ...queryModel } }).then(result => result.data);
    }


    getInterfaces(): IPromise<IInterfaceModel[]> {
        return this.$http.get<IInterfaceModel[]>(`${this.baseUri}Interfaces`, {}).then(result => result.data);
    }
}

register.service('interfaceVersionFileSelectService', InterfaceVersionFileSelectService)