'use strict';

import {Chart} from "chart.js";

/**
 * Splits the text across several lines so that it fits the given width
 * @param {string} text
 * @param {CanvasRenderingContext2D} ctx canvas context, needed for measuring the text's width
 * @param {number} maxWidth width in pixels
 * @returns {string[]}
 */
export function wordWrap(text: string, ctx: any, maxWidth: number): string[] {
    let lastLineBreakIndex: number, lastSpace: number;

    for (let i = 0; i < text.length; i++) {
        const isSpace = text[i] == ' ',
            isLineBreak = text[i] == "\n";

        if (isSpace || isLineBreak || i == text.length - 1) {
            const str = text.substring(lastLineBreakIndex ? lastLineBreakIndex + 1 : 0, i);
            if (ctx.measureText(str).width > maxWidth) {
                if (lastSpace !== undefined) {
                    text = text.substr(0, lastSpace) + "\n" + text.substr(lastSpace + 1);
                    lastLineBreakIndex = lastSpace;
                }
            }

            if (isSpace) {
                lastSpace = i;
            }
            else if (isLineBreak) {
                lastLineBreakIndex = i;
            }

            // console.log(str, ctx.measureText(str).width, maxWidth, text);
        }
    }

    return text.split("\n");
}

// copied from core.scale.js
export function parseFontOptions(options: any) {
    var valueOrDefault = Chart.helpers.valueOrDefault;
    var globalDefaults = Chart.defaults.global;
    var size = valueOrDefault(options.fontSize, globalDefaults.defaultFontSize);
    var style = valueOrDefault(options.fontStyle, globalDefaults.defaultFontStyle);
    var family = valueOrDefault(options.fontFamily, globalDefaults.defaultFontFamily);

    return {
        size: size,
        style: style,
        family: family,
        font: Chart.helpers.fontString(size, style, family)
    };
}