'use strict';

import { register } from "@systemorph/web";
import { PROCESS_DOCUMENTATION_EVENT } from '../processDocumentation.events';
import { IScope, blockUI } from "angular";
import { IProcessDocumentationService, IProcessDocumentationExportModel } from '../processDocumentation.api';

register.directive("processDocumentationOverviewHeader", () => {
    return {
        replace: true,
        restrict: 'E',
        template: `
            <header class="p-header">
            <div class="pull-right export-word" ng-if="!headerCtrl.noData">
                <button type="button" class="btn btn-link btn-link-new" title="Export" ng-click="headerCtrl.export()">
                    <i class="fa fa-file-word-o"></i> Export
                </button>
            </div>
            <h1>Process Documentation</h1>
        </header>`,
        controllerAs: "headerCtrl",
        controller: ProcessDocumentationOverviewHeaderController
    }
});

export class ProcessDocumentationOverviewHeaderController {
    constructor(protected $scope: IScope,
        protected blockUI: blockUI.IBlockUIService,
        protected processDocumentationService: IProcessDocumentationService) {
    }

    export() {
        var ret: IProcessDocumentationExportModel[] = [];

        this.$scope.$broadcast(PROCESS_DOCUMENTATION_EVENT.collectExportProcessDocumentationItems, ret);

        this.blockUI.start('Loading...');

        this.processDocumentationService.export(ret)
            .finally(() => this.blockUI.stop());
    }
}
