import {IFilterSearchProperty, SimpleFilterSearchProperty} from "./positions.api";

export const VIEW_MODE = {
    min: 'min',
    full: 'full'
};

export const DISPLAY_ORDER_START_VALUE = 10;
export const DISPLAY_ORDER_INCREMENT = 10;

export const POSITION_PROPERTY = {
    systemName: 'systemName',
    displayName: 'displayName',
    cacheBehavior: 'cacheBehavior',
    type: 'type',
    applicability: 'applicability',
    code: 'code'
};

export const FILTER_SEARCH_PROPERTIES: IFilterSearchProperty[] = [
    new SimpleFilterSearchProperty(POSITION_PROPERTY.systemName),
    new SimpleFilterSearchProperty(POSITION_PROPERTY.displayName),
    new SimpleFilterSearchProperty(POSITION_PROPERTY.cacheBehavior),
    new SimpleFilterSearchProperty(POSITION_PROPERTY.type),
    { systemName: POSITION_PROPERTY.applicability, getText: position => position.formulas.map(f => f.applicability)},
    { systemName: POSITION_PROPERTY.code, displayName: 'Formula', getText: position => position.formulas.map(f => f.code)},
];

export const DEFAULT_FILTER_CASE_SENSITIVE = false;
export const DEFAULT_FILTER_SEARCH_PROPERTIES = FILTER_SEARCH_PROPERTIES.map(p => p.systemName);
export const DEFAULT_VIEW_MODE = VIEW_MODE.full;

export const CACHE_BEHAVIOR_OPTIONS = [
    { value: 'P', displayName: 'P'},
    { value: 'D', displayName: 'D'},
    { value: null, displayName: 'None'}
];

export const POSITION_STATE = {
    importPosition: 'importPositionState',
    importPositionRegistry: 'importPositionRegistryState',
    reportPosition: 'reportPositionState',
    reportPositionRegistry: 'reportPositionRegistryState'
};