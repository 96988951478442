'use strict';

import {register, STATE} from "@systemorph/web";
import {IPositionRegistryEntryModel} from "../positions.api";
import {POSITION_STATE} from "../position.constants";
import {IReportUtilsService} from "../../reportingWeb.api";
import {IScope} from "angular";
import {IStateParamsService, IStateService} from "angular-ui-router";
import {PositionRegistryController} from "./positionRegistryController";

interface IImportPositionRegistryStateParams extends IStateParamsService {
    import: string;
    state: string;
    storage: string;
}

class ImportPositionRegistryController extends PositionRegistryController {
    import: string;

    /*@ngInject*/
    constructor($scope: IScope,
                private $state: IStateService,
                $stateParams: IImportPositionRegistryStateParams,
                reportUtilsService: IReportUtilsService) {
        super($stateParams.state, $stateParams.storage, `Import Position Registry`, $scope, reportUtilsService);

        this.import = $stateParams.import;
    }

    getEntryUrl(entry: IPositionRegistryEntryModel, editableTypeName: string) {
        return this.$state.href(POSITION_STATE.importPosition, {
            import: this.import,
            state: this.state,
            storage: this.storage,
            positionName: entry.positionName,
            editableTypeName
        });
    }
}

register.state({
    name: POSITION_STATE.importPositionRegistry,
    parent: STATE.defaultLayout,
    url: '/importPositionRegistry?import&state&storage',
    template: require('./positionRegistry.html'),
    controllerAs: 'registryCtrl',
    controller: ImportPositionRegistryController,
    suppressReloadMessage: true
});