'use strict';

import { register } from "@systemorph/web";
import { DataGridExportProvider, IDataGrid, /*do not remove*/IDataGridExportedField } from "@systemorph/ui-web";
import { IReportGridRow, IReportGridColumn } from './reportGrid.api';
import { REPORT_MODEL_ROW_TYPE } from '../../report';

export class ReportGridExportProvider extends DataGridExportProvider {
    /*@ngInject*/
    constructor(exportType: string, grid: IDataGrid) {
        super(exportType, grid);
    }
    getExportedRow(row: IReportGridRow, columns: IReportGridColumn[]) {
        if (row.entity.entity.RowDefinition.RowType === REPORT_MODEL_ROW_TYPE.title)
            return [{ value: row.entity.entity.Row, columnSpan: columns.length, style: 'titleRowStyle' }];

        return super.getExportedRow(row, columns);
    }
}

register.factory("reportGridExportProvider", () => {
    return ReportGridExportProvider;
});
