'use strict';

import { register, IMenuItemScope, ANGULAR_EVENT, DOM_EVENT } from "@systemorph/web";
import {
    IActiveProcessInfo, IBusinessProcessService,
    ICurrentProcessModel
} from "../businessProcess.api";
import { DEFAULT_BRANCH_INFO } from '../businessProcess.constants';
import { blockUI, ITimeoutService, IDocumentService, IRootScopeService, IQService, IPromise } from "angular";

interface IBusinessProcessSelectorMenuItemScope extends IMenuItemScope {
    processSelector: BusinessProcessSelectorController;
}

var currentLabel: string = null;
var isChangingActiveProcess = false;

register.directive('businessProcessSelectorMenuItem', () => {
    return {
        restrict: 'E',
        replace: true,
        template: `
            <div click-outside="processSelector.close()">
                <div class="active-process" ng-click="processSelector.toggleIsOpen()">{{processSelector.label}} <i class="fa fa-sort-desc"></i></div>
                <div class="process-list" ng-if="processSelector.isOpen">
                    <div ng-show="processSelector.isLoading" class="loading">Loading...</div>
                    <div ng-if="!processSelector.isLoading">
                        <div ng-show="!processSelector.processes.length" class="no-processes">No current processes found</div>
                        <div class="process" 
                            ng-repeat="process in processSelector.processes" 
                            ng-class="{active: processSelector.isActiveProcess(process), 'default-branch': processSelector.isDefaultBranch(process)}" 
                            ng-click="processSelector.setActiveProcess(process)">{{process.displayName}}</div>
                        <div class="view-all"><a href="/allProcesses" ng-click="processSelector.close()">View all processes</a></div>
                    </div>
                </div>
            </div>
        `,
        controller: BusinessProcessSelectorController,
        controllerAs: 'processSelector'
    };
});

class BusinessProcessSelectorController {
    processes: ICurrentProcessModel[];
    label: string;
    isOpen: boolean;

    private activeProcessInfo: IActiveProcessInfo;
    isLoading: boolean;

    constructor($scope: IBusinessProcessSelectorMenuItemScope,
                private businessProcessService: IBusinessProcessService,
                private blockUI: blockUI.IBlockUIService,
                $document: IDocumentService,
                private $rootScope: IRootScopeService,
                private $q: IQService,
                private $timeout: ITimeoutService) {

        this.label = currentLabel;

        this.refreshActiveProcess()
            .then(() => {
                if (isChangingActiveProcess)
                    this.unblock();
            });

        var onKeydown = (event: any) => {
            if (event.keyCode === 27) this.close();
        };

        $document.keydown(onKeydown);

        $scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            $document.off(DOM_EVENT.keydown, onKeydown);
            currentLabel = this.label;
        });
    }

    toggleIsOpen() {
        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            this.isLoading = true;
            this.loadCurrentProcesses()
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }

    close() {
        this.isOpen = false;
        this.$timeout();
    }

    setActiveProcess(process: ICurrentProcessModel) {
        if (!this.isActiveProcess(process)) {
            isChangingActiveProcess = true;
            this.block();
            this.label = process.displayName;
            this.close();
            this.businessProcessService.setActiveProcess(process.branchName);
        }
    }

    isActiveProcess(process: ICurrentProcessModel) {
        return this.activeProcessInfo.process && process.branchName === this.activeProcessInfo.process.branchName
            || process.branchName === DEFAULT_BRANCH_INFO.name && this.activeProcessInfo.branchName === DEFAULT_BRANCH_INFO.name && this.activeProcessInfo.isHeadRevision;
    }

    isDefaultBranch(process: ICurrentProcessModel) {
        return process.branchName === DEFAULT_BRANCH_INFO.name;
    }

    private refreshActiveProcess() {
        return this.businessProcessService.getActiveProcessInfo()
            .then(activeProcessInfo => {
                this.activeProcessInfo = activeProcessInfo;
                if (activeProcessInfo.branchName === DEFAULT_BRANCH_INFO.name && activeProcessInfo.isHeadRevision) {
                    this.label = DEFAULT_BRANCH_INFO.displayName;
                }
                else if (activeProcessInfo.process) {
                    this.label = this.activeProcessInfo.process.displayName;
                }
                else {
                    const processName = activeProcessInfo.branchName === DEFAULT_BRANCH_INFO.name ? DEFAULT_BRANCH_INFO.displayName : activeProcessInfo.branchName;
                    this.label = `History Snapshot (${processName}, rev. ${activeProcessInfo.revision})`;
                }
            });
    }

    private loadCurrentProcesses() {
        return this.businessProcessService.getCurrentProcesses()
            .then(processes => {
                this.processes = processes;
                this.processes.splice(0, 0, {
                    displayName: DEFAULT_BRANCH_INFO.displayName,
                    branchName: DEFAULT_BRANCH_INFO.name,
                        processId: null,
                        processSystemName: null,
                        year: null,
                        period: null,
                        periodicity: null,
                        state: null
                });
            });
    }

    private block() {
        this.blockUI.start('Loading...');
    }

    private unblock() {
        this.blockUI.stop();
    }
}
