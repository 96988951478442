import { register, IDateTimeService } from "@systemorph/web";

import { IDataGridExportService, IDataGridExportProvider, IDataGrid, dataGridExportType } from '@systemorph/ui-web';
import { IBasicFormEntity } from '@systemorph/reporting-web';
import * as angular from "angular";
import * as uiGrid from "angular-ui-grid";
import IInjectorService = ng.auto.IInjectorService;

import * as pdfmake from 'pdfmake/build/pdfmake';


interface IUiGridExporterService {
    loadAllDataIfNeeded(grid: uiGrid.IGrid, rowTypes: string, colTypes: string): ng.IPromise<any>;
    getColumnHeaders(grid: uiGrid.IGrid, colTypes: string): any;

    formatAsCsv(exportColumnHeaders: any, exportData: any, exporterCsvColumnSeparator: string): any;
    downloadFile(exporterCsvFilename: any, csvContent: any, exporterCsvColumnSeparator: any, exporterOlderExcelCompatibility: any): void;

    prepareAsPdf(grid: uiGrid.IGrid, exportColumnHeaders: any, exportData: any): any;
    isIE(): boolean;
    downloadPDF(exporterPdfFilename: string, docDefinition: any): void;
}

interface IExtendedDataGridExportService extends IDataGridExportService {
    getData(grid: IDataGrid, rowTypes: string, colTypes: string, exportType: string, exportProvider: IDataGridExportProvider): any[];
}

export enum OutputType { Csv, Pdf };

export interface IMetadataExportService {
    formatMetadata(formEntity: IBasicFormEntity, metadataObj: { [key: string]: string[] }, type: OutputType, isExclude?: boolean): string;
    formatContent(content: any, metadata: any, type: OutputType): any;
}

export interface IBaloiseDataGridExportService {
    exportToCsvWithMetadata(grid: IDataGrid, rowTypes: string, colTypes: string, fileName: string, formEntity: IBasicFormEntity, metadataObj: { [key: string]: string[] }): ng.IPromise<any>;
    exportToPdfWithMetadata(grid: IDataGrid, rowTypes: string, colTypes: string, exportName: string, formEntity: IBasicFormEntity, metadataObj: { [key: string]: string[] }): angular.IPromise<any>;
}

class MetadataExportService implements IMetadataExportService {
    private exclude = ['year', 'period'];
    private timestamp = 'timestamp';
    constructor(private dateTimeService: IDateTimeService) {
    }

    formatMetadata(formEntity: IBasicFormEntity, metadataObj: { [key: string]: string[] }, type: OutputType, isExclude = true): string {
        const reduceResult = (type == OutputType.Csv) ? this.reduceCsvResult : this.reducePdfResult;
        let result: any = (type == OutputType.Csv) ? "" : [];

        return Object.keys(metadataObj).reduce((metadata: any, key: string) => {
            if (key.indexOf('$') == -1 && (!isExclude || isExclude && this.exclude.indexOf(key) == -1)) {
                let val = this.getValue(key, formEntity, metadataObj);
                if (key == this.timestamp) {
                    val = this.dateTimeService.parse(val).toLocaleString();
                }
                return reduceResult(metadata, key, val);
            }
            return metadata;
        }, result);
    }

    formatContent(content: any, metadata: any, type: OutputType): any {
        return (type == OutputType.Csv) ? this.formatCsvContent(content, metadata) : this.formatPdfContent(content, metadata)
    }

    private getValue(key: string, formEntity: IBasicFormEntity, metadataObj: { [key: string]: string[] }): string {
        return (metadataObj[key] && metadataObj[key] !== null) ? metadataObj[key] : (formEntity[key] && formEntity[key] !== null) ? formEntity[key] : "";
    }

    private reduceCsvResult(sum: any, key: string, val: string): any {
        let line = `${key.toUpperCase()},"${val}"`;
        return (sum) ? `${sum}\r\n${line}` : line;
    }

    private reducePdfResult(sum: any, key: string, val: string): any {
        let line = [[{ text: `${key.toUpperCase()}:`, style: 'labelMetadata' }, { text: val, style: 'valueMetadata' }]];
        sum.push(line);
        return sum;
    }

    private formatCsvContent(content: any, metadata: any): string {
        return (!metadata) ? content : `@@Metadata\r\n${metadata}\r\n@@Body\r\n${content}`;
    }

    private formatPdfContent(docDefinition: any, metadata: any): any {
        const metadataStyles = {
            labelMetadata: { fontSize: 12, color: '#8e8e8e', bold: true },
            valueMetadata: { fontSize: 12 },
            tableMetadata: { margin: [0, 5, 0, 15], width: 'auto' }
        }
        docDefinition.styles = { ...docDefinition.styles, ...metadataStyles };

        const metadataContent = {
            style: 'tableMetadata',
            layout: 'noBorders',
            table: {
                headerRows: 0,
                body: metadata
            }
        };
        docDefinition.content = [metadataContent, ...docDefinition.content];

        return docDefinition;
    }
}


export class BaloiseDataGridExportService implements IBaloiseDataGridExportService {
    private metadataService: IMetadataExportService;
    constructor(
        private injector: IInjectorService,
        private dataGridExportService: IExtendedDataGridExportService,
        private uiGridExporterService: IUiGridExporterService,
        private dateTimeService: IDateTimeService) {

        this.metadataService = new MetadataExportService(dateTimeService);
    }

    exportToCsvWithMetadata(grid: IDataGrid, rowTypes: string, colTypes: string, fileName: string, formEntity: IBasicFormEntity, metadataObj: { [key: string]: string[] }) {
        var options: any = grid.options;

        var exportProvider: IDataGridExportProvider = this.injector.instantiate<IDataGridExportProvider>(this.injector.get<Function>(grid.options.exportProvider), { grid, exportType: dataGridExportType.csv });

        options.exporterCsvFilename = fileName;
        options.exporterCsvLinkElement = angular.element(document.querySelectorAll(".custom-csv-link-location"));

        grid.columns.forEach((c: any) => {
            const name = c.colDef.name || c.colDef.field;
            if (!name || name.replace(/ /g, '').length === 0) {
                c.colDef.exporterSuppressExport = true;
            }
        });

        let metadata = this.metadataService.formatMetadata(formEntity, metadataObj, OutputType.Csv, false);

        return this.uiGridExporterService.loadAllDataIfNeeded(grid, rowTypes, colTypes).then(() => {
            var exportColumnHeaders = options.showHeader ? this.uiGridExporterService.getColumnHeaders(grid, colTypes) : [];
            var exportData = this.dataGridExportService.getData(grid, rowTypes, colTypes, dataGridExportType.csv, exportProvider);
            var csvContent = this.uiGridExporterService.formatAsCsv(exportColumnHeaders, exportData, options.exporterCsvColumnSeparator);
            csvContent = this.metadataService.formatContent(csvContent, metadata, OutputType.Csv);

            this.uiGridExporterService.downloadFile(options.exporterCsvFilename, csvContent, options.exporterCsvColumnSeparator, options.exporterOlderExcelCompatibility);
        });
    }

    exportToPdfWithMetadata(grid: IDataGrid, rowTypes: string, colTypes: string, exportName: string, formEntity: IBasicFormEntity, metadataObj: { [key: string]: string[] }): angular.IPromise<any> {
        var options: any = grid.options;

        var exportProvider: IDataGridExportProvider = this.injector.instantiate<IDataGridExportProvider>(
            this.injector.get<Function>(grid.options.exportProvider),
            { grid, exportType: dataGridExportType.pdf });

        options.exporterPdfDefaultStyle = { fontSize: 8 };
        options.exporterPdfTableStyle = { margin: [10, 10, 0, 30] };
        options.exporterPdfTableHeaderStyle = { fontSize: 10, bold: true, color: '#039' };

        var logo = exportProvider.getPdfLogo();

        var headerColumns: any[] = [];

        const { year, period } = formEntity;
        const headerName = `${exportName}_${year}_${period.replace('_', '')}.pdf`;

        if (logo)
            headerColumns.push({
                // usually you would use a dataUri instead of the name for client-side printing
                // sampleImage.jpg however works inside playground so you can play with it
                image: logo,
                width: 100,
                margin: [8, 0, 0, 0]
            });

        headerColumns.push({ text: headerName, margin: [20, 2, 10, 2] });

        options.exporterPdfHeader = [
            {
                style: 'headerStyle',
                columns: headerColumns
            },
        ];

        options.exporterPdfFooter = (currentPage: number, pageCount: number) => {
            return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
        };

        const originalPdfFormatter = options.exporterPdfCustomFormatter;

        options.exporterPdfCustomFormatter = (docDefinition: any) => {
            docDefinition.styles.headerStyle = { fontSize: 15, bold: true, margin: [50, 10, 50, 0], color: '#039' };
            docDefinition.styles.footerStyle = { fontSize: 8, color: '#666', margin: [50, 0, 0, 0] };
            docDefinition.styles.groupNameStyle = { fontSize: 10, color: '#fff', bold: true, fillColor: '#808080' };
            if (originalPdfFormatter) originalPdfFormatter(docDefinition);
            return docDefinition;
        };
        options.exporterPdfOrientation = 'landscape';
        options.exporterPdfPageSize = 'A4';
        options.exporterPdfMaxGridWidth = 700;
        options.exporterPdfFilename = `${exportName}.pdf`;

        grid.columns.forEach((c: any) => {
            const name = c.colDef.name || c.colDef.field;
            if (!name || name.replace(/ /g, '').length === 0) {
                c.colDef.exporterSuppressExport = true;
            }
        });

        ////////////////////////////////////////////////////////////////////////////////////////////////

        return this.uiGridExporterService.loadAllDataIfNeeded(grid, rowTypes, colTypes).then(() => {
            var exportColumnHeaders = this.uiGridExporterService.getColumnHeaders(grid, colTypes);
            exportColumnHeaders.forEach((cd: { align: string, displayName: string, name: string, width: any }) => {
                if (cd.name !== (<any>this.dataGridExportService).reportingNodeName) {
                    cd.width = "*"
                }
            })

            var exportData = this.dataGridExportService.getData(grid, rowTypes, colTypes, dataGridExportType.pdf, exportProvider);

            var docDefinition = this.uiGridExporterService.prepareAsPdf(grid, exportColumnHeaders, exportData);
            let metadata = this.metadataService.formatMetadata(formEntity, metadataObj, OutputType.Pdf);
            docDefinition = this.metadataService.formatContent(docDefinition, metadata, OutputType.Pdf);

            if (this.uiGridExporterService.isIE() || navigator.appVersion.indexOf("Edge") !== -1) {
                this.uiGridExporterService.downloadPDF(options.exporterPdfFilename, docDefinition);
            } else {
                pdfmake.createPdf(docDefinition).open();
            }
        });
    }
}

register.factory("baloiseDataGridExportService", ($injector: IInjectorService, dataGridExportService: IExtendedDataGridExportService, uiGridExporterService: IUiGridExporterService, dateTimeService: IDateTimeService) => {
    return new BaloiseDataGridExportService($injector, dataGridExportService, uiGridExporterService, dateTimeService);
});

