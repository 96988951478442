'use strict';

import { register, IPropertyOfEntityScope, STATE } from '@systemorph/web';
import { IStateService } from 'angular-ui-router';

register.directive('typeLinkProperty', () => {
    return {
        replace: true,
        restrict: 'E',
        scope: true,
        template: `<span>
                        <a ng-show="!ctrl.isAbstract" class="details-string" ng-href="{{::ctrl.url}}" ng-bind="::ctrl.entityType"></a>
                        <span ng-show="ctrl.isAbstract" class="details-string" ng-bind="::ctrl.entityType"></span>
                    </span>`,
        controller: Controller,
        controllerAs: 'ctrl'
    }
});

const IS_ABSTRACT_TYPE = 'Abstract';

class Controller {
    url: string;
    entityType: string;
    isAbstract: boolean;
    constructor($scope: IPropertyOfEntityScope, $state: IStateService) {
        this.entityType = $scope.entity[$scope.propertyLayout.systemName];
        const relatedProperty = $scope.propertyLayout.displayDirectiveParameters['rel'];
        this.isAbstract = $scope.entity[relatedProperty];
        if (!this.isAbstract) {
            this.url = $state.href(STATE.catalog, { entityType: this.entityType });
        }
    }
}