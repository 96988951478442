'use strict';

import {register} from "@systemorph/web";
import * as angular from 'angular';

interface IFormulaDebugContextPanelScope extends angular.IScope {
    contextPanelCtrl: FormulaDebugContextPanelController;
}

register.directive('formulaDebugContextPanel', function() {
    return {
        replace: true,
        restrict: 'E',
        template: `
            <section ng-if="contextPanelCtrl.isReady"
                     class="unv-cpl-section unv-cpl-section-formula-debug" 
                     ng-class="{'is-focused': contextPanelCtrl.isFocused}" 
                     ng-mousedown="contextPanelCtrl.focus()" 
                     click-outside="contextPanelCtrl.blur()">
                <header class="unv-cpl-section-header">
                    <h2 class="unv-cpl-section-title">Formula Debug</h2>
                </header>
                <render directive="formula-debug-container" 
                        is-focused="contextPanelCtrl.isFocused"></render>
            </section>
        `,
        controller: FormulaDebugContextPanelController,
        controllerAs: 'contextPanelCtrl',
        link: (scope: IFormulaDebugContextPanelScope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes) => {
            scope.contextPanelCtrl.link();
        }
    }
});

class FormulaDebugContextPanelController {
    isReady: boolean;
    isFocused = true;

    constructor(private $timeout: angular.ITimeoutService,
                private blockUI: angular.blockUI.IBlockUIService) {
    }

    link() {
        this.blockUI.start('Loading...');

        require.ensure(['../formulaDebug'], require => {
            require('../formulaDebug');

            this.$timeout(() => {
                this.isReady = true;
                this.blockUI.stop();
            });
        });
    }

    focus() {
        this.isFocused = true;
    }

    blur() {
        this.isFocused = false;
    }
}