'use strict';

import { register, COMMON_EVENT } from "@systemorph/web";
import { IProcessDocumentationQAndA, IProcessDocumentationComment, IProcessDocumentationChangeStatusOption, IProcessDocumentationService } from '../processDocumentation.api';
import { ITemplateCacheService } from "angular";

const templateKey = require.resolve('./processDocumentationStatusPopover.html');
register.directive("processDocumentationStatus", ($templateCache: ITemplateCacheService) => {
    $templateCache.put(templateKey, require('./processDocumentationStatusPopover.html'));

    return {
        restrict: 'E',
        replace: true,
        scope: true,
        controllerAs: "ctrl",
        bindToController: {
            item: "=",
            popupRightOriented: "=",
        },
        template: require('./processDocumentationStatus.html'),
        controller: ProcessDocumentationStatusController
    }
});

class ProcessDocumentationStatusController {
    item: IProcessDocumentationComment | IProcessDocumentationQAndA;
    popupRightOriented: boolean;
    isOpen: boolean;
    options: IProcessDocumentationChangeStatusOption[];

    popoverTemplateUrl = templateKey;

    constructor(private $scope: ng.IScope, private $rootScope: ng.IScope, private processDocumentationService: IProcessDocumentationService) {
    }

    changeStatus(status: string) {
        this.isOpen = false;
        return this.processDocumentationService.changeStatus(this.item.Id, status).then(() => {
            this.$rootScope.$broadcast(COMMON_EVENT.commitDataToServer);
        });
    }

    getOptions() {
        return this.processDocumentationService.getChangeStatusOptions(this.item.Id).then(options => {
            this.options = options;
        });
    }
}

