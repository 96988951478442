'use strict';

import { KEY } from '@systemorph/web';
import { IAugmentedJQuery, IFormController, ITimeoutService } from 'angular';
import { AsyncFormController } from "../utils";

export abstract class SmFormController {
    form: IFormController;
    asyncForm: AsyncFormController;

    isEditing: boolean;
    isEditingProperty: string;

    constructor(protected $timeout: ITimeoutService) {
    }

    protected isEditorVisible(field: string) {
        return this.isEditing || this.isEditingProperty == field;
    }

    protected onPropertyMousedown(property: string) {
        if (!this.isEditingProperty) {
            this.startEditing(property);
        }
    }

    protected startEditing(property?: string) {
        if (property) {
            this.isEditingProperty = property;
            this.isEditing = false;
        }
        else {
            this.isEditingProperty = null;
            this.isEditing = true;
        }

        // resetting $submitted to hide validation messages before "Save" is pressed
        this.form.$setPristine();
    }

    protected onEditorBlur() {
        if (this.isEditingProperty) {
            this.$timeout(() => {
                this.trySave();
            });
        }
    }

    protected trySave() {
        if (this.form.$pending) {
            this.asyncForm.onPendingResolved(() => this.trySave());
            return;
        }

        this.form.$setSubmitted();

        if (this.form.$valid) {
            if (this.form.$dirty) {
                this.save();
            }
            else {
                this.cancelEditing();
            }
        }
    }

    protected save() {
        this.isEditingProperty = null;
        this.isEditing = false;
    }

    protected onEditorKeydown(keyCode: number) {
        if (keyCode == KEY.esc) {
            this.cancelEditing();
        }
    }

    protected cancelEditing() {
        this.isEditingProperty = null;
        this.isEditing = false;
    }
}