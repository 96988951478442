'use strict';

import { register } from "@systemorph/web";
import { IReleaseVersionService, IReleaseVersion } from "../../releaseNotes.api";

register.directive('currentReleaseInfo', (): any => {
    return {
        restrict: 'E',
        replace: true,
        template: require('./currentReleaseInfo.html'),
        controllerAs: 'ctrl',
        controller: CurrentReleaseInfoController
    }
});

class CurrentReleaseInfoController {
    release: IReleaseVersion

    constructor(private releaseVersionService: IReleaseVersionService) {
        this.releaseVersionService.getCurrentReleaseVersion().then((result: IReleaseVersion) => {
            this.release = result;
        });
    }
}
