'use strict';


import { register } from "@systemorph/web";
import { IGroupAssignmentModel, IGroupAssignmentService } from '@systemorph/access-control-web';
import { IPromise, IHttpService, ILocationService, ITimeoutService } from "angular";

export interface IBaloiseGroupAssignmentService extends IGroupAssignmentService {
    parseTimestamp(data: IGroupAssignmentModel[]): IGroupAssignmentModel[];
}

register.decorator('groupAssignmentService', ($delegate: IBaloiseGroupAssignmentService, $http: IHttpService) => {

    const baseApiUrl: string = '/api/baloiseGroupAssignmentUtils/';

    $delegate.getGroupAssignmentsToBeApprovedByCurrentUser = (): IPromise<IGroupAssignmentModel[]> => {
        return $http.get<IGroupAssignmentModel[]>(`${baseApiUrl}preparedGroupAssignmentsToBeApprovedByCurrentUser`, {})
            .then(result => $delegate.parseTimestamp(result.data));
    }

    return $delegate;
});
