'use strict';

import { register, STATE, setPageTitle } from "@systemorph/web";
import { IScope } from "angular";

register.state({
    name: "dataModelTypesState",
    parent: STATE.defaultLayout,
    url: '/dataModelTypes',
    template: require('./dataModelTypes.html'),
    controller: /*@ngInject*/($scope: IScope) => {
        setPageTitle($scope, 'Data Model Types');
    },
    suppressReloadMessage: true
})