'use strict';

import { IDeferred, IQService } from "angular";

export class DeferredStore<T> {
    private deferred: IDeferred<T>;

    constructor(private $q: IQService) {
    }

    get() {
        return this.getDeferred().promise;
    }

    set(value: T) {
        this.getDeferred().resolve(value);
    }

    reset() {
        this.deferred = null;
    }

    private getDeferred() {
        if (!this.deferred) {
            this.deferred = this.$q.defer<T>();
        }

        return this.deferred;
    }
}