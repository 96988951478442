'use strict';

import {register, getOrAddCachedValue, IDataSourceService} from '@systemorph/web';
import { FormEntityRegistry } from "@systemorph/form-entity-web";
import { IUtilsService } from "@systemorph/ui-web";
import {IPositionRegistryEntryModel} from "./positions/positions.api";
import {uniq} from 'lodash';
import {
    IReportUtilsService,
    IActiveBranchInfo,
    IPeriodModel,
    IFormEntityDimensionModel,
    IReportDefinition
} from './reportingWeb.api';

class ReportUtilsService implements IReportUtilsService {
    private cache: ng.ICacheObject;
    /*@ngInject*/
    constructor(private $http: ng.IHttpService,
                private $q: ng.IQService,
                private $cacheFactory: ng.ICacheFactoryService,
                private formEntityRegistry: FormEntityRegistry,
                private utilsService: IUtilsService,
                private dataSourceService: IDataSourceService
    ) {
        this.cache = $cacheFactory("ReportUtilsService");
    }

    getResultsKeyId(): ng.IPromise<string> {
        return this.$q.all([this.formEntityRegistry.getFilterObjectMainPropertiesOnly()]).then((formEntityResults: any[]) => {
            var filterObject: any = formEntityResults[0];

            var cacheKey = "resultsKeyId" + Object.keys(filterObject).map((k: string) => `${k}:${filterObject[k] || ''}`).join("_");

            return getOrAddCachedValue(this.cache, cacheKey, () => {
                return this.$http.get<string>(`/api/reportUtils/ResultsKeyId`, { params: filterObject })
                    .then(response => {
                        return response.data;
                    });
            });
        });
    }

    isTypeEditable(typeName: string): ng.IPromise<boolean> {
        return this.formEntityRegistry.getFilterObject()
            .then(params => {
                params.typeName = typeName;
                return this.$http.get<boolean>('/api/reportUtils/isTypeEditable', { params })
                    .then(result => result.data);
            });
    }

    getBranches() {
        return this.utilsService.getTypeQuery<any>('ChangeSet')
            .then(query => {
                return query.toArray(true)
                    .then((changeSets: any[]) => {
                        return uniq(changeSets.map<string>(c => c.Branch));
                    });
            });
    }

    getActiveBranchInfo() {
        return this.$http.get<IActiveBranchInfo>('/api/reportUtils/activeBranchInfo')
            .then(result => result.data);
    }

    getYears(periodicity: string[]) {
        var cacheKey = `years_${periodicity.join('_') || ''}_${this.dataSourceService.getDataSourceAsString()}`;
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<number[]>(`/api/reportUtils/years`, { params: { periodicity: periodicity} })
                .then(result => {
                    return result.data;
                });
        });
    }

    getPeriods(year: number, periodicity: string[]): ng.IPromise<IPeriodModel[]> {
        var cacheKey = `periods_${year || ''}_${periodicity.join('_') || ''}_${this.dataSourceService.getDataSourceAsString()}`;
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<IPeriodModel[]>(`/api/reportUtils/periods`, { params: { year: year || '', periodicity } })
                .then(result => {
                    return result.data;
                });
        });
    }

    isMonthlyPeriod(period: string): ng.IPromise<boolean> {
        var cacheKey = `isMonthly_${period || ''}`;
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<boolean>(`/api/reportUtils/isMonthlyPeriod`, { params: { period: period || '' } })
                .then(result => {
                    return result.data;
                });
        });
    }

    getSliceDimensions(filterObject: any, reportDefinition?: IReportDefinition): ng.IPromise<IFormEntityDimensionModel[]> {
        var cacheKey = "sliceDimensions" + Object.keys(filterObject).map((k: string) => filterObject[k] || '_')
            + (reportDefinition ? reportDefinition.Slice : '');
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.post<IFormEntityDimensionModel[]>(`/api/reportUtils/sliceDimensions`, reportDefinition, { params: filterObject })
                .then(result => {
                    return result.data;
                });
        });
    }

    getFilterDimensions(filterObject: any, reportDefinition?: IReportDefinition): ng.IPromise<IFormEntityDimensionModel[]> {
        var cacheKey = "filterDimensions" + Object.keys(filterObject).map((k: string) => filterObject[k] || '_')
            + (reportDefinition ? reportDefinition.Slice : '');
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.post<IFormEntityDimensionModel[]>(`/api/reportUtils/filterDimensions`, reportDefinition, { params: filterObject })
                .then(result => {
                    return result.data;
                });
        });
    }

    getPositionRegistryEntries(state: string, storage: string) {
        return this.$http.get<IPositionRegistryEntryModel[]>('/api/reportUtils/positionRegistryEntries', { params: { state, storage } })
            .then(result => result.data);
    }

    getPositionRegistryEntry(state: string, storage: string, positionName: string) {
        return this.$http.get<IPositionRegistryEntryModel>('/api/reportUtils/positionRegistryEntry', { params: { state, storage, positionName } })
            .then(result => result.data);
    }

    exportPositions(editableTypeName: string) {
        return this.$http.post<boolean>('/api/reportUtils/exportPositions', { editableTypeName })
            .then(result => result.data);
    }

    exportReportDefinition(reportType: string) {
        return this.$http.get<boolean>('/api/reportUtils/exportReportDefinition', { params: { reportType } })
            .then(result => result.data);
    }
}

register.service("reportUtilsService", ReportUtilsService);