'use strict';

import { register, STATE } from "@systemorph/web";
import { POSITION_STATE } from '../position.constants';
import { IScope, ITimeoutService, blockUI } from "angular";
import {IStateParamsService} from "angular-ui-router";
import {PositionController} from "./positionController";

interface IReportPositionStateParams extends IStateParamsService {
    report: string;
    state: string;
    storage: string;
    positionName: string;
    editableTypeName: string;
}

class ReportPositionController extends PositionController {
    /*@ngInject*/
    constructor($scope: IScope,
                $timeout: ITimeoutService,
                $stateParams: IReportPositionStateParams,
                blockUI: blockUI.IBlockUIService) {
        // todo: remove hard-code for data level
        const languagePath = ['Report', $stateParams.report, 'Data', $stateParams.positionName];

        super($stateParams.state, $stateParams.storage, $stateParams.positionName, $stateParams.editableTypeName,
            languagePath, $scope, $timeout, blockUI);
    }
}

register.state({
    name: POSITION_STATE.reportPosition,
    parent: STATE.defaultLayout,
    url: '/reportPosition?report&state&storage&positionName&editableTypeName&filterTerm&filterCaseSensitive&filterProperties&mode&page&pageSize',
    template: require('./position.html'),
    controllerAs: 'positionCtrl',
    controller: ReportPositionController,
    suppressReloadMessage: true
});