'use strict';

import { IMenuItemScope, COMMON_EVENT, IMenuItem } from "@systemorph/web";
import { IReportStateParams } from "@systemorph/reporting-web";
import { FormEntityRegistry, FORM_ENTITY_EVENT, FormEntityProvider } from "@systemorph/form-entity-web";
import { IProcessDocumentationItem, IProcessDocumentationService, IProcessDocumentationFormEntityUpdatedProperties } from '../processDocumentation.api';
import { PROCESS_DOC_STATE_NAME } from '../processDocumentation.constants';

export abstract class ProcessDocumentationContextPanelController {
    blockName: string;
    reportType: string;
    viewAllLink: string;
    menuItem: IMenuItem;

    items: IProcessDocumentationItem[];
    canBeAdded: boolean;

    constructor(protected $scope: IMenuItemScope,
        protected $q: ng.IQService,
        protected $timeout: ng.ITimeoutService,
        protected $state: ng.ui.IStateService,
        protected $stateParams: IReportStateParams,
        protected formEntityRegistry: FormEntityRegistry,
        protected blockUI: ng.blockUI.IBlockUIService,
        protected processDocumentationService: IProcessDocumentationService,
        protected typeName: string,
        protected category: string) {

        this.menuItem = $scope.item;
        this.blockName = `${ this.typeName.replace(/\W/g, "") }BlockName`;
        this.reportType = $stateParams.reportType;
        this.viewAllLink = $state.href(PROCESS_DOC_STATE_NAME.processDocumentation, { category: category });

        this.reload();

        this.$scope.$on(FORM_ENTITY_EVENT.updated, (event: ng.IAngularEvent, formEntityScope: string, provider: FormEntityProvider, newValues: IProcessDocumentationFormEntityUpdatedProperties) => {
            if (!formEntityScope) {
                const relevantProperties = provider.getMainPropertyNames();
                if (relevantProperties.some(p => p in newValues)) {
                    //timeout is needed to allow subscribers of this event to clear the caches
                    this.$timeout(() => {
                        this.reload();
                    });
                }
            }
        });

        this.$scope.$on(COMMON_EVENT.commitDataToServer, (e: ng.IAngularEvent) => {
            //timeout is needed to allow subscribers of this event to clear the caches
            this.$timeout(() => {
                this.reload();
            });
        });
        this.$scope.$on(COMMON_EVENT.persistenceContextChanged, (e: ng.IAngularEvent) => {
            //timeout is needed to allow subscribers of this event to clear the caches
            this.$timeout(() => {
                this.reload();
            });
        });
        this.$scope.$on(COMMON_EVENT.dataVersionChanged, (e: ng.IAngularEvent) => {
            //timeout is needed to allow subscribers of this event to clear the caches
            this.$timeout(() => {
                this.reload();
            });
        });
    }

    private reload(): ng.IPromise<IProcessDocumentationItem[]> {
        this.blockUI.instances.get(this.blockName).start("Loading...");

        return this.formEntityRegistry.getFormEntity().then(formEntity => {
            if (!formEntity['year']) {
                this.items = [];
                return;
            }

            return this.$q.all([
                this.processDocumentationService.getProcessDocumentationItems(this.typeName, this.reportType),
                this.processDocumentationService.canCreate(this.typeName, this.reportType)
            ]).then((results: any[]) => {

                this.items = results[0];
                this.canBeAdded = results[1];


                setTimeout(() => { this.$scope.$digest(); }, 0);

                return this.items;
            });
        }).finally(() => this.blockUI.instances.get(this.blockName).stop());
    }

    create(): ng.IPromise<any> {
        return this.processDocumentationService.openProcessDocumentationCreationDialog(this.typeName, this.reportType);
    }

    isEditable(item: IProcessDocumentationItem) {
        return this.processDocumentationService.canEdit(item);
    }

    isDeletable(item: IProcessDocumentationItem) {
        return this.processDocumentationService.canDelete(item);
    }

    delete(item: IProcessDocumentationItem) {
        return this.processDocumentationService.delete(item);
    }

    edit(item: IProcessDocumentationItem) {
        return this.processDocumentationService.edit(item, this.typeName, this.reportType);
    }
}