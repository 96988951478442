'use strict';

import { register } from '@systemorph/web';

export interface IBytesFilter { 
    (bytesInput: number | string, precision?: number): string;
}

register.filter('bytes', () => {
    return (bytesInput: number | string, precision?: number) => {
        const bytes = <number>bytesInput;
        if (isNaN(parseFloat((<string>bytesInput))) || !isFinite((bytes))) {
            return '-';
        }
        if (typeof precision === 'undefined') {
            precision = 1;
        }
        const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
        const number = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${(bytes / Math.pow(1024, number)).toFixed(precision)} ${units[number]}`;
    }
});