'use strict';

import {
    register,
    IPersisterProviderService,
    ISystemorphEntity,
    IEntityService,
    IEntityAccessRightsService,
    ILayoutService } from '@systemorph/web';
import {
    FormulaEditorPropertyController,
    IFormulaEditorItemWrapper,
    IFormulaEditorPropertyScope, languagePathParameterName
} from '../formulaEditorPropertyController';
import {IUtilsService} from '@systemorph/ui-web';
import {IVariableEditorItem} from '../formulaEditor/variableEditorDirective';
import {IAugmentedJQuery, IQService, ITimeoutService} from 'angular';

interface IVariableDefinition extends ISystemorphEntity {
    Name: string;
    Code: string;
    Type: string;
}

interface IVariableEditorItemWrapper extends IVariableEditorItem, IFormulaEditorItemWrapper {
    entity?: IVariableDefinition
}

register.directive('variablesProperty', () => {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        // render is used because editor directive is lazy loaded
        template: `
            <div>
                <div ng-if="!propertyCtrl.isLoading">
                    <render directive="variableEditor"
                            items="propertyCtrl.items"
                            is-editable="propertyCtrl.isEditable"
                            is-creatable="propertyCtrl.isCreatable"
                            is-deletable="propertyCtrl.isDeletable"
                            language-path="propertyCtrl.languagePath"
                            on-save="propertyCtrl.saveItem(item)"
                            on-update="propertyCtrl.updateItem(item, properties)"
                            on-delete="propertyCtrl.deleteItem(item)">
                    </render>
                </div>
                <span ng-if="propertyCtrl.isLoading">Loading...</span>
            </div>
        `,
        controller: VariablesPropertyController,
        controllerAs: 'propertyCtrl'
    }
});

class VariablesPropertyController extends FormulaEditorPropertyController {
    entityTypeName = 'VariableDefinition';

    constructor($scope: IFormulaEditorPropertyScope,
                $q: IQService,
                $timeout: ITimeoutService,
                $element: IAugmentedJQuery,
                persisterProviderService: IPersisterProviderService,
                layoutService: ILayoutService,
                entityAccessRightsService: IEntityAccessRightsService,
                utilsService: IUtilsService,
                entityService: IEntityService) {
        super($scope, $q, $timeout, $element, persisterProviderService, layoutService, entityAccessRightsService, utilsService, entityService);

        this.languagePath = [$scope.propertyLayout.displayDirectiveParameters[languagePathParameterName], $scope.entity.SystemName];
    }

    protected getNewItem(): IVariableEditorItemWrapper {
        return {
            name: null,
            code: null,
            type: null,
            isEditable: true,
            isDeletable: true
        }
    }

    protected mapEditorItemToEntity(item: IVariableEditorItemWrapper, entity: IVariableDefinition): IVariableDefinition {
        entity.Name = item.name;
        entity.Code = item.code;
        entity.Type = item.type;

        return entity;
    }

    protected getItems() {
        return this.utilsService.getCollectionQuery<IVariableDefinition>(this.$scope.entity, this.$scope.propertyLayout.systemName)
            .then(query => {
                return query.orderBy('it.Name').toArray().then(entities => {
                    return entities.map(entity => {
                        const item = this.mapEntityToEditorItem(entity);

                        item.entity = entity;
                        item.isCollapsed = true;

                        return item;
                    });
                });
            });
    }

    private mapEntityToEditorItem(entity: IVariableDefinition): IVariableEditorItemWrapper {
        return {
            name: entity.Name,
            code: entity.Code,
            type: entity.Type,
            isEditable: this.entityAccessRightsService.canEdit(entity),
            isDeletable: this.entityAccessRightsService.canDelete(entity)
        };
    }
}