'use strict';

import { register} from "@systemorph/web";
import { 
    BaseAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyControllerAs,
    defaultAutocompleteFormEntityPropertyTempplate,
} from './baseAutocompleteFormEntityPropertyController';
import { 
    IFormEntityPropertyScope
} from './baseFormEntityPropertyController';
import { IAutocompleteBoxItem, convertToAutocmpleteBoxItems } from "@systemorph/ui-web";
import { IPromise, IQService } from "angular";
import {FormEntityService} from "../formEntityService";

register.directive('independentAutocompleteFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: IndependentAutocompleteFormEntityPropertyController
    };
});

export class IndependentAutocompleteFormEntityPropertyController extends BaseAutocompleteFormEntityPropertyController<string, string> {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                $q: IQService) {
        const autocomplete = convertToAutocmpleteBoxItems(<any[]>$scope.entity[$scope.propertyLayout.systemName + "Autocomplete"])

        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            return $q.when(autocomplete);
        };

        super($scope, formEntityService, getAutocompleteFunc, false);
    }

    protected getUrlOrOldValue(): string {
        //In case entity is not form entity.
        if (this.entity.getStateParamsOrCurrentValue) {
            return <string>this.entity.getStateParamsOrCurrentValue(this.propertyLayout);
        }

        return undefined;
    }

    protected getIndexFromAutocomplete(value: string, autocomplete: IAutocompleteBoxItem[]): number {
        return autocomplete.findIndex(x => x.value === value);
    }
}
