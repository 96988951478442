import {register} from "@systemorph/web";
import {IHttpService, IScope, blockUI, element} from "angular";
import {isEmpty, first, last, map, findIndex} from 'lodash';

const releaseNotesBlock = 'releaseNotesBlock';

register.directive('releaseNotes', () => {
    return {
        template: `
                    <div class="release-notes" block-ui="${releaseNotesBlock}">
                            <multi-select-container selected="rnctrl.selectedReleases" class="release-selector">
                            <h2>Name, date and branch</h2>
                                <div class="release" selectable-line="release" ng-repeat="release in rnctrl.releases">
                                    <p class="release__name">{{release.ReleaseName}}</p>
                                    <p class="release__date">{{release.ReleaseCompleteDate | date: 'dd-MM-yyyy'}}</p>
                                    <p class="release__branch">{{release.Branch}}</p>
                                </div>
                            </multi-select-container>
                            <div class="work-items">
                                <h2>Related Work Items</h2>
                                <span class="no-workitems-stub" ng-if="rnctrl.noData">No related work items were found.<br>There is no difference in work items between current and previous releases.</span>
                                <div ng-if="!rnctrl.noData && !rnctrl.isLoading" ui-grid="rnctrl.gridOptions" ui-grid-selection ui-grid-exporter class="work-items-grid" style="width: auto;">
                                </div>
                            </div>
                    </div>`,
        controllerAs: 'rnctrl',

        controller: ReleaseNotesController
    };
});

const combineIdWithUrl = (data: any[]) =>
    map(data, (row) =>
        ({
            ...row,
            Id: `<a href="${row.Url}" target="_blank">${row.Id}</a>`,
            RealId: row.Id
        }))

class ReleaseNotesController {
    releases: any[] = [];
    selectedReleases: any[] = [];
    gridOptions: any = {};
    noData: boolean;
    isLoading: boolean; // this is needed to fix issue 22831

    constructor(private $scope: IScope, $http: IHttpService, blockUI: blockUI.IBlockUIService) {
        this.gridOptions = {
            exporterMenuVisibleData: true,
            enableGridMenu: true,
            enableSelectAll: true,
            exporterMenuPdf: false,
            gridMenuShowHideColumns: true,
            exporterCsvFilename: 'Releases.csv',
            exporterCsvLinkElement: element(document.querySelectorAll(".custom-csv-link-location")),
            exporterExcelFilename: 'Releases.xlsx',
            exporterExcelSheetName: 'Sheet1',

            exporterFieldCallback: function (grid: any, row: any, col: any, value: any) {
                if (col.name === 'Id') {
                    return row.entity.RealId;
                }
                return value;
            },

            enableFiltering: true,
            columnDefs: [
                {
                    name: 'Id',
                    enableFiltering: false,
                    width: '100',
                    columnType: 'text',
                    cellTemplate: '<div class="ui-grid-cell-contents" ng-bind-html="COL_FIELD"></div>'
                },
                {
                    name: 'RealId',
                    columnType: 'text',
                    visible: false,
                    exporterSuppressExport: true,
                    enableHiding: false
                },
                {
                    name: 'Type',
                    columnType: 'text',
                    enableFiltering: false,
                    width: '100'
                },
                {
                    name: 'Title',
                    columnType: 'text',
                    enableFiltering: false,
                    cellTooltip: true
                },
                {
                    name: 'State',
                    columnType: 'text',
                    enableFiltering: false,
                    width: '150'
                },
                {
                    name: 'Tags',
                    columnType: 'text'
                },
            ],
            onRegisterApi: function (gridApi: any) {
                $scope.gridApi = gridApi;
            }
        }

        this.noData = isEmpty(this.gridOptions.data);
        $http.get('/api/releasenotes/getreleases').then(({data}) => {
            this.releases = <any[]>data;
            this.selectedReleases = [first(this.releases)];
        });

        $scope.$watchCollection(() => this.selectedReleases, (selectedReleases) => {
            if (!isEmpty(selectedReleases)) {
                this.isLoading = true;
                let request = null;
                blockUI.instances.get(releaseNotesBlock).start('Loading workitems...');
                if (selectedReleases.length > 1) {
                    request = $http.get('/api/releasenotes/getreleasediffworkitemsinfo', {
                        params: {
                            newReleaseId: last(selectedReleases).Id,
                            oldReleaseId: first(selectedReleases).Id
                        }
                    }).then(({data}) => {
                        this.gridOptions.data = combineIdWithUrl(<any[]>data);
                        this.noData = isEmpty(this.gridOptions.data);
                    })
                } else {
                    const newReleaseId = first(selectedReleases).Id;
                    const oldRelease = this.releases[findIndex(this.releases, {Id: newReleaseId}) + 1];

                    if (oldRelease) {
                        request = $http.get('/api/releasenotes/getreleasediffworkitemsinfo', {
                            params: {
                                newReleaseId: newReleaseId,
                                oldReleaseId: oldRelease.Id
                            }
                        }).then(({data}) => {
                            this.gridOptions.data = combineIdWithUrl(<any[]>data);
                            this.noData = isEmpty(this.gridOptions.data);
                        })
                    } else {
                        request = $http.get('/api/releasenotes/getreleaseworkitemsinfo',
                            {
                                params: {
                                    releaseId: newReleaseId
                                }
                            })
                            .then(({data}) => {
                                this.gridOptions.data = combineIdWithUrl(<any[]>data);
                                this.noData = isEmpty(this.gridOptions.data);
                            });
                    }
                }

                request.catch(() => {
                    this.gridOptions.data = null;
                    this.noData = isEmpty(this.gridOptions.data);
                }).finally(() => {
                    blockUI.instances.get(releaseNotesBlock).stop();
                    this.isLoading = false;
                });
            }
        })
    }
}
