'use strict';

import {register, setPageTitle, STATE} from "@systemorph/web";
import { RELEASE_NOTES_STATE } from "../releaseNotes.constants";
import { IScope } from 'angular';

class ReleaseNotesController {
    clientReleaseNotesDirective: string;

    private readonly clientReleaseNotesDirectiveKey: string = "clientReleaseNotesDirective";

    /*@ngInject*/
    constructor(private $scope: IScope) {
        setPageTitle(this.$scope, "Release Notes");

        this.clientReleaseNotesDirective = appSettings[this.clientReleaseNotesDirectiveKey];
    }
}

register.state({
    name: RELEASE_NOTES_STATE.releaseNotes,
    parent: STATE.defaultLayout,
    url: '/releaseNotes',
    template: require('./releaseNotes.html'),
    suppressReloadMessage: true,
    controllerAs: 'pageCtrl',
    controller: ReleaseNotesController
})