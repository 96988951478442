'use strict';

import {register} from "@systemorph/web";
import {IDataGridColumnDef, IDataGridOptions} from "../dataGrid.api";
import {BaseColumnDisplay} from "./baseColumnDisplay";

const defaultIndentationSize = 18;

export class HierarchicalColumnDisplay extends BaseColumnDisplay {
    /*@ngInject*/
    constructor(colDef: IDataGridColumnDef, dataGridOptions: IDataGridOptions) {
        super(colDef, dataGridOptions);
    }

    getDisplayWidth(displayValue: any, cellData: any, hierarchyLevel: number): number {
        return super.getDisplayWidth(displayValue, cellData)
            + hierarchyLevel * (this.colDef.indentationSize || defaultIndentationSize)
            + 8; // icon width
    }

    protected getIndentation() {
        return this.colDef.indentationSize || defaultIndentationSize;
    }

    getTemplate(): string {
        const baseTemplate = super.getTemplate();
        const indent = this.getIndentation();

        const displayValuePath = this.getDisplayValuePath();

        return `
            <div ng-show="${displayValuePath}" ng-attr-style="padding-left:{{row.entity.hierarchyLevel * ${indent}}}px;">
                <span ng-click="grid.appScope.dataGrid.toggleExpand(row)">
                    <i ng-if="row.entity.isExpandable" class="fa no-selection" ng-class="{'fa-caret-right': !row.entity.isExpanded, 'fa-caret-down': row.entity.isExpanded}"></i>
                    ${baseTemplate}
                </span>
            </div>`;
    }
}

register.factory("hierarchicalColumnDisplay", function() {
    return HierarchicalColumnDisplay;
});

