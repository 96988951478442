 'use strict';

import {
    register,
    IMessageService,
    IConfirmationService,
    arrayToDictionary
} from '@systemorph/web';
import { 
    IGroupAssignmentOfGroup, 
    IGroupMetaInfo, 
    IGroupAssignmentService, 
    AC_COMMON_EVENT, 
    AC_GROUP_ASSIGNMENT_MODE,
    IGroupAssignmentOfGroupFilter 
} from "@systemorph/access-control-web";
import { IGroupAssignmentsByScopeHierarchy, IReportingNodesAndHierarchyModel } from '../../accessControlByReportingNode.api';
import { IScope, IPromise, blockUI, ITimeoutService, IAngularEvent, IQService, IFormController } from 'angular';
import { TreeUtils } from "@systemorph/ui-web";
import { groupBy } from 'lodash';
import { IReportingNodeTreeModel, IReportingNodeService } from "../../../reportingWeb.api";

register.directive('groupAssignmentsOfGroupByReportingNode', () => {
    return {
        replace: true,
        scope: true,
        template: require('./groupAssignmentsOfGroupByReportingNode.html'),
        controller: GroupAssignmentsOfGroupByReportingNodeController,
        controllerAs: 'garnCtrl',
        bindToController: {
            groupMetaInfo: "=",
            initialFilterText: "=",
            mode: "="
        }
    }
});

class GroupAssignmentsOfGroupByReportingNodeController {
    //bindend
    groupMetaInfo: IGroupMetaInfo;
    initialFilterText: string;
    mode: string;

    hasError: boolean;

    currentGroupAssignmentsHierarchy: IGroupAssignmentsByScopeHierarchy[];
    showStatusFilter: boolean;
    blockName: string;
    isTooManyReportingNodesSelected: boolean;
    assignmentStatus: string;
    filterText: string;

    filterForm: IFormController;

    hierarchyAndReportingNodesModel: IReportingNodesAndHierarchyModel;

    constructor(private $scope: IScope,
                private $timeout: ITimeoutService,
                private messageService: IMessageService,
                private groupAssignmentService: IGroupAssignmentService,
                private reportingNodeService: IReportingNodeService,
                private confirmationService: IConfirmationService,
                private blockUI: blockUI.IBlockUIService) {

        this.init();
    }

    private init(): void {
        this.blockName = `garnBlock_of_${this.groupMetaInfo.systemName}_with_at_${this.groupMetaInfo.assignmentType}`;
        this.showStatusFilter = this.mode === AC_GROUP_ASSIGNMENT_MODE.direct;

        this.assignmentStatus = null;
        this.filterText = this.initialFilterText || '';

        this.startBlock("Loading data...")
        this.reportingNodeService.getAllHierarchyNamesCached(true).then(hierarchies => {
            if (!hierarchies.length) {
                this.hasError = true;
                this.stopBlock();
                return;
            }
            const initialHierarchy = hierarchies[0];
            this.reportingNodeService.getReportingNodeTreesCached(initialHierarchy, true).then(reportingNodes => {
                const reportingNodesFlatten = TreeUtils.flattenTreeNodes(reportingNodes, r => r.children)

                const selectedReportingNodeIds = this.getInitialSelectedIds(reportingNodesFlatten, this.initialFilterText);

                this.hierarchyAndReportingNodesModel = {
                    hierarchy: initialHierarchy,
                    selectedIds: selectedReportingNodeIds
                }

                this.stopBlock();
                this.setGroupAssignmentsAsync();
            })
        })
    
        this.$scope.$on(AC_COMMON_EVENT.deleteGroupAssignment, (e: IAngularEvent, assignment: IGroupAssignmentOfGroup) => {
            e.stopPropagation();
            this.deleteAssignment(assignment);
        })

        this.$scope.$on(AC_COMMON_EVENT.refreshGroupAssignment, (e: IAngularEvent) => {
            this.setGroupAssignmentsAsync();
        })
    }

    private getInitialSelectedIds(reportingNodesFlatten: IReportingNodeTreeModel[], initialFilter: string) {
        if (initialFilter){
            return reportingNodesFlatten.map(x => x.id);
        }
        // TODO find out better way of doing this
        return reportingNodesFlatten.map(x => x.id);
    }

    private currentLoadingPromise: IPromise<any>;

    private setGroupAssignmentsAsync() {
        this.startBlock("Loading assignments...");

        const promise = this.currentLoadingPromise = this.reportingNodeService.getReportingNodeTreesCached(this.hierarchyAndReportingNodesModel.hierarchy, true).then(reportingNodes => {
            const isTooMuchSelected = this.groupAssignmentService.isTooMuchScopesForRequest(this.hierarchyAndReportingNodesModel.selectedIds.filter(x => x));
            const selectedIds = this.hierarchyAndReportingNodesModel.selectedIds.slice();

            const filter: IGroupAssignmentOfGroupFilter = {
                assignmentStatus: this.assignmentStatus,
                assignmentType: this.groupMetaInfo.assignmentType,
                groupId: this.groupMetaInfo.id,
                memberText: this.filterText,
                scopeIds: isTooMuchSelected ? null : this.hierarchyAndReportingNodesModel.selectedIds.filter(x => x),
                mode: this.mode
            }

            return this.groupAssignmentService.getGroupAssignmentsOfGroup(filter)
                .then(groupAssignments => {
                    if (this.currentLoadingPromise === promise) {
                        // apply ui filter in any case
                        groupAssignments = groupAssignments.filter(x => selectedIds.indexOf(x.scopeId) !== -1);
                        this.currentGroupAssignmentsHierarchy = this.getGroupAssignmentsByScopeHierarchy(reportingNodes, groupAssignments);
                    }
                })
                .catch(() => {
                    if (this.currentLoadingPromise === promise) {
                        this.messageService.alertError(`Error on loading group assignments`);
                        this.currentGroupAssignmentsHierarchy = [];
                    }
                })
                .finally(() => {
                    this.$timeout(() => this.stopBlock()); // due to odata request doesnt trigger digest sycle
                })
        }) 

        return promise;
    }
    
    private deleteAssignment(assignment: IGroupAssignmentOfGroup) {
        const flattenToDictionary = arrayToDictionary(TreeUtils.flattenTreeNodes(this.currentGroupAssignmentsHierarchy, x => x.children), x => x.scope.id)

        this.confirmationService.open({
            cancelLabel: "Cancel",
            okLabel: "Ok",
            okCssClass: 'btn-danger',
            size: "lg",
            title: `Please confirm`,
            template: `Remove ${assignment.member.displayName} from ${this.groupMetaInfo.displayName} by ${flattenToDictionary[assignment.scopeId].scope.displayName}?`
        }).then(() => {
            this.startBlock("Deleting assignments...");
            this.groupAssignmentService.deleteGroupAssignments([assignment.id])
                .then(() => {
                    this.stopBlock();
                    this.setGroupAssignmentsAsync();
                })
                .catch((errorMessage: string) => {
                    this.stopBlock();
                    this.messageService.alertError(`Error on deleting group assignments: ${errorMessage}.`);
                });
        });
    }

    filterChanged() {
        if (this.filterForm.$valid) {
            this.setGroupAssignmentsAsync();
        }
    }

    reportingNodeSelectionChanged() {
        this.setGroupAssignmentsAsync();
    }

    hierarchyChanged() {
        this.setGroupAssignmentsAsync();
    }

    private startBlock(message: string): void {
        this.blockUI.instances.get(this.blockName).start(message)
    }

    private stopBlock(): void {
        this.blockUI.instances.get(this.blockName).stop();
    }

    private getGroupAssignmentsByScopeHierarchy(reportingNodes: IReportingNodeTreeModel[], groupAssignments: IGroupAssignmentOfGroup[]) {
        const groupAssignmentsRawGrouped = groupBy(groupAssignments, x => x.scopeId);
    
        return TreeUtils.getTreeFromAnotherTree<IReportingNodeTreeModel, IGroupAssignmentsByScopeHierarchy>(reportingNodes,
            r => r.children,
            (reportingNode, children, level) => {
                const assignments = groupAssignmentsRawGrouped[reportingNode.id];
                if (!assignments && children.length === 0 ) {
                    return null;
                } 
                const newNode: IGroupAssignmentsByScopeHierarchy = {
                    scope: reportingNode,
                    assignments: assignments,
                    isExpanded: true,
                    children: children
                }

                children.forEach(c => {
                    c.parent = newNode;
                });

                return newNode;
            });
    }
}