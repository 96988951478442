'use strict';


import { register, IMenuItemScope } from '@systemorph/web';

register.directive('absolutePathFileLinkMenuItem', () => {
    return {
        replace: true,
        template: `<div>
                    <static-file-link-for-menu menu-item="item" 
                                               static-file-url="absFileUrl"
                                               highlighted-text="highlightedText">
                    </static-file-link-for-menu>
                  </div>`,
        transclude: true,
        controller: ($scope: IMenuItemScope) => {
            $scope.absFileUrl = $scope.item.url;
        }
    };
});