'use strict';

import { register, IPropertyOfEntityScope, IDateTimeService, COMMON_EVENT} from "@systemorph/web";
import { FORM_ENTITY_EVENT } from '../formEntity.events';
import { IFormEntity } from '../formEntity.api';

interface IDateFormEntityPropertyScope extends IPropertyOfEntityScope {
    entity: IFormEntity;
}

register.directive('dateFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: '<div><edit-date-time-property></edit-date-time-property></div>',
        controller: ($scope: IDateFormEntityPropertyScope,  dateTimeService: IDateTimeService) => {
            $scope.entity[$scope.propertyLayout.systemName] = dateTimeService.parse($scope.entity.getStateParamsOrCurrentValue($scope.propertyLayout));

            $scope.$emit(FORM_ENTITY_EVENT.propertyInitialized, $scope.propertyLayout, $scope.entity[$scope.propertyLayout.systemName]);

            setTimeout(() => {
                $scope.$watch(() => $scope.entity[$scope.propertyLayout.systemName], (newVal: Date, oldVal: Date) => {
                    if (newVal !== oldVal) {
                        $scope.$emit(COMMON_EVENT.propertyChanged, $scope.entity, $scope.propertyLayout, newVal, oldVal);
                    }
                });
            }, 0);
        }
    };
});
