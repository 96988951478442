// not used anymore, splitting is done in chartService.renderChart
import { Chart, ChartLayoutPaddingObject } from "chart.js";
import { parseFontOptions, wordWrap } from "../tools";
import * as angular from "angular";
import { IChartConfiguration } from '../charts.api';
import { CHART_AXIS_TYPE } from '../charts.constants';

let originalHeight: number;

// Dynamic word-wrapping support for y-axis labels.
// Respects the line-breaks in original labels.
export const multilineAxisLabelsPlugin = {
    skip: false,

    beforeRender: function (chart: any) {
        if (this.skip) return;

        const config: IChartConfiguration = chart.config;
        const yScale: any = chart.scales['y-axis-0'];

        if (yScale.type == CHART_AXIS_TYPE.category && config.data && (config.data.labels || config.data.yLabels)) {
            if (!config.data.originalLabels)
                config.data.originalLabels = <any> config.data.labels;

            if (!originalHeight)
                originalHeight = $(chart.ctx.canvas.parentNode).height();

            const xScale: any = chart.scales['x-axis-0'];
            const tickFont = parseFontOptions(yScale.options.ticks);
            const title: any = chart.titleBlock;

            const padding = <ChartLayoutPaddingObject> config.options.layout.padding;
            const maxWidth = ($(chart.ctx.canvas.parentNode).width() - padding.left - padding.right) / 2; // yScale.maxWidth;

            // explicitly setting the label font to calculate width properly during wrapping,
            // otherwise width calculation is wrong when tooltips are shown
            const originalFont = chart.ctx.font;
            chart.ctx.font = tickFont.font;

            const wrappedLabels = config.data.originalLabels.map(label => {
                return wordWrap(label, chart.ctx, maxWidth);
            });

            chart.ctx.font = originalFont;

            const currentLabels = config.data.labels.map(l => angular.isArray(l) ? l : [1]);

            const labelsChanged = !angular.equals(wrappedLabels, currentLabels);

            // console.log(`-------------------------------------------\nchartWidth: ${chart.width}, leftPadding: ${padding.left}, rightPadding: ${padding.right}, maxWidth: ${maxWidth}`);

            if (labelsChanged) {
                const linesPerLabel = Chart.helpers.numberOfLabelLines(wrappedLabels);
                const requiredHeight = linesPerLabel * config.data.labels.length * tickFont.size * 1.5;
                config.data.labels = wrappedLabels;

                // calculating required total height as a sum of title height, y-axis and x-axis heights
                // given more elements their height should be accounted as well
                const requiredTotalHeight = requiredHeight + title.height + xScale.height + padding.top + padding.bottom;

                // console.log(`rendering... new height: ${requiredTotalHeight}, old height: ${$(chart.ctx.canvas.parentNode).height()}`);

                // timeout is needed to let the current rendering cycle finish before starting the next one
                setTimeout(() => {
                    $(chart.ctx.canvas.parentNode).height(Math.max(requiredTotalHeight, originalHeight));
                    chart.update();
                    this.skip = false;
                });

                // skipping the consequent calls to beforeRender until wrapping is applied
                // (for some reason beforeRender is called multiple times)
                this.skip = true;

                // cancel rendering
                return false;
            }
        }
    }
};
