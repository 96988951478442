// todo: remove when domain service reports are gone
export const COMPARISON_TYPE = {
    noComparison: "None",
    allQuarters: "AllQuarters",
    previousYear: "PreviousYear",
    previousYearAllQuarters: "PreviousYearAllQuarters",
    previousQuarterAndPreviousYear: "PreviousQuarterAndPreviousYear",
    previousEvenQuarter: "PreviousEvenQuarter",
    previousTwoEvenQuarters: "PreviousTwoEvenQuarters",
    previousThreeEvenQuarters: "PreviousThreeEvenQuarters",
    previousEvenQuarterAndPreviousYear: "PreviousEvenQuarterAndPreviousYear",
    previousHalfYearAndPreviousYear: "PreviousHalfYearAndPreviousYear",
};

export const REPORT_GRID_COLUMN_DISPLAY = {
    reportNumber: 'reportNumberColumnDisplay',
    chart: 'chartColumnDisplay'
};