import { IProcessModel } from "../businessProcess.api";

export const getProcessModelName = (processModel: IProcessModel): string => {
    if (!processModel) {
        return '';
    };

    const systemName = processModel.systemName;
    const displayName = processModel.displayName;

    return systemName === displayName || !systemName ? displayName : `${displayName} (${systemName })`;
}