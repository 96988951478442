'use strict';

import {Dictionary} from "lodash";
import {INgModelController, IQService, IPromise} from "angular";
import {IValidationResult} from "../uiWeb.api";

export abstract class AsyncValidatorController<TValue> {
    errorMessages: Dictionary<Dictionary<string>>;

    constructor(protected validatorName: string,
                protected $q: IQService) {
    }

    setValidator(ngModelCtrl: INgModelController) {
        ngModelCtrl.$asyncValidators[this.validatorName] = (modelValue: TValue, viewValue: TValue) => {
            return this.validate(modelValue)
                .then(validationResult => {
                    if (validationResult) {
                        if (this.errorMessages && ngModelCtrl.$name) {
                            if (!this.errorMessages[ngModelCtrl.$name]) {
                                this.errorMessages[ngModelCtrl.$name] = {};
                            }

                            this.errorMessages[ngModelCtrl.$name][this.validatorName] = this.getMessage(validationResult);
                        }

                        return this.$q.reject();
                    }
                });
        };
    }

    protected abstract validate(modelValue: TValue): IPromise<IValidationResult>;

    protected getMessage(validationResult: IValidationResult) {
        return validationResult.errorMessage;
    }
}