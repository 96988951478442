'use strict';

import {register} from "@systemorph/web";
import {IScope, ISCEService} from 'angular';
import {Dictionary, keys, head} from 'lodash';

interface IValidationTooltipScope extends IScope {
    ValidationTooltipCtrl: ValidationTooltipController;
}

register.directive('validationTooltip', () => {
    return {
        replace: true,
        scope: true,
        template: `
            <div class="form-field__validation"
                 ng-show="validationTooltipCtrl.tooltipHtml">
                <i class="material-icons md-18 form-field__validation-icon"
                   data-toggle="tooltip"
                   uib-tooltip-html="validationTooltipCtrl.tooltipHtml"
                   tooltip-append-to-body="true"
                   tooltip-class="form-field__validation-tooltip">error_outline</i>
            </div>
        `,
        bindToController: {
            errors: '=',
            messages: '='
        },
        controller: ValidationTooltipController,
        controllerAs: 'validationTooltipCtrl',
    }
});

class ValidationTooltipController {
    errors: Dictionary<boolean>;
    messages: Dictionary<string>;
    tooltipHtml: string;

    constructor(private $scope: IValidationTooltipScope,
                private $sce: ISCEService) {
        $scope.$watchCollection(() => this.errors, () => {
            if (this.errors) {
                this.tooltipHtml = this.$sce.trustAsHtml(this.getTooltipHtml());
            }
        });
    }

    private getTooltipHtml() {
        const key = keys(this.messages)
            .find(k => this.errors[k]);

        return key && this.messages[key];
    }
}

