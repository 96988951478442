'use strict';

import { register, COMMON_EVENT, PERSISTANCE_EVENT } from "@systemorph/web";
import { IBusinessProcessService } from '../businessProcess.api';
import { blockUI, IScope, ITimeoutService } from "angular";
import {SERVER_EVENTS} from "../businessProcess.constants";

register.directive("activeProcessHome", () => {
    return {
        restrict: 'E',
        replace: true,
        bindToController: {
        },
        template: `
            <div ng-if="activeProcess.isReady">
                <business-process-details ng-if="activeProcess.processId" process-id="activeProcess.processId" process-name="activeProcess.processName"></business-process-details>
                <business-processes-summary ng-if="!activeProcess.processId"></business-processes-summary>
            </div>`,
        controllerAs: "activeProcess",
        controller: ActiveProcessHomeController
    }
});

class ActiveProcessHomeController {
    isReady: boolean;
    processId: string;
    processName: string;

    constructor($scope: IScope,
                private $timeout: ITimeoutService,
                private businessProcessService: IBusinessProcessService,
                private blockUI: blockUI.IBlockUIService) {

        const eventsToReload = [
            COMMON_EVENT.dataVersionChanged,
            COMMON_EVENT.persistenceContextChanged,
            PERSISTANCE_EVENT.backedNotifiedDataVersionWasChanged,
            SERVER_EVENTS.processFinished
        ];

        eventsToReload.forEach(eventName => {
            $scope.$on(eventName, () => {
                this.isReady = false;

                this.$timeout(() => {
                    this.refresh();
                });
            });
        });

        this.refresh();
    }

    refresh() {
        this.blockUI.start('Loading...');

        this.businessProcessService.getActiveProcessInfo()
            .then(activeProcessInfo => {
                if (activeProcessInfo.process) {
                    this.processId = activeProcessInfo.process.processId;
                    this.processName = activeProcessInfo.process.processSystemName;
                }
                else {
                    this.processId = this.processName = null;
                }
            })
            .finally(() => {
                this.blockUI.stop();
                this.isReady = true;
            });
    }
}