'use strict';

import { register } from "@systemorph/web";
import { IAugmentedJQuery, IAttributes, ITimeoutService, blockUI, IScope } from 'angular';
import { REPORT_DESIGNER_EVENT } from "../report/reportDesigner/reportDesigner.events";
import { ReportDesignerController } from "../report/reportDesigner/reportDesigner";

interface IReportDesignerContextPanelScope extends IScope {
    contextPanelCtrl: ReportDesignerContextPanelController;
}

register.directive('reportDesignerContextPanel', function() {
    return {
        replace: true,
        restrict: 'E',
        template: `
            <section ng-if="contextPanelCtrl.isReady"
                     class="unv-cpl-section unv-cpl-section__report-designer">
                <header class="unv-cpl-section-header sm-report-editor__header">
                    <h2 class="unv-cpl-section-title">Report Designer</h2>
                    <button ng-click="designerCtrl.save()"
                            ng-disabled="!designerCtrl.hasChanges"
                            class="btn btn-inline btn-success btn-sm btn__icon sm-report-editor-changes__apply"
                            title="Approve changes">
                        <i class="material-icons">done</i>
                    </button>
                    <button ng-click="designerCtrl.discardChanges(true)"
                            ng-disabled="!designerCtrl.hasChanges"
                            class="btn btn-inline btn-danger btn-clear btn-sm btn__icon sm-report-editor-changes__discard"
                            title="Discard changes">
                        <i class="material-icons">undo</i>
                    </button>
                    <button class="btn btn-inline btn-primary btn__icon sm-report-editor-changes__export"
                            ng-click="designerCtrl.exportToCsv()"
                            title="Export to CSV">
                        <i class="material-icons">call_made</i>
                    </button>
                </header>
                <render directive="reportDesigner"></render>
            </section>
        `,
        controller: ReportDesignerContextPanelController,
        controllerAs: 'contextPanelCtrl',
        link: (scope: IReportDesignerContextPanelScope, element: IAugmentedJQuery, attrs: IAttributes) => {
            scope.contextPanelCtrl.link();
        }
    }
});

class ReportDesignerContextPanelController {
    isReady: boolean;
    private designerCtrl: ReportDesignerController;

    constructor(private $scope: IScope,
                private $timeout: ITimeoutService,
                private blockUI: blockUI.IBlockUIService) {
    }

    link() {
        this.blockUI.start('Loading...');

        require.ensure(['../report/reportDesigner'], require => {
            require('../report/reportDesigner');

            this.$timeout(() => {
                this.isReady = true;
                this.blockUI.stop();
            });
        });

        this.$scope.$on(REPORT_DESIGNER_EVENT.initialized, (e, designerCtrl: ReportDesignerController) => {
            this.designerCtrl = designerCtrl;
        })
    }
}