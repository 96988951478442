'use strict';

import {
    ChartConfiguration,
    ChartData,
    ChartDataSets,
    ChartLegendOptions,
    ChartOptions,
    ChartTitleOptions
} from "chart.js";
import { IFunc } from '../../utils/utils.api';

export interface IChartConfiguration extends ChartConfiguration {
    options?: IChartOptions;
    data?: IChartData;
}

export interface IChartData extends ChartData {
    datasets?: IChartDataSets[];
    originalLabels?: string[];
    xLabels?: Array<any>;
    yLabels?: Array<any>;
}

export interface IChartDataSets extends ChartDataSets {
    format?: string;
    formatter?: IFunc<number, string>;
}

export interface IBarChartConfiguration extends IChartConfiguration {
}

export interface IWaterfallChartConfiguration extends IBarChartConfiguration {
    options?: IWaterfallChartOptions;
}

export interface IChartTitleOptions extends ChartTitleOptions {
    originalText?: string;
}

export interface IChartLegendOptions extends ChartLegendOptions {
    isHorizontal?: boolean;
    maxColumns?: number;
}

export interface IChartOptions extends ChartOptions {
    title?: IChartTitleOptions;
    legend?: IChartLegendOptions;
    // number formatting
    format?: string;
    formatter?: IFunc<number, string>;
    // number formatting for axis labels
    axisFormat?: string;
    axisFormatter?: (value: number, index: number, values: number[]) => string;
    // units label to add to the linear axes labels, e.g. Thousands, Millions etc
    units?: string;
    // do not create a dataset per each comparison item
    noComparison?: boolean;
}

export interface IWaterfallChartOptions extends IChartOptions {
    // tells which data elements contain absolute numbers rather than delta's,
    // by default waterfall expects deltas, can be array of data indexes or true if all numbers are absolute values
    absoluteValues?: any;
    // tells which bars should be displayed as absolute values (starting from zero) rather than deltas,
    // the first bar is shown as absolute by default
    showAbsoluteValues?: number[];
    // absolute values color, e.g. 'rgb(54, 162, 235)'
    absoluteValueColor?: string;
    // positive change color
    increaseColor?: string;
    // negative change color
    decreaseColor?: string;
    // whether to show the guide lines connecting the bars between each other,
    // true by default
    guideLines?: boolean;
}

export interface IChartService {
    renderChart(container: any, configuration: IChartConfiguration): Chart;
    getRightPanelCharts(): IChartConfiguration[];
    setRightPanelCharts(charts: IChartConfiguration[]): void;
    addRightPanelCharts(...charts: IChartConfiguration[]): void;
}
