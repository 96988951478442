'use strict';

import {register, COMMON_EVENT, DETAILS_MODE, IPropertyInfo, IPropertyLayout, PROPERTY_TYPE_META, ANGULAR_EVENT} from "@systemorph/web";
import {
    IDependencyCycleArgs,
    IDependencyPropertyChangedArgs,
    IFormEntity,
    IFormEntityDependencyPropertyChangedFeedback,
    IFormEntityScope
} from './formEntity.api';
import { FORM_ENTITY_EVENT } from './formEntity.events';
import {IPromise, IQService, ITimeoutService, IRootScopeService, blockUI, IAngularEvent} from 'angular';
import {FormEntityProvider} from './formEntityProvider';
import {FormEntityController} from './formEntityDirective';
import {FormEntityService} from './formEntityService';
import {Dictionary, isEmpty, difference, chain} from 'lodash';
import {IState, IStateParamsService} from 'angular-ui-router';

interface IFormEntityPropertiesScope extends IFormEntityScope {
    formEntityPropertiesCtrl: FormEntityPropertiesController;
}

register.directive('formEntityProperties', () => {
    return {
        replace: true,
        bindToController: {
            provider: '='
        },
        require: 'formEntity',
        template: `
            <div class="form-entity sm-form-entity__items form-row clearfix properties-container" ng-class="{'sm-form-entity__items--deferred-apply': formEntityPropertiesCtrl.provider.isDeferredApply()}">
                <properties-suite-new ng-if="formEntityPropertiesCtrl.visiblePropertyInfos"
                      entity="formEntityPropertiesCtrl.provider.formEntity"
                      property-infos="formEntityPropertiesCtrl.visiblePropertyInfos"
                      type-layout="formEntityPropertiesCtrl.provider.layout"
                      mode="formEntityPropertiesCtrl.mode"
                      render-as-list="false"></properties-suite-new>
                <div class="sm-btn-execute__ctr">
                    <button ng-if="formEntityPropertiesCtrl.provider.isDeferredApply()"
                            ng-click="formEntityPropertiesCtrl.sendPendingNotifications()"
                            ng-disabled="!formEntityPropertiesCtrl.pendingChanges"
                            class="btn btn-sm btn-success sm-btn__execute"
                            ng-attr-title="{{ formEntityPropertiesCtrl.pendingChanges ? 'Confirm selection' : '' }}">
                            <i class="material-icons md-18">cached</i>
                            <span>Execute</span>
                    </button>
                </div>
            </div>`,
        controller: FormEntityPropertiesController,
        controllerAs: 'formEntityPropertiesCtrl',
    }
});

export class FormEntityPropertiesController {
    mode: string;
    allPropertyInfos: IPropertyInfo[];
    visiblePropertyInfos: IPropertyInfo[];
    isReady: boolean;

    private enabledProperties: IPropertyLayout[];
    private internalProperties: IPropertyLayout[];
    private visibleProperties: IPropertyLayout[];
    private externalProperties: IPropertyLayout[];

    private formEntityCtrl: FormEntityController;
    private initExternalPropertiesPromise: IPromise<any>;

    // list of changed properties pending notification
    pendingChanges: IFormEntityDependencyPropertyChangedFeedback[];

    // binding
    provider: FormEntityProvider;

    constructor(private $scope: IFormEntityPropertiesScope,
                private $q: IQService,
                private $timeout: ITimeoutService,
                private $rootScope: IRootScopeService,
                private formEntityService: FormEntityService,
                private blockUI: blockUI.IBlockUIService) {
        this.mode = DETAILS_MODE.edit;

        this.formEntityCtrl = $scope.formEntityCtrl;

        this.provider.formEntity.__provider = this.provider;

        this.enabledProperties = this.provider.getEnabledProperties();
        this.internalProperties = this.provider.getInternalProperties();
        this.externalProperties = this.provider.getExternalProperties();

        this.allPropertyInfos = this.internalProperties
            .map(pl => {
                return {
                    propertyLayout: pl,
                    propertyClass: 'class-' + pl.systemName // todo: use more sensible prefix
                }
            });

        if (!isEmpty(this.internalProperties)) {
            this.setVisibleProperties(false)
                .then(() => {
                    this.emitInitializedEvent();
                });
        }
        else {
            this.emitInitializedEvent();
        }

        this.initExternalPropertiesPromise
            = this.formEntityService.initializeExternalProperties(this.formEntityCtrl.scope, this.$scope, this.provider);

        this.$scope.$on(COMMON_EVENT.propertyChanged, (event,
                                                              formEntity: IFormEntity,
                                                              propertyLayout: IPropertyLayout,
                                                              newValue: any,
                                                              oldValue: any) => {
            this.onPropertyChanged(propertyLayout, newValue, oldValue);
        });
    }

    private onPropertyChanged(propertyLayout: IPropertyLayout, newValue: any, oldValue: any) {
        const propertyChangedFeedback: IFormEntityDependencyPropertyChangedFeedback = {
            propertyLayout,
            newValue,
            oldValue
        };

        this.refreshDependentProperties([propertyLayout], [propertyChangedFeedback], false, false)
            .then(feedback => {
                if (!this.pendingChanges) {
                    this.pendingChanges = [];
                }

                this.pendingChanges.push(...feedback);

                this.formEntityCtrl.updateStateParams();

                if (!this.provider.isDeferredApply()) {
                    this.sendPendingNotifications();
                }
            });

        this.$scope.$on(ANGULAR_EVENT.stateChangeSuccess, (e: IAngularEvent, toState: IState, toParams: IStateParamsService, fromState: IState, fromParams: IStateParamsService) => {
            this.resetPendingChanges();
        });
    }

    private resetPendingChanges() {
        this.pendingChanges = null;
    }

    private sendPendingNotifications() {
        const newValues = chain(this.pendingChanges)
            .keyBy(f => f.propertyLayout.systemName)
            .mapValues(f => f.newValue)
            .value();

        this.formEntityCtrl.notifyFormEntityUpdated(newValues);
        this.pendingChanges = null;
    }

    // show/hide properties based on provider.visibleProperties
    private setVisibleProperties(isConditionalPropertiesCycle: boolean) {
        const visibleProperties =
            this.internalProperties.filter(pl => this.provider.visibleProperties.includes(pl.systemName));

        const addedPropertyLayouts = this.visibleProperties
            ? difference(visibleProperties, this.visibleProperties) : visibleProperties;

        const removedPropertiesLayouts = this.visibleProperties
            ? difference(this.visibleProperties, visibleProperties) : [];

        this.visibleProperties = visibleProperties;

        this.visiblePropertyInfos = this.allPropertyInfos.filter(pi => visibleProperties.includes(pi.propertyLayout));

        const addedPropertiesPromise: IPromise<IFormEntityDependencyPropertyChangedFeedback[]> =
            !isEmpty(addedPropertyLayouts) ?
                this.waitForEventFromProperties(addedPropertyLayouts, FORM_ENTITY_EVENT.propertyInitialized)
                    .then(() => {
                        const addedProperties = addedPropertyLayouts.map(pl => pl.systemName);
                        this.formEntityService
                            .stateParamsToFormEntity(this.provider, this.formEntityCtrl.stateParamPrefix, addedProperties)
                        return this.refreshProperties(addedProperties, true, isConditionalPropertiesCycle);
                    })
                : this.$q.resolve([]);

        const removedPropertiesPromise = !isEmpty(removedPropertiesLayouts)
            ? this.waitForEventFromProperties(removedPropertiesLayouts, FORM_ENTITY_EVENT.propertyDestroyed)
                .then(() => {
                    removedPropertiesLayouts.forEach(pl => {
                        this.provider.setValue(pl, undefined);
                    });

                    return removedPropertiesLayouts.map<IFormEntityDependencyPropertyChangedFeedback>(pl => {
                        return {
                            propertyLayout: pl,
                            newValue: undefined,
                            oldValue: this.provider.getValue(pl)
                        };
                    });
                })
            : this.$q.resolve([]);

        return this.$q.all([addedPropertiesPromise, removedPropertiesPromise]);
    }

    private waitForEventFromProperties(properties: IPropertyLayout[], event: string) {
        const eventReceived: IPropertyLayout[] = [];

        const deferred = this.$q.defer();

        const off = this.$scope.$on(event, (e, propertyLayout: IPropertyLayout) => {
            eventReceived.push(propertyLayout);

            if (properties.every(pl => eventReceived.includes(pl))) {
                off();
                deferred.resolve();
            }
        });

        return deferred.promise;
    }

    private emitInitializedEvent() {
        this.$scope.$emit(FORM_ENTITY_EVENT.initialized, this);
    }

    // tells the properties to refresh and pick up the new values in formEntity
    refreshProperties(properties: string[], isInitializing: boolean, isConditionalPropertiesCycle: boolean) {
        const internalProperties = this.internalProperties
            .filter(pl => properties.includes(pl.systemName));

        const externalProperties = this.externalProperties
            .filter(pl => properties.includes(pl.systemName));

        const independentInternalProperties = internalProperties
            .filter(pl => {
                const dependencies = this.provider.getDependencies(pl, false);
                return !dependencies.some(d => properties.includes(d.systemName));
            });

        const independentExternalProperties = externalProperties
            .filter(pl => {
                const dependencies = this.provider.getDependencies(pl, false);
                return !dependencies.some(d => properties.includes(d.systemName));
            });

        const independentProperties = [...independentInternalProperties, ...independentExternalProperties];

        const waitPromises: IPromise<any>[] = [];

        this.$scope.$broadcast(FORM_ENTITY_EVENT.refreshProperties, independentInternalProperties.map(p => p.systemName),
            waitPromises);

        // todo: implement refreshing the external properties
        // i.e. formEntityService.refreshExternalProperties(independentExternalProperties)
        // (subscription by formEntityService.onRefreshExternalProperties)

        return this.$q.all(waitPromises).then(() => {
            const independentPropertiesFeedback: IFormEntityDependencyPropertyChangedFeedback[] = independentProperties
                .map(p => {
                    const value = this.provider.getValue(p);
                    return {
                        propertyLayout: p,
                        newValue: value,
                        oldValue: undefined
                    };
                });

            return this.refreshDependentProperties(internalProperties, independentPropertiesFeedback,
                isInitializing, isConditionalPropertiesCycle);
        });
    }

    private refreshDependentProperties(properties: IPropertyLayout[],
                                       independentPropertiesFeedback: IFormEntityDependencyPropertyChangedFeedback[],
                                       isInitializing: boolean,
                                       isConditionalPropertiesCycle: boolean) {
        const args: IDependencyCycleArgs = {
            affectedProperties: isConditionalPropertiesCycle ? properties
                : this.provider.getAffectedProperties(independentPropertiesFeedback.map(f => f.propertyLayout)),
            feedback: [],
            changes: {},
            checkedConditionalProperties: [],
            isInitializing,
            isConditionalPropertiesCycle
        };

        this.block();

        return this.nextIteration(args, independentPropertiesFeedback)
            .then(feedback => {
                return feedback.filter(f => args.isInitializing || this.isFeedbackPropertyChanged(f));
            })
            .finally(() => this.unblock());
    }

    private nextIteration(args: IDependencyCycleArgs, previousFeedback: IFormEntityDependencyPropertyChangedFeedback[])
        : IPromise<IFormEntityDependencyPropertyChangedFeedback[]> {
        previousFeedback.forEach(f => {
            args.changes[f.propertyLayout.systemName] = f;
            args.feedback.push(f);
        });

        if (!args.isConditionalPropertiesCycle) {
            const props = this.getConditionalPropertiesToCheck(args);

            if (!isEmpty(props)) {
                return this.getConditionalPropertiesFeedback(props)
                    .then(feedback => {
                        args.checkedConditionalProperties.push(...props);
                        return this.nextIteration(args, feedback);
                    });
            }
        }

        return this.getDependentPropertiesFeedback(args)
            .then(feedback => {
                if (!isEmpty(feedback)) {
                    return this.nextIteration(args, feedback);
                }

                // end of recursion
                return args.feedback;
            });
    }

    private getConditionalPropertiesToCheck(args: IDependencyCycleArgs) {
        return this.provider.getConditionalProperties()
            .filter(pl => !args.checkedConditionalProperties.includes(pl.systemName))
            .filter(pl => {
                const affectedDependencies =
                    this.provider.getDependencies(pl, false)
                        .filter(p => args.affectedProperties.includes(p));

                return (!isEmpty(affectedDependencies) || args.isInitializing) &&
                    affectedDependencies.every(d => (d.systemName in args.changes)
                        || args.checkedConditionalProperties.includes(d.systemName));
            })
            .map(pl => pl.systemName);
    }

    private getConditionalPropertiesFeedback(props: string[]) {
        return this.formEntityService.refreshVisibleProperties(this.provider, props)
            .then(() => {
                return this.setVisibleProperties(true)
                    .then(([addedPropertiesFeedback, removedPropertiesFeedback]) => {
                        addedPropertiesFeedback.forEach(f => {
                            f.isAdded = true;
                        });

                        removedPropertiesFeedback.forEach(f => {
                            f.isRemoved = true;
                        });

                        return [...addedPropertiesFeedback, ...removedPropertiesFeedback];
                    });
            });
    }

    private getDependentPropertiesFeedback(args: IDependencyCycleArgs) {
        const visibleAffectedProperties = args.affectedProperties
            .filter(pl => this.provider.visibleProperties.includes(pl.systemName));

        const propertiesToRefresh = visibleAffectedProperties.filter(p => {
            if (p.systemName in args.changes) {
                return false;
            }

            const affectedDependencies = this.provider.getDependencies(p, false)
                .filter(p => args.affectedProperties.includes(p));

            return affectedDependencies.every(d => d.systemName in args.changes)
                && (args.isInitializing || affectedDependencies
                    .some(d => this.isFeedbackPropertyChanged(args.changes[d.systemName])));
        });

        if (isEmpty(propertiesToRefresh)) {
            return this.$q.when([]);
        }

        const eventArgs: IDependencyPropertyChangedArgs = {
            provider: this.provider,
            changes: args.changes,
            propertiesToRefresh,
            feedbackPromises: []
        };

        this.$rootScope.$emit(FORM_ENTITY_EVENT.dependencyPropertyChanged, eventArgs);

        if (eventArgs.feedbackPromises.length !== propertiesToRefresh.length) {
            const propertyNames = propertiesToRefresh.map(p => p.systemName).join(', ');
            throw `${this.provider.name}: wrong feedback from the following properties - ${propertyNames}`;
        }

        return this.$q.all<IFormEntityDependencyPropertyChangedFeedback>(eventArgs.feedbackPromises);
    }

    private isFeedbackPropertyChanged(f: IFormEntityDependencyPropertyChangedFeedback) {
        const isChanged = f.propertyLayout.propertyTypeMeta === PROPERTY_TYPE_META.dimension
            ? (f.newValue || <any>{}).Id !== (f.oldValue || <any>{}).Id
            : f.newValue !== f.oldValue;

        return f.isAdded || f.isRemoved || isChanged;
    }

    private block() {
        this.blockUI.start('Loading...');
    }

    private unblock() {
        this.blockUI.stop();
    }
}