'use strict';

import { register, ISystemorphEntity, IDimensionNameFilter, LAYOUT_PROPERTY, IPropertyLayoutValidation } from "@systemorph/web";
import { 
    BaseAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyControllerAs,
    defaultAutocompleteFormEntityPropertyTempplate,
} from './baseAutocompleteFormEntityPropertyController';
import { IFormEntityPropertyScope } from './baseFormEntityPropertyController';
import { IAutocompleteBoxItem } from "@systemorph/ui-web";
import { IUtilsService } from "@systemorph/ui-web";
import { IPromise, IFilterService } from "angular";
import {FormEntityService} from "../formEntityService";

register.directive('independentDimensionAutocompleteFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: IndependentDimensionAutocompleteFormEntityPropertyController
    };
});

export class BaseIndependentDimensionAutocompleteFormEntityPropertyController extends BaseAutocompleteFormEntityPropertyController<ISystemorphEntity, string> {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                getAutocompleteFunc: () => IPromise<IAutocompleteBoxItem[]>,
                updateAutocompleteIfSomethingChanged: boolean) {
        super($scope, formEntityService, getAutocompleteFunc, updateAutocompleteIfSomethingChanged);
    }

    protected getIndexFromAutocomplete(value: string, autocomplete: IAutocompleteBoxItem[]): number {
        return autocomplete.findIndex(x => x.value.SystemName === value);
    }
}

class IndependentDimensionAutocompleteFormEntityPropertyController extends BaseIndependentDimensionAutocompleteFormEntityPropertyController {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                $filter: IFilterService, utilsService: IUtilsService) {

        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            return utilsService.getTypeQuery($scope.propertyLayout.propertyTypeName).then(query => {
                    return query.orderBy("it.DisplayName").toArray(true).then(
                        (values: ISystemorphEntity[]) => {
                            const validation: IPropertyLayoutValidation[] = this.propertyLayout.editDirectiveParameters[LAYOUT_PROPERTY.validation] || [];
                            const isRequired = validation.some(x => x.validityName === "required");
                            const valuesWithEmptyOntion: ISystemorphEntity[] = isRequired || !values.length 
                                                                                    ? values
                                                                                    : [<any>{ DisplayName: "" }].concat(values);

                            return valuesWithEmptyOntion.map(x => ({value: x, displayName: $filter<IDimensionNameFilter>("dimensionName")(x)}));
                        });
                });
        };

        super($scope, formEntityService, getAutocompleteFunc, true);
    }
}
