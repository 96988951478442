'use strict';

import { register, setPageTitle, STATE } from "@systemorph/web";
import {FormEntityRegistry, FORM_ENTITY_EVENT, FormEntityProvider} from "@systemorph/form-entity-web";
import { IGroupingOption, IProcessDocumentationItemsOverviewModel, IProcessDocumentationService, IProcessDocumentationFormEntity, IProcessDocumentationFormEntityUpdatedProperties } from '../processDocumentation.api';
import { PROCESS_DOCUMENTATION_EVENT } from '../processDocumentation.events';
import { PROCESS_DOC_STATE_NAME } from '../processDocumentation.constants';
import { IScope, ITimeoutService, IAngularEvent } from "angular";

export class ProcessDocumentationOverviewController {
    models: IProcessDocumentationItemsOverviewModel[];

    searchText: string;
    groupingOptions: IGroupingOption[];
    noGroupingOption: IGroupingOption;
    activeGroupingOption: IGroupingOption;
    noData: boolean;
    isReady: boolean;

    /*@ngInject*/
    constructor(private $scope: IScope,
                private formEntityRegistry: FormEntityRegistry,
                private $timeout: ITimeoutService,
                private processDocumentationService: IProcessDocumentationService) {
        setPageTitle($scope, "Process documentation");

        formEntityRegistry.getFormEntity().then((formEntity: IProcessDocumentationFormEntity) => {
            if (!formEntity.year) {
                this.noData = true;
                return;
            }
            this.models = this.getModels();
            this.groupingOptions = this.getGroupingOptions();
            this.noGroupingOption = {
                name: null,
                propertyName: null,
                isDisabled: false,
                disabledCategories: []
            };
            this.activeGroupingOption = this.groupingOptions[0] || this.noGroupingOption;

            this.selectCategory(formEntity.category);
        }).finally(() => this.isReady = true);


        $scope.$on(FORM_ENTITY_EVENT.updated, (event: IAngularEvent, formEntityScope: string, provider: FormEntityProvider, newValues: IProcessDocumentationFormEntityUpdatedProperties) => {
            if (!formEntityScope) {
                if ("category" in newValues) {
                    this.selectCategory(newValues.category);
                    if (this.activeGroupingOption.isDisabled) {
                        this.activateGroupingOption(this.noGroupingOption);
                    }
                } 
            }
        });
    }

    selectCategory(category: string) {
        this.models.forEach(m => {
            if (!category || category === m.category) {
                m.isInitialized = true;
                m.isVisible = true;
            } else {
                m.isVisible = false;
            }
        });

        this.groupingOptions.forEach(go => {
            go.isDisabled = !!category && go.disabledCategories.indexOf(category) !== -1;
        });
    }

    activateGroupingOption(option: IGroupingOption) {
        this.activeGroupingOption = !option || option === this.activeGroupingOption ? this.noGroupingOption : option;
        this.$timeout(() => {
            this.$scope.$broadcast(PROCESS_DOCUMENTATION_EVENT.updateProcessDocumentationItemsOverview);
        });
    }

    clearSearch() {
        this.searchText = "";
        this.$timeout(() => {
            this.$scope.$broadcast(PROCESS_DOCUMENTATION_EVENT.updateProcessDocumentationItemsOverview);
        });
    }

    search() {
        this.$timeout(() => {
            this.$scope.$broadcast(PROCESS_DOCUMENTATION_EVENT.updateProcessDocumentationItemsOverview);
        });
    }

    getGroupingOptions(): IGroupingOption[] {
        return [
            { name: "Report Type", propertyName: "ReportType", isDisabled: false, disabledCategories: [] },
            { name: "Reporting Node", propertyName: "ResultsKey", isDisabled: false, disabledCategories: [] },
            { name: "Author", propertyName: "Author", isDisabled: false, disabledCategories: [this.processDocumentationService.categories.qAndAs, this.processDocumentationService.categories.supportingMaterials ] },
            { name: "Asked by", propertyName: "AskedBy", isDisabled: false, disabledCategories: [this.processDocumentationService.categories.comments, this.processDocumentationService.categories.supportingMaterials] },
            { name: "Responded by", propertyName: "RespondedBy", isDisabled: false, disabledCategories: [this.processDocumentationService.categories.comments, this.processDocumentationService.categories.supportingMaterials] },
            { name: "Priority", propertyName: "Priority", isDisabled: false, disabledCategories: [this.processDocumentationService.categories.supportingMaterials] },
            { name: "Status", propertyName: "Status", isDisabled: false, disabledCategories: [this.processDocumentationService.categories.supportingMaterials] },
            { name: "Tags", propertyName: "Tags", isDisabled: false, disabledCategories: [] }
        ];
    }

    private getModels(): IProcessDocumentationItemsOverviewModel[] {
        return [
            {
                isExpanded: true,
                isInitialized: false,
                isVisible: false,
                category: this.processDocumentationService.categories.comments,
                groupedItemsDirective: "process-documentation-comment-group",
                typeName: "ProcessDocumentationComment",
                name: "Comments"
            },
            {
                isExpanded: true,
                isInitialized: false,
                isVisible: false,
                category: this.processDocumentationService.categories.qAndAs,
                groupedItemsDirective: "process-documentation-q-and-a-group",
                typeName: "ProcessDocumentationQAndA",
                name: "Questions and answers"
            },
            {
                isExpanded: true,
                isInitialized: false,
                isVisible: false,
                category: this.processDocumentationService.categories.supportingMaterials,
                groupedItemsDirective: "process-documentation-supporting-material-group",
                typeName: "ProcessDocumentationSupportingMaterial",
                name: "Supporting materials"
            }
        ];
    }
}

register.state({
    name: PROCESS_DOC_STATE_NAME.processDocumentation,
    parent: STATE.defaultLayout,
    url: '/processDocumentation',
    template: require('./processDocumentationOverview.html'),
    controllerAs: 'ctrl',
    controller: ProcessDocumentationOverviewController,
    suppressReloadMessage: true
})