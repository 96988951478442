'use strict';

import { IPropertyOfEntityScope, register, arrayToDictionary, COMMON_EVENT, ANGULAR_EVENT } from '@systemorph/web';
import {
    IFormEntity,
    FORM_ENTITY_EVENT,
    IFormEntityDependencyPropertyChangedFeedback,
    FormEntityService
} from '@systemorph/form-entity-web';
import { padStart, Dictionary } from 'lodash';
import { ISliceFormEntityDimensionModel } from '../reportingFormEntity.api';
import { IReportUtilsService } from '../../../reportingWeb.api';
import { IPromise, ITemplateCacheService } from 'angular';
import { IReportService } from "../../../report";

export interface ISliceFormEntityPropertyScope extends IPropertyOfEntityScope {
    entity: IFormEntity;
}

register.directive('sliceFormEntityProperty', ($templateCache: ITemplateCacheService) => {
    const popoverTemplateUrl = require.resolve('./sliceFormEntityPropertyPopover.html');
    $templateCache.put(popoverTemplateUrl, require('./sliceFormEntityPropertyPopover.html'));

    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: `<div class="ui-select-bootstrap" style="width: 100%;">
                        <button type="button"
                                ng-keydown="ctrl.onKeydown($event)"
                                class="btn btn-default form-control ui-select-match"
                                tabindex="-1"
                                ng-class="{'active': ctrl.isOpen}"
                                popover-trigger="none"
                                popover-is-open="ctrl.isOpen"
                                prevent-enter-key
                                uib-popover-template="'${popoverTemplateUrl}'"
                                popover-placement="auto bottom-left"
                                ng-click="ctrl.open()">
                            <span ng-bind="ctrl.displayValue"></span><span class="caret"></span>
                        </button>
                    </div>`,
        controllerAs: "ctrl",
        controller: SliceFormEntityPropertyController
    };
});

export class SliceFormEntityPropertyController {
    options: ISliceFormEntityDimensionModel[];
    optionsDictionary: Dictionary<ISliceFormEntityDimensionModel>;
    displayValue: string;
    isOpen: boolean;
    protected separator: string = ",";
    protected displaySeparator: string = ", ";

    constructor(protected $scope: ISliceFormEntityPropertyScope,
                protected $element: ng.IAugmentedJQuery,
                protected formEntityService: FormEntityService,
                private reportService: IReportService,
                private reportUtilsService: IReportUtilsService) {

        this.$scope.entity[this.$scope.propertyLayout.systemName] = this.$scope.entity.getStateParamsOrCurrentValue(this.$scope.propertyLayout) || "";

        const dependencyHandlerOff = this.formEntityService.onDependencyPropertyChanged(this.$scope.propertyLayout, changes => {
            const oldValue: string = $scope.entity[$scope.propertyLayout.systemName];
            return this.loadDimensionsAutocompleteAndSetValue().then(() => {
                const newValue: string = $scope.entity[$scope.propertyLayout.systemName];
                const feedback: IFormEntityDependencyPropertyChangedFeedback = {
                    propertyLayout: $scope.propertyLayout,
                    newValue: newValue,
                    oldValue: oldValue
                };

                return feedback;
            });
        });

        $scope.$emit(FORM_ENTITY_EVENT.propertyInitialized, $scope.propertyLayout, $scope.entity[$scope.propertyLayout.systemName]);

        this.$scope.$on(FORM_ENTITY_EVENT.refreshProperties, (event, properties: string[], waitPromises: IPromise<any>[]) => {
            if (properties.includes(this.$scope.propertyLayout.systemName)) {
                waitPromises.push(this.loadDimensionsAutocompleteAndSetValue());
            }
        });

        this.$scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            dependencyHandlerOff();
            this.$scope.$emit(FORM_ENTITY_EVENT.propertyDestroyed, this.$scope.propertyLayout, this.getValue());
        });
    }

    private loadDimensionsAutocompleteAndSetValue(): ng.IPromise<any> {
        var mainProperties = this.$scope.entity.__provider.getMainPropertyNames();
        var params: any = this.$scope.entity.__provider.getFilterObject(mainProperties);

        return this.reportUtilsService.getSliceDimensions(params, this.reportService.reportDefinition)
            .then(autocomplete => {
                this.options = autocomplete
                    .map((x, index) => {
                        return {
                            isChecked: false,
                            systemName: x.systemName,
                            parentDimension: x.parentDimension,
                            order: null,
                            shortName: x.shortName,
                            displayName: x.displayName,
                            filterDimensionStrategy: x.filterDimensionStrategy,
                            parent: null,
                            level: 0,
                            sortKey: padStart(index.toString(), autocomplete.length.toString().length, '0')
                        }
                    });
                this.optionsDictionary = arrayToDictionary(this.options, x => x.systemName);

                this.options.forEach(option => {
                    if (option.parentDimension)
                        option.parent = this.optionsDictionary[option.parentDimension];
                });

                this.options.forEach((option, index) => {
                    let parent = option.parent;
                    while (parent) {
                        option.level++;
                        option.sortKey = `${parent.sortKey}_${option.sortKey}`;
                        parent = parent.parent;
                    }
                });

                this.options.sort((a, b) => a.sortKey.localeCompare(b.sortKey));

                this.updateOptions(this.$scope.entity[this.$scope.propertyLayout.systemName]);

                this.afterOptionsUpdated();
            });
    }

    protected toggleSelected(option: ISliceFormEntityDimensionModel): void {
        option.isChecked = !option.isChecked;
        if (option.isChecked) {
            option.order = this.options.filter(x => x.isChecked).length;
        } else {
            var orderToBeDeleted = option.order;
            this.options.filter(o => o.isChecked && o.order > orderToBeDeleted).forEach(o => {
                o.order--;
            });
            option.order = null;
        }
    }

    protected open(): void {
        this.isOpen = true;
    }

    protected clickOutSide(): void {
        this.updateValue();
    }

    protected onKeydown(evt: JQueryEventObject): void {
        if (this.isOpen) {
            switch (evt.keyCode) {
            case 27: //esc
                evt.stopPropagation();
                evt.preventDefault();
                this.updateOptions(this.$scope.entity[this.$scope.propertyLayout.systemName]);
                this.isOpen = false;
                break;
            case 13: // enter
            case 9: // tab
                evt.preventDefault();
                evt.stopPropagation();
                this.updateValue();
                break;
            }
        }
    }

    protected getDisplayValue(shortNames = false): string {
        var values = this.options.filter(x => x.isChecked).sort((x1, x2) => x1.order - x2.order).map(x => x.shortName || x.displayName);
        return values.length === 0 ? null : values.join(this.displaySeparator);
    }

    protected getValue(): string {
        var values = this.options.filter(x => x.isChecked).sort((x1, x2) => x1.order - x2.order).map(x => x.systemName);
        return values.length > 0 ? values.join(this.separator) : null;
    }

    protected updateOptions(value: string): void {
        value = value || "";
        this.options.forEach(x => {
            x.isChecked = false;
            x.order = null;
        });
        value.split(this.separator).filter(x => !!x && x.length !== null).forEach((x: string, i: number) => {
            if (x in this.optionsDictionary) {
                this.optionsDictionary[x].isChecked = true;
                this.optionsDictionary[x].order = i + 1;
            }
        });
    }

    protected updateValue(): void {
        this.isOpen = false;
        var oldValue = this.$scope.entity[this.$scope.propertyLayout.systemName];
        this.afterOptionsUpdated();
        var newValue = this.$scope.entity[this.$scope.propertyLayout.systemName];
        if (newValue !== oldValue) {
            this.$scope.$emit(COMMON_EVENT.propertyChanged, this.$scope.entity, this.$scope.propertyLayout, newValue);
        }
    }

    protected afterOptionsUpdated(): void {
        this.$scope.entity[this.$scope.propertyLayout.systemName] = this.getValue();
        this.$scope.entity['sliceDisplayName'] = this.getDisplayValue();
        this.displayValue = this.getDisplayValue(true) || 'None';
    }
}
