'use strict';

import { IMenuItemScope, register } from '@systemorph/web';
import { IDataGridExportService } from '../export';
import { DataGridController } from '../dataGridDirective';

interface IExportToPdfDataGridMenuItemScope extends IMenuItemScope {
    export(): void;
}

register.directive('exportToPdfDataGridMenuItem', (dataGridExportService: IDataGridExportService, $q: ng.IQService) => {
    return {
        replace: true,
        restrict: 'E',
        require: '^^datagrid',
        transclude: true,
        template: `<button type="button" class="btn btn-danger btn-inline btn-inline-static" ng-click="export()" uib-tooltip="Export pdf" tooltip-placement="top-right" tooltip-append-to-body="true">
                    <span class="fa fa-file-pdf-o"></span>
                  </button>`,
        link($scope: IExportToPdfDataGridMenuItemScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, dataGrid: DataGridController) {
            $scope.export = () => {
                dataGrid.gridApiPromise.then(gridApi => {
                    var exportName = dataGrid.options.getExportName();
                    if (!exportName) throw 'No export name provided';
                    var exportNamePromise = typeof exportName === 'string' ? $q.when(exportName) : exportName;
                    exportNamePromise.then(exportName => {
                        dataGridExportService.exportToPdf(gridApi.grid, 'visible', 'visible', `${exportName}.pdf`);
                    })
                })
            };
        }

    };
});
