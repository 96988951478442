'use strict';

import { register, STATE, setPageTitle } from "@systemorph/web";
import { BUSINESS_PROCESS_STATE } from '../businessProcess.constants';
import { IScope } from "angular";

register.state({
    name: BUSINESS_PROCESS_STATE.allProcesses,
    parent: STATE.defaultLayout,
    url: '/allProcesses',
    template: require('./allProcesses.html'),
    controller: /*@ngInject*/($scope: IScope) => {
        setPageTitle($scope, 'All processes');
    },
    suppressReloadMessage: true
})