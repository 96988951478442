'use strict';

import { register } from "@systemorph/web";
import { IAutocompleteBoxItem } from './autocompleteBox.api';
import { IScope, INgModelController, ITimeoutService, IAugmentedJQuery, IAttributes } from "angular";
import { isObject, isEmpty } from "lodash";

register.directive('autocompleteBox', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        require: 'ngModel',
        template: require('./autocompleteBox.html'),
        controllerAs: "autocompleteBoxCtrl",
        bindToController: {
            value: "=ngModel",
            autocomplete: "=",
            emptyMessage: "@",
            onChange: "&?"
        },
        controller: AutocompleteBoxController,
        link: (scope: IAutocompleteBoxScope, element: IAugmentedJQuery, attrs: IAttributes, ngModelCtrl: INgModelController) => {
            const ctrl: AutocompleteBoxController = scope.autocompleteBoxCtrl;
            ctrl.ngModelCtrl = ngModelCtrl;
            ngModelCtrl.$formatters.push((value) => ctrl.ngModelChangedOutSide(value));

            attrs.$observe('disabled', function() {
                ctrl.disabled = attrs.disabled !== undefined ? attrs.disabled : false;
            });
        }
    };
});

interface IAutocompleteBoxScope extends IScope {
    autocompleteBoxCtrl: AutocompleteBoxController;
}

export class AutocompleteBoxController {
    ngModelCtrl: INgModelController;
    selectedItem: IAutocompleteBoxItem;
    items: IAutocompleteBoxItem[];
    disabled: boolean;
    cssClass: string;

    //bindings
    value: any;
    autocomplete: any[];
    emptyMessage: string;
    onChange: () => void;

    constructor(protected $scope: IScope, private $timeout: ITimeoutService) {
        if (!this.emptyMessage) {
            this.emptyMessage = "No options available"
        }

        $scope.$watch(() => this.autocomplete, () => {
            if (this.autocomplete) {
                this.items = normalizeItems(this.autocomplete);
                this.ngModelChangedOutSide(this.value);
            }
            this.cssClass = isEmpty(this.autocomplete) ? "no-options" : null;
        });
    }

    onClose() {
        this.ngModelCtrl.$setTouched();
    }

    ngModelChangedOutSide(value: any) {
        if (this.items) {
            this.selectedItem = this.items.find(x => x.value === value);
        }
    }

    onSelectionChange(newVal: any, oldVal: any) {
        if (newVal !== oldVal) {
            this.ngModelCtrl.$setViewValue(newVal.value);
            this.ngModelCtrl.$setDirty();

            this.$timeout(() => {
                if (this.onChange)
                    this.onChange();
            });
        }
    }
}

export function normalizeItems(items: any[]) {
    return items.map((item: any) => {
        return item && isObject(item) ? item : { value: item, displayName: item};
    });
}

register.directive('booleanAutocomplete', () => {
    return {
        link: function(scope: IAutocompleteBoxScope) {
            scope.autocompleteBoxCtrl.autocomplete = [
                { value: true, displayName: 'Yes' },
                { value: false, displayName: 'No' }
            ];
        }
    }
});