import { IDataSource, ISystemorphEntity } from '@systemorph/web';
import {IPositionRegistryEntryModel} from "./positions/positions.api";
import {IPromise} from 'angular';

export interface IReportingNodeTreeModel {
    id: string;
    parentId: string;
    systemName: string;
    displayName: string;
    children: IReportingNodeTreeModel[];
}

export interface IReportingNodeService {
    getReportingNodeTreesCached(hierarchy: string, withDeletedAndRestricted?: boolean, dataSource?: IDataSource): angular.IPromise<IReportingNodeTreeModel[]>;
    getAllHierarchyNamesCached(withDeletedAndRestricted?: boolean, dataSource?: IDataSource): angular.IPromise<string[]>;
    reportingNodeToString(reportingNode: IReportingNodeTreeModel): string;
}

export interface IPeriodModel {
    displayName: string;
    systemName: string;
    number: number;
    periodicity: string;
}

export enum FormEntityFilterStrategies {
    LoadAll, Lazy
}

export interface IFormEntityDimensionModel {
    displayName: string;
    systemName: string;
    shortName: string;
    parentDimension: string;
    filterDimensionStrategy: string;
}

export interface IReportUtilsService {
    getResultsKeyId(): ng.IPromise<string>;
    isTypeEditable(typeName: string): angular.IPromise<boolean>;
    getBranches(): ng.IPromise<string[]>;
    getActiveBranchInfo(): ng.IPromise<IActiveBranchInfo>;
    getYears(periodicity: string[]): ng.IPromise<number[]>;
    getPeriods(year: number, periodicity: string[]): ng.IPromise<IPeriodModel[]>;
    isMonthlyPeriod(period: string): ng.IPromise<boolean>;
    getSliceDimensions(filterObject: any, reportDefinition?: IReportDefinition): ng.IPromise<IFormEntityDimensionModel[]>;
    getFilterDimensions(filterObject: any, reportDefinition?: IReportDefinition): ng.IPromise<IFormEntityDimensionModel[]>;
    getPositionRegistryEntries(state: string, storage: string): ng.IPromise<IPositionRegistryEntryModel[]>;
    getPositionRegistryEntry(state: string, storage: string, positionName: string): ng.IPromise<IPositionRegistryEntryModel>;
    exportPositions(editableTypeName: string): IPromise<boolean>;
    exportReportDefinition(reportType: string): IPromise<boolean>;
}

export interface IActiveBranchInfo {
    revision: number;
    isHeadRevision: boolean;
    branchName: string;
}

export interface IHierarchicalReportingEntity {
    reportingNodeId: string;
    reportingNodeDisplayName: string;
    children: IHierarchicalReportingEntity[];
    level: number;

    isExpandable: boolean;
    isExpanded: boolean;
    isVisible: boolean;

    numReportingUnitsStarted: number;
    numReportingUnitsDataComplete: number;
    numReportingUnitsReadyForReview: number;
    numReportingUnitsReopened: number;
    numReportingUnitsReviewCompleted: number;
    numReportingUnitsRuSignOff: number;
    numReportingUnitsCenterSignOff: number;
}

export interface IReportDefinition extends ISystemorphEntity {
    Grouping: string;
    LoadingBehavior: string;
    Transformation: string;
    Chart: string;
    Menu: string;
    Process: string;
    Slice: string;
    FormEntity: string;
    Variables: IVariableDefinition[];
    Rows: IReportRowDefinition[];
    Columns: IReportColumnDefinition[];
}

export interface IReportRowDefinition extends ISystemorphEntity {
    Style?: string;
    RowType?: string;
    Order?: number;
    Title?: boolean;
    Transformation?: string;
    Parent?: string;
    Format?: string;
    IsEditable?: boolean;
    Chart?: string;
}

export interface IReportColumnDefinition extends ISystemorphEntity {
    Width?: number;
    Style?: string;
    Display?: string;
    IsPinned?: boolean;
    Format?: string;
    IsGrouped?: boolean;
    ColumnType?: string;
    IsEditable?: string;
    Value?: string;
    Order?: number;
    Chart?: string;
}

export interface IVariableDefinition extends ISystemorphEntity {
    Name: string;
    Code: string;
    Type: string;
}