'use strict';

import { register, IMenuItemScope } from '@systemorph/web';

register.directive('formulaFrameworkDebuggerBaloiseMenuItem', () => {
    return {
        replace: true,
        template: `<div>
                    <static-file-link-for-menu menu-item="item" 
                                               static-file-url="fileUrl"
                                               highlighted-text="highlightedText">
                    </static-file-link-for-menu>
                  </div>`,
        transclude: true,
        controller: ($scope: IMenuItemScope) => {
            $scope.fileUrl = require('../../Content/pdf/Formula Framework Debugger Baloise.pdf');
        }
    };
});