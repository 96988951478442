'use strict';

import { register } from "@systemorph/web";
import {
    BaseAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyTempplate,
    defaultAutocompleteFormEntityPropertyControllerAs,
    IFormEntityPropertyScope, FormEntityService
} from '@systemorph/form-entity-web';
import { IAutocompleteBoxItem } from "@systemorph/ui-web";
import { IBusinessProcessService, IActiveProcessInfo } from "@systemorph/business-processes-web";
import { IPromise, IQService } from "angular";
import { IIfrsFormEntityService } from '../ifrsFormEntity.api';

register.directive('ifrsBusinessProcessFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: BusinessProcessFormEntityPropertyController
    };
});

class BusinessProcessFormEntityPropertyController extends BaseAutocompleteFormEntityPropertyController<string, string> {
    protected activeProcessInfo: IActiveProcessInfo;

    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                $q: IQService,
                ifrsFormEntityService: IIfrsFormEntityService,
                businessProcessService: IBusinessProcessService) {
        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            return $q.all([
                businessProcessService.getActiveProcessInfo(),
                ifrsFormEntityService.getBusinessProcesses()
            ]).then(([activeProcessInfo, businessProcessesAutocomplete]) => {
                this.activeProcessInfo = activeProcessInfo;
                return businessProcessesAutocomplete;
            });
        };

        super($scope, formEntityService, getAutocompleteFunc, true);
    }

    protected getIndexFromAutocomplete(value: string, autocomplete: IAutocompleteBoxItem[]): number {
        return autocomplete.findIndex(x => x.value === value);
    }

    protected getDefaultNotFoundValue(): string {
        return "";
    }
}
