'use strict';

import {register} from "@systemorph/web";
import {IDataGrid, IDataGridColumn, IDataGridExportedField, IDataGridRow} from "../dataGrid.api";
import {dataGridExportType} from "./dataGridExport";
import { IDataGridExportProvider } from './export.api';

// default export provider
export class DataGridExportProvider implements IDataGridExportProvider {
    protected exportType: string;
    protected grid: IDataGrid;

    /*@ngInject*/
    constructor(exportType: string, grid: IDataGrid) {
        this.exportType = exportType;
        this.grid = grid;
    }

    getExportedRow(row: IDataGridRow, columns: IDataGridColumn[]) {
        var extractedRow: IDataGridExportedField[] = [];

        columns.forEach(column => {
            extractedRow.push(this.getExportedCell(row, column));
        });

        return extractedRow;
    }

    getExportedCell(row: IDataGridRow, column: IDataGridColumn) {
        var indentString = this.exportType === dataGridExportType.pdf ? "\t\t\t" : "    ";

        var cellValue = this.getCellValue(row, column);

        if (column.colDef.columnDisplayName === "hierarchicalColumnDisplay") {
            for (var i = 0; i < row.entity.hierarchyLevel; i++) {
                cellValue = indentString + cellValue;
            }
        }

        var extractedField: IDataGridExportedField = { value: this.grid.options.exporterFieldCallback(this.grid, row, column, cellValue) };

        if (column.colDef.exporterPdfAlign) {
            extractedField.alignment = column.colDef.exporterPdfAlign;
        }

        return extractedField;
    }

    getCellValue(row: IDataGridRow, column: IDataGridColumn) {
        const columnDisplay = column.colDef.columnDisplay;

        return this.exportType === dataGridExportType.csv ? columnDisplay.getCsvExportValue(row.entity.entity, row.entity.cellDataStore[column.name])
            : columnDisplay.getPdfExportValue(row.entity.entity, row.entity.cellDataStore[column.name]);
    }

    getPdfLogo(): string {
        return null;
    }
}

register.factory("dataGridExportProvider", () => {
    return DataGridExportProvider;
});
