'use strict';

import {register, ANGULAR_EVENT} from "@systemorph/web";
import {IFormEntityScope} from './formEntity.api';
import {FORM_ENTITY_SELECTOR_TYPE} from './formEntity.constants';
import { FORM_ENTITY_EVENT } from './formEntity.events';
import {blockUI, IAngularEvent, IQService, IRootScopeService, ITimeoutService} from 'angular';
import {FormEntityProvider} from './formEntityProvider';
import {FormEntityRegistry} from './formEntityRegistry';
import {IPromise} from 'angular';
import {IState, IStateParamsService, IStateService} from 'angular-ui-router';
import {FormEntityService} from './formEntityService';
import {FormEntityPropertiesController} from "./formEntityPropertiesDirective";
import {Dictionary, keys, isEmpty} from 'lodash';

register.directive('formEntity', () => {
    return {
        replace: true,
        bindToController: {
            providerName: '=provider',
            providerSelector: '=', // state or context
            scope: '@',
            useStateParams: '=',
            stateParamPrefix: '=',
            context: '=',
            contextArgs: '='
        },
        template: `
            <div>
                <form-entity-properties ng-if="formEntityCtrl.provider" 
                                        provider="formEntityCtrl.provider">
                </form-entity-properties> 
            </div>`,
        controller: FormEntityController,
        controllerAs: 'formEntityCtrl'
    }
});

export class FormEntityController {
    provider: FormEntityProvider;
    formEntityPropertiesCtrl: FormEntityPropertiesController;

    // binding
    providerName: string;
    providerSelector: string;
    scope: string = null;
    useStateParams: boolean;
    stateParamPrefix: string;
    context: string;
    contextArgs: any;

    constructor(private $scope: IFormEntityScope,
                private $q: IQService,
                private $rootScope: IRootScopeService,
                private $timeout: ITimeoutService,
                private formEntityRegistry: FormEntityRegistry,
                private formEntityService: FormEntityService,
                private $state: IStateService,
                private $stateParams: IStateParamsService,
                private blockUI: blockUI.IBlockUIService) {
        if (this.providerName) {
            if (!this.scope) {
                this.scope = this.providerName;
            }

            this.renderByName(this.providerName);
        }
        else {
            if (this.providerSelector == FORM_ENTITY_SELECTOR_TYPE.state) {
                this.renderByState($state.current.name, $stateParams);

                this.$scope.$on(ANGULAR_EVENT.stateChangeStart, (e: IAngularEvent, toState: IState, toParams: IStateParamsService, fromState: IState, fromParams: IStateParamsService) => {
                    // dont let reportController etc pick up the old formEntity while renderByState is pending
                    if (!e.defaultPrevented) {
                        this.formEntityRegistry.reset(this.scope);
                    }
                });

                this.$scope.$on(ANGULAR_EVENT.stateChangeSuccess, (e: IAngularEvent, toState: IState, toParams: IStateParamsService, fromState: IState, fromParams: IStateParamsService) => {
                    this.renderByState(toState.name, toParams);
                });
            }
            else if (this.providerSelector == FORM_ENTITY_SELECTOR_TYPE.context) {
                this.$scope.$watchGroup([() => this.context, () => this.contextArgs], () => {
                    this.formEntityRegistry.reset(this.scope);
                    this.renderByContext(this.context, this.contextArgs);
                });
            }
        }
    }

    reset() {
        this.formEntityRegistry.reset(this.scope);

        if (this.providerName) {
            this.renderByName(this.providerName);
        }
        else if (this.providerSelector == FORM_ENTITY_SELECTOR_TYPE.state) {
            this.renderByState(this.$state.current.name, this.$stateParams);
        }
        else if (this.providerSelector == FORM_ENTITY_SELECTOR_TYPE.context) {
            this.renderByContext(this.context, this.contextArgs);
        }
    }

    private block() {
        this.blockUI.start('Loading...');
    }

    private unblock() {
        this.blockUI.stop();
    }

    private render(providerNamePromise: IPromise<string>) {
        return providerNamePromise
            .then(providerName => {
                if (providerName) {
                    this.formEntityService.getProvider(providerName)
                        .then(provider => {
                            if (!this.provider || providerName != this.provider.name) {
                                if (this.provider) {
                                    const previousValues = this.provider.getValues();
                                    provider.setValues(previousValues);
                                }

                                const waitPromises: IPromise<any>[] = [];

                                this.$rootScope.$broadcast(FORM_ENTITY_EVENT.rendering, this.scope, provider, this.provider, waitPromises);

                                this.$q.all(waitPromises)
                                    .then(() => {
                                        if (this.useStateParams) {
                                            this.formEntityService.stateParamsToFormEntity(provider, this.stateParamPrefix);
                                        }

                                        const isReplacing = !!this.provider;

                                        this.provider = null;
                                        this.formEntityPropertiesCtrl = null;

                                        this.$timeout(() => {
                                            this.provider = provider;

                                            const off = this.$scope.$on(FORM_ENTITY_EVENT.initialized, (event, ctrl: FormEntityPropertiesController) => {
                                                this.formEntityPropertiesCtrl = ctrl;

                                                this.formEntityRegistry.set(this.scope, provider, this);

                                                if (this.useStateParams) {
                                                    this.formEntityService.formEntityToStateParams(provider, this.stateParamPrefix);
                                                }

                                                this.$rootScope.$broadcast(isReplacing ? FORM_ENTITY_EVENT.replaced : FORM_ENTITY_EVENT.added, this.scope, provider);

                                                off();
                                            });
                                        });
                                    });
                            }
                            else {
                                this.formEntityRegistry.set(this.scope, provider, this);
                            }
                        });
                }
                else {
                    this.provider = null;
                    this.formEntityRegistry.set(this.scope, null, null);
                    this.$rootScope.$broadcast(FORM_ENTITY_EVENT.removed, this.scope);
                }
            });
    }

    private renderByName(providerName: string) {
        this.block();

        this.render(this.$q.when(providerName))
            .finally(() => {
                this.unblock()
            });
    }

    private renderByState(stateName: string, stateParams: any) {
        this.block();

        // applying stateParams if currentProvider is not changed
        const providerNamePromise = this.formEntityService.selectProviderByState(stateName, stateParams, this.scope)
            .then(providerName => {
                if (providerName && this.provider && providerName === this.provider.name) {
                    return this.formEntityService.getProvider(providerName)
                        .then(provider => {
                            const updatedProperties = this.formEntityService.stateParamsToFormEntity(provider, this.stateParamPrefix);

                            if (isEmpty(updatedProperties)) {
                                return providerName;
                            }

                            return this.formEntityPropertiesCtrl.refreshProperties(updatedProperties, false, false)
                                .then(() => {
                                    this.formEntityService.formEntityToStateParams(provider, this.stateParamPrefix);
                                    return providerName;
                                });
                        });
                }

                return providerName;
            });

        this.render(providerNamePromise)
            .finally(() => {
                this.unblock()
            });
    }

    private renderByContext(context: string, contextArgs: any) {
        this.block();

        const providerNamePromise = this.formEntityService.selectProviderByContext(context, contextArgs);

        this.render(providerNamePromise)
            .finally(() => {
                this.unblock()
            });
    }

    updateStateParams() {
        if (this.useStateParams) {
            this.formEntityService.formEntityToStateParams(this.provider, this.stateParamPrefix);
        }
    }

    notifyFormEntityUpdated(newValues: Dictionary<any>) {
        this.formEntityService.notifyFormEntityUpdated(this.scope, this.provider, newValues);
    }

    setValues(values: Dictionary<any>, notify = true) {
        this.provider.setValues(values);

        return this.formEntityPropertiesCtrl.refreshProperties(keys(values), false, false)
            .then(() => {
                this.updateStateParams();
                if (notify) {
                    this.notifyFormEntityUpdated(values);
                }
            });
    }

    setProvider(providerName: string) {
        this.formEntityRegistry.reset(this.scope);
        this.renderByName(providerName);
    }
}