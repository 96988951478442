'use strict';

import { 
    register, 
    ILayoutService, 
    IEntityGridArgs, 
    IEntityGridService, 
    IPersisterProviderService, 
    DETAILS_MODE,
    DISPLAY_CONTEXT,
    ITypeLayout
} from '@systemorph/web';
import { IScope } from 'angular';

register.directive('datamodelTypes', () => {
    return {
        replace: true,
        restrict: 'E',
        scope: true,
        template: require('./dataModelTypes.html'),
        controller: Controller,
        controllerAs: 'ctrl',
        bindToController: {
        }
    }
});

class Controller {
    blockServiceName: string;

    constructor(private $scope: IScope, 
                private layoutService: ILayoutService,
                private entityGridService: IEntityGridService,
                private persisterProviderService: IPersisterProviderService) {
        
        this.blockServiceName = `dataModelTypesBlock`;

        const typeName = `DataModelTypeInfo`;
        const displayContext = DISPLAY_CONTEXT.details;
        this.layoutService.layout(typeName, displayContext).$promise.then((layout: ITypeLayout) => {
            const entityGridArgs: IEntityGridArgs = {
                scope: this.$scope,
                disablePaging: true,
                entityTypeName: typeName,
                displayContext: displayContext,
                blockServiceName: this.blockServiceName,
                mode: DETAILS_MODE.display,
                refresh: () => {
                    return this.persisterProviderService.getDomainPersister().then(domainPersister => {
                        return domainPersister.query(layout.collectionName);
                    });
                }
            };

            this.entityGridService.createGridOptionsAndPutToScope(entityGridArgs);
        });
    }
}