'use strict';

import { register, IColumnFilterDirectiveService, IGridFilterOptions, IColumnFilterService, IColumnFilterScope, NumericJaydataOperator, NumericOperator, ColumnFilterType, FILTER_EVENT } from "@systemorph/web";

interface IInversedBooleanColumnFilterScope extends IColumnFilterScope {
    entityChecked: boolean;
}

class SearchInversedBooleanService implements IColumnFilterDirectiveService {
    getIcon = (): string => {
        return "B";
    };

    getOdataPredicateAfterEdit = (filterOptions: IGridFilterOptions): string => {
        return filterOptions.propertyLayout.systemName + " " + filterOptions.operation + " " + filterOptions.fieldValues[0];
    };

    getOdataPredicate = (filterOptions: IGridFilterOptions): string => {
        return this.getOdataPredicateAfterEdit(filterOptions);
    };

    getJaydataPredicate = (filterOptions: IGridFilterOptions): string => {
        return "it." + filterOptions.propertyLayout.systemName + " " + NumericJaydataOperator[NumericOperator.eq] + " " + filterOptions.fieldValues[0];
    };
}

register.service('filterInversedBooleanPropertyService', () => {
    return new SearchInversedBooleanService();
});

register.directive("filterInversedBooleanProperty", () => {
    return {
        replace: true,
        scope: false,
        template: require('./filterInversedBooleanProperty.html'),
        controller: ($scope: IInversedBooleanColumnFilterScope,
            $rootScope: ng.IScope,
            columnFilterService: IColumnFilterService,
            $controller: ng.IControllerService) => {

            $controller('columnFilterController', { $scope: $scope });

            $scope.entityChecked = undefined;

            if ($scope.filters) {
                var filterOptions: IGridFilterOptions[] = $scope.filters.filter((f: IGridFilterOptions) => (f.typeLayout === $scope.typeLayout && f.propertyLayout === $scope.propertyLayout));
                if (filterOptions && filterOptions.length > 0) {
                    $scope.entityChecked = (filterOptions[0].fieldValues[0].toString() === 'true');
                }
            }

            $scope.search = () => {
                if ($scope.entityChecked != undefined) {
                    var filterOptions = $scope.updateGridFilterOptions($scope.createFilterOptions($scope.entityChecked))[0];
                    $rootScope.$broadcast(FILTER_EVENT.closeFilter, { isApplied: true, filterOptions: filterOptions, gridOptions: $scope.gridOptions });
                } else {
                    $scope.clear();
                }
            }

            $scope.createFilterOptions = (checked: boolean): IGridFilterOptions[] => {
                var filterOptions = <any>{
                    typeLayout: $scope.typeLayout,
                    propertyLayout: $scope.propertyLayout,
                    fieldValues: [checked],
                    type: ColumnFilterType.Boolean,
                    operation: NumericOperator[NumericOperator.eq]
                };
                return [filterOptions];
            };
        }
    }
});