'use strict';

import {register, IPersisterProviderService, ISystemorphEntity, IEntityService, IEntityAccessRightsService, ILayoutService} from '@systemorph/web';
import {
    FormulaEditorPropertyController,
    IFormulaEditorItemWrapper,
    IFormulaEditorPropertyScope, languagePathParameterName
} from '../formulaEditorPropertyController';
import {IUtilsService} from '@systemorph/ui-web';
import {IFunctionEditorItem} from '../formulaEditor/functionEditorDirective';
import {IAugmentedJQuery, IQService, ITimeoutService} from 'angular';
import {Dictionary} from 'lodash';
import {IStateService} from 'angular-ui-router';

export interface IFunctionDefinition extends ISystemorphEntity {
    Name: string;
    Code: string;
    State: string;
    Storage: string;
    IsShared: boolean;
}

interface IFunctionEditorItemWrapper extends IFunctionEditorItem, IFormulaEditorItemWrapper {
    entity?: IFunctionDefinition
}

register.directive('functionsProperty', () => {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        // render is used because editor directive is lazy loaded
        template: `
            <div>
                <div ng-if="!propertyCtrl.isLoading">
                    <render directive="functionEditor"
                            items="propertyCtrl.items"
                            is-creatable="propertyCtrl.isCreatable"
                            language-path="propertyCtrl.languagePath"
                            on-save="propertyCtrl.saveItem(item)"
                            on-delete="propertyCtrl.deleteItem(item)"
                            get-shared-items="propertyCtrl.getSharedItems()"
                            on-add-shared="propertyCtrl.addShared(item)"
                            on-delete-shared="propertyCtrl.deleteSharedItem(item)">
                    </render>
                </div>
                <span ng-if="propertyCtrl.isLoading">Loading...</span>
            </div>
        `,
        controller: FunctionsPropertyController,
        controllerAs: 'propertyCtrl'
    }
});

class FunctionsPropertyController extends FormulaEditorPropertyController {
    items: IFunctionEditorItemWrapper[];
    entityTypeName = 'FunctionDefinition';

    constructor($scope: IFormulaEditorPropertyScope,
                $q: IQService,
                $timeout: ITimeoutService,
                $element: IAugmentedJQuery,
                persisterProviderService: IPersisterProviderService,
                layoutService: ILayoutService,
                entityAccessRightsService: IEntityAccessRightsService,
                utilsService: IUtilsService,
                entityService: IEntityService,
                private $state: IStateService) {
        super($scope, $q, $timeout, $element, persisterProviderService, layoutService, entityAccessRightsService, utilsService, entityService);

        this.languagePath = [$scope.propertyLayout.displayDirectiveParameters[languagePathParameterName], $scope.entity.SystemName];
    }

    addShared(item: IFunctionEditorItemWrapper) {
        return this.updatePropertyCollection();
    }

    deleteItem(item: IFunctionEditorItemWrapper) {
        return this.updatePropertyCollection();
    }

    deleteSharedItem(item: IFunctionEditorItemWrapper) {
        return super.deleteItem(item);
    }

    getSharedItems() {
        return this.getSharedFunctions()
            .then(sharedFunctions => {
                const mySharedItems = this.items.filter(i => i.isShared).map(i => i.entity.Id);

                return this.utilsService.getTypeQuery<IFunctionDefinition>(this.entityTypeName).then(query => {
                    // return query.filter(f => f.IsShared == true)
                    return query.filter(`it.IsShared == true`)
                        .filter(`!(it.Id in ["${mySharedItems.join('", "')}"])`)
                        .orderBy(`it.Name`)
                        .toArray()
                        .then(functions => {
                            return functions.map(func => {
                                const item = this.mapEntityToEditorItem(func, sharedFunctions[func.Name]);

                                item.entity = func;
                                item.isCollapsed = true;

                                return item;
                            });
                        });
                });
            });
    }

    protected mapEditorItemToEntity(item: IFunctionEditorItemWrapper, entity: IFunctionDefinition): IFunctionDefinition {
        entity.Name = item.name;
        entity.Code = item.code;
        entity.State = item.state;
        entity.Storage = item.storage;
        entity.IsShared = item.isShared;

        return entity;
    }

    protected getItems() {
        return this.getSharedFunctions()
            .then(sharedFunctions => {
                return this.utilsService.getCollectionQuery<IFunctionDefinition>(this.$scope.entity, this.$scope.propertyLayout.systemName)
                    .then(query => {
                        return query.orderBy('it.Name').toArray().then(functions => {
                            return functions.map(func => {
                                const sharedWith = sharedFunctions[func.Name]
                                    .filter(entity => entity.Id != this.$scope.entity.Id);

                                const item = this.mapEntityToEditorItem(func, sharedWith.length > 0 ? sharedWith : null);

                                item.entity = func;
                                item.isCollapsed = true;

                                return item;
                            });
                        });
                    });
            });
    }

    // returns dictionary of import configurations by function name
    private getSharedFunctions() {
        return this.utilsService.getTypeQuery<ISystemorphEntity>(this.$scope.typeLayout.systemName)
            .then(query => {
                return query.include(this.$scope.propertyLayout.systemName).orderBy('it.DisplayName').toArray()
                    .then(entities => {
                        const sharedFunctionsDict: Dictionary<ISystemorphEntity[]> = {};

                        entities.forEach(entity => {
                            const functions: IFunctionDefinition[] = entity[this.$scope.propertyLayout.systemName];

                            functions.forEach(f => {
                                    if (!sharedFunctionsDict[f.Name]) {
                                        sharedFunctionsDict[f.Name] = [];
                                    }

                                    sharedFunctionsDict[f.Name].push(entity);
                                });
                        });

                        return sharedFunctionsDict;
                    });
            });
    }

    private mapEntityToEditorItem(entity: IFunctionDefinition, sharedWith: ISystemorphEntity[]): IFunctionEditorItemWrapper {
        return {
            name: entity.Name,
            code: entity.Code,
            state: entity.State,
            storage: entity.Storage,
            isShared: entity.IsShared,
            sharedWith: sharedWith ? sharedWith.map(i => this.getSharedWithItem(i)) : null,
            isEditable: this.entityAccessRightsService.canEdit(entity),
            isDeletable: this.entityAccessRightsService.canDelete(entity)
        };
    }

    private getSharedWithItem(entity: ISystemorphEntity) {
        return {
            url: this.$state.href(this.$state.current, { entityId: entity.Id }),
            displayName: entity.DisplayName
        };
    }
}