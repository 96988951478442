'use strict';

import {register} from "@systemorph/web";
import {BaseColumnDisplay} from "./baseColumnDisplay";
import {IDataGridColumnDef, IDataGridOptions, IDataGridRow} from "../dataGrid.api";

export class BooleanColumnDisplay extends BaseColumnDisplay {
    /*@ngInject*/
    constructor(colDef: IDataGridColumnDef, dataGridOptions: IDataGridOptions) {
        super(colDef, dataGridOptions);
    }

    onChange(row: IDataGridRow) {
        this.setValue(row.entity.entity, row.entity.displayValues[this.colDef.name]);

        if (this.dataGridOptions.saveValue)
            this.dataGridOptions.saveValue(row.entity.entity, this.colDef);
    }

    getTemplate() {
        const configPath = this.getCellConfigPath();
        const displayValuePath = this.getDisplayValuePath();

        return `
            <span style="display: block; text-align: center">
                <i ng-show="!${configPath}.isEditable && ${displayValuePath}" class="material-icons md-18">check</i>
                <input ng-show="${configPath}.isEditable" 
                       type="checkbox"
                       ng-model="${displayValuePath}" 
                       ng-change="col.colDef.columnDisplay.onChange(row)"/>
            </span>`;
    }
}

register.factory("booleanColumnDisplay", () => BooleanColumnDisplay);