'use strict';

import { register } from "@systemorph/web";
import { 
    IAutocompleteBoxItem, 
} from '@systemorph/ui-web';
import {
    BaseAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyTempplate,
    defaultAutocompleteFormEntityPropertyControllerAs, IFormEntityPropertyScope, FormEntityService
} from "@systemorph/form-entity-web";
import { IReportingNodeTreeModel } from "../../../reportingWeb.api";
import { IPromise, IQService } from "angular";

register.directive('exchangeRateFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: ExchangeRateFormEntityPropertyController
    };
});

class ExchangeRateFormEntityPropertyController extends BaseAutocompleteFormEntityPropertyController<string, string> {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                $q: IQService) {
        const autocomplete: string[] = $scope.entity[$scope.propertyLayout.systemName + "Autocomplete"];

        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            //TODO there is no specification how exchangeRate can depends on reportingNode. Find out it from content team.
            const reportingNode: IReportingNodeTreeModel = $scope.entity["reportingNode"];
            const ret: IAutocompleteBoxItem[] = autocomplete.map(x => {
                const item: IAutocompleteBoxItem = {
                    displayName: x,
                    value: x
                }
                return item;
            });

            return $q.when(ret);
        };

        super($scope, formEntityService, getAutocompleteFunc, false);
    }

    protected getIndexFromAutocomplete(value: string, autocomplete: IAutocompleteBoxItem[]): number {
        return autocomplete.findIndex(x => x.value === value);
    }
}