'use strict';

import {register, setPageTitle, STATE} from "@systemorph/web";
import { BALOISE_RELEASE_NOTES_STATE } from "../baloiseReleaseNotes.constants";
import { IScope } from 'angular';

class OldReleaseNotesController {
    /*@ngInject*/
    constructor(private $scope: IScope) {
        setPageTitle(this.$scope, "Release Notes 2019-2022");
    }
}

register.state({
    name: BALOISE_RELEASE_NOTES_STATE.baloiseOldReleaseNotes,
    parent: STATE.defaultLayout,
    url: '/oldReleaseNotes',
    template: require('../baloiseOldReleaseNotes.html'),
    suppressReloadMessage: true,
    controllerAs: 'pageCtrl',
    controller: OldReleaseNotesController
})