'use strict';

import 'moment-timezone';
import * as moment from 'moment';

import { register, IDateTimeService } from "@systemorph/web";

export interface IBaloiseDateTimeService extends IDateTimeService {
    toCETWithoutDST(date: Date, format?: string): Date;
    getCETAbbr(): string
}

register.decorator('dateTimeService', ($delegate: IBaloiseDateTimeService) => {
    const cetTimezone = 'Europe/Zurich';

    $delegate.toCETWithoutDST = (date: Date, format = 'lll') => {
        let momentDate = (<any>moment(date)).tz(cetTimezone);
        return ((momentDate.isDST()) ? momentDate.subtract(1, 'h') : momentDate).format(format);
    }

    $delegate.getCETAbbr = () => {
        return (<any>moment()).tz(cetTimezone).format('z');
    }

    return $delegate;
});
