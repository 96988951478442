'use strict';

import { register, IActivityService, IRunPhaseArgs, IActivityPhaseDirectiveScope, COMMON_EVENT, ACTIVITY_EVENT} from "@systemorph/web";
import { IBusinessProcessPromotionArgs } from "./activities.api";
import { IFormController } from "angular";
import { isEmpty } from "lodash";
import { IProcessModel, IBusinessProcessService } from '../businessProcess.api';

register.directive('promoteProcessActivityDialog', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: require('./promoteProcessActivityDialog.html'),
        controllerAs: "ctrl",
        controller: PromoteProcessActivityDialogController
    };
});

interface IPromoteProcesssActivityPhaseDirectiveScope extends IActivityPhaseDirectiveScope {
    dialogForm: IFormController;
}

class PromoteProcessActivityDialogController {
    private hasSelected: boolean;
    private hasBeenTouched: boolean;// do not kill, used in binings

    isLoading: boolean;
    title: string;
    tracker: IProcessModel;
    masterProcessName: string;

    private phaseArgs: IBusinessProcessPromotionArgs;

    constructor(private $scope: IPromoteProcesssActivityPhaseDirectiveScope,
                private activityService: IActivityService,
                private businessProcessService: IBusinessProcessService) {

        const activityArgs = <IRunPhaseArgs>$scope.activity.directiveArgs;
        const phaseArgs: IBusinessProcessPromotionArgs = this.phaseArgs = activityArgs.phaseArgs;
        this.masterProcessName = phaseArgs.processName;

        this.isLoading = true;

        this.businessProcessService.getChangeableTracker(phaseArgs.processTrackerId, phaseArgs.fromStateSystemName, phaseArgs.toStateSystemName).then(tracker => {
            this.isLoading = false;

            this.tracker = tracker;

            //in case tracker has no sub processes => promoted tracker is selected by default
            if (!this.tracker.subProcessModels || !this.tracker.subProcessModels.length) {
                this.tracker.isSelected = true;
                this.hasSelected = true;
            }
            
            this.tracker.isExpanded = true;

            this.businessProcessService.applyFiltering(this.tracker);

            this.expandTree();

            this.$scope.$emit(ACTIVITY_EVENT.addActivitySuccessActions, this.getConfirmFunction(activityArgs), 'Ok', "Continue");
        })
    }

    private expandTree(tracker: IProcessModel = this.tracker) {
        tracker.isExpanded = true;
        if (tracker.subProcessModels) {
            tracker.subProcessModels.forEach(p => this.expandTree(p));
        }
    }

    onSelectChanged() {
        this.hasBeenTouched = true;
        this.hasSelected = !isEmpty(this.getSelectedTrackerIds(this.tracker));
    }

    private getConfirmFunction(activityArgs: IRunPhaseArgs): () => void {
        return () => {
            if (!this.$scope.dialogForm)
                throw "Form was not found";

            if (!this.hasSelected) {
                this.hasBeenTouched = true;
                this.$scope.activity.blocked = false;
                return;
            }

            this.$scope.$broadcast(COMMON_EVENT.dirtifyPropertySuite);

            if (this.$scope.dialogForm.$invalid) {
                this.$scope.activity.blocked = false;
                return;
            }

            this.activityService.runPhase({
                activityId: activityArgs.activityId,
                masterContext: activityArgs.masterContext,
                phaseName: activityArgs.phaseName,
                phaseArgs: {
                    ...this.phaseArgs,
                    selectedProcessTrackerIds: this.getSelectedTrackerIds(this.tracker),
                    skipValidation: false
                }
            });
        }
    }

    private getSelectedTrackerIds(tracker: IProcessModel, result: string[] = []) {
        if (tracker.isSelected)
            result.push(tracker.processTrackerId);

        if (tracker.subProcessModels)
            tracker.subProcessModels.forEach(p => this.getSelectedTrackerIds(p, result));

        return result;
    }
}