'use strict';

import {
    register,
    ICloneService,
    IDataSourceService,
    ILayoutService,
    IEntityViewInjectionService,
    IEntityService,
    IDataSource,
    DETAILS_MODE,
    DISPLAY_CONTEXT,
    ITypeLayout,
    IPropertyLayout,
    ISystemorphEntity,
    IPropertyInfo,
    COMMON_EVENT,
    propertyLayoutSystemNameToLowerCase
} from '@systemorph/web';
import {
    element,
    IAttributes,
    IAugmentedJQuery,
    ICompileService,
    IRepeatScope,
    IQService,
} from "angular";

interface IPropertiesSuiteScope extends ng.IScope {
    entity: ISystemorphEntity;
    propertyInfos: IPropertyInfo[];
    localPropertyInfos: IPropertyInfo[];
    typeLayout: ITypeLayout;
    localTypeLayout: ITypeLayout;
    displayContext: string;
    localDisplayContext: string;
    mode: string;
    localMode: string;
    maxLevel: number;
    currentLevel: number;
    renderAsList: boolean;
    hideLabel: boolean;
    doCamelCase: boolean;
    [propName: string]: any;
}

register.directive('propertiesSuiteNew', () => {
    return {
        // https://docs.angularjs.org/api/ng/service/$compile#-transclude-element-in-the-replace-template-root-can-have-unexpected-effects
        // replace: true,
        restrict: 'E',
        scope: {
            entity: "=",
            propertyInfos: "=",
            typeLayout: "=",
            displayContext: "=",
            mode: "=",
            maxLevel: "=",
            currentLevel: "=",
            renderAsList: "=",
            hideLabel: "=",
            doCamelCase: "="
        },
        template: `<div properties-suite-item ng-repeat="pi in localPropertyInfos"></div>`,
        controller: PropertiesSuiteController,
        controllerAs: 'propertiesSuiteCtrl'
    }
});

class PropertiesSuiteController {
    constructor(private $scope: IPropertiesSuiteScope,
                private $element: IAugmentedJQuery,
                private $q: IQService,
                private dataSourceService: IDataSourceService,
                private layoutService: ILayoutService,
                private cloneService: ICloneService,
                private entityService: IEntityService) {
        $element.addClass('properties-suite');

        if ($scope.propertyInfos && !$scope.typeLayout) {
            throw "Property info cannot be set without layout";
        }

        $scope.localMode = $scope.mode || DETAILS_MODE.display;
        $scope.localDisplayContext = $scope.displayContext || DISPLAY_CONTEXT.details;

        const dataSource = dataSourceService.getDataSourceFromScope($scope);

        const layoutPromise = $scope.typeLayout
            ? $q.when($scope.typeLayout)
            : typeof $scope.entity["getType"] === "function"
                ? layoutService.layout((<any>$scope.entity["getType"]()).name, $scope.localDisplayContext, dataSource).$promise
                : "$type" in $scope.entity
                    ? layoutService.layout($scope.entity["$type"], $scope.localDisplayContext, dataSource).$promise
                    : $q.when(null);

        layoutPromise.then(layout => {
            if (!layout)
                throw "Layout cannot be defined";

            $scope.localTypeLayout = layout;

            this.refreshPropertyInfos();

            $scope.$watch(() => $scope.propertyInfos, (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    this.refreshPropertyInfos();
                }
            });

            $scope.$on(COMMON_EVENT.dirtifyPropertySuite, ()  => {
                $scope.localPropertyInfos.forEach(pi => {
                    const input = entityService.getInput($scope, <any>$scope.entity, pi.propertyLayout);
                    if (input) {
                        input.$dirty = true;
                    }
                });
            });
        });
    }

    private refreshPropertyInfos() {
        this.$scope.localPropertyInfos = this.$scope.propertyInfos || this.$scope.localTypeLayout.propertyLayouts
            .filter((pl: IPropertyLayout) => {
                if (this.$scope.localMode === DETAILS_MODE.create) {
                    return pl.isCreatable || pl.isVisible;
                }
                if (this.$scope.localMode === DETAILS_MODE.edit) {
                    return pl.isEditable || pl.isVisible;
                }
                if (this.$scope.localMode === DETAILS_MODE.display || this.$scope.localMode === DETAILS_MODE.thumbnail) {
                    return pl.isVisible;
                }

                throw "Unrecognized mode " + this.$scope.localMode;
            })
            .map((pl: IPropertyLayout) => {
                return {
                    propertyLayout: pl,
                    propertyClass: ''
                }
            });

        if (this.$scope.doCamelCase) {
            this.$scope.localPropertyInfos = this.$scope.localPropertyInfos.map((pi: IPropertyInfo) => {
                const cloned = this.cloneService.deepClone(pi);
                cloned.propertyLayout = propertyLayoutSystemNameToLowerCase(pi.propertyLayout);
                return cloned;
            });
        }

    }
}

interface IPropertySuiteItemScope extends IPropertiesSuiteScope, IRepeatScope {
    pi: IPropertyInfo
}

register.directive('propertiesSuiteItem', ($compile: ICompileService,
                                                      $q: IQService,
                                                      entityService: IEntityService,
                                                      cloneService: ICloneService,
                                                      dataSourceService: IDataSourceService,
                                                      layoutService: ILayoutService,
                                                      entityViewInjectionService: IEntityViewInjectionService) => {
    return {
        scope: true,
        link: (scope: IPropertySuiteItemScope, $element: IAugmentedJQuery, attrs: IAttributes) => {
            const pi = scope.pi;
            const isDisplay = scope.localMode === DETAILS_MODE.display;

            const directiveName = entityService.getPropertyDirectiveName(scope.localTypeLayout, pi.propertyLayout, scope.localMode);

            let requiredClass = "";

            if (scope.localMode === DETAILS_MODE.create || scope.localMode === DETAILS_MODE.edit) {
                if (layoutService.isRequiredProperty(pi.propertyLayout)) {
                    requiredClass = "required-property";
                }
            }

            const viewInjectionDirective = entityViewInjectionService.getViewInjectionDirective(scope, "propertyLayout.systemName");

            $element.addClass(`clearfix relative-position entity-section ${pi.propertyClass} sm-control--${directiveName}`);

            return entityService
                .getPropertyTemplate(scope.localTypeLayout, pi.propertyLayout, scope.localMode, !isDisplay)
                .then(template => {
                    const labelHtml = scope.hideLabel
                        ? ''
                        : `<div class="display-label">
                                <strong class="${requiredClass}" title="${pi.propertyLayout.displayName}">
                                    ${pi.propertyLayout.displayName}
                                </strong>
                           </div>`;

                    return `
                        <div ${viewInjectionDirective} ng-controller="fakePropertyBindController"
                                                        fake-bind-entity="$parent.entity"
                                                        fake-bind-display-context="$parent.localDisplayContext"
                                                        fake-bind-type-layout="$parent.localTypeLayout"
                                                        fake-bind-mode="$parent.localMode"
                                                        fake-bind-max-level="$parent.maxLevel" 
                                                        fake-bind-current-level="$parent.currentLevel" 
                                                        fake-bind-property-layout="$parent.localPropertyInfos[${scope.$index}].propertyLayout">
                            ${labelHtml}
                            <div class="display-field" ${isDisplay ? "inline-edit" : ""}>
                                ${template}
                            </div>
                        </div>`;
                })
                .then(template => {
                        const newElement = element(template);
                        $element.append(newElement);
                        $compile(newElement)(scope);
                    });
        }
    }
});