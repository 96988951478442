'use strict';

import { register } from "@systemorph/web";
import {
    defaultAutocompleteFormEntityPropertyTempplate,
    defaultAutocompleteFormEntityPropertyControllerAs,
    IFormEntityPropertyScope, FormEntityService
} from '@systemorph/form-entity-web';
import { IAutocompleteBoxItem } from "@systemorph/ui-web";
import { IIfrsFormEntityService } from "../ifrsFormEntity.api";
import { BaseYearFormEntityPropertyController } from "@systemorph/reporting-web";
import { IPromise } from "angular";

register.directive('ifrsYearFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: YearFormEntityPropertyController
    };
});

class YearFormEntityPropertyController extends BaseYearFormEntityPropertyController {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                ifrsFormEntityService: IIfrsFormEntityService) {
        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            const businessProcess: string = $scope.entity['businessProcess'];
            return ifrsFormEntityService.getYears(businessProcess)
                .then(years => years.map((y: number): IAutocompleteBoxItem => ({value: y, displayName: y.toString()})));
        };
        super($scope, formEntityService, getAutocompleteFunc, true);
    }
}
