'use strict';

import { register, IActivityService, IRunPhaseArgs, IActivityPhaseDirectiveScope, IActivityLogNotification, ACTIVITY_EVENT } from "@systemorph/web";
import { IConfirmationOfChangeBusinessStatusArgs } from "./activities.api";

register.directive('changeStatusConfirmationActivityDialog', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: require('./changeStatusConfirmationActivityDialog.html'),
        controllerAs: "ctrl",
        controller: ChangeStatusConfirmationActivityDialogController
    };
});

class ChangeStatusConfirmationActivityDialogController {

    private activityId: string;
    private masterContext: string;
    private phaseName: string;
    private phaseArgs: IConfirmationOfChangeBusinessStatusArgs;
    notifications: IActivityLogNotification[];

    constructor($scope: IActivityPhaseDirectiveScope,
                private activityService: IActivityService) {

        this.activityService = activityService;

        var activityArgs = <IRunPhaseArgs>$scope.activity.directiveArgs;
        this.activityId = activityArgs.activityId;
        this.masterContext = activityArgs.masterContext;
        this.phaseName = activityArgs.phaseName;
        this.phaseArgs = activityArgs.phaseArgs;
        this.notifications = [
            ...(this.phaseArgs.validationsLog.errors || []),
            ...(this.phaseArgs.validationsLog.warnings || [])
        ]

        if (this.phaseArgs.validationsLog.status !== "Failed") {
            $scope.$emit(ACTIVITY_EVENT.addActivitySuccessActions,
                this.getConfirmFunction(),
                'Ok',
                "Confirm");

            $scope.$emit(ACTIVITY_EVENT.addActivityActions,
                [
                    {
                        id: "cancel",
                        order: 1,
                        click: this.getCancelFunction(),
                        buttonCssClass: 'btn-danger btn-clear',
                        showCondition: () => true,
                        htmlContent: '<span class="glyphicon glyphicon-ban-circle"></span>&nbsp;Cancel',
                        tooltip: "Cancel"
                    }
                ]);
        } else {
            $scope.$emit(ACTIVITY_EVENT.addActivityActions, [
                {
                    id: "close",
                    order: 0,
                    click: this.getCancelFunction(),
                    buttonCssClass: 'btn-danger btn-clear',
                    showCondition: () => true,
                    htmlContent: '<span class="glyphicon glyphicon-ban-circle"></span>&nbsp;Close',
                    tooltip: "Close"
                }
            ]);
        }
    }


    private getConfirmFunction(): () => void {
        return () => {
            this.phaseArgs.userResponse = "Continue";

            this.activityService.runPhase({
                activityId: this.activityId,
                masterContext: this.masterContext,
                phaseName: this.phaseName,
                phaseArgs: this.phaseArgs
            });
        }
    }

    private getCancelFunction(): () => void {
        return () => {
            this.phaseArgs.userResponse = "Cancel";

            this.activityService.runPhase({
                activityId: this.activityId,
                masterContext: this.masterContext,
                phaseName: this.phaseName,
                phaseArgs: this.phaseArgs
            });
        }
    }
}