'use strict';
import {IScope, IAugmentedJQuery, IAttributes} from 'angular';
import {register, DOM_EVENT} from "@systemorph/web";
import {forEach, filter, map, includes} from 'lodash';

const selectedClassName = 'selected';

register.directive('multiSelectContainer', () => ({
    restrict: 'E',
    controller: MultiLineSelectController,
    bindToController: {
        'selected': '='
    },
    controllerAs: 'mlsctrl'
}));

type  Item = { content: any, element: IAugmentedJQuery };

export class MultiLineSelectController {
    items: Item[] = [];
    selected: any[] = [];
    previousClickedItem: Item = null;

    registerItem(item: Item) {
        this.items.push(item);
        if (includes(this.selected, item.content)) {
            item.element.addClass(selectedClassName);
        }
    }

    resetSelection() {
        forEach(this.items, (item: Item) => {
            item.element.removeClass(selectedClassName);
        });

        this.selected = [];
    }


    onItemClick(item: Item, shiftKey: boolean) {
        if (!shiftKey) {
            this.resetSelection();
            item.element.toggleClass(selectedClassName);
        } else {
            if (this.previousClickedItem) {
                let start = this.items.indexOf(this.previousClickedItem);
                let end = this.items.indexOf(item);
                if (start > end) {
                    start = start + end;
                    end = start - end;
                    start = start - end;
                }
                for (let i = start; i <= end; i++) {
                    this.items[i].element.addClass(selectedClassName);
                }
            }
        }

        this.previousClickedItem = item;
        const filtered = filter(this.items, (item) => item.element.hasClass(selectedClassName));
        this.selected = map(filtered, 'content');
    }
}

register.directive('selectableLine', () => ({
    restrict: 'A',
    require: '^^multiSelectContainer',
    scope: {
        selectableLine: '='
    },
    link: (scope: IScope, $element: IAugmentedJQuery, attrs: IAttributes, containerCtrl: MultiLineSelectController) => {
        const item = {content: scope.selectableLine, element: $element};
        containerCtrl.registerItem(item);

        $element.on(DOM_EVENT.click, (event: JQueryInputEventObject) => {
            containerCtrl.onItemClick(item, event.shiftKey);
        });
    }
}));

