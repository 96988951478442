'use strict';

import { IMenuItemScope, register } from '@systemorph/web';

interface IFilterColumnsDataGridMenuItemScope extends IMenuItemScope {
    filter(): void;
}

register.directive('filterColumnsDataGridMenuItem', () => {
    return {
        replace: true,
        restrict: 'E',
        transclude: true,
        template: `<button type="button" class="btn btn-info btn-inline btn-inline-static" ng-click="filter()" uib-tooltip="Column actions" tooltip-placement="top-right" tooltip-append-to-body="true">
                    <span class="glyphicon glyphicon-eye-open"></span>
                  </button>`,
        controller: ($scope: IFilterColumnsDataGridMenuItemScope, $element: ng.IAugmentedJQuery) => {
            $scope.filter = () => {
                setTimeout(() => {
                    $element.closest('.data-grid').find(".grid .ui-grid-menu-button .ui-grid-icon-container").click();
                }, 0);
            };
        }
    };
});
