
export const AUDIT_TYPE = {
    time: 'Time',
    branch: 'Branch'
};

export const AUDIT_TIME = {
    now: 'Now',
    dayAgo: 'DayAgo',
    twoDaysAgo: 'TwoDaysAgo',
    custom: 'Custom'
};