'use strict';

import { COMMON_EVENT, ANGULAR_EVENT } from "@systemorph/web";
import { BaseFormEntityPropertyController, IFormEntityPropertyScope } from "./baseFormEntityPropertyController";
import { IAutocompleteBoxItem } from '@systemorph/ui-web';
import {
    IDependencyPropertyChangedArgs,
    IFormEntityDependencyPropertyChangedFeedback
} from "../formEntity.api";
import { FORM_ENTITY_EVENT } from "../formEntity.events";
import { IPromise, IAngularEvent } from 'angular';
import { isEmpty, head } from "lodash";
import {FormEntityService} from "../formEntityService";

export class ValueDiff<TValue> {
    constructor(public newValue: TValue, public oldValue: TValue) {
    }
}

export const defaultAutocompleteFormEntityPropertyTempplate = `
    <div class="autocomplete-form-entity-property" ng-class="{'is-loading': ctrl.isLoading}">
        <autocomplete-box ng-model='ctrl.value' 
                          autocomplete='ctrl.autocomplete' 
                          on-change="ctrl.onChange()" 
                          ng-disabled="ctrl.isLoading">
        </autocomplete-box>
    <div>`;

export const defaultAutocompleteFormEntityPropertyControllerAs = `ctrl`;

export abstract class BaseAutocompleteFormEntityPropertyController<TValue, TUrlOrOldValue> extends BaseFormEntityPropertyController<TValue> {
    value: TValue;
    autocomplete: IAutocompleteBoxItem[];
    protected autocompleteCacheKey: string;
    isLoading: boolean;

    constructor(protected $scope: IFormEntityPropertyScope,
                protected formEntityService: FormEntityService,
                protected getAutocompleteFunc: () => IPromise<IAutocompleteBoxItem[]>,
                updateAutocompleteIfSomethingChanged: boolean = true) {
        super($scope);

        //TODO move this to separate location #12846
        this.autocompleteCacheKey = `__${this.propertyLayout.systemName}CachedAutocomplete`;
        this.autocomplete = this.entity[this.autocompleteCacheKey];
        //TODO stop
        this.value = this.getValue();

        const dependentPropertyNames = $scope.entity.__provider
            .getDependencies($scope.propertyLayout, false);

        if (!isEmpty(dependentPropertyNames)) {
            this.actAsDependentProperty();
        }

        if (updateAutocompleteIfSomethingChanged) {
            $scope.$on(COMMON_EVENT.commitDataToServer, (e: IAngularEvent) => {
                this.refresh(true);
            });
        }

        this.$scope.$on(FORM_ENTITY_EVENT.refreshProperties, (event, properties: string[], waitPromises: IPromise<any>[]) => {
            if (properties.includes(this.$scope.propertyLayout.systemName)) {
                waitPromises.push(this.refresh(false));
            }
        });

        this.emitInitializedEvent();
    }

    //TODO: remove event-based setup for the form entities #12853
    protected actAsDependentProperty() {
        const off = this.formEntityService.onDependencyPropertyChanged(this.$scope.propertyLayout, changes => {
            return this.refresh(false).then(diff => {
                const feedback: IFormEntityDependencyPropertyChangedFeedback = {
                    propertyLayout: this.propertyLayout,
                    newValue: diff.newValue,
                    oldValue: diff.oldValue
                };

                return feedback;
            });
        });

        this.$scope.$on(ANGULAR_EVENT.scopeDestroy, off);
    }
    // TODO end

    protected getUrlOrOldValue(): TUrlOrOldValue {
        return <TUrlOrOldValue>this.entity.getStateParamsOrCurrentValue(this.propertyLayout);
    }

    // this is default reaction on this.value changed from autocomplete. see defaultTemplates
    onChange(){
        this.setValue(this.value, true);
    }

    protected refresh(notify: boolean): IPromise<ValueDiff<TValue>> {
        this.isLoading = true;

        return this.getAutocompleteFunc()
            .then((autocomplete: IAutocompleteBoxItem[]) => {
                this.saveAutocomplete(autocomplete);
                const desiredValue = this.getUrlOrOldValue();

                const oldValue: TValue = this.getValue();
                const newValue: TValue = this.findValueInAutocomplete(desiredValue, autocomplete);

                this.value = newValue;
                this.setValue(newValue, notify);

                return new ValueDiff<TValue>(newValue, oldValue);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    protected findValueInAutocomplete(value: TUrlOrOldValue, autocomplete: IAutocompleteBoxItem[]): TValue {
        const index: number = this.getIndexFromAutocomplete(value, autocomplete);
        if (index === -1) {
            if (isEmpty(autocomplete)){
                return undefined;
            }
            return head(autocomplete).value;
        }
        return autocomplete[index].value;
    }

    protected abstract getIndexFromAutocomplete(value: TUrlOrOldValue, autocomplete: IAutocompleteBoxItem[]): number;

    protected saveAutocomplete(autocomplete: IAutocompleteBoxItem[]): void {
        //TODO move this to separate location #12846
        this.entity[this.autocompleteCacheKey] = autocomplete;
        //TODO stop
        this.autocomplete = autocomplete;
    }
}