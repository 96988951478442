'use strict';

import { Dictionary } from 'lodash';
import { IUnitsMultiplier } from './report.api';
import { arrayToDictionary } from "./helpers";

export const REPORT_MODEL_NODE_TYPE = {
    group: "Group",
    column: "Column"
};
export const REPORT_MODEL_ROW_TYPE = {
    title: "Title",
};

export const REPORT_MODEL_RELOAD = {
    full: "Full",
    singleRow: "SingleRow",
    noReload: "NoReload"
};

export const SPECIAL_NUMBER = {
    nan: "NaN",
    negativeInfinity: "-Infinity",
    infinity: "Infinity"
};
export const REPORT_STATE = {
    report: 'reportState'
};

export const UNITS_MULTIPLIERS: IUnitsMultiplier[] = [
    { prefix: 'k', multiplier: 1000 },
    { prefix: 'm', multiplier: 1000000 },
    { prefix: 'b', multiplier: 1000000000 },
];

export const UNITS_MULTIPLIERS_DICTIONARY: Dictionary<IUnitsMultiplier> = arrayToDictionary(UNITS_MULTIPLIERS, m => m.multiplier);