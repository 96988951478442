'use strict';

import {
    register, ILayoutService, DISPLAY_CONTEXT, ITypeLayout, IPropertyLayout, ACTIVITY_EVENT,
    IActivityService, mapKeysDeep,
    DETAILS_MODE, IActivityPhaseDirectiveScope
} from '@systemorph/web';
import { IBusinessProcessKickoffArgs } from './activities.api';
import { upperFirst, map, filter, compose, camelCase, isEmpty, get } from 'lodash/fp';
import { DataPack } from '../businessProcess.api';
import { IFormController, IAugmentedJQuery, ITimeoutService } from 'angular';

register.directive('setupKickoffActivityDialog', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: require('./setupKickoffActivityDialog.html'),
        controllerAs: "kickOffctrl",
        controller: Controller
    };
});

class Controller {
    entity: IBusinessProcessKickoffArgs;
    typeLayout: ITypeLayout;
    displayContext: string = DISPLAY_CONTEXT.details;;
    propertyLayouts: IPropertyLayout[];
    mode: string = DETAILS_MODE.edit;

    dialogForm: IFormController;

    constructor(private $scope: IActivityPhaseDirectiveScope,
                activityService: IActivityService,
                layoutService: ILayoutService,
                $element: IAugmentedJQuery,
                $timeout: ITimeoutService) {

        this.entity = $scope.activity.directiveArgs.phaseArgs;
        
        $timeout(()=> {
            $element.closest(".modal-dialog").addClass("modal-xl");
        });

        if (!this.entity) {
            throw new Error('setupKickoff. Entity is empty.');
        }

        //HACK start!:  This is needed for mapped as json directive. Otherwise it will not understand layout
        this.entity.processInstance = mapKeysDeep(upperFirst)(this.entity.processInstance);
        //HACK end

        layoutService.layout(this.entity.$type, DISPLAY_CONTEXT.details).$promise
            .then((kickOffInitialValuesLayout: ITypeLayout) => {
                this.typeLayout = kickOffInitialValuesLayout;
                this.propertyLayouts =
                    compose(
                        filter<IPropertyLayout>((propertyLayout: IPropertyLayout) => !isEmpty(get(propertyLayout.systemName, this.entity))),
                        map((propertyLayout: IPropertyLayout) =>
                            ({
                                ...propertyLayout,
                                systemName: camelCase(propertyLayout.systemName)
                            })
                        ),
                        filter<IPropertyLayout>('isVisible')
                    )(this.typeLayout.propertyLayouts);
            });


        $scope.$emit(ACTIVITY_EVENT.addActivitySuccessActions,
            () => {
                if (this.dialogForm.$invalid) {
                    this.$scope.activity.blocked = false;
                    return;
                }

                const activityArgs = $scope.activity.directiveArgs;

                //TODO start: move it to datapacks component
                activityArgs.phaseArgs.dataPacks = filter<DataPack>('isSelected')(activityArgs.phaseArgs.dataPacks);
                //TODO end
                activityService.runPhase({
                    activityId: activityArgs.activityId,
                    masterContext: activityArgs.masterContext,
                    phaseName: activityArgs.phaseName,
                    phaseArgs: activityArgs.phaseArgs
                });
            },
            'Start Process',
            'Start Process'
        );
    }
}