'use strict';

import { Dictionary, last } from 'lodash';
import {IPropertyLayout} from '@systemorph/web';

export function dictionaryToArray<T>(dict: Dictionary<T>): T[] {
    return Object.keys(dict).map(key => dict[key]);
}

export function removeFromArray<T>(array: T[], item: T) {
    var index = array.indexOf(item);
    if (index !== -1)
        array.splice(index, 1);
}

export function clearArray(array: any[]) {
    array.splice(0, array.length);
}

export function reorderArrayItem<T>(array: T[], oldIndex: number, newIndex: number) {
    if (!array || oldIndex >= array.length || newIndex >= array.length) {
        throw 'wrong arguments';
    }

    const item = array.splice(oldIndex, 1);
    array.splice(newIndex, 0, ...item);
}

// returns names of object's properties and methods (including the ones from prototypes chain, unlike Object.keys)
export function getObjectProperties(obj: any) {
    const props: string[] = [];

    for (let p in obj)
        props.push(p);

    return props;
}

export function normalizeComponentName(name: string, suffix: string) {
    if (name.substr(name.length - suffix.length) !== suffix) name += suffix;
    return lcFirst(name);
}

export function isNullOrUndefined(val: any) {
    return val === null || val === undefined;
}

export function extendMissingProperties(dest: any, src: any) {
    for (var k in src) {
        if (Array.isArray(src[k])) {
            if (dest[k] !== null) {
                if (!dest[k]) dest[k] = [];
                extendMissingProperties(dest[k], src[k]);
            }
        }
        else if (isObject(src[k])) {
            if (isObject(dest[k]) || dest[k] === undefined) {
                if (!dest[k]) dest[k] = {};
                extendMissingProperties(dest[k], src[k]);
            }
        }
        else {
            if (dest[k] === undefined)
                dest[k] = src[k];
        }
    }

    return dest;
}

export function filterCollection<T>(collection: T[], getElementName: (el: T) => string, args: any[]) {
    const indexesByName: Dictionary<number> = {};
    collection.forEach((el, i) => indexesByName[getElementName(el)] = i);

    const getIndex = (arg: number) => arg < 0 ? collection.length + arg : arg;

    const result: T[] = [];

    args.forEach((arg: any) => {
        if (Array.isArray(arg)) {
            let [start, end] = <any[]>arg;
            if (typeof(start) == 'string') start = indexesByName[start];
            else start = getIndex(start);
            if (end !== undefined) {
                if (typeof(end) == 'string') {
                    if (indexesByName[end] === undefined) throw `Element named ${end} not found`;
                    end = indexesByName[end];
                }
                else {
                    end = getIndex(end);
                }
            }
            result.push(...collection.slice(start, end !== undefined ? end + 1 : undefined));
        }
        else {
            if (typeof(arg) == 'string') arg = indexesByName[arg];
            else arg = getIndex(arg);

            if (arg < collection.length) result.push(collection[arg]);
        }
    });

    return result;
}

export function updateCollectionOrder<T, K extends keyof T>(collection: T[], reorderedItems: T[], orderProperty: K, start = 0, increment = 1) {
    const precedingItems: any[] = collection.slice(0, collection.indexOf(reorderedItems[0]));
    const subsequentItems: any[] = collection.slice(collection.indexOf(last(reorderedItems)) + 1);

    if (subsequentItems.length > 0) {
        const gapStart = precedingItems.length > 0 ? last(precedingItems)[orderProperty] : start;
        const gapEnd = subsequentItems.length > 0
            ? subsequentItems[0][orderProperty] : last(precedingItems)[orderProperty] + increment;

        if (gapEnd - gapStart > reorderedItems.length) {
            const interval = Math.floor((gapEnd - gapStart - 1) / reorderedItems.length);

            reorderedItems.forEach((item, i) => {
                item[orderProperty] = gapStart + interval * (i + 1);
            });
        }
        else {
            reorderedItems.forEach((item, i) => {
                item[orderProperty] = gapStart + increment * (i + 1);
            });

            // shifting subsequent items order forward
            subsequentItems.forEach((item, i) => {
                item[orderProperty] = gapStart + increment * reorderedItems.length + increment * (i + 1);
            });
        }
    }
    else {
        const lastDisplayOrder = last(precedingItems)[orderProperty];

        reorderedItems.forEach((item, i) => {
            item[orderProperty] = lastDisplayOrder + increment * (i + 1);
        });
    }
}

export function debounce(func: Function, wait: number, immediate = false) {
    var timeout: any;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

let canvas: HTMLCanvasElement;

export function getTextWidth(text: string, font: string) {
    if (!canvas) canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}
export function isObject(value: any) {
    // http://jsperf.com/isobject4
    return value !== null && typeof value === 'object';
}

export function escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function getElementOffset(element: any, parentElement: any) {
    const el = $(element);

    const elementAndItsRelativeParents = el
        .parentsUntil(parentElement)
        .filter((i, el) => $(el).css('position') == 'relative')
        .get()
        .concat(el.get(0));

    const result = {
        top: 0,
        left: 0
    };

    elementAndItsRelativeParents.forEach(el => {
        result.top += el.offsetTop;
        result.left += el.offsetLeft;
    });

    return result;
}

export function extractListPropertyTypeName(propertyLayout: IPropertyLayout) {
    return propertyLayout.propertyTypeName.substr(5);
}

// the following is needed to reduce the size of reportModelParser bundle
// some of it duplicates lodash, some - platform utils

// sample => Sample
export function ucFirst(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

// SAMPLE => sAMPLE
export function lcFirst(str: string) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}


export function arraySum(array: number[]) {
    return array.reduce((a, b) => a + b, 0);
}

export function arrayMax(array: number[]) {
    return Math.max(...array);
}