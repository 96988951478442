'use strict';

import {register} from "@systemorph/web";
import {startCase, Dictionary, mapValues, keyBy, isEqual } from 'lodash';
import {
    IScope,
    IAugmentedJQuery,
    IAttributes,
    INgModelController,
    ITemplateCacheService
} from "angular";
import {isObject} from "../../utils";
import {ISearchBoxContextItem} from "./searchBox.api";

interface ISearchBoxScope extends IScope {
    searchBoxCtrl: SearchBoxCtrlController;
}

function normalizeContextItems(items: any) {
    return items.map((item: any) => {
        return item && isObject(item) ? item : { systemName: item, displayName: startCase(item)};
    });
}

register.directive('searchBox', ($templateCache: ITemplateCacheService) => {
    const popoverTemplateUrl = require.resolve('./searchBoxPopover.html');
    $templateCache.put(popoverTemplateUrl, require('./searchBoxPopover.html'));

    return {
        replace: true,
        scope: true,
        require: 'ngModel',
        bindToController: {
            originalContextItems: '=contextItems',
            context: '=',
            isCaseSensitive: '=',
            placeholder: '@',
            onOptionsChange: '&'
        },
        template: `
            <div class="sm-filter-search dropdown-container">
                <div class="ui-select-bootstrap dropdown">
                    <span class="sm-filter-search__options"
                          ng-class="{'sm-filter-search__options--custom': !searchBoxCtrl.isDefaultContextAndOptions()}"
                          popover-trigger="outsideClick"
                          popover-placement="bottom-left"
                          uib-popover-template="'${popoverTemplateUrl}'"
                          popover-is-open="searchBoxCtrl.isOpen"
                          title="Search options">
                          <i class="material-icons">search</i>
                          <i class="material-icons">arrow_drop_down</i>
                    </span>
                    <input class="form-control sm-filter-search__field"
                           ng-model="searchBoxCtrl.searchText"
                           ng-change="searchBoxCtrl.onSearchTextChange()"
                           placeholder="{{searchBoxCtrl.placeholder}}"/>
                    <i class="material-icons md-18 sm-filter-search__field-clear"
                       ng-if="searchBoxCtrl.searchText"
                       ng-click="searchBoxCtrl.clear()"
                       title="Clear"
                       aria-hidden="true">cancel</i>
                </div>
            </div>
           `,
        link: function(scope: ISearchBoxScope, element: IAugmentedJQuery, attrs: IAttributes, ngModelCtrl: INgModelController) {
            scope.searchBoxCtrl.link(ngModelCtrl)
        },
        controller: SearchBoxCtrlController,
        controllerAs: 'searchBoxCtrl'
    }
});

class SearchBoxCtrlController {
    searchText: string;
    selectedContextItems: Dictionary<boolean>;
    isOpen: boolean;
    ngModelCtrl: INgModelController;
    contextItems: ISearchBoxContextItem[];
    isCaseSensitiveInner: boolean;

    // binding
    originalContextItems: any[];
    isCaseSensitive = false;
    context: string[];
    onOptionsChange: (locals: {context: string[], isCaseSensitive: boolean}) => void;

    constructor(private $scope: ISearchBoxScope,
                private $element: IAugmentedJQuery) {
        if (this.originalContextItems)
            this.contextItems = normalizeContextItems(this.originalContextItems);

        if (!this.context)
            this.context = this.contextItems.map(i => i.systemName);

        this.selectedContextItems = mapValues(keyBy(this.contextItems, i => i.systemName), i => this.context.indexOf(i.systemName) != -1);
        this.isCaseSensitiveInner = this.isCaseSensitive;

        this.$scope.$watch(() => this.isOpen, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                if (newValue) this.onPopoverOpen();
                else this.onPopoverClose();
            }
        });
    }

    startCase = startCase;

    link(ngModelCtrl: INgModelController) {
        this.ngModelCtrl = ngModelCtrl;

        this.ngModelCtrl.$render = () => {
            this.searchText = this.ngModelCtrl.$viewValue;
        }
    }

    private onPopoverOpen() {
    }

    private onPopoverClose() {
        const context = this.getContextValue();

        const contextChanged = !isEqual(context, this.context);
        const isCaseSensitiveChanged =  this.isCaseSensitiveInner != this.isCaseSensitive;

        if (contextChanged)
            this.context = context;

        if (isCaseSensitiveChanged)
            this.isCaseSensitive = this.isCaseSensitiveInner;

        if (this.onOptionsChange && (contextChanged || isCaseSensitiveChanged))
            this.onOptionsChange({context, isCaseSensitive: this.isCaseSensitive});
    }

    private getContextValue() {
        return this.contextItems.map(i => i.systemName)
            .filter(i => this.selectedContextItems[i]);
    }

    onSearchTextChange() {
        this.ngModelCtrl.$setViewValue(this.searchText);
    }

    selectContextItem(item: ISearchBoxContextItem) {
        this.selectedContextItems[item.systemName] = !this.selectedContextItems[item.systemName];
    }

    selectCaseSensitive() {
        this.isCaseSensitiveInner = !this.isCaseSensitiveInner;
    }

    clear() {
        this.searchText = '';
        this.onSearchTextChange();
        this.$element.find('input').focus();
    }

    isDefaultContextAndOptions() {
        return this.contextItems.length != Object.keys(this.selectedContextItems).length
            || this.contextItems.some(x => !this.selectedContextItems[x.systemName])
            || this.isCaseSensitive;
    }
}