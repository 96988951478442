import {register, IRecursiveDirectiveService} from '@systemorph/web';

register.directive('groupAssignmentsOfGroupHierarchical', (recursiveDirectiveService: IRecursiveDirectiveService) => {
    return {
        replace: true,
        restrict: 'E',
        scope: {
            hierarchies: "=",// of type IGroupAssignmentsByScopeHierarchy[],
            highlightText: "=" //string
        },
        template: require('./groupAssignmentsOfGroupHierarchical.html'),
        compile: (element: angular.IAugmentedJQuery) => {
            return recursiveDirectiveService.compile(<any>element);
        }
    }
});