'use strict';

import { register, COMMON_EVENT, PERSISTANCE_EVENT } from "@systemorph/web";
import { IBusinessProcessService } from "../businessProcess.api";
import { SERVER_EVENTS } from "../businessProcess.constants";
import { IQService, IScope, blockUI } from "angular";

register.directive("businessProcessesSummary", () => {
    return {
        restrict: 'E',
        // replace: true,
        template: require('./businessProcessesSummary.html'),
        scope: true,
        controller: BusinessProcessesSummaryController,
        controllerAs: "summary",
    }
});

class BusinessProcessesSummaryController {
    isReady: boolean;
    hasCompletedProcesses: boolean;
    hasActiveProcesses: boolean;

    constructor($scope: IScope,
                private businessProcessService: IBusinessProcessService,
                private blockUI: blockUI.IBlockUIService,
                private $q: IQService) {

        this.refresh();

        const eventsToReload = [
            COMMON_EVENT.dataVersionChanged, 
            COMMON_EVENT.persistenceContextChanged,
            COMMON_EVENT.commitDataToServer,
            PERSISTANCE_EVENT.backedNotifiedDataVersionWasChanged,
            SERVER_EVENTS.processFinished
        ];
        eventsToReload.forEach(eventName => {
            $scope.$on(eventName, () => {
                this.refresh();
            });
        })
    }

    refresh() {
        this.blockUI.start(null);

        const completedProcessesPromise = this.businessProcessService.getProcesses(0, 1, true)
            .then(data => {
                this.hasCompletedProcesses = data.totalModelsCount > 0;
            });

        const activeProcessesPromise = this.businessProcessService.getProcesses(0, 1, false)
            .then(data => {
                this.hasActiveProcesses = data.totalModelsCount > 0;
            });

        this.isReady = false;
        return this.$q.all([activeProcessesPromise, completedProcessesPromise])
            .finally(() => {
                this.blockUI.stop();
                this.isReady = true;
            })
    }
}