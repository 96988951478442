'use strict';

import {register} from "@systemorph/web";
import {IDeferred, IQService} from 'angular';
import {Dictionary} from 'lodash';
import {FormEntityProvider} from './formEntityProvider';
import {FormEntityController} from "./formEntityDirective";

interface IFormEntityRegistryEntry {
    deferred: IDeferred<IFormEntityRegistryEntry>;
    provider?: FormEntityProvider;
    formEntityController?: FormEntityController;
    isSet?: boolean;
}

export class FormEntityRegistry {
    private entries: Dictionary<IFormEntityRegistryEntry> = {};

    constructor(private $q: IQService) {
    }

    isSet(scope: string = null) {
        return this.entries[scope] && this.entries[scope].provider;
    }

    get(scope: string = null) {
        if (!this.entries[scope])
            this.entries[scope] = { deferred: this.$q.defer() };

        return this.entries[scope].deferred.promise;
    }

    set(scope: string, provider: FormEntityProvider, formEntityController: FormEntityController) {
        if (!this.entries[scope])
            this.entries[scope] = { deferred: this.$q.defer() };

        const entry = this.entries[scope];
        entry.deferred.resolve(entry);
        entry.provider = provider;
        entry.formEntityController = formEntityController;
        entry.isSet = true;
    }

    reset(scope: string = null) {
        const entry = this.entries[scope];

        if (entry && entry.isSet) {
            // entry.deferred.reject(`Removing "${entry.provider.name}" provider`)
            delete this.entries[scope];
        }
    }

    getProvider(scope: string = null) {
        if (!this.entries[scope])
            this.entries[scope] = { deferred: this.$q.defer() };

        return this.entries[scope].deferred.promise
            .then(entry => entry.provider);
    }

    getFilterObject(scope: string = null) {
        return this.getProvider(scope)
            .then(provider => {
                if (!provider)
                    throw `No provider is set for ${scope} scope`;

                return provider.getFilterObject()
            });
    }

    getFilterObjectMainPropertiesOnly(scope: string = null) {
        return this.getProvider(scope)
            .then(provider => {
                if (!provider)
                    throw `No provider is set for ${scope} scope`;

                return provider.getFilterObjectMainPropertiesOnly()
            });
    }

    getFormEntity(scope: string = null) {
        return this.getProvider(scope)
            .then(provider => {
                if (!provider)
                    throw `No provider is set for ${scope} scope`;

                return provider.formEntity
            });
    }
}

register.service('formEntityRegistry', FormEntityRegistry);