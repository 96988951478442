'use strict';

import { register, setPageTitle, IUserService, IMessageService } from '@systemorph/web';
import { 
    IAccessControlDialogService, 
    AC_ASSIGNMENT_TYPE, 
    ACCOUNT_STATE,
    IAccountService, IAuthenticationData
} from '@systemorph/access-control-web';
import { IScope, IHttpService} from 'angular';

//TODO after this is merged to master, pay attention to the index.ts file in same directory. It must export this file.
export class NotAuthorizedBuController {
    showLogoutButton : boolean;

    /*@ngInject*/
    constructor(private $scope: IScope,
                private authenticationData: IAuthenticationData,
                private $http: IHttpService,
                private userService: IUserService,
                private messageService: IMessageService,
                private accessControlDialogService: IAccessControlDialogService) {

        setPageTitle(this.$scope, "You have no rights");

        this.showLogoutButton = this.authenticationData.isForms;
    }

    logout() {
        this.$http.post("/api/account/logout", {}).then(() => {
            window.location.reload();
        })
    }

    request() {
        this.userService.getCurrentUserInfo().then(currentUserInfo => {
            this.accessControlDialogService.requestGroupAssignmentForAccessObject(AC_ASSIGNMENT_TYPE.member, currentUserInfo.systemName, currentUserInfo.displayName)
                .then(ret => {
                    this.messageService.alertSuccess("Requests were sent");
                });
        })
    }
}

register.state({
    name: "notAuthorizedBu",
    parent: ACCOUNT_STATE.noAccessLayout,
    url: '/?ReturnUrl',
    template: require('./notAuthorizedBu.html'),
    controller: NotAuthorizedBuController,
    controllerAs: 'pageCtrl',
    resolve: {
        authenticationData: /*@ngInject*/(accountService: IAccountService) => {
            return accountService.getAuthenticationData();
        }
    },
    suppressReloadMessage: true
}, { applicationStage: "NotAuthorizedBu", useAsOtherwise: true });