'use strict';

import {register, STATE} from "@systemorph/web";
import {IPositionRegistryEntryModel} from "../positions.api";
import { POSITION_STATE } from "../position.constants";
import {IReportUtilsService} from "../../reportingWeb.api";
import {IScope} from "angular";
import { IStateParamsService, IStateService } from "angular-ui-router";
import {PositionRegistryController} from "./positionRegistryController";

interface IReportPositionRegistryStateParams extends IStateParamsService {
    report: string;
    state: string;
    storage: string;
}

class ReportPositionRegistryController extends PositionRegistryController {
    report: string;

    /*@ngInject*/
    constructor($scope: IScope,
                private $state: IStateService,
                $stateParams: IReportPositionRegistryStateParams,
                reportUtilsService: IReportUtilsService) {
        super($stateParams.state, $stateParams.storage, `Report Position Registry`, $scope, reportUtilsService);

        this.report = $stateParams.report;
    }

    getEntryUrl(entry: IPositionRegistryEntryModel, editableTypeName: string) {
        return this.$state.href(POSITION_STATE.reportPosition, {
            report: this.report,
            state: this.state,
            storage: this.storage,
            positionName: entry.positionName,
            editableTypeName
        });
    }
}

register.state({
    name: POSITION_STATE.reportPositionRegistry,
    parent: STATE.defaultLayout,
    url: '/reportPositionRegistry?report&state&storage',
    template: require('./positionRegistry.html'),
    controllerAs: 'registryCtrl',
    controller: ReportPositionRegistryController,
    suppressReloadMessage: true
});