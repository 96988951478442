'use strict';

import {COMMON_EVENT} from "@systemorph/web";
import {IPositionRegistryEntryModel} from "../positions.api";
import {IReportUtilsService} from "../../reportingWeb.api";
import {IScope} from "angular";

export abstract class PositionRegistryController {
    isReady: boolean;
    entries: IPositionRegistryEntryModel[];

    constructor(protected state: string,
                protected storage: string,
                protected title: string,
                protected $scope: IScope,
                protected reportUtilsService: IReportUtilsService) {
        this.refreshEntries()
            .finally(() => {
                this.isReady = true;
            });

        this.$scope.$on(COMMON_EVENT.dataVersionChanged, (event) => {
            this.refreshEntries();
        });
    }

    private refreshEntries() {
        return this.reportUtilsService.getPositionRegistryEntries(this.state, this.storage)
            .then(entries => {
                this.entries = entries;
            });
    }

    abstract getEntryUrl(entry: IPositionRegistryEntryModel, editableTypeName: string): string;
}