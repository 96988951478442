'use strict';

import { register } from "@systemorph/web";
import {
    defaultAutocompleteFormEntityPropertyTempplate,
    defaultAutocompleteFormEntityPropertyControllerAs,
    IFormEntityPropertyScope, FormEntityService
} from "@systemorph/form-entity-web";
import { IAutocompleteBoxItem } from "@systemorph/ui-web";
import { IPeriodModel, BasePeriodFormEntityPropertyController } from '@systemorph/reporting-web';
import { IPromise, IAugmentedJQuery } from 'angular';
import { IIfrsFormEntityService } from '../ifrsFormEntity.api';

register.directive('ifrsPeriodFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controller: PeriodFormEntityPropertyController,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs
    };
});

class PeriodFormEntityPropertyController extends BasePeriodFormEntityPropertyController {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                $element: IAugmentedJQuery,
                ifrsFormEntityService: IIfrsFormEntityService) {
        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            const year: number = $scope.entity['year'];
            const businessProcess: string = $scope.entity['businessProcess'];
            return ifrsFormEntityService.getPeriods(year, businessProcess)
                .then(periods => periods.map((p: IPeriodModel): IAutocompleteBoxItem => ({value: p.systemName, displayName: p.displayName})));
        };
        super($scope, formEntityService, $element, getAutocompleteFunc, true);
    }
}
