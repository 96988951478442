'use strict';

import { register, getOrAddCachedValue } from '@systemorph/web'
import { IHttpService, ICacheFactoryService, ICacheObject, IPromise, IQService } from 'angular';

export interface IInterfaceModel {
    name: string;
    formEntityProvider: string;
    displayName: string;
    formats: string[];
}

export interface IFileModel {
    interface: string;
    name: string;
    lastModified: Date;
    size: number;
}

export class InterfaceFileSelectService {
    private cache: ICacheObject;

    private readonly baseUri = '/api/interfaceFileSelect/';

    constructor(private $http: IHttpService, private $q: IQService, $cacheFactory: ICacheFactoryService) {
        this.cache = $cacheFactory("InterfaceFileSelect");
    }

    getFiles(interfaceName: string, queryModel: any): IPromise<IFileModel[]> {
        if (!interfaceName) {
            return this.$q.when([]);
        }

        return this.$http.get<IFileModel[]>(`${this.baseUri}Files`, { params: { interfaceName, ...queryModel }}).then(result => result.data);
    }

    getInterfaces(): IPromise<IInterfaceModel[]> {
        const cacheKey = "InterfaceFileSelectKey"
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<IInterfaceModel[]>(`${this.baseUri}Interfaces`, {}).then(result => result.data);
        })
    }
}

register.service('interfaceFileSelectService', InterfaceFileSelectService)