'use strict';

import {register, IPropertyOfEntityScope} from "@systemorph/web";
import {IReportService} from "./report/report.api";

interface IReportingNumberPropertyScope extends IPropertyOfEntityScope {
    value: string;
}

register.directive('reportingNumberProperty', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        template: '<span class="details-string details-number" ng-bind="::value"></span>',
        controller: ReportingNumberPropertyController
    };
});

class ReportingNumberPropertyController {
    constructor($scope: IReportingNumberPropertyScope, reportService: IReportService) {
        const value = $scope.entity[$scope.propertyLayout.systemName];
        $scope.value = reportService.formatNumber(value);
    }
}