'use strict';

import { register, IPropertyOfEntityScope } from "@systemorph/web";
import { IAutocompleteBoxItem } from "../autocompleteBox/autocompleteBox.api";
import { convertToAutocmpleteBoxItems } from "../autocompleteBox/autocompleteBox.helpers";
import { head } from "lodash";

register.directive('autocompleteProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: `<div>
                       <autocomplete-box ng-model='ctrl.value' autocomplete='ctrl.autocomplete' on-change="ctrl.onChange()"> />
                   <div>`,
        controllerAs: `ctrl`,
        controller: AutocompletePropertyController
    };
});

class AutocompletePropertyController {
    value: any;
    autocomplete: IAutocompleteBoxItem[];

    constructor(private $scope: IPropertyOfEntityScope) {
        this.autocomplete = convertToAutocmpleteBoxItems(<any[]>$scope.entity[$scope.propertyLayout.systemName + "Autocomplete"]);
        this.value = $scope.entity[$scope.propertyLayout.systemName];

        if (this.autocomplete.findIndex(x => x.value === this.value) === -1) {
            const autocompleteItem = head(this.autocomplete)
            this.value = $scope.entity[$scope.propertyLayout.systemName] = autocompleteItem ? autocompleteItem.value : undefined;
        }
    }

    onChange() {
        this.$scope.entity[this.$scope.propertyLayout.systemName] = this.value;
    }
}
