'use strict';

export interface IFilteredListItem {
    isFilterable?: boolean; // true by default
    isCollapsible?: boolean; // true by default
    isCollapsed?: boolean;
}

export interface IFilteredListItemProperty {
    systemName: string;
    displayName?: string;
    getSearchText: (item: any) => string[];
    highlightSelector: string;
}