export const BUSINESS_PROCESS_STATE = {
    allProcesses: "allProcessesState",
    businessProcessDetails: "businessProcessDetailsState"
} 

export const ACTIVITY_TYPE = {
    statusChange: 'ProcessStatusChange'
};

export const DEFAULT_BRANCH_INFO = {
    name: "Default",
    displayName: "Production"
};

export const SERVER_EVENTS = {
    processFinished: "processFinishedEvent",
};

export const CURRENT_PROCESS_STATE = {
    finished: "Finished", 
    active: "Active"
};
