'use strict';

import { register, STATE } from "@systemorph/web";
import { POSITION_STATE } from '../position.constants';
import { IScope, ITimeoutService, blockUI } from "angular";
import {IStateParamsService} from "angular-ui-router";
import {PositionController} from "./positionController";

interface IImportPositionStateParams extends IStateParamsService {
    import: string;
    state: string;
    storage: string;
    positionName: string;
    editableTypeName: string;
}

class ImportPositionController extends PositionController {
    state: string;
    storage: string;
    positionName: string;
    editableTypeName: string;
    languagePath: string[];
    isReady: boolean;

    /*@ngInject*/
    constructor($scope: IScope,
                $timeout: ITimeoutService,
                $stateParams: IImportPositionStateParams,
                blockUI: blockUI.IBlockUIService) {
        // todo: remove hard-code for data level
        const languagePath = ['Import', $stateParams.import, 'Data', $stateParams.positionName];

        super($stateParams.state, $stateParams.storage, $stateParams.positionName, $stateParams.editableTypeName,
            languagePath, $scope, $timeout, blockUI);
    }
}

register.state({
    name: POSITION_STATE.importPosition,
    parent: STATE.defaultLayout,
    url: '/importPosition?import&state&storage&positionName&editableTypeName&filterTerm&filterCaseSensitive&filterProperties&mode&page&pageSize',
    template: require('./position.html'),
    controllerAs: 'positionCtrl',
    controller: ImportPositionController,
    suppressReloadMessage: true
});