'use strict';

import {register, IMenuItem} from "@systemorph/web";
import { IReportGridService } from './reportGrid.api';

class ReportGridService implements IReportGridService {
    /*@ngInject*/
    constructor(private $http: ng.IHttpService) {
    }

    getReportGridMenus(typeName: string, reportType: string, filterObject: any): ng.IPromise<IMenuItem[]> {
        if (reportType) {
            filterObject.reportType = reportType;
        }
        if (typeName) {
            filterObject.typeName = typeName;
        }

        return this.$http.get('/api/reportModel/GetReportMenuItems', { params: filterObject })
            .then((result: { data: IMenuItem[] }) => {
                return result.data;
            }).catch<IMenuItem[]>(() => {
                throw new Error("Report grid menus failed to load");
            });
    }

    getComparisonMenus(filterObject: any): ng.IPromise<IMenuItem[]> {
        return this.$http.get<IMenuItem[]>('/api/reportModel/GetComparisonMenuItems', { params: filterObject })
            .then(result => result.data)
            .catch<IMenuItem[]>(() => {
                throw new Error("Report grid comparison menus failed to load");
            });
    }

}

register.factory("reportGridService", ($http: ng.IHttpService) => {
    return new ReportGridService($http);
});