'use strict';

import { register, formatNumber } from "@systemorph/web";
import { FormEntityRegistry } from "@systemorph/form-entity-web";
import { DeferredStore, isNullOrUndefined } from "@systemorph/ui-web";
import { ReportModelParser } from "./reportModelParser";
import { IHttpService, ICacheObject, ICacheFactoryService, IQService } from 'angular';
import { IReportModel, IReportService} from './report.api';
import { SPECIAL_NUMBER } from './report.constants';
import { ReportController } from "./pages";
import { IReportDefinition } from "../reportingWeb.api";

const defaultNumberFormat = '# ##0.##!';

class ReportService implements IReportService {
    isDesignMode: boolean;
    reportDefinition: IReportDefinition;
    units = 1;

    private cache: ICacheObject;

    reportControllerStore: DeferredStore<ReportController>;

    constructor(private $http: IHttpService,
        private $q: IQService,
        $cacheFactory: ICacheFactoryService,
        private formEntityRegistry: FormEntityRegistry) {

        this.cache = $cacheFactory("ReportService");

        this.reportControllerStore = new DeferredStore<ReportController>($q);
    }

    loadReportModel() {
        return this.formEntityRegistry.getProvider()
            .then(provider => {
                const params = provider.getFilterObject();
                return this.$http.post<IReportModel>(`/api/ReportModel`, this.reportDefinition, { params })
                    .then(response => {
                        const reportModel = response.data;
                        reportModel.formEntity = provider.formEntity; // todo: get rid of this
                        reportModel.parser = new ReportModelParser(reportModel, params);

                        return reportModel;
                    });
            });
    }

    // todo: move to reportUtilsService
    // using "units(n)" format on top of the standard number formatting
    // sample format - # ##0.##! units(1)
    formatNumber(valueNumber: number, format?: string): string {
        let value: any = valueNumber;
        if (!format)
            format = defaultNumberFormat;

        if (value === SPECIAL_NUMBER.nan || value === SPECIAL_NUMBER.negativeInfinity || value === SPECIAL_NUMBER.infinity)
            return '-';

        const emptyMatch = format.match(/(?:\s+)?empty\(\s*(\S+)\s*\)/i);

        if (isNullOrUndefined(value)) {
            if (emptyMatch)
                return emptyMatch[1];
            return null;
        }

        if (emptyMatch)
            format = format.replace(emptyMatch[0], '');

        let formatUnits: number;
        const unitsMatch = format.match(/(?:\s+)?units\((\d+(?:.\d+)?)\)/i);

        if (unitsMatch) {
            formatUnits = Number(unitsMatch[1]);
            format = format.replace(unitsMatch[0], '');
        }

        return formatNumber(value, format, (value, isPercentage) => {
            const units = formatUnits || this.units;
            return (isPercentage || !units) ? value : value / units;
        });
    }
}

register.service("reportService", ReportService);