
'use strict';

import { register, IPropertyOfEntityScope } from "@systemorph/web";
import { IReportHierarchyItem, IReportHierarchyService } from "../../report/report.api";
import { ITreeItemModel, TreeUtils } from "@systemorph/ui-web";

interface IEditReportTypeScope extends IPropertyOfEntityScope {
}

register.directive("editReportTypeProperty", () => {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        template: `
            <div class="report-type-selector" ng-model="entity[propertyLayout.systemName]" apply-client-validation>
                <dropdown-tree ng-if="editReportType.treeItems"
                               items="editReportType.treeItems"
                               on-select="editReportType.onItemSelected(item)"
                               disabled="editReportType.isLoading">
                </dropdown-tree>
            </div>
        `,
        controller: EditReportTypeController,
        controllerAs: 'editReportType'
    }
});

class EditReportTypeController {
    isLoading: boolean;
    treeItems: ITreeItemModel[];

    constructor(private $scope: IEditReportTypeScope, private reportHierarchyService: IReportHierarchyService) {
        this.refreshTreeItems();
    }

    onItemSelected(item: ITreeItemModel) {
        this.$scope.entity[this.$scope.propertyLayout.systemName] = item.data.reportType;
    }

    private refreshTreeItems() {
        this.isLoading = true;
        return this.reportHierarchyService.getEditableReportsHierarchy()
            .then(reportHierarchyItems => {
                this.treeItems = TreeUtils.getTreeFromAnotherTree<IReportHierarchyItem, ITreeItemModel>(reportHierarchyItems,
                    x => x.children,
                    (reportHierarchyItem, children, level): ITreeItemModel => {
                        const newNode = {
                            name: reportHierarchyItem.name,
                            data: reportHierarchyItem,
                            isSelectable: reportHierarchyItem.isReportDefinition,
                            isSelected: reportHierarchyItem.reportType === this.$scope.entity[this.$scope.propertyLayout.systemName],
                            children: children
                        }
                        children.forEach(c => {
                            c.parent = newNode;
                        });
                        return newNode;
                    });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}
