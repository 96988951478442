import { Chart } from "chart.js";
import { sum, max } from 'lodash';

const { helpers, defaults } = Chart;

function getBoxWidth(labelOpts: any, fontSize: number) {
    return labelOpts.usePointStyle ?
        fontSize * Math.SQRT2 :
        labelOpts.boxWidth;
}

// overriding legend's draw method to proper column layout and center alignment
export const legendPlugin = {
    beforeInit: function (chart: any, options: any) {
        // define column layout and legend dimensions
        chart.legend.afterFit = function () {
            const hitBoxes: any[] = this.legendHitBoxes,
                items: any[] = this.legendItems,
                maxColumns = this.options.maxColumns
                    ? Math.min(this.options.maxColumns, items.length)
                    : this.options.isHorizontal ? items.length : 2;

            let columns: any[], totalWidth: number;

            for (let colsCount = maxColumns; colsCount >= 0; colsCount--) {
                columns = [];

                for (let itemIndex = 0; itemIndex < hitBoxes.length; itemIndex++) {
                    let colIndex = itemIndex % colsCount,
                        item = items[itemIndex],
                        box = hitBoxes[itemIndex],
                        column = columns[colIndex];

                    if (!column) {
                        column = columns[colIndex] = { boxes: [], items: [], width: 0 };
                    }

                    column.items.push(item);
                    column.boxes.push(box);
                    column.width = Math.max(column.width, box.width);
                }

                if (!this.options.isHorizontal) {
                    columns.forEach((col, index) => {
                        const columnStartIndex = sum(columns.filter((c, i) => i < index).map(c => c.items.length));
                        col.boxes = hitBoxes.slice(columnStartIndex, columnStartIndex + col.boxes.length);
                        col.items = items.slice(columnStartIndex, columnStartIndex + col.items.length);
                        col.width = Math.max(...col.boxes.map((b: any) => b.width));
                    });
                }

                totalWidth = sum(columns.map(c => c.width)) + this.options.labels.padding * (columns.length - 1);

                if (totalWidth < this.maxWidth) break;
            }

            this.columns = columns;
            this.totalColumnsWidth = totalWidth;

            const fontSize = helpers.valueOrDefault(this.options.labels.fontSize, defaults.global.defaultFontSize);
            const itemHeight = fontSize + this.options.labels.padding;
            this.width = this.maxWidth;
            const maxItems = columns.length > 0 ? max(columns.map(c => c.items.length)) : 0;
            this.height = maxItems * (itemHeight + this.options.labels.padding) + this.options.labels.padding;
        };

        chart.legend.draw = function () {
            const opts = this.options;
            const labelOpts = opts.labels;
            const globalDefault = defaults.global;
            const lineDefault = globalDefault.elements.line;

            if (opts.display) {
                const ctx = this.ctx;
                const valueOrDefault = helpers.valueOrDefault;
                const fontColor = valueOrDefault(labelOpts.fontColor, globalDefault.defaultFontColor);
                const fontSize = valueOrDefault(labelOpts.fontSize, globalDefault.defaultFontSize);
                const fontStyle = valueOrDefault(labelOpts.fontStyle, globalDefault.defaultFontStyle);
                const fontFamily = valueOrDefault(labelOpts.fontFamily, globalDefault.defaultFontFamily);
                const labelFont = helpers.fontString(fontSize, fontStyle, fontFamily);
                const itemHeight = fontSize + labelOpts.padding;

                let cursor: any;

                // Canvas setup
                ctx.textAlign = 'left';
                ctx.textBaseline = 'middle';
                ctx.lineWidth = 0.5;
                ctx.strokeStyle = fontColor; // for strikethrough effect
                ctx.fillStyle = fontColor; // render in correct colour
                ctx.font = labelFont;

                const boxWidth = getBoxWidth(labelOpts, fontSize);

                const drawLegendBox = function (x: number, y: number, legendItem: any) {
                    if (isNaN(boxWidth) || boxWidth <= 0) {
                        return;
                    }

                    // Set the ctx for the box
                    ctx.save();

                    ctx.fillStyle = valueOrDefault(legendItem.fillStyle, (<any> globalDefault).defaultColor);
                    ctx.lineCap = valueOrDefault(legendItem.lineCap, lineDefault.borderCapStyle);
                    ctx.lineDashOffset = valueOrDefault(legendItem.lineDashOffset, lineDefault.borderDashOffset);
                    ctx.lineJoin = valueOrDefault(legendItem.lineJoin, lineDefault.borderJoinStyle);
                    ctx.lineWidth = valueOrDefault(legendItem.lineWidth, lineDefault.borderWidth);
                    ctx.strokeStyle = valueOrDefault(legendItem.strokeStyle, (<any> globalDefault).defaultColor);
                    var isLineWidthZero = (valueOrDefault(legendItem.lineWidth, lineDefault.borderWidth) === 0);

                    if (ctx.setLineDash) {
                        // IE 9 and 10 do not support line dash
                        ctx.setLineDash(valueOrDefault(legendItem.lineDash, lineDefault.borderDash));
                    }

                    if (opts.labels && opts.labels.usePointStyle) {
                        // Recalculate x and y for drawPoint() because its expecting
                        // x and y to be center of figure (instead of top left)
                        var radius = fontSize * Math.SQRT2 / 2;
                        var offSet = radius / Math.SQRT2;
                        var centerX = x + offSet;
                        var centerY = y + offSet;

                        // Draw pointStyle as legend symbol
                        helpers.canvas.drawPoint(ctx, legendItem.pointStyle, radius, centerX, centerY);
                    } else {
                        // Draw box as legend symbol
                        if (!isLineWidthZero) {
                            ctx.strokeRect(x, y, boxWidth, fontSize);
                        }
                        ctx.fillRect(x, y, boxWidth, fontSize);
                    }

                    ctx.restore();
                };
                const fillText = function (x: number, y: number, legendItem: any, textWidth: number) {
                    var halfFontSize = fontSize / 2;
                    var xLeft = boxWidth + halfFontSize + x;
                    var yMiddle = y + halfFontSize;

                    ctx.fillText(legendItem.text, xLeft, yMiddle);

                    if (legendItem.hidden) {
                        // Strikethrough the text if hidden
                        ctx.beginPath();
                        ctx.lineWidth = 2;
                        ctx.moveTo(xLeft, yMiddle);
                        ctx.lineTo(xLeft + textWidth, yMiddle);
                        ctx.stroke();
                    }
                };

                cursor = {
                    x: this.left + ((this.width - this.totalColumnsWidth) / 2),
                    y: this.top + labelOpts.padding
                };

                this.columns.forEach((col: any) => {
                    col.items.forEach((item: any, index: number) => {
                        const box: any = col.boxes[index];
                        drawLegendBox(cursor.x, cursor.y, item);
                        box.left = cursor.x;
                        box.top = cursor.y;
                        fillText(cursor.x, cursor.y, item, ctx.measureText(item.text).width);
                        cursor.y += itemHeight;
                    });

                    cursor.x += col.width + labelOpts.padding;
                    cursor.y = this.top + labelOpts.padding;
                });
            }
        };
    }
};
