'use strict';

import { register } from "@systemorph/web";
import { TreeUtils } from "../../utils/treeUtils";
import { ITreeItemModel } from '../tree/tree.api';

interface IDropdownTreeScope extends ng.IScope {
    dropdownTree: DropdownTreeController;
}

register.directive("dropdownTree", ($templateCache: ng.ITemplateCacheService) => {
    const popoverTemplateUrl = 'common/dropdownTree/dropdownTree.html';
    $templateCache.put(popoverTemplateUrl, `<tree items="dropdownTree.items" on-select="dropdownTree.onItemSelected(item)"></tree>`);

    return {
        restrict: 'AE',
        replace: true,
        scope: true,
        template: `
            <div class="dropdown-tree dropdown-container">
                <div class="dropdown-display">
                    <div class="ui-select-bootstrap dropdown">
                        <button class="btn btn-default form-control ui-select-match"
                                ng-disabled="dropdownTree.disabled || !dropdownTree.items.length"
                                popover-trigger="outsideClick"
                                type="button"
                                tooltip-append-to-body="true"
                                uib-popover-template="'${popoverTemplateUrl}'"
                                popover-placement="bottom-left"
                                popover-is-open="dropdownTree.isOpen"
                                aria-expanded="false">
                            <span class="pull-left text-muted" ng-if="!dropdownTree.items.length" ng-bind-html="::(dropdownTree.noOptionsText || 'No available options')"></span>
                            <span class="pull-left" ng-if="dropdownTree.items.length" ng-bind="dropdownTree.currentSelection"></span>
                            <span class="caret"></span>
                        </button>
                    </div>
                </div>
            </div>`,
        bindToController: {
            items: '=',
            disabled: '=',
            noOptionsText: '@?',
            onSelect: '&?',
        },
        controller: DropdownTreeController,
        controllerAs: 'dropdownTree',
    }
});

class DropdownTreeController {
    items: ITreeItemModel[];
    isOpen: boolean;
    onSelect: any;
    currentSelection: string;

    constructor(private $scope: IDropdownTreeScope) {
        this.setCurrentSelection();
    }

    private setCurrentSelection() {
        var selectedItems = TreeUtils.flattenTreeNodes(this.items, i => i.children, i => i.isSelected);
        if (selectedItems.length)
            this.currentSelection = selectedItems[0].name;
    }

    onItemSelected(item: ITreeItemModel) {
        this.isOpen = false;
        this.setCurrentSelection();
        if (this.onSelect)
            this.onSelect({item});
    }
}