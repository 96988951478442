'use strict';
import 'jquery-ui/ui/widgets/draggable';

import { register, ANGULAR_EVENT, DOM_EVENT } from "@systemorph/web";
import {
    IFormEntityProviderModel,
    FormEntityRegistry,
    FORM_ENTITY_EVENT,
    FormEntityService,
    FormEntityProvider
} from "@systemorph/form-entity-web";
import {IAuditFormEntity, IAuditProps} from './reportAudit.api';
import {kebabCase, lowerFirst, Dictionary} from 'lodash';
import { AUDIT_EVENT } from './reportAudit.events';

export interface IReportAuditScope extends ng.IScope {
    audit: ReportAuditController;
    auditForm: ng.IFormController;
}

register.directive("reportAudit", () => {
    return {
        restrict: 'AE',
        replace: true,
        scope: true,
        template: `
            <form name="auditForm">
                <div class="report-audit" ng-class="{'audit-enabled': audit.formEntity.auditType}" ng-if="audit.isReady">
                    <div class="audit-indicator"></div>
                    <div class="audit-switch-wrapper">
                        <div class="audit-switch" ng-click="audit.togglePopupOpen()">
                            Audit mode {{audit.formEntity.auditType ? 'ON' : ''}}
                        </div>
                    </div>
                    <div class="audit-popup" ng-if="audit.isPopupOpen">
                        <div class="audit-popup-header">
                            <h2>Audit report options</h2>
                            <span class="audit-popup-close" ng-click="audit.togglePopupOpen()">&times;</span>
                        </div>
                        <div>
                            <div class="audit-popup-body" ng-class="{'hide-tabs': audit.formEntity.auditTypeAutocomplete.length == 1}">
                                <uib-tabset active="audit.currentTypeIndex">
                                    <uib-tab index="$index" 
                                             select="audit.onTypeChanged(auditType.SystemName)"
                                             ng-repeat="auditType in audit.formEntity.auditTypeAutocomplete" 
                                             heading="{{auditType.DisplayName}}">
                                        <div ng-if="audit.currentTypeIndex === $index">
                                            <render directive="{{audit.getAuditOptionsDirectiveName(auditType.SystemName)}}"></render>
                                        </div>
                                    </uib-tab>
                                </uib-tabset>
                            </div>
                            <div class="audit-popup-footer">
                                <button class="btn btn-primary" ng-click="audit.onDisable()" ng-show="audit.formEntity.auditType">Off</button>
                                <button class="btn btn-cancel" ng-click="audit.togglePopupOpen()" ng-show="!audit.formEntity.auditType">Cancel</button>
                                <button class="btn btn-cancel" ng-click="audit.onReset()" ng-show="audit.isResetEnabled">Reset</button>
                                <button class="btn btn-success" ng-click="audit.onApply()" ng-disabled="!audit.isApplyEnabled()">Apply</button>
                                <button class="btn btn-success" ng-click="audit.onOk()" ng-disabled="!audit.isOkEnabled()">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        `,
        controller: ReportAuditController,
        controllerAs: 'audit'
    }
});

export class ReportAuditController {
    formEntityProvider: FormEntityProvider;
    formEntity: IAuditFormEntity;
    isReady: boolean;
    isPopupOpen: boolean;
    currentTypeIndex: number; // needed because our version of uibTabset doesn't work with string indexes
    currentType: string;
    previousProperties: string[];
    isResetEnabled: boolean;
    shouldApplyOnReset: boolean;

    constructor(private $scope: IReportAuditScope,
                private $timeout: ng.ITimeoutService,
                private $element: ng.IAugmentedJQuery,
                private $document: ng.IDocumentService,
                private formEntityService: FormEntityService,
                private formEntityRegistry: FormEntityRegistry) {
        this.refreshFormEntity();

        $scope.$on(FORM_ENTITY_EVENT.updated, (event: ng.IAngularEvent, formEntityScope: string, provider: FormEntityProvider, newValues: Dictionary<any>) => {
            if (!formEntityScope) {
                this.updateForm();
            }
        });

        $scope.$on(FORM_ENTITY_EVENT.replaced, (e: ng.IAngularEvent, scope: string, provider: IFormEntityProviderModel) => {
            if (!scope) {
                this.refreshFormEntity();
            }
        });

        $scope.$on(AUDIT_EVENT.childInitialized, () => {
            if (this.currentType === this.formEntity.auditType)
                this.updateForm();
            else
                this.resetForm();

            this.$scope.auditForm.$setPristine();
        });

        $document.on(DOM_EVENT.keydown + '.reportAudit', (event: any) => {
            if (event.keyCode === 27) this.togglePopupOpen(false);
        });

        $scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            $document.off(DOM_EVENT.keydown + '.reportAudit');
        });
    }

    private refreshFormEntity() {
        return this.formEntityRegistry.getProvider()
            .then(provider => {
                this.formEntityProvider = provider;

                return this.formEntityRegistry.getFormEntity()
                    .then((formEntity: IAuditFormEntity) => {
                        this.formEntity = formEntity;
                    });
            })
            .finally(() => {
                this.isReady = !!this.formEntityProvider;
            });
    }

    togglePopupOpen(isOpen?: boolean) {
        this.isPopupOpen = isOpen === undefined ? !this.isPopupOpen : isOpen;

        if (this.isPopupOpen) {
            // setting the active tab, this will trigger onTypeChanged
            this.currentTypeIndex = this.formEntity.auditType
                ? this.formEntity.auditTypeAutocomplete.map(t => t.SystemName).indexOf(this.formEntity.auditType)
                : 0;
            this.$timeout(() => {
                this.makeDraggable();
            });
        }
    }

    getAuditOptionsDirectiveName = (auditType: string) =>  kebabCase(lowerFirst(auditType)) + '-audit';

    private makeDraggable() {
        (<any>this.$element.find('.audit-popup')).draggable({
            containment: 'document',
            handle: '.audit-popup-header'
        });
    }

    onTypeChanged(auditType: string) {
        this.currentType = auditType;
    }

    onApply() {
        this.updateFormEntity(this.currentType);
        this.$scope.auditForm.$setPristine();
    }

    onOk() {
        if (this.isApplyEnabled()) this.onApply();
        this.togglePopupOpen(false);
    }

    isApplyEnabled() {
        return this.$scope.auditForm.$valid && (this.currentType !== this.formEntity.auditType || this.$scope.auditForm.$dirty);
    }

    isOkEnabled() {
        return this.$scope.auditForm.$valid;
    }

    onDisable() {
        this.$scope.$broadcast(AUDIT_EVENT.disable);
        this.togglePopupOpen(false);
        this.updateFormEntity(null);
    }

    onReset() {
        this.resetForm();

        if (this.shouldApplyOnReset)
            this.onApply();
    }

    private resetForm() {
        this.$scope.$broadcast(AUDIT_EVENT.reset);
    }

    private updateFormEntity(auditType: string) {
        if (this.previousProperties) {
            this.previousProperties.forEach(prop => {
                delete this.formEntity[prop];
            });
        }

        const newValues: IAuditProps = {
            auditType,
        };

        this.$scope.$broadcast(AUDIT_EVENT.updateFormEntity, newValues);

        Object.keys(newValues).forEach(prop => {
            const entity: any = this.formEntity, values: any = newValues;
            if (entity[prop] != values[prop]) entity[prop] = values[prop];
            else delete values[prop];
        });

        this.formEntityService.formEntityToStateParams(this.formEntityProvider, null);
        this.formEntityService.notifyFormEntityUpdated(null, this.formEntityProvider, newValues);
    }

    private updateForm() {
        this.$scope.$broadcast(AUDIT_EVENT.updateForm);
    }
}