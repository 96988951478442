'use strict';

import { register } from "@systemorph/web";
import {
    BaseAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyTempplate,
    defaultAutocompleteFormEntityPropertyControllerAs,
    IFormEntityPropertyScope, FormEntityService
} from '@systemorph/form-entity-web';
import { IAutocompleteBoxItem } from "@systemorph/ui-web";
import { IPromise, IAugmentedJQuery, IQService } from "angular";
import { IIfrsFormEntityService } from '../ifrsFormEntity.api';

register.directive('ifrsSensitivityFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: SensitivityFormEntityPropertyController
    };
});

class SensitivityFormEntityPropertyController extends BaseAutocompleteFormEntityPropertyController<string, string> {

    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                ifrsFormEntityService: IIfrsFormEntityService,
                $element: IAugmentedJQuery, $q: IQService) {
        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            const year: number = $scope.entity["year"];
            const period: string = $scope.entity["period"];
            const businessProcess: string = $scope.entity["businessProcess"];

            if (!period) {
                return $q.when([]);
            }

            return ifrsFormEntityService.getSensitivities(businessProcess, year, period);
        };
        super($scope, formEntityService, getAutocompleteFunc, true);
    }

    protected getIndexFromAutocomplete(value: string, autocomplete: IAutocompleteBoxItem[]): number {
        return autocomplete.findIndex(x => x.value === value);
    }
}
