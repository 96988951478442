'use strict';

import { register } from "@systemorph/web";
import {
    BaseAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyTempplate,
    defaultAutocompleteFormEntityPropertyControllerAs,
    IFormEntityPropertyScope, FormEntityService
} from '@systemorph/form-entity-web';
import { IAutocompleteBoxItem } from "@systemorph/ui-web";
import { IPromise, IQService } from "angular";
import { IIfrsFormEntityService } from '../ifrsFormEntity.api';

register.directive('ifrsProjectionFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: IfrsProjectionFormEntityPropertyController
    };
});

class IfrsProjectionFormEntityPropertyController extends BaseAutocompleteFormEntityPropertyController<number, number> {
    private lastPeriod: string; 
    private isPeriodChanged: boolean; 

    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                ifrsFormEntityService: IIfrsFormEntityService,
                $q: IQService) {
        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            const period: string = $scope.entity["period"];

            if (this.lastPeriod && this.lastPeriod !== period) {
                this.isPeriodChanged = true;
            }

            this.lastPeriod = period;

            return ifrsFormEntityService.getProjections(period);
        };
        super($scope, formEntityService, getAutocompleteFunc, true);
    }

    protected getIndexFromAutocomplete(value: number, autocomplete: IAutocompleteBoxItem[]): number {
        if (this.isPeriodChanged) {
            this.isPeriodChanged = false;
            return -1;
        }

        return autocomplete.findIndex(x => x.value === value);
    }

    protected getUrlOrOldValue(): number {
        const value = this.entity.getStateParamsOrCurrentValue(this.propertyLayout);
        const urlOrOldValue: string = value ? value.toString() : undefined;
        return parseInt(<string>urlOrOldValue);
    }
}
