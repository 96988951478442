'use strict';

import * as angular from 'angular';
import { IDataSourceService, getOrAddCachedValue, IDataSource } from "@systemorph/web";
import { COMMON_EVENT } from "@systemorph/web";
import { IReportingNodeService, IReportingNodeTreeModel } from './reportingWeb.api';

export class ReportingNodeService implements IReportingNodeService {
    private cache: angular.ICacheObject;
    /*@ngInject*/
    constructor(protected $http: angular.IHttpService,
                protected dataSourceService: IDataSourceService,
                protected $rootScope: angular.IScope,
                $cacheFactory: angular.ICacheFactoryService) {

        this.cache = $cacheFactory('reportingNodes');

        // when version projector has no information about the revision, but only knows branch
        // then cache key will never change but it should
        // due to that cache have to be invalidated
        [ COMMON_EVENT.dataVersionChanged, COMMON_EVENT.persistenceContextChanged ].forEach(eventName => {
            this.$rootScope.$on(eventName, (e: ng.IAngularEvent) => {
                this.cache.removeAll();
            });
        })
    }

    getReportingNodeTreesCached(hierarchy: string, withDeletedAndRestricted: boolean = false, dataSource?: IDataSource): angular.IPromise<IReportingNodeTreeModel[]> {
        return this.getReportingNodeTreesCachedInner(hierarchy, 'ReportingNode', withDeletedAndRestricted, dataSource);
    }

    protected getReportingNodeTreesCachedInner(hierarchy: string, reportingNodeTypeName: string, withDeletedAndRestricted: boolean = false, dataSource?: IDataSource): angular.IPromise<IReportingNodeTreeModel[]> {
        const cacheKey = `${ hierarchy }_${withDeletedAndRestricted}_${ this.dataSourceService.getDataSourceAsString(dataSource) }`
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            const requestConfig: angular.IRequestShortcutConfig = { 
                params: { 
                    hierarchy: hierarchy || '',
                    withDeletedAndRestricted,
                    reportingNodeTypeName: reportingNodeTypeName
                }, 
                headers: this.dataSourceService.getHeaders(dataSource)
            };
            return this.$http.get<IReportingNodeTreeModel[]>(`/api/reportingNode/reportingNodeTree`, requestConfig).then(result => result.data);
        })
    }

    getAllHierarchyNamesCached(withDeletedAndRestricted: boolean = false, dataSource?: IDataSource): angular.IPromise<string[]> {
        const cacheKey = `$all-hierarchy-names-${withDeletedAndRestricted}-${ this.dataSourceService.getDataSourceAsString(dataSource) }`;
        const requestConfig: angular.IRequestShortcutConfig = { 
            headers: this.dataSourceService.getHeaders(dataSource),
            params: {
                withDeletedAndRestricted
            }
        };
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<string[]>(`/api/reportingNode/allHierarchyNames`, requestConfig).then(result => result.data);
        })
    }
    
    reportingNodeToString(reportingNode: IReportingNodeTreeModel): string {
        if (!reportingNode)
            return '';

        const systemName = reportingNode.systemName;
        const displayName = reportingNode.displayName;

        return systemName === displayName || !systemName ? displayName : displayName + " (" + systemName + ")";
    }
}

angular.module('systemorph')
    .factory('ReportingNodeService', () => ReportingNodeService)
    .service('reportingNodeService',  /*@ngInject*/($injector: angular.auto.IInjectorService, ReportingNodeService: Function) => {
        return $injector.instantiate(ReportingNodeService);
    });