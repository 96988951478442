'use strict';

import { IDataGridColumnTypeConfig } from './dataGrid.api';
import { DATA_GRID_COLUMN_DISPLAY, DATA_GRID_EDITOR } from './dataGrid.constants';
import {Dictionary} from 'lodash';

export const dataGridColumnTypeConfig: Dictionary<IDataGridColumnTypeConfig> = {
    rowNumber: {
        defaultColumnDisplay: DATA_GRID_COLUMN_DISPLAY.rowNumber
    },
    enum: {
        defaultColumnDisplay: DATA_GRID_COLUMN_DISPLAY.enum,
        defaultEditor: DATA_GRID_EDITOR.dropdown,
    },
    dimension: {
        defaultColumnDisplay: DATA_GRID_COLUMN_DISPLAY.dimension,
        defaultEditor: DATA_GRID_EDITOR.dimension,
    },
    richText: {
        defaultColumnDisplay: DATA_GRID_COLUMN_DISPLAY.richText,
        defaultEditor: DATA_GRID_EDITOR.noEditor
    },
    boolean: {
        defaultColumnDisplay: DATA_GRID_COLUMN_DISPLAY.boolean,
        defaultEditor: DATA_GRID_EDITOR.noEditor
    },
    text: {
        defaultColumnDisplay: DATA_GRID_COLUMN_DISPLAY.base,
        defaultEditor: DATA_GRID_EDITOR.basic
    }
};