'use strict';

import {register, ILayoutService, IPersisterProviderService, DISPLAY_CONTEXT, ITypeLayout, IQueryable, ISystemorphEntity} from "@systemorph/web";
import { IFunc, IUtilsService, PromiseFunc } from "./utils.api";
import {debounce} from 'lodash';
import {IQService, IPromise, IDeferred, auto, IHttpService} from 'angular';

class UtilsService implements IUtilsService {
    /*@ngInject*/
    constructor(private layoutService: ILayoutService,
                private $q: IQService,
                private $http: IHttpService,
                private persisterProviderService: IPersisterProviderService) {
    }

    getTypeQuery<T extends ISystemorphEntity>(typeName: string): IPromise<IQueryable<T>> {
        return this.layoutService.layout(typeName, DISPLAY_CONTEXT.details).$promise
            .then((typeLayout: ITypeLayout) => this.persisterProviderService.query(typeLayout.collectionName).then(ret => <IQueryable<T>>ret));
    }

    getCollectionQuery<T extends ISystemorphEntity>(entity: ISystemorphEntity, propertyName: string, queryConfig?: IFunc<IQueryable<T>, IQueryable<T>>) {
        return this.persisterProviderService.getPersister()
            .then(persister => {
                const query = <IQueryable<T>>persister.queryCollectionProperty(entity, propertyName);
                return queryConfig ? queryConfig(query) : query;
            });
    }
    
    debouncePromise<T>(func: PromiseFunc<T>, wait?: number) {
        let lastDeferred: IDeferred<T>;

        const debounced = debounce((deferred: IDeferred<T>, ...args: any[]) => {
            func(...args).then(result => deferred.resolve(result))
                .catch(reason => deferred.reject(reason));
        }, wait);

        return (...args: any[]) => {
            if (lastDeferred) lastDeferred.reject('Canceled by subsequent call');
            lastDeferred = this.$q.defer();
            debounced(lastDeferred, ...args);
            return lastDeferred.promise;
        }
    }

    getNewGuid() {
        return this.$http.get<string>('/api/uiUtils/newGuid')
            .then(result => result.data);
    }

    getNewEntity<T extends ISystemorphEntity>(entityType: string, doNotTrack = false) {
        return this.$q.all([this.persisterProviderService.getPersister(), this.getNewGuid()])
            .then(([persister, newGuid]) => {
                const context: any = (<any>persister).context;
                const entity: T = context[`createAdd${entityType}`]();

                entity.Id = newGuid;

                if (doNotTrack) {
                    persister.detachEntity(entity);
                }

                return entity;
            });
    }
}

register.factory('utilsService', ($injector: auto.IInjectorService) => $injector.instantiate(UtilsService));