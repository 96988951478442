'use strict';

import { IIfrsFormEntityService } from './ifrsFormEntity.api';
import { register, IDataSourceService, getOrAddCachedValue } from '@systemorph/web';
import { IAutocompleteBoxItem } from '@systemorph/ui-web';
import { IPeriodModel } from '@systemorph/reporting-web';
import { IPromise, ICacheFactoryService, IHttpService, ICacheObject } from 'angular';

class IfrsFormEntityService implements IIfrsFormEntityService {
    cache: ICacheObject;

    constructor($cacheFactory: ICacheFactoryService,
                private $http: IHttpService,
                private dataSourceService: IDataSourceService) {
        this.cache = $cacheFactory("IfrsFormEntityService");
    }

    getProjections(period: string): IPromise<IAutocompleteBoxItem[]> {
        var cacheKey = `projections_${period}_${this.dataSourceService.getDataSourceAsString()}`;
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<IAutocompleteBoxItem[]>(`/api/ifrsFormEntity/projections`, { params: { period } })
                .then(result => {
                    return result.data;
                });
        });
    }

    getSensitivities(businessProcess: string, year: number, period: string): IPromise<IAutocompleteBoxItem[]> {
        var cacheKey = `sensitivities_${businessProcess}_${year}_${period}_${this.dataSourceService.getDataSourceAsString()}`;
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<IAutocompleteBoxItem[]>(`/api/ifrsFormEntity/sensitivities`, { params: { businessProcess, year, period } })
                .then(result => {
                    return result.data;
                });
        });
    }

    getBusinessProcesses(): IPromise<IAutocompleteBoxItem[]> {
        var cacheKey = `businessProcesses_${this.dataSourceService.getDataSourceAsString()}`;
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<IAutocompleteBoxItem[]>(`/api/ifrsFormEntity/dataCollectionProcesses`, {})
                .then(result => {
                    return result.data;
                });
        });
    }

    getYears(businessProcess: string): import("angular").IPromise<number[]> {
        businessProcess = businessProcess || '';
        var cacheKey = `years_${businessProcess || ''}_${this.dataSourceService.getDataSourceAsString()}`;
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<number[]>(`/api/ifrsFormEntity/years`, { params: { businessProcess} })
                .then(result => {
                    return result.data;
                });
        });
    }
    
    getPeriods(year: number, businessProcess: string): IPromise<IPeriodModel[]> {
        businessProcess = businessProcess || '';
        const yearStr = year == null ? '' : year.toString(); 
        var cacheKey = `periods_${yearStr}_${businessProcess}_${this.dataSourceService.getDataSourceAsString()}`;
        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<IPeriodModel[]>(`/api/ifrsFormEntity/periods`, { params: { year: yearStr, businessProcess } })
                .then(result => {
                    return result.data;
                });
        });
    }
}

register.service("ifrsFormEntityService", IfrsFormEntityService);