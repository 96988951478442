'use strict';

import { register, COMMON_EVENT, PERSISTANCE_EVENT } from "@systemorph/web";
import { IBusinessProcessService, IProcessOverviewModel, IProcessModel } from "../businessProcess.api";
import { SERVER_EVENTS } from "../businessProcess.constants";
import { IScope, blockUI } from "angular";

register.directive("businessProcessesList", () => {
    return {
        restrict: 'E',
        replace: true,
        template: require('./businessProcessesList.html'),
        scope: true,
        bindToController: {
            isCompleted: '='
        },
        controllerAs: "ctrl",
        controller: BusinessProcessesListCotroller
    }
});

class BusinessProcessesListCotroller {
    //bindings
    isCompleted: boolean;

    //models
    blockName: string;
    skip: number;
    take: number;
    hasMore: boolean;
    isReady: boolean;
    processes: IProcessModel[];
    noProcessesMessage: string;

    constructor($scope: IScope,
                private blockUI: blockUI.IBlockUIService,
                private businessProcessService: IBusinessProcessService) {

        this.blockName = `processesList${this.isCompleted ? "Completed" : "Active"}`;
        this.skip = 0;
        this.take = 10;
        this.hasMore = false;
        this.processes = [];
        this.noProcessesMessage = this.isCompleted
            ? "There are no completed business processes."
            : "There are no active business processes.";
        this.isReady = false;

        this.load(true).then(() => {
            this.isReady = true;
        });

        const eventsToReload = [
            COMMON_EVENT.commitDataToServer,
            COMMON_EVENT.persistenceContextChanged,
            COMMON_EVENT.dataVersionChanged,
            PERSISTANCE_EVENT.backedNotifiedDataVersionWasChanged,
            SERVER_EVENTS.processFinished
        ];
        eventsToReload.forEach(eventName => {
            $scope.$on(eventName, () => {
                this.skip = 0;
                this.load(true);
            });
        })
    }

    showMore() {
        this.load(false);
    }

    private load(shouldReplace?: boolean) {
        this.blockUI.instances.get(this.blockName).start("Loading...");

        return this.businessProcessService.getProcesses(this.skip, this.take, this.isCompleted).then((model: IProcessOverviewModel) => {
            this.skip += model.processModels.length;
            this.hasMore = this.skip < model.totalModelsCount;
            if (shouldReplace) {
                this.processes = model.processModels;
            } else {
                this.processes = this.processes.concat(model.processModels);
            }
        }).finally(() => {
            this.blockUI.instances.get(this.blockName).stop();
        });
    }
}