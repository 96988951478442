'use strict';

import { register , STATE, IDataSource, IPersister, ITypeLayout, IEntityGridResult } from '@systemorph/web'
import { FormEntityRegistry, FORM_ENTITY_EVENT, FormEntityProvider } from '@systemorph/form-entity-web'
import { RESULT_ENTITY_STATE, RESULT_ENTITY_QUERY_CONTEXT } from '../resultEntity.constants'
import { IScope, IControllerService, IAngularEvent } from 'angular';
import { IStateParamsService } from 'angular-ui-router';
import {Dictionary} from 'lodash';

// TODO: Right after #13784 ticket is finished, this code have to be refactored.
register.state({
    name: RESULT_ENTITY_STATE.resultEntityCatalog,
    parent: STATE.defaultLayout,
    url: '/ResultEntityCatalog/:entityType?mode&page&pageSize&sort&direction&filter',
    template: require('@systemorph/web/app/catalog/pages/catalog.html'),
    controller: /*@ngInject*/($scope: IScope, $controller: IControllerService, formEntityRegistry: FormEntityRegistry, $stateParams: IStateParamsService) => {
        $scope.queryFunction = ((persister: IPersister, typeLayout: ITypeLayout, dataSource?: IDataSource) => {
            return formEntityRegistry.getFilterObject().then(filterObject => {
                return persister
                    .query(typeLayout.collectionName, (dataSource || <any> {}).dataVersion)
                    .withQueryContext(RESULT_ENTITY_QUERY_CONTEXT.resultEntityCatalog, filterObject);
            })
        });

        // HACK: due to catalogController clones original stateParams at the constructor, number of parameter have to be limited
        // must be fixed at the Vertex side
        const stateParams = {
            entityType: $stateParams['entityType'], 
            mode: $stateParams['mode'], 
            page: $stateParams['page'], 
            pageSize: $stateParams['pageSize'], 
            sort: $stateParams['sort'],
            direction: $stateParams['direction'], 
            filter: $stateParams['filter']
        }
        $controller('catalogController', { $scope: $scope, $stateParams: stateParams });

        $scope.$on(FORM_ENTITY_EVENT.updated, (event: IAngularEvent, formEntityScope: string, provider: FormEntityProvider, newValues: Dictionary<any>) => {
            if (!formEntityScope) {
                $scope.entityGridResult.then((e: IEntityGridResult) => e.softUpdate());
            }
        });
    },
    suppressReloadMessage: true
})