'use strict';

import {Chart, ChartArea, ChartPoint} from "chart.js";
import * as angular from "angular";

Chart.defaults.waterfall = Chart.defaults.bar;
Chart.defaults.horizontalWaterfall = Chart.defaults.horizontalBar;

Chart.controllers.waterfall = getWaterfallCtrl(Chart.controllers.bar);
Chart.controllers.horizontalWaterfall = getWaterfallCtrl(Chart.controllers.horizontalBar);

function getWaterfallCtrl(baseController: any) {
    return baseController.extend({
        draw: function (ease: any) {
            baseController.prototype.draw.apply(this, arguments);

            const isHorizontal = this.chart.config.type === 'horizontalWaterfall';

            // drawing the rectangles' left borders missing from standard bar chart
            var me = this;
            var chart = me.chart;
            var config: any = chart.config;
            var scale = me.getValueScale();
            var rects = me.getMeta().data;
            var dataset = me.getDataset();
            var ilen = rects.length;

            var ctx = this.chart.ctx;

            let guideLineStart: ChartPoint;
            let lastValue: number;

            Chart.helpers.canvas.clipArea(chart.ctx, chart.chartArea);

            for (let i = 0; i < ilen; ++i) {
                if (dataset.data[i] !== undefined && dataset.data[i] !== null) {
                    const isAbsoluteValue = i === 0 || config.options.absoluteValues === true
                        || angular.isArray(config.options.absoluteValues) && config.options.absoluteValues.indexOf(i) !== -1;
                    const isIncrease = dataset.data[i] > (lastValue || 0);
                    const vm: any = rects[i]._view;
                    let left: number, right: number, top: number, bottom: number,
                        start: ChartPoint, end: ChartPoint;

                    if (isHorizontal) {
                        left = vm.base;
                        right = vm.x;
                        top = vm.y - vm.height / 2;
                        bottom = vm.y + vm.height / 2;
                        start = { x: left, y: top };
                        end = { x: left, y: bottom };
                    }
                    else {
                        left = vm.x - vm.width / 2;
                        right = vm.x + vm.width / 2;
                        top = vm.y;
                        bottom = vm.base;
                        start = { x: left, y: bottom };
                        end = { x: right, y: bottom };
                    }

                    ctx.beginPath();
                    ctx.strokeStyle = vm.borderColor;
                    ctx.lineWidth = 1;

                    ctx.moveTo(start.x, start.y);
                    ctx.lineTo(end.x, end.y);

                    ctx.stroke();

                    if (me.chart.config.options.guideLines) {
                        if (guideLineStart) {
                            ctx.beginPath();
                            ctx.strokeStyle = 'rgba(0,0,0,0.25)';
                            ctx.lineWidth = 1;

                            let guideLineEnd: ChartPoint = isHorizontal
                                ? { x: isAbsoluteValue ? (dataset.data[i] < 0 ? left : right) : (isIncrease ? left : right), y: top }
                                : { x: left, y: isAbsoluteValue ? (dataset.data[i] < 0 ? bottom : top) : (isIncrease ? bottom : top) };

                            ctx.moveTo(guideLineStart.x, guideLineStart.y);
                            ctx.lineTo(guideLineEnd.x, guideLineEnd.y);
                            ctx.stroke();
                        }

                        guideLineStart = isHorizontal
                            ? { x: isIncrease ? right : left, y: bottom }
                            : { x: right, y: isIncrease ? top : bottom };
                    }

                    lastValue = dataset.data[i];
                }
            }

            Chart.helpers.canvas.unclipArea(chart.ctx);
        },

        initialize: function(chart: any, datasetIndex: number) {
            const config: any = chart.config;
            const data: number[] = chart.data.datasets[datasetIndex].data;
            let accumulatedValue = 0;
            data.forEach((d, i) => {
                const isAbsoluteValue = i === 0 || config.options.absoluteValues === true
                    || angular.isArray(config.options.absoluteValues) && config.options.absoluteValues.indexOf(i) !== -1
                const currentValue = data[i] || 0;
                accumulatedValue = isAbsoluteValue ? currentValue : accumulatedValue + currentValue;
                if (!isAbsoluteValue && !(data[i] === undefined || data[i] === null)) data[i] = accumulatedValue;
            });

            baseController.prototype.initialize.apply(this, arguments);
        },

        calculateBarValuePixels: function(datasetIndex: number, index: number) {
            var me = this;
            var chart = me.chart;
            var meta = me.getMeta();
            var scale = me.getValueScale();
            var datasets = chart.data.datasets;
            var value = scale.getRightValue(datasets[datasetIndex].data[index]);
            var start = 0;
            var base: any, head: any, size: any;

            const config = chart.config;
            const data: number[] = datasets[datasetIndex].data;

            // calculate the start point based on the previous bar's value
            if (index > 0 && (!chart.config.options.showAbsoluteValues || chart.config.options.showAbsoluteValues.indexOf(index) === -1)) {
                let i = index - 1;
                do {
                    if (data[i] !== undefined && data[i] !== null) {
                        start = data[i];
                        break;
                    }
                } while (i-- >= 0)
            }

            base = scale.getPixelForValue(Math.min(start, value));
            head = scale.getPixelForValue(Math.max(start, value));
            size = (head - base) / 2;

            return {
                size: size,
                base: base,
                head: head,
                center: head + size / 2
            };
        },
    });
}