'use strict';

import { register } from "@systemorph/web";
import { IBaloiseDateTimeService } from './baloiseDateTimeService';

register.decorator('dateTimeKindDirective', ($delegate: any, dateTimeService: IBaloiseDateTimeService) => {
    const directive = $delegate[0];
    const origLink = $delegate[0].link;
    directive.compile = () => {
        return ($scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
            if ($scope.propertyLayout && $scope.propertyLayout.displayDirectiveParameters && $scope.propertyLayout.displayDirectiveParameters['timeZone'] == 'cet') {
                const format = $scope.propertyLayout.displayDirectiveParameters['format'];
                $scope.dtzCtrl._modelWithZone = dateTimeService.toCETWithoutDST($scope.dtzCtrl._modelWithZone, format);
                var value = (dateTimeService.getCETAbbr() || "unknown").toLowerCase();

                element.addClass("date-time-kind");
                element.attr("date-time-kind-value", value);
            } else {
                origLink($scope, element, attrs);
            }
        }
    };
    return $delegate;
});