'use strict';

import { register, IMenuItemScope} from "@systemorph/web";
import { IReportStateParams } from "@systemorph/reporting-web";
import { FormEntityRegistry } from "@systemorph/form-entity-web";
import { ProcessDocumentationContextPanelController } from './processDocumentationContextPanelController';
import { IProcessDocumentationService } from '../processDocumentation.api';

register.directive("processDocumentationContextPanelComments", () => {
    return {
        replace: true,
        restrict: 'E',
        template: require('./processDocumentationContextPanelComments.html'),
        controllerAs: "ctrl",
        controller: ProcessDocumentationContextPanelCommentsController
    }
});

export class ProcessDocumentationContextPanelCommentsController extends ProcessDocumentationContextPanelController {
    constructor($scope: IMenuItemScope,
                $q: ng.IQService,
                $timeout: ng.ITimeoutService,
                $state: ng.ui.IStateService,
                $stateParams: IReportStateParams,
                formEntityRegistry: FormEntityRegistry,
                blockUI: ng.blockUI.IBlockUIService,
                processDocumentationService: IProcessDocumentationService) {

        super($scope,
            $q,
            $timeout,
            $state,
            $stateParams,
            formEntityRegistry,
            blockUI,
            processDocumentationService,
            "ProcessDocumentationComment",
            processDocumentationService.categories.comments);
    }
}