'use strict';

import { register } from "@systemorph/web";

register.directive('editInversedBooleanProperty', () => {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        template: '<input type="checkbox" ng-model="!entity[propertyLayout.systemName]" />'
    };
});