
import { arrayToDictionary } from "@systemorph/web";
import { Dictionary, groupBy } from "lodash";
import { IPredicate, IFunc } from "./utils.api";
import { dictionaryToArray } from "./reportingUtils";

export class TreeUtils {
    static flattenTreeNodes<TTreeNode>(items: TTreeNode[], getChildren: IFunc<TTreeNode, TTreeNode[]>, filter?: IPredicate<TTreeNode>) {
        var flaattenNodes: TTreeNode[] = [];
        items.forEach(i => {
            if (!filter || filter(i))
                flaattenNodes.push(i);
            const children = getChildren(i);
            if (children)
                flaattenNodes.push(...this.flattenTreeNodes(children, getChildren, filter));
        });
        return flaattenNodes;
    }

    static getTreeFromArray<TData, TTreeNode>(allData: TData[],
                                              getKey: IFunc<TData, string>,
                                              getParentKey: IFunc<TData, string>,
                                              treeNodeFactory: (x: TData, children: TTreeNode[] | null, level: number) => TTreeNode | null,
                                              getMissingParentById?: IFunc<string, TData>,
                                              compareSiblingTreeNodesFn?: (x1: TTreeNode, x2: TTreeNode) => number): TTreeNode[] {

        const defaultParentId: string = null;
        const allDataWithMissingParents = getMissingParentById 
                                                ? this.tryAddMissingParents(allData, arrayToDictionary(allData, getKey), getParentKey, getMissingParentById)
                                                : allData;
        const dataByParentId: Dictionary<TData[]> = groupBy(allDataWithMissingParents, x => getParentKey(x) || defaultParentId);
    
        let rootNodes = (dataByParentId[defaultParentId] || [])
                            .map(x => this.getTreeFromArrayInner<TData, TTreeNode>(x, getKey, treeNodeFactory, compareSiblingTreeNodesFn, dataByParentId, 0))
                            .filter(x => x);

        if (compareSiblingTreeNodesFn)
            rootNodes = rootNodes.sort(compareSiblingTreeNodesFn);
        
        return rootNodes;
    }

    private static tryAddMissingParents<TData>(data: TData[], 
                                              allDataById: Dictionary<TData>, 
                                              getParentKey: IFunc<TData, string>, 
                                              getMissingParentById: IFunc<string, TData>): TData[] {
        const misingParentsDictionary = data.reduce((prev, d) => {
            const parentId = getParentKey(d);
            if (parentId != null && !(parentId in allDataById) && !(parentId in prev)) {
                const missingParent = getMissingParentById(parentId);
                if (missingParent != null) {
                    prev[parentId] = missingParent;
                }
            }
            return prev;
        }, <Dictionary<TData>>{});

        const misingParentsArray = dictionaryToArray(misingParentsDictionary);

        if (!misingParentsArray.length)
            return data; 

        const allDataByIdWithMissingParents = {...allDataById, ...misingParentsDictionary};
        return [...data, ...this.tryAddMissingParents(misingParentsArray, allDataByIdWithMissingParents, getParentKey, getMissingParentById)];
    }

    static getTreeFromArrayInner<TData, TTreeNode>(data: TData,
                                                  getKey: IFunc<TData, string>,
                                                  treeNodeFactory: (x: TData, children: TTreeNode[] | null, level: number) => TTreeNode | null,
                                                  compareSiblingTreeNodesFn: (x1: TTreeNode, x2: TTreeNode) => number,
                                                  dataByParentId: Dictionary<TData[]>,
                                                  level: number): TTreeNode {

        // key must always be defined. 
        const key = getKey(data);
        // first part of ternary condition is just a fix for the bug with null 
        let children = !key || !dataByParentId[key]
            ? []
            : dataByParentId[key]
                .map(x => this.getTreeFromArrayInner(x, getKey, treeNodeFactory, compareSiblingTreeNodesFn, dataByParentId, level + 1))
                .filter(x => !!x);

        if (compareSiblingTreeNodesFn)
            children = children.sort(compareSiblingTreeNodesFn);

        return  treeNodeFactory(data, children, level);
    }

    static getTreeFromAnotherTree<TSourceTreeNode, TTargetTreeNode>(sourceTreeNodes: TSourceTreeNode[], 
                                                                    getChildren: IFunc<TSourceTreeNode, TSourceTreeNode[]>, 
                                                                    targetTreeNodeFactory: (x: TSourceTreeNode, children: TTargetTreeNode[] | null, level: number) => TTargetTreeNode | null): TTargetTreeNode[] {
        return this.getTreeFromAnotherTreeInner(sourceTreeNodes, getChildren, targetTreeNodeFactory, 0);
    }

    private static getTreeFromAnotherTreeInner<TSourceTreeNode, TTargetTreeNode>(sourceTreeNodes: TSourceTreeNode[], 
                                                                                 getChildren: IFunc<TSourceTreeNode, TSourceTreeNode[]>, 
                                                                                 targetTreeNodeFactory: (x: TSourceTreeNode, children: TTargetTreeNode[] | null, level: number) => TTargetTreeNode | null, 
                                                                                 level: number): TTargetTreeNode[] {
        if (sourceTreeNodes == null) {
            return [];
        }
        return sourceTreeNodes.map(sourceTreeNode => {
            const children = this.getTreeFromAnotherTreeInner(getChildren(sourceTreeNode), getChildren, targetTreeNodeFactory, level + 1);
            return targetTreeNodeFactory(sourceTreeNode, children, level);
        }).filter(x => x);
    }
}

