import { Dictionary } from 'lodash'

// HACK
// The following file is needed to reduce the size of reportModelParser bundle
// some of it duplicates lodash, some - platform utils

export function arraySum(array: number[]) {
    return array.reduce((a, b) => a + b, 0);
}

export function filterCollection<T>(collection: T[], getElementName: (el: T) => string, args: any[]) {
    const indexesByName: Dictionary<number> = {};
    collection.forEach((el, i) => indexesByName[getElementName(el)] = i);

    const getIndex = (arg: number) => arg < 0 ? collection.length + arg : arg;

    const result: T[] = [];

    args.forEach((arg: any) => {
        if (Array.isArray(arg)) {
            let [start, end] = <any[]>arg;
            if (typeof(start) == 'string') start = indexesByName[start];
            else start = getIndex(start);
            if (end !== undefined) {
                if (typeof(end) == 'string') {
                    if (indexesByName[end] === undefined) throw `Element named ${end} not found`;
                    end = indexesByName[end];
                }
                else {
                    end = getIndex(end);
                }
            }
            result.push(...collection.slice(start, end !== undefined ? end + 1 : undefined));
        }
        else {
            if (typeof(arg) == 'string') arg = indexesByName[arg];
            else arg = getIndex(arg);

            if (arg < collection.length) result.push(collection[arg]);
        }
    });

    return result;
}

export function isNullOrUndefined(val: any) {
    return val === null || val === undefined;
}

export function normalizeComponentName(name: string, suffix: string) {
    if (name.substr(name.length - suffix.length) !== suffix) name += suffix;
    return lcFirst(name);
}

export function parseIniString(data: string){
    var regex: any = {
        section: /^\s*\[\s*([^\]]*)\s*\]\s*$/,
        param: /^\s*([^=]+?)\s*=\s*(.*?)\s*$/,
        comment: /^\s*;.*$/
    };
    var value: any = {};
    var lines = data.split(/[\r\n]+/);
    var section: any = null;
    lines.forEach(function(line){
        if(regex.comment.test(line)){
            return;
        }else if(regex.param.test(line)){
            var match = line.match(regex.param);
            if(section){
                value[section][match[1]] = match[2];
            }else{
                value[match[1]] = match[2];
            }
        }else if(regex.section.test(line)){
            var match = line.match(regex.section);
            value[match[1]] = {};
            section = match[1];
        }else if(line.length == 0 && section){
            section = null;
        };
    });
    return value;
}

// sample => Sample
export function ucFirst(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

// SAMPLE => sAMPLE
export function lcFirst(str: string) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

export function arrayToDictionary<T>(items: T[], keySelector: (item: T) => any = item => item): Dictionary<T>  {
    return items.reduce((result: Dictionary<T>, item: T) => {
        result[keySelector(item)] = item;
        return result;
    }, <Dictionary<T>>{});
}