'use strict';

import {
    register, ISystemorphEntity, 
    IDimensionNameFilter
} from "@systemorph/web";
import {
    BaseIndependentDimensionAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyControllerAs,
    defaultAutocompleteFormEntityPropertyTempplate, FormEntityService,
    IFormEntityPropertyScope
} from "@systemorph/form-entity-web";
import { IAutocompleteBoxItem, IUtilsService } from "@systemorph/ui-web";
import { IFilterService, IPromise } from "angular";

register.directive('processDocumentationPriorityFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: ProcessDocumentationPriorityFormEntityPropertyController
    };
});

class ProcessDocumentationPriorityFormEntityPropertyController extends BaseIndependentDimensionAutocompleteFormEntityPropertyController {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                utilsService: IUtilsService,
                $filter: IFilterService) {
        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            return utilsService.getTypeQuery($scope.propertyLayout.propertyTypeName).then(query => {
                    return query.orderBy("it.DisplayName").toArray(true).then(
                        (values: ISystemorphEntity[]) => {
                            const valuesWithEmptyOntion: ISystemorphEntity[] =  [<any>{ DisplayName: "" }].concat(values);

                            return valuesWithEmptyOntion.map(x => ({value: x, displayName: $filter<IDimensionNameFilter>("dimensionName")(x)}));
                        });
                });
        };

        super($scope, formEntityService, getAutocompleteFunc, true);
    }
}
