'use strict';

import {
    IPropertyOfEntityScope,
    ISystemorphEntity,
    IPersisterProviderService,
    IEntityService,
    IEntityAccessRightsService,
    ILayoutService,
    DISPLAY_CONTEXT } from '@systemorph/web';
import {IUtilsService, extractListPropertyTypeName} from '@systemorph/ui-web';
import {IAugmentedJQuery, IQService, IPromise, IScope, ITimeoutService} from 'angular';
import {IFormulaEditorItem} from './formulaEditor/formulaEditor.api';

export interface IFormulaEditorPropertyScope extends IPropertyOfEntityScope {
    propertyCtrl: FormulaEditorPropertyController;
}

export interface IFormulaEditorItemWrapper extends IFormulaEditorItem {
    entity?: ISystemorphEntity;
}

export const languagePathParameterName = 'languagePath';

export abstract class FormulaEditorPropertyController {
    items: IFormulaEditorItemWrapper[];
    isCreatable: boolean;
    isLoading: boolean;
    languagePath: string[];

    protected constructor(protected $scope: IFormulaEditorPropertyScope,
                          protected $q: IQService,
                          protected $timeout: ITimeoutService,
                          protected $element: IAugmentedJQuery,
                          protected persisterProviderService: IPersisterProviderService,
                          protected layoutService: ILayoutService,
                          protected entityAccessRightsService: IEntityAccessRightsService,
                          protected utilsService: IUtilsService,
                          protected entityService: IEntityService) {
        $element.closest('form').addClass('formula-editor-property');

        const isCreatablePromise = this.layoutService
            .layout(extractListPropertyTypeName($scope.propertyLayout), DISPLAY_CONTEXT.details).$promise
            .then(layout => {
                this.isCreatable = this.entityAccessRightsService.canEdit($scope.entity) && layout.isCreatable;
            });

        this.isLoading = true;

        const itemsPromise = this.getItems().then(items => this.items = items);

        this.$q.all([this.preload(), itemsPromise, isCreatablePromise]).finally(() => {
            this.isLoading = false;
        });
    }

    saveItem(item: IFormulaEditorItemWrapper) {
        if (item.isNew) {
            return this.getNewItemEntity(item)
                .then(itemEntity => {
                    item.entity = itemEntity;

                    this.mapEditorItemToEntity(item, item.entity);

                    return this.commit()
                        .then(() => {
                            item.isNew = false;
                            item.isCollapsible = true;
                            item.isFilterable = true;

                            return this.updatePropertyCollection();
                        });
                });
        }
        else {
            this.mapEditorItemToEntity(item, item.entity);
            return this.commit();
        }
    }

    protected updatePropertyCollection() {
        return this.entityService.updatePropertyCollection(this.$scope.entity.Id,
            this.$scope.propertyLayout.systemName, this.items.filter(i => !i.isNew).map(i => i.entity.Id));
    }

    deleteItem(item: IFormulaEditorItemWrapper) {
        return this.entityService.deleteEntity(item.entity);
    }

    protected abstract entityTypeName: string;
    protected abstract mapEditorItemToEntity(item: IFormulaEditorItemWrapper, entity: ISystemorphEntity): ISystemorphEntity;

    protected abstract getItems(): IPromise<IFormulaEditorItem[]>;

    protected getNewItemEntity(item: IFormulaEditorItemWrapper) {
        return this.$q.all([this.persisterProviderService.getPersister(), this.utilsService.getNewGuid()])
            .then(([persister, newGuid]) => {
                const context: any = (<any>persister).context;
                const entity: ISystemorphEntity = context[`createAdd${this.entityTypeName}`]();

                entity.Id = newGuid;

                return entity;
            });
    }

    private commit() {
        const entityScope = this.getEntityScope();

        // hack: this is done to avoid page-reload which is triggered by commitDataToServer event in entityDirective
        // todo: add ability to suppress page-reload in entity directive
        entityScope.isModalContent = true;

        return this.persisterProviderService.getPersister()
            .then(persister => {
                return persister.commit();
            })
            .then(() => {
                entityScope.isModalContent = false;
            });
    }

    private getEntityScope() {
        let result: IScope;

        let scope: IScope = this.$scope;

        while (scope = scope.$parent) {
            if (scope.localEntity)
                result = scope;
        }

        return result;
    }

    private preload() {
        const deferred = this.$q.defer();

        require.ensure(['./formulaEditor'], require => {
            require('./formulaEditor');

            this.$timeout(() => {
                deferred.resolve();
            });
        });

        return deferred.promise;
    }
}