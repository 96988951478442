'use strict';

import { register, IUserService, IUserInfoResult } from "@systemorph/web";
import * as angular from 'angular';
import { ProcessDocumentationItemGroupController } from './processDocumentationItemGroupController';
import { IProcessDocumentationService, IProcessDocumentationQAndA } from '../processDocumentation.api';

class ProcessDocumentationQAndAGroupController extends ProcessDocumentationItemGroupController {
    potentialResponder: IUserInfoResult;

    constructor($scope: angular.IScope,
                processDocumentationService: IProcessDocumentationService,
                userService: IUserService) {

        super($scope, processDocumentationService);

        userService.getCurrentUserInfo().then((userInfo: IUserInfoResult) => {
            this.potentialResponder = userInfo;
        });
    }

    isResponseEmpty(item: IProcessDocumentationQAndA): boolean {
        return this.processDocumentationService.isResponseEmpty(item);
    }

    canAnswerQuestion(item: IProcessDocumentationQAndA): boolean {
        return this.processDocumentationService.canAnswerQuestion(item, this.potentialResponder.id);
    }

    answerQuestion(item: IProcessDocumentationQAndA): ng.IPromise<any> {
        return this.processDocumentationService.answerQuestion(item, this.typeName, this.potentialResponder.id);
    }
}

register.directive("processDocumentationQAndAGroup", () => {
    return {
        template: require('./processDocumentationQAndAGroup.html'),
        controllerAs: "ctrl",
        controller: ProcessDocumentationQAndAGroupController,
        bindToController: {
            searchText: '@',
            typeName: '@',
            group: '='
        }
    }
});
