'use strict';

import { ISystemorphEntity } from '@systemorph/web';
import { IPromise } from 'angular';

export interface IProcessPromotableModel {
    fromSystemName: string;
    fromDisplayName: string;
    targetStateSystemName: string;
    targetDisplayName: string;
    displayName: string;
    icon: string;//this property is not provided by server, but generated on ui
}

export interface IProcessHistoryModel {
    processStates: IProcessStateModel[];
    historyItems: IProcessHistoryItem[];
}

export interface IProcessHistoryItem {
    fromStateSystemName: number;
    fromState: IProcessStateModel;//this is joined property from processStates of IProcessModel
    toStateSystemName: number;
    toState: IProcessStateModel;//this is joined property from processStates of IProcessModel
    date: Date;
    userName: string;
    userImageUrl: string;
    userId: string;
    changeComment: string;
}

export interface IProcessStateModel {
    count: number;
    systemName: string;
    displayName: string;
}

export interface ISelectableProcessModel extends IProcessModel {
    isSelected: boolean;
}

export interface IProcessModel {
    systemName: string;
    displayName: string;
    kickoffDate: Date;
    label: string;
    processTrackerId: string;
    businessProcessId: string;
    processBranchName: string;
    currentStateSystemName: string;
    isFinished: boolean;
    subProcessModels: IProcessModel[];

    isExpanded: boolean;
    isSelected: boolean;
    subProcessModelsFiltered: IProcessModel[];
    parent: IProcessModel;
}

// ongoing process model
export interface ICurrentProcessModel {
    displayName: string;
    branchName: string;
    processId: string;
    processSystemName: string;
    year: number;
    period: number;
    periodicity: string;
    state: string;
}

export interface INewlyMergedDataModel {
    items: INewlyMergedDataItemModel[];
    canMerge: boolean;
}

export interface INewlyMergedDataItemModel {
    displayName: string;
    revision: number;
}

export interface IActiveProcessInfo {
    process: ICurrentProcessModel,
    isMaxRevisionProjector: boolean;
    isHeadRevision: boolean;
    revision: number;
    branchName: string;
}

export interface IProcessDataType {
    systemName: string;
    displayName: string;

    isSelected?: boolean;
}


export interface IProcessOverviewModel {
    processModels: IProcessModel[];
    totalModelsCount: number;
}


export interface IBusinessProcessService {
    getProcess(processId: string, withSubModels?: boolean): IPromise<IProcessModel>;
    getProcesses(skip: number, take: number, isCompleted?: boolean): IPromise<IProcessOverviewModel>;
    getProcessStates(processTrackerId: string): IPromise<IProcessStateModel[]>;
    getProcessStateCachedAndCalculateCounts(masterProcessName: string, processModel: IProcessModel): IPromise<IProcessStateModel[]>;
    getAllStates(masterProcessName: string, processModel: IProcessModel): IPromise<IProcessStateModel[]>;

    getProcessTrackerHistory(masterProcessName: string, process: IProcessModel): IPromise<IProcessHistoryModel>;
    getProcessTrackerPromote(processTrackerId: string, processName: string): IPromise<IProcessPromotableModel[]>;
    getChangeableTracker(processTrackerId: string, fromStateSystemName: string, toStateSystemName: string): IPromise<IProcessModel>;
    startMotion(process: IProcessModel, processName: string, promoteModel: IProcessPromotableModel): void;

    applyFiltering(process: IProcessModel, stateFilter?: string): void;
    flattenProcess(process: IProcessModel): IProcessModel[];
    getProcessDataTypes(process: IProcessModel): IProcessDataType[];

    getCurrentProcesses(): IPromise<ICurrentProcessModel[]>;
    getNewlyMergedData(): angular.IPromise<INewlyMergedDataModel>;
    getActiveProcessInfo(noCache?: boolean): IPromise<IActiveProcessInfo>;
    mergeNewData(sourceRevision: number): IPromise<void>;
    setActiveProcess(branch: string): IPromise<void>;
}

export interface DataPack extends ISystemorphEntity 
{
    milestones: Milestone[];
}

export interface Milestone
{
    $type: string;
    displayName: string;
    date: string; 
}