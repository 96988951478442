'use strict';

import { register, DOM_EVENT } from "@systemorph/web";
import { IDataGridEditorContainerScope } from "./dataGridEditorContainer";
import * as uiGrid from "angular-ui-grid"
import { IDataGridEditorParameters } from '../dataGrid.api';
import { DATA_GRID_EDITOR } from '../dataGrid.constants';

interface IBasicDataGridEditorScope extends IDataGridEditorContainerScope {
    editValue: any;
}

interface IBasicDataGridEditorParameters extends IDataGridEditorParameters {
    editAsPercentage: boolean;
}

register.directive("basicDataGridEditor", function (uiGridEditConstants: uiGrid.IGridEditConstants, $timeout: ng.ITimeoutService, $compile: ng.ICompileService) {
    return {
        replace: true,
        link(scope: IBasicDataGridEditorScope, element: ng.IAugmentedJQuery, attributes: ng.IAttributes) {
            const editorParameters: IBasicDataGridEditorParameters = scope.col.colDef.columnDisplay.getEditorParameters(scope.row.entity.entity, scope.row.entity.cellDataStore[scope.col.colDef.name], DATA_GRID_EDITOR.basic) || <any> {};
            const validators: string[] = scope.col.colDef.columnDisplay.getValidators(scope.row.entity.entity, scope.row.entity.cellDataStore[scope.col.name]) || [];

            if (editorParameters.editAsPercentage && validators.indexOf('is-decimal') === -1) validators.push('is-decimal');
            if (editorParameters.isRequired) validators.push('required');

            const validatorsString = validators.length ? validators.join(' ') : null;

            const template = `<input type="INPUT_TYPE" ng-class="'colt' + col.uid" ui-grid-editor basic-data-grid-editor-input ${ validatorsString } ng-model="editValue">`;

            element.html(template);
            $compile(element.contents())(scope);
        }
    }
});

register.directive("basicDataGridEditorInput", function (uiGridEditConstants: uiGrid.IGridEditConstants,
    uiGridConstants: uiGrid.IGridConstants,
    $timeout: ng.ITimeoutService) {
    return {
        require: 'ngModel',
        link(scope: IBasicDataGridEditorScope, element: ng.IAugmentedJQuery, attributes: ng.IAttributes, ngModel: ng.INgModelController) {
            const container = scope.editorContainer;
            const editorParameters = <IBasicDataGridEditorParameters> container.editorParameters;

            scope.editValue = container.columnDisplay.getValue(container.entity);

            if (scope.editValue && editorParameters.editAsPercentage) scope.editValue *= 100;

            // enable deep edit mode by default (don't cancel editing on pressing the arrow keys)
            scope.deepEdit = true;

            // fix scrolling issue when deep edit mode is enabled (cancel editing on scroll)
            element.on(DOM_EVENT.click, function () {
                $timeout(() => {
                    $timeout(() => scope.grid.disableScrolling = false);
                })
            });

            // save edited value
            scope.$on(uiGridEditConstants.events.END_CELL_EDIT, function () {
                if (ngModel.$dirty && ngModel.$valid) {
                    let value: any = ngModel.$modelValue;
                    if (value && editorParameters.editAsPercentage) value /= 100;
                    container.save(value);
                }
                else {
                    container.cancel();
                }
            });

            // cancel editing
            scope.$on(uiGridEditConstants.events.CANCEL_CELL_EDIT, function () {
                container.cancel();
            });
        }
    }
});