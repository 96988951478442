'use strict';
import * as angular from "angular";

angular.module('systemorph')
    .run( /*@ngInject*/($animate: any) => {
    
        // disabling animation to improve performance, if it's needed - should be enabled for specific
        // elements only using the $animateProvider.classNameFilter method
        $animate.enabled(false);
    });
