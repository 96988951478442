export const DATA_GRID_COLUMN_DISPLAY = {
    base: "baseColumnDisplay",
    rowNumber: "rowNumberColumnDisplay",
    enum: "enumColumnDisplay",
    dimension: "dimensionColumnDisplay",
    hierarchical: "hierarchicalColumnDisplay",
    hierarchicalDimension: "hierarchicalDimensionColumnDisplay",
    richText: "richTextColumnDisplay",
    boolean: "booleanColumnDisplay"
};

export const DATA_GRID_EDITOR = {
    noEditor: "noEditor", // used in case of using custom editor, e.g. richText editor shown in the popover
    basic: "basicDataGridEditor",
    dropdown: "dropdownDataGridEditor",
    dimension: "dimensionDataGridEditor",
};

export const DATA_GRID_COLUMN_TYPE = {
    rowNumber: "RowNumber",
    rowHeader: "RowHeader",
    text: "Text",
    richText: "RichText",
    number: "Number",
    enum: "Enum",
    dimension: "Dimension",
    boolean: "Boolean"
};