export const BUSINESS_PROCESSES_EVENT = {
    currentProcessChanged: 'businessProcesses.currentProcessChanged'
};

export const INTERFACES = {
    SI: "SI"
};

export const BUSINESS_PROCESSES = {
    IFRS17Close: "IFRS17Close"
};