'use strict';

import {register} from "@systemorph/web";
import {BaseColumnDisplay} from "./baseColumnDisplay";
import {DataGridController} from "../dataGridDirective";
import { IDataGridColumnDef, IDataGridOptions } from '../dataGrid.api';

export class RowNumberColumnDisplay extends BaseColumnDisplay {
    /*@ngInject*/
    constructor(colDef: IDataGridColumnDef, dataGridOptions: IDataGridOptions, private dataGrid: DataGridController) {
        super(colDef, dataGridOptions);
    }

    getDisplayWidth(displayValue: any, cellData: any): number {
        return this.getTextWidth(this.dataGrid.entities.length.toString());
    }

    getTemplate(): string {
        return '<span>{{grid.renderContainers.body.visibleRowCache.indexOf(row) + 1}}</span>';
    }
}

register.factory("rowNumberColumnDisplay", () => RowNumberColumnDisplay);