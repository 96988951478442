'use strict';

import { register, IPropertyOfEntityScope } from '@systemorph/web';
import { FORM_ENTITY_SELECTOR_TYPE, FormEntityRegistry, FORM_ENTITY_EVENT, FormEntityProvider } from '@systemorph/form-entity-web';
import { InterfaceFileSelectService, IFileModel, IInterfaceModel } from './interfaceFileSelectService';
import { blockUI, IAngularEvent } from 'angular';
import { head, isEmpty, Dictionary } from 'lodash';

interface IInterfaceFileUserInput {
    interface: string;
    fileName: string;
    dataFormat: string;
}

class Controller {
    blockName: string;

    activeInterface: IInterfaceModel;
    allInterfaces: IInterfaceModel[];

    readonly formEntitySelector = FORM_ENTITY_SELECTOR_TYPE.context; 
    readonly formEntityScope = `interfaceScope`; 
    readonly formEntityContext = `InterfaceContext`; 
    formEntityContextArgs: { interfaceName: string}; 

    activeFile: IFileModel;
    allFiles: IFileModel[];

    activeDataFormat: string;

    constructor(private $scope: IPropertyOfEntityScope, 
                private blockUI: blockUI.IBlockUIService,
                private formEntityRegistry: FormEntityRegistry,
                private interfaceFileSelectService: InterfaceFileSelectService) {
        this.blockName = `InterfaceFileSelectBlock_${$scope.entity.Id}`;

        this.block();
        this.interfaceFileSelectService.getInterfaces().then(data => {
            this.allInterfaces = data;
            if (!isEmpty(data)) {
                this.selectInterface(head(data));
            }
        }).finally(() => {
            this.unblock();
        })

        const formEntityEvents = [
            FORM_ENTITY_EVENT.updated,
            FORM_ENTITY_EVENT.replaced,
            FORM_ENTITY_EVENT.added
        ];
        formEntityEvents.forEach(eventName => {
            this.$scope.$on(eventName, (event: IAngularEvent, formEntityScope: string, provider: FormEntityProvider, newValues: Dictionary<any>) => {
                if (formEntityScope == this.formEntityScope) {
                    this.loadFiles();
                }
            });
        })
    }

    selectInterface(selectedInterface: IInterfaceModel) {
        if (this.activeInterface && this.activeInterface.name == selectedInterface.name) {
            return;
        }
        this.activeInterface = selectedInterface;

        if (isEmpty(this.activeInterface.formats)) {
            this.activeDataFormat = null;
        } else {
            this.activeDataFormat = head(this.activeInterface.formats);
        }

        this.setFormEntityContextArgs();
    }

    private setFormEntityContextArgs() {
        this.formEntityContextArgs = { interfaceName: this.activeInterface.formEntityProvider };
    }

    private loadFiles() {
        this.block();
        this.formEntityRegistry.getFilterObject(this.formEntityScope).then(queryModel => {
            queryModel.interface = this.activeInterface.name;
            return this.interfaceFileSelectService.getFiles(this.activeInterface.name, queryModel).then(data => {
                this.allFiles = data;
                this.activeFile = null;
                if (isEmpty(data)) {
                    this.clearEntityValue();
                } else {
                    this.selectFile(head(data));
                }
            })
        }).catch(() => {
            this.allFiles = [];
            this.activeFile = null;
            this.clearEntityValue();
        }).finally(() => {
            this.unblock();
        })
    }

    selectFile(file: IFileModel) {
        if (this.activeFile && this.activeFile.name == file.name) {
            return;
        }

        this.activeFile = file;
        this.updateEntityValue();
    }

    onDataFormatChange() {
        this.updateEntityValue();
    }

    private clearEntityValue() {
        this.$scope.entity[this.$scope.propertyLayout.systemName] = null;
    }

    private updateEntityValue() {
        const userInput: IInterfaceFileUserInput = {
            interface: this.activeInterface.name,
            fileName: this.activeFile.name,
            dataFormat: this.activeDataFormat
        };

        this.$scope.entity[this.$scope.propertyLayout.systemName] = userInput;
    }

    private block() {
        this.blockUI.instances.get(this.blockName).start("Loading...")
    }

    private unblock() {
        this.blockUI.instances.get(this.blockName).stop()
    }
}

register.directive("interfaceFileSelect", () => {
    return {
        replace: true,
        scope: true,
        template: require('./interfaceFileSelect.html'),
        controllerAs: 'interfaceCtrl',
        controller: Controller
    }
})