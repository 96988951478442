'use strict';

import { IScope } from 'angular';

import { register, ITypeLayout, IPropertyLayout, DISPLAY_CONTEXT, DATE_TIME_KIND } from "@systemorph/web";
import { isEmpty, get, map, head, compose } from 'lodash/fp';
import { DataPack } from '../../businessProcess.api';

register.directive('datapackSelector', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: require('./datapackSelector.html'),
        controllerAs: "ctrl",
        controller: Controller
    };
});

class Controller {
    milestoneTypeLayout: ITypeLayout;
    datePropertyLayout: IPropertyLayout;
    displayContext: string;

    isSelectable: boolean;
    dataPacks: DataPack[];
    milestonesHeaders: string[];

    empty: boolean;
    dateOptions: { kind: string; format: string; };

    constructor($scope: IScope) {
        if (isEmpty($scope.entity) || !get('propertyLayout.systemName', $scope)) {
            throw new Error('packSelector Directive. Prerequsites are empty: propertyLayout.systemName or entity ')
        }

        this.dataPacks = $scope.entity[$scope.propertyLayout.systemName];
        this.empty = isEmpty(this.dataPacks);
        this.isSelectable = this.dataPacks.length > 1;
        this.milestonesHeaders =
            compose(
                map('displayName'),
                head,
                map('milestones'),
            )(this.dataPacks)

        this.displayContext = DISPLAY_CONTEXT.details;

        this.dateOptions = {
            kind: DATE_TIME_KIND.utc,
            format: 'mediumDate'
        }
    }
}