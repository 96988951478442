'use strict';

import { register, COMMON_EVENT } from "@systemorph/web";
import { 
    IProcessModel, IProcessPromotableModel,
    ACTIVITY_TYPE, 
} from '@systemorph/business-processes-web';
import { IBaloiseBusinessProcessService } from './baloiseBusinessProcess.api';
import { IHttpService, ILocationService, ITimeoutService } from "angular";
import { IStateParamsService, IStateService } from "angular-ui-router";

export const ACTIVE_BUSINESS_PROCESS_STATE_PARAM = 'bp';

export const PROCESS_WITH_SUFFIX = "IFRS17Close";

export const cutVersionSuffix = (businessProcess: string) => {
    const delimeter = "_";
    if (businessProcess) {
        const delimiterCount = (businessProcess.match(new RegExp(delimeter, 'g')) || []).length;
        const isProcessWithPrefix = businessProcess.trim().startsWith(PROCESS_WITH_SUFFIX);
        if (isProcessWithPrefix && delimiterCount > 2 || !isProcessWithPrefix && delimiterCount > 0) {
            const suffixIndex = businessProcess.lastIndexOf(delimeter);
            return businessProcess.substring(0, suffixIndex);
        }
    }
    return businessProcess;
}

register.decorator('businessProcessService', ($delegate: IBaloiseBusinessProcessService, $state: IStateService, $stateParams: IStateParamsService, $location: ILocationService, $timeout: ITimeoutService) => {

    $delegate.startMotion = (process: IProcessModel, processName: string, promoteModel: IProcessPromotableModel): void => {
            const params = {
            displayName: `${processName} / ${process.displayName} &mdash; ${promoteModel.displayName}`,
            systemName: `${promoteModel.fromSystemName}_to_${promoteModel.targetStateSystemName}`,
            processName: processName,
            processDisplayName: process.displayName,
            promoteModelName: promoteModel.displayName,
            processTrackerId: process.processTrackerId,
            processId: process.businessProcessId,
            fromStateSystemName: promoteModel.fromSystemName,
            toStateSystemName: promoteModel.targetStateSystemName,
            category: ACTIVITY_TYPE.statusChange
        }

        const http: IHttpService = (<any>$delegate).$http;
        http.post<boolean>("/api/baloiseBusinessProcess/startPromote", params);
    }

    $delegate.$rootScope.$on(COMMON_EVENT.dataVersionChanged, () => {
        $delegate.getActiveProcessInfo(true).then(activeProcessInfo => {
            $stateParams[ACTIVE_BUSINESS_PROCESS_STATE_PARAM] = cutVersionSuffix(activeProcessInfo.branchName);
            $state.go($state.current.name, $stateParams, { notify: false });
        })
    });

    $delegate.$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        if (!fromParams[ACTIVE_BUSINESS_PROCESS_STATE_PARAM]) {
            $delegate.getActiveProcessInfo(true).then(activeProcessInfo => {
                $location.search(ACTIVE_BUSINESS_PROCESS_STATE_PARAM, cutVersionSuffix(activeProcessInfo.branchName));
                $timeout(() => {
                    $state.current.reloadOnSearch = undefined;
                });
            })
        } else {
            toParams[ACTIVE_BUSINESS_PROCESS_STATE_PARAM] = fromParams[ACTIVE_BUSINESS_PROCESS_STATE_PARAM];
        }
    });

    return $delegate;
});
