'use strict';

import { register, IPropertyOfEntityScope, MENU_STATE_PARAMS, MENU_EVENT } from "@systemorph/web";
import { IStateService } from "angular-ui-router";
import { IScope } from "angular";

interface IProcessDocumentationTitleUrlArgs {
    stateName: string;
    stateParams: Object;
    contextPanelMenuId: string;
    title: string;
}

class Contrroller {
    urlArgs: IProcessDocumentationTitleUrlArgs;

    constructor(private $scope: IPropertyOfEntityScope,
                private $rootScope: IScope,
                private $state: IStateService) {
        this.urlArgs = $scope.entity[$scope.propertyLayout.systemName];
    }

    onclick() {
        const stateParams = {
            ...this.urlArgs.stateParams,
            [MENU_STATE_PARAMS.activeContextPanelMenuId]: this.urlArgs.contextPanelMenuId,
            [MENU_STATE_PARAMS.isContextPanelOpen]: true
        };

        this.$rootScope.$broadcast(MENU_EVENT.outSideExpandUniversalContextPanel, this.urlArgs.contextPanelMenuId);
        this.$state.go(this.urlArgs.stateName, stateParams, { reload: true });
    }
}

register.directive("processDocumentationLinkProperty", () => {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        template: `<a class="details-string" ng-bind="::ctrl.urlArgs.title" ng-click="ctrl.onclick()"></a>`, 
        controllerAs: 'ctrl',
        controller: Contrroller
    }
});
