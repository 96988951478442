'use strict';

import {getObjectProperties} from "./reportingUtils";
import * as reportingUtils from "./reportingUtils";
import { Dictionary } from 'lodash';

const evalExpressionCache: Dictionary<string> = {};

export function evalExpression<T = any>(expression: any, context?: any, silent = false , assignValue?: any) {
    "use strict";

    // globals
    const isNullOrUndefined = reportingUtils.isNullOrUndefined;

    let expr: string;

    if (context) {
        if (!evalExpressionCache[expression])
            evalExpressionCache[expression] = substituteContextVars(expression, context);

        expr = evalExpressionCache[expression];
    }
    else {
        expr = expression;
    }

    if (arguments.length == 4)
        expr = `${expr} = assignValue`;

    if (silent) {
        try {
            return <T>eval(expr);
        }
        catch(e) {}
    }
    else {
        return <T>eval(expr);
    }
}

function substituteContextVars(expression: string, context: any) {
    let result: string = '';
    const contextVars = getObjectProperties(context);
    let openQuote: string;
    let openQuoteIndex: number;
    let substStartIndex: number;

    for (let i = 0; i < expression.length; i++) {
        const char = expression[i];

        if (char == '"' || char == "'") {
            if (openQuote) { // closing quote
                if (char != openQuote || expression[i - 1] == '\\') continue;
                result += expression.substring(openQuoteIndex, i + 1);
                openQuote = undefined;
                openQuoteIndex = undefined;
            }
            else { // opening quote
                result += substituteContextVarsInner(expression.substring(substStartIndex == null ? 0 : substStartIndex, i), contextVars);
                openQuote = char;
                openQuoteIndex = i;
                substStartIndex = undefined;
            }
        }
        else if (!openQuote && substStartIndex == undefined) {
            substStartIndex = i;
        }
    }

    if (openQuote)
        throw `Unexpected end of expression, no closing quote (${openQuote}) found: ${expression}`;

    if (substStartIndex >= 0)
        result += substituteContextVarsInner(expression.substr(substStartIndex), contextVars);

    // console.log(`-----------------------\n${expression}\n${result}`);

    return result;
}

function substituteContextVarsInner(expression: string, contextVars: string[]) {
    return expression.replace(/\b(\w+)((?:\.\w+)*)\b/g, (match, g1, g2) => {
        if (contextVars.indexOf(g1) != -1) g1 = `context.${g1}`;
        return g1 + g2 || '';
    });
}