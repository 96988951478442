'use strict';

import { register, STATE, COMMON_EVENT, PERSISTANCE_EVENT } from "@systemorph/web";
import {BUSINESS_PROCESS_STATE, SERVER_EVENTS} from '../businessProcess.constants';
import { IScope, ITimeoutService } from "angular";
import { IStateParamsService } from "angular-ui-router";

interface IBusinessProcessDetailsPageStateParams extends IStateParamsService {
    processId: string;
    processName: string;
}

class Controller {
    isReady = true;
    processId: string;
    processName: string;

    /*@ngInject*/
    constructor($scope: IScope,
                $stateParams: IBusinessProcessDetailsPageStateParams,
                private $timeout: ITimeoutService) {
        this.processId = $stateParams.processId;
        this.processName = $stateParams.processName;

        const eventsToReload = [
            COMMON_EVENT.dataVersionChanged,
            COMMON_EVENT.persistenceContextChanged,
            PERSISTANCE_EVENT.backedNotifiedDataVersionWasChanged,
            SERVER_EVENTS.processFinished
        ];

        eventsToReload.forEach(eventName => {
            $scope.$on(eventName, () => {
                this.refresh();
            });
        });
    }

    private refresh() {
        this.isReady = false;

        this.$timeout(() => {
            this.isReady = true;
        });
    }
}

register.state({
    name: BUSINESS_PROCESS_STATE.businessProcessDetails,
    parent: STATE.defaultLayout,
    url: '/businessProcessDetails/:processId/:processName?state',
    template: require('./businessProcessDetails.html'),
    controllerAs: 'bpDetailsCtrl',
    controller: Controller,
    suppressReloadMessage: true
});