'use strict';

import { IMenuItemScope, register } from '@systemorph/web';
import { DataGridController, IDataGridOptions } from '@systemorph/ui-web';
import { FormEntityRegistry } from '@systemorph/form-entity-web';
import { IBasicFormEntity } from '@systemorph/reporting-web';
import { IBaloiseDataGridExportService } from '../export/baloiseDataGridExportService';

interface IExportToCsvDataGridMenuItemScope extends IMenuItemScope {
    export(): void;
}

register.directive('exportToCsvWithMetadataDataGridMenuItem', (baloiseDataGridExportService: IBaloiseDataGridExportService, $q: ng.IQService, formEntityRegistry: FormEntityRegistry) => {
    return {
        replace: true,
        restrict: 'E',
        require: '^^datagrid',
        transclude: true,
        template: `<button type="button" class="btn btn-success btn-inline btn-inline-static" ng-click="export()" uib-tooltip="Export csv" tooltip-placement="top-right" tooltip-append-to-body="true">
                    <span class="fa fa-file-excel-o"></span>
                  </button>`,
        link($scope: IExportToCsvDataGridMenuItemScope, $element: ng.IAugmentedJQuery, $attrs: ng.IAttributes, dataGrid: DataGridController) {
            $scope.export = () => {
                $q.all([
                        dataGrid.gridApiPromise,
                        formEntityRegistry.get()
                    ])
                    .then((results: any[]) => {
                        const gridApi = results[0];
                        const entry = results[1];

                        var exportName = dataGrid.options.getExportName();
                        if (!exportName) throw 'No export name provided';

                        var exportNamePromise = typeof exportName === 'string' ? $q.when(exportName) : exportName;
                        exportNamePromise.then(exportName => {
                            const { environment } = $scope.item.parameters;
                            baloiseDataGridExportService.exportToCsvWithMetadata(gridApi.grid, 'visible', 'visible', `${exportName}_${environment}.csv`, <IBasicFormEntity>entry.provider.formEntity, $scope.item.parameters);
                        })
                    });
            };
        }
    };
});
