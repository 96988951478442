'use strict';

import * as angular from "angular";
import {register, DOM_EVENT} from "@systemorph/web";
import {isPromise} from "@systemorph/web";
import {DataGridEditorContainerController, IDataGridEditorContainerScope} from "./dataGridEditorContainer";
import * as uiGrid from "angular-ui-grid"
import { IDropdownDataGridEditorParameters, IDropdownDataGridEditorItem } from './editor.api';

export interface IDropdownDataGridEditorScope extends IDataGridEditorContainerScope {
    dropdownEditor: DropdownDataGridEditorController
}

register.directive("dropdownDataGridEditor", function() {
    return {
        link(scope: IDropdownDataGridEditorScope, element: ng.IAugmentedJQuery, attributes: ng.IAttributes) {
            scope.dropdownEditor.link(element, attributes);
        },
        controller: DropdownDataGridEditorController,
        controllerAs: 'dropdownEditor'
    }
});

export class DropdownDataGridEditorController {
    protected container: DataGridEditorContainerController;
    protected editorParameters: IDropdownDataGridEditorParameters;

    originalValue: any;
    editValue: any;
    items: IDropdownDataGridEditorItem[];

    constructor(protected $scope: IDropdownDataGridEditorScope,
                protected $q: ng.IQService,
                protected $document: ng.IDocumentService,
                protected $compile: ng.ICompileService,
                protected uiGridEditConstants: uiGrid.IGridEditConstants) {

        this.container = $scope.editorContainer;
        this.editorParameters = <IDropdownDataGridEditorParameters>this.container.editorParameters;

        this.originalValue = this.container.columnDisplay.getValue(this.container.entity);
    }

    link(element: ng.IAugmentedJQuery, attributes: ng.IAttributes) {
        this.initialize();

        const template = this.getTemplate();

        element.html(template);
        this.$compile(element.contents())(this.$scope);

        const onDocumentMousedown = (evt: JQueryEventObject) => {
            if ($(evt.target).closest('.ui-select-bootstrap').length === 0) {
                this.$scope.$emit(this.uiGridEditConstants.events.END_CELL_EDIT);
                this.$document.off(DOM_EVENT.mousedown, onDocumentMousedown);
                this.save();
            }
        };

        this.$document.on(DOM_EVENT.mousedown, onDocumentMousedown);
    }

    protected initialize() {
        if (this.editorParameters) {
            const itemsResult: any = this.getItems();
            if (isPromise(itemsResult)) {
                this.block();
                (<ng.IPromise<any[]>>itemsResult)
                    .then(items => {
                        this.setupItems(items);
                    })
                    .finally(() => this.unblock());
            }
            else {
                this.setupItems(<any[]>itemsResult);
            }
        }
    }

    private setupItems(items: any[]) {
        this.items = items.map<IDropdownDataGridEditorItem>(i => {
            if (angular.isNumber(i) || angular.isString(i)) return { value: i, displayName: i };
            return i;
        });

        if (!(this.editorParameters && this.editorParameters.isRequired)) {
            this.items.splice(0, 0, {
                value: null,
                displayName: ""
            });
        }

        this.editValue = this.getSelectedValue(this.items, this.originalValue);
    }

    protected block() {

    }

    protected unblock() {

    }

    protected getTemplate() {
        return `
            <ui-select ng-model="dropdownEditor.editValue" ui-select-append-to-body class="data-grid-dropdown-editor">
                <ui-select-match>{{$select.selected.displayName}}</ui-select-match>
                <ui-select-choices repeat="item.value as item in dropdownEditor.items | filter: $select.search">
                    <span ng-bind-html="item.displayName | highlight: $select.search"></span>
                </ui-select-choices>
            </ui-select>`;
    }

    protected getItems() {
        if (this.editorParameters.getItems)
            return this.editorParameters.getItems(this.container.entity, this.container.colDef);
        else if (this.editorParameters.items)
            return this.editorParameters.items;
        else
            return [];
    }

    protected getSelectedValue(items: IDropdownDataGridEditorItem[], originalValue: any) {
        if (items) {
            const isMatchPredicate = this.getIsMatchPredicate();
            const match = items.filter(i => i.value && originalValue && isMatchPredicate(i.value, originalValue))[0];
            if (match) return match.value;
        }

        return null;
    }

    protected getIsMatchPredicate() {
        return this.editorParameters && this.editorParameters.itemIdProperty
            ? (a: any, b: any) => a[this.editorParameters.itemIdProperty] === b[this.editorParameters.itemIdProperty]
            : (a: any, b: any) => angular.equals(a, b);
    }

    protected save() {
        const isMatchPredicate = this.getIsMatchPredicate();
        if ((this.editValue && !this.originalValue) || (!this.editValue && this.originalValue) || (this.editValue && this.originalValue && !isMatchPredicate(this.editValue, this.originalValue))) {
            this.container.save(this.editValue);
        }
    }
}