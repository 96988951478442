'use strict';

import { COMMON_EVENT, register } from '@systemorph/web';
import { FORM_ENTITY_EVENT } from '../formEntity.events';
import {BaseFormEntityPropertyController, IFormEntityPropertyScope} from "./baseFormEntityPropertyController";

export const textboxFormEntityPropertyDDOProvider = () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: `<input class="form-control" ng-model="textbox.entity[textbox.propertyLayout.systemName]" placeholder="{{textbox.placeholder}}" />`,
        controller: TextboxPropertyController,
        controllerAs: 'textbox',
    }
};

export class TextboxPropertyController extends BaseFormEntityPropertyController<string> {
    protected previousValue: string;
    protected placeholder: string;
    protected input: ng.IAugmentedJQuery;

    constructor(protected $scope: IFormEntityPropertyScope,
                protected $element: ng.IAugmentedJQuery,
                protected $stateParams: ng.ui.IStateParamsService) {
        super($scope);

        this.input = $element.find('input');

        this.placeholder = this.propertyLayout.editDirectiveParameters['placeholder'];

        this.setInitialValue();

        $scope.$emit(FORM_ENTITY_EVENT.propertyInitialized, this.propertyLayout);

        this.input.keydown(event => {
            if (event.keyCode === 13)
                this.onChange();
        });

        this.input.blur(() => this.onChange());
    }

    protected setInitialValue() {
        this.previousValue = this.entity.getStateParamsOrCurrentValue(this.propertyLayout);
    }

    private onChange() {
        var value = this.getValue();
        if (value != this.previousValue && (value || this.previousValue)) {
            this.$scope.$emit(COMMON_EVENT.propertyChanged, this.entity, this.propertyLayout, value);
            this.previousValue = value;
        }
    }
}

register.directive('textboxFormEntityProperty', textboxFormEntityPropertyDDOProvider);