'use strict';

import { register, generateId } from '@systemorph/web';
import { ITreeItemModel, IAutocompleteBoxItem, TreeUtils} from '@systemorph/ui-web';
import { IReportingNodesAndHierarchyModel } from "../../accessControlByReportingNode.api";
import { IReportingNodeService, IReportingNodeTreeModel } from "../../../reportingWeb.api";
import { IScope, IAugmentedJQuery, IAttributes, INgModelController, ITimeoutService, blockUI, IQService, IPromise } from 'angular';

register.directive('reportingNodeAndHierarchySelector', () => {
    return {
        replace: true,
        restrict: 'E',
        scope: true,
        require: 'ngModel',
        template: require('./reportingNodeAndHierarchySelector.html'),
        controller: ReportingNodeAndHierarchySelectorController,
        controllerAs: 'rnhsCtrl',
        bindToController: {
            model: '=ngModel',
            onHierarchyChange: '&',
            onSelectionChange: '&'
        },
        link: function(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, ngModelCtrl: INgModelController) {
            const ctrl: ReportingNodeAndHierarchySelectorController = scope.rnhsCtrl;
            ngModelCtrl.$formatters.push((value: IReportingNodesAndHierarchyModel) => ctrl.ngModelChangedOutSide(value));
        }
    }
});

class ReportingNodeAndHierarchySelectorController {
    // binded
    model: IReportingNodesAndHierarchyModel;
    onHierarchyChange: () => void;
    onSelectionChange: () => void;

    blockName: string;
    isReady: boolean;
    treeItems: ITreeItemModel[];
    private treeItemsFlatten: ITreeItemModel[];
    allExpanded: boolean;
    allSelected: boolean;
    hierarchyAutocomplete: IAutocompleteBoxItem[];

    constructor(private $scope: IScope, 
                private $q: IQService, 
                private reportingNodeService: IReportingNodeService, 
                private blockUI: blockUI.IBlockUIService, 
                private $timeout: ITimeoutService) {

        this.blockName = `block_name_${generateId(10)}`;
        this.isReady = false;
        this.startBlock("Loading hierarchies");
        this.reportingNodeService.getAllHierarchyNamesCached(true).then(hierarchies => {
            this.hierarchyAutocomplete = hierarchies.map(x => (<IAutocompleteBoxItem>{value: x, displayName: x}));

            if (!this.model.hierarchy && hierarchies.length) {
                this.model.hierarchy = hierarchies[0];
                this.hierarchyChangedHandler()
            }

            this.isReady = true;
            this.stopBlock();
        })
    }

    ngModelChangedOutSide(model: IReportingNodesAndHierarchyModel) {
        this.resetTreeItems(model);
    }

    hierarchyChangedHandler() {
        this.resetTreeItems(this.model);
        if (this.onHierarchyChange) {
            this.onHierarchyChange()
        };
    }

    onItemSelected(item: ITreeItemModel) {
        this.selectionChangedHandler();
    }

    expandAll(expand = true) {
        this.treeItemsFlatten.forEach(i => i.isExpanded = expand);
        this.allExpanded = expand;
    }

    selectAll() {
        this.treeItemsFlatten.forEach(i => i.isSelected = this.allSelected);
        this.selectionChangedHandler();
    }

    private selectionChangedHandler() {
        this.allSelected = this.treeItemsFlatten.every(i => i.isSelected);
        this.model.selectedIds = this.treeItemsFlatten.filter(x => x.isSelected).filter(x => !!x.data).map(x => x.data.id);
        if (this.onSelectionChange){
            this.onSelectionChange();
        }
    }

    private lastHierarchy: string;

    private resetTreeItems(model: IReportingNodesAndHierarchyModel) {
        if (!model.hierarchy || model.hierarchy == this.lastHierarchy /* ie hierarchy was not changed*/)
            return;

        this.treeItems = null;
        this.treeItemsFlatten = [];
        const hierarchy = this.lastHierarchy = model.hierarchy;
        // the trick with $timeout is done to avoid explicit recompile
        // treeView component has some issues with destroying the scope. 
        this.$timeout(() => {
            this.startBlock("Loading reporting nodes");

            this.$q.when(this.reportingNodeService.getReportingNodeTreesCached(model.hierarchy, true)).then(reportingNodeTrees => {
                if (this.lastHierarchy === hierarchy) {
                    this.treeItems = TreeUtils.getTreeFromAnotherTree<IReportingNodeTreeModel, ITreeItemModel>(reportingNodeTrees,
                        r => r.children,
                        (reportinNode, children, level) => {
                            const isSelected = model.selectedIds.indexOf(reportinNode.id) !== -1;
                            const newNode = {
                                name: this.reportingNodeService.reportingNodeToString(reportinNode),
                                data: reportinNode,
                                isSelected: isSelected,
                                isExpanded: true, //children.some(x => x.isSelected || x.isExpanded),
                                children: children
                            }
                            children.forEach(c => {
                                c.parent = newNode;
                            })
                            return newNode;
                        });
    
                    this.treeItemsFlatten = TreeUtils.flattenTreeNodes(this.treeItems, i => i.children);
                    this.allSelected = this.treeItemsFlatten.every(i => i.isSelected);
                }
            }).finally(() => {
                this.stopBlock();
            })
        })
    }

    private startBlock(message: string): void {
        this.blockUI.instances.get(this.blockName).start(message)
    }

    private stopBlock(): void {
        this.blockUI.instances.get(this.blockName).stop();
    }
}