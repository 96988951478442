'use strict';

import "chartjs-plugin-datalabels";
import "./waterfall";
import { Chart, ChartConfiguration, ChartData, ChartPoint, ChartTooltipItem } from "chart.js";
import { register, formatNumber } from "@systemorph/web";
import { legendPlugin } from './plugins/legend.plugin';
import { multilineTitlePlugin } from './plugins/multilineTitle.plugin';
import { multilineAxisLabelsPlugin } from './plugins/multilineAxisLabels.plugin';
import { upperFirst } from 'lodash';
import {extendMissingProperties, isNullOrUndefined} from '../../utils';
import {
    IChartConfiguration,
    IWaterfallChartConfiguration,
    IChartService,
    IChartData,
    IChartDataSets
} from './charts.api';
import { merge, isNumber, isObject } from 'angular';
import { CHART_EVENT } from './charts.events';
import { CHART_COLOR, CHART_AXIS_TYPE, CHART_TYPE } from './charts.constants';


export function transparentize(color: string, opacity?: number) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return Chart.helpers.color(color).alpha(alpha).rgbString();
}

const defaultColors = Object.keys(CHART_COLOR).map(k => (<any> CHART_COLOR)[k]);

const waterfallAbsoluteValueColor = CHART_COLOR.blue;
const waterfallIncreaseColor = CHART_COLOR.orange;
const waterfallDecreaseColor = CHART_COLOR.red;

class ChartService implements IChartService {
    private rightPanelCharts: IChartConfiguration[];
    private rightPanelChartsDeferred: ng.IDeferred<IChartConfiguration[]>;

    constructor(private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService) {
    }

    renderChart(container: any, baseConfig: IChartConfiguration): Chart {
        const config = this.applyDefaults(baseConfig);
        return new Chart(container, config);
    }

    private applyDefaults(baseConfig: IChartConfiguration): IChartConfiguration {
        const commonDefaults = {
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: baseConfig.data && baseConfig.data.datasets && baseConfig.data.datasets.filter(d => !!d.label).length > 0
                }
            },
            plugins: [legendPlugin, multilineTitlePlugin]
        };

        if (baseConfig.type != CHART_TYPE.pie) {
            commonDefaults.plugins.push(<any>multilineAxisLabelsPlugin)
        }

        this.applyFormatting(baseConfig);

        const typeConfigFunc = `get${ upperFirst(baseConfig.type) }Config`;

        const me: any = this;

        if (!me[typeConfigFunc])
            throw `No configuration found for '${ baseConfig.type }' chart type`;

        const typeConfig = me[typeConfigFunc](baseConfig);

        return extendMissingProperties(typeConfig, commonDefaults);
    }

    private applyFormatting(config: IChartConfiguration) {
        if (!config.options.formatter && config.options.format)
            config.options.formatter = value => formatNumber(value, config.options.format);

        if (!config.options.axisFormatter && config.options.axisFormat)
            config.options.axisFormatter = value => formatNumber(value, config.options.axisFormat);

        if (config.data.datasets) {
            config.data.datasets.forEach(ds => {
                if (!ds.formatter && ds.format)
                    ds.formatter = value => formatNumber(value, config.options.format);
            });
        }
    }

    private getLineConfig(baseConfig: IChartConfiguration) {
        const globalFormatter = baseConfig.options.formatter;
        const axisFormatter = baseConfig.options.axisFormatter || globalFormatter;

        const lineDefaults: IChartConfiguration = {
            type: 'line',
            data: {},
            options: {
                layout: {
                    padding: {
                        left: 20,
                        right: 20,
                        top: 0,
                        bottom: 0
                    }
                },
                tooltips: {
                    mode: 'nearest',
                    intersect: true,
                    callbacks: {
                        title: function (tooltipItems: ChartTooltipItem[], data: ChartData) {
                            return data.datasets[tooltipItems[0].datasetIndex].label;
                        },
                        label: function (tooltipItem: ChartTooltipItem, data: IChartData) {
                            const config: ChartConfiguration = (<any> this)._chart.config;

                            const ds = data.datasets[tooltipItem.datasetIndex];
                            const dsData: ChartPoint[] = <ChartPoint[]> ds.data;
                            const point: ChartPoint = dsData[tooltipItem.index];

                            const xAxisType = config.options.scales.xAxes[0].type;
                            const yAxisType = config.options.scales.yAxes[0].type;

                            const formatter = ds.formatter || globalFormatter;

                            const x = xAxisType === CHART_AXIS_TYPE.linear && formatter ? formatter(<number> point.x) : <any> point.x;
                            const y = yAxisType === CHART_AXIS_TYPE.linear && formatter ? formatter(<number> point.y) : <any> point.y;

                            return xAxisType === CHART_AXIS_TYPE.linear && yAxisType !== CHART_AXIS_TYPE.linear
                                ? `${ y }, ${ x }`
                                : `${ x }, ${ y }`;
                        }
                    }
                },
                scales: {
                    yAxes: [],
                    xAxes: [{
                        ticks: {
                        }
                    }]
                },
                plugins: {
                    datalabels: {
                        display: false,
                        // formatter: (value: number, context: any) => {
                        //     return formatter ? formatter(value) : value;
                        // }
                    }
                }
            }
        };

        const config: IChartConfiguration = extendMissingProperties(baseConfig, lineDefaults);

        if (config.data.datasets) {
            config.data.datasets.forEach((ds, i) => {
                const color = Chart.helpers.color(defaultColors[i % defaultColors.length]);

                const dataSetDefaults = {
                    lineTension: 0.1,
                    backgroundColor: color.alpha(0.2).rgbString(),
                    borderColor: color.alpha(1).rgbString(),
                    borderWidth: 1,
                    fill: false
                };

                extendMissingProperties(ds, dataSetDefaults);
            })
        }

        const axes = [...config.options.scales.xAxes, ...config.options.scales.yAxes];

        axes.forEach(axis => {
            if (axis.type === CHART_AXIS_TYPE.linear) {
                const scaleLabelParts: string[] = [];
                if (axis && axis.scaleLabel && axis.scaleLabel.labelString) scaleLabelParts.push(axis.scaleLabel.labelString);
                if (baseConfig.options.units) scaleLabelParts.push(baseConfig.options.units);
                const scaleLabel = scaleLabelParts.length > 0 ? scaleLabelParts.join(', ') : null;

                let suggestedMin: number, suggestedMax: number;
                if (config.data.datasets) {
                    const dataValueKey = config.options.scales.xAxes.indexOf(axis) != -1 ? 'x' : 'y';
                    const datas = config.data.datasets.map(d => d.data).filter(d => !!d);
                    const values = [].concat(...datas).map<number>(v => isObject(v) ? (<any> v)[dataValueKey] : v)
                        .filter(v => isNumber(v));
                    if (values.length) {
                        const minValue = Math.min(...values),
                            maxValue = Math.max(...values);
                        suggestedMin = minValue - (maxValue - minValue) * 0.05;
                        suggestedMax = maxValue + (maxValue - minValue) * 0.05
                    }
                }

                const linearAxisDefaults = {
                    scaleLabel: {
                        display: !!scaleLabel,
                        labelString: scaleLabel
                    },
                    ticks: {
                        callback: (value: number, index: number, values: number[]) => {
                            return axisFormatter ? axisFormatter(value, index, values) : value;
                        },
                        suggestedMin,
                        suggestedMax
                    }
                };

                extendMissingProperties(axis, linearAxisDefaults);
            }

            if (axis.type === CHART_AXIS_TYPE.category) {
                const categoryAxisDefaults = {
                    ticks: {
                    }
                };

                extendMissingProperties(axis, categoryAxisDefaults);
            }
        });

        return config;
    }

    private getCommonBarAndWaterfallConfig(baseConfig: IChartConfiguration) {
        const globalFormatter = baseConfig.options.formatter;

        const barAndWaterfallDefaults = {
            options: {
                tooltips: {
                    enabled: true,
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: false,
                            maxRotation: 90,
                        }
                    }]
                },
                plugins: {
                    datalabels: {
                        display: false,
                        formatter: (value: number, context: any) => {
                            const ds = <IChartDataSets>context.dataset;
                            const formatter = ds.formatter || globalFormatter;
                            return formatter ? formatter(value) : value;
                        }
                    }
                }
            }
        };

        const config: IChartConfiguration = merge(barAndWaterfallDefaults, baseConfig);

        if (config.data.datasets) {
            config.data.datasets.forEach((ds: any, i: number) => {
                let backgroundColor: any, borderColor: any;

                if (config.type === CHART_TYPE.waterfall || config.type === CHART_TYPE.horizontalWaterfall) {
                    let waterfallConfig = <IWaterfallChartConfiguration> config;
                    let previousValue = 0;
                    const colors: any[] = ds.data.map((d: any, index: number) => {
                        const isAbsoluteValue = index === 0 || waterfallConfig.options.showAbsoluteValues && waterfallConfig.options.showAbsoluteValues.indexOf(index) !== -1;
                        const currentValue = isAbsoluteValue ? (d || 0) : previousValue + (d || 0);
                        const isIncrease = currentValue >= previousValue;
                        previousValue = currentValue;
                        const color = isAbsoluteValue
                            ? waterfallConfig.options.absoluteValueColor || waterfallAbsoluteValueColor
                            : isIncrease ? waterfallConfig.options.increaseColor || waterfallIncreaseColor : waterfallConfig.options.decreaseColor || waterfallDecreaseColor;
                        return Chart.helpers.color(color);
                    });
                    backgroundColor = colors.map(c => c.alpha(0.2).rgbString());
                    borderColor = colors.map(c => c.alpha(1).rgbString());
                }
                else {
                    const color = Chart.helpers.color(defaultColors[i % defaultColors.length]);
                    backgroundColor = color.alpha(0.2).rgbString();
                    borderColor = color.alpha(1).rgbString();
                }

                const dataSetDefaults = {
                    backgroundColor,
                    borderColor,
                    borderWidth: 1
                };

                extendMissingProperties(ds, dataSetDefaults);
            })
        }

        return config;
    }

    private getCommonVerticalBarAndWaterfallConfig(baseConfig: IChartConfiguration) {
        const globalFormatter = baseConfig.options.formatter;
        const axisFormatter = baseConfig.options.axisFormatter || globalFormatter;

        const yAxis = baseConfig.options.scales && baseConfig.options.scales.xAxes && baseConfig.options.scales.yAxes[0];
        const scaleLabelParts: string[] = [];
        if (yAxis && yAxis.scaleLabel && yAxis.scaleLabel.labelString) scaleLabelParts.push(yAxis.scaleLabel.labelString);
        if (baseConfig.options.units) scaleLabelParts.push(baseConfig.options.units);
        const yScaleLabel = scaleLabelParts.length > 0 ? scaleLabelParts.join(', ') : null;

        const barDefaults = {
            options: {
                scales: {
                    yAxes: [{
                        scaleLabel: {
                            display: !!yScaleLabel,
                            labelString: yScaleLabel
                        },
                        ticks: {
                            beginAtZero: true,
                            callback: (value: number, index: number, values: number[]) => {
                                return axisFormatter ? axisFormatter(value, index, values) : value;
                            }
                        }
                    }]
                }
            }
        };

        const config: IChartConfiguration = merge(barDefaults, baseConfig);

        return config;
    }

    private getCommonHorizontalBarAndWaterfallConfig(baseConfig: IChartConfiguration) {
        const globalFormatter = baseConfig.options.formatter;
        const axisFormatter = baseConfig.options.axisFormatter || globalFormatter;

        const xAxis = baseConfig.options.scales && baseConfig.options.scales.xAxes && baseConfig.options.scales.xAxes[0];
        const scaleLabelParts: string[] = [];
        if (xAxis && xAxis.scaleLabel && xAxis.scaleLabel.labelString) scaleLabelParts.push(xAxis.scaleLabel.labelString);
        if (baseConfig.options.units) scaleLabelParts.push(baseConfig.options.units);
        const xScaleLabel = scaleLabelParts.length > 0 ? scaleLabelParts.join(', ') : null;

        const datalabelsEnabled = baseConfig.options.plugins
            && baseConfig.options.plugins['datalabels']
            && baseConfig.options.plugins['datalabels'].display;

        return {
            options: {
                layout: {
                    padding: {
                        left: 10,
                        right: datalabelsEnabled ? 80 : 10,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: !!xScaleLabel,
                            labelString: xScaleLabel,
                        },
                        ticks: {
                            beginAtZero: true,
                            callback: (value: number, index: number, values: number[]) => {
                                return axisFormatter ? axisFormatter(value, index, values) : value;
                            }
                        }
                    }]
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'right'
                    }
                }
            }
        };
    }

    private getCommonBarConfig(baseConfig: IChartConfiguration) {
        const globalFormatter = baseConfig.options.formatter;

        const commonBarConfig = {
            options: {
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem: ChartTooltipItem, data: IChartData) {
                            const ds = data.datasets[tooltipItem.datasetIndex];
                            const dsData = <number[]> ds.data;
                            const dsLabel = <string> data.datasets[tooltipItem.datasetIndex].label;
                            const index = tooltipItem.index;
                            const formatter = ds.formatter || globalFormatter;
                            const value = formatter ? formatter(dsData[index]) : dsData[index];
                            return dsLabel ? `${ dsLabel }: ${ value }` : value;
                        }
                    },
                    mode: 'nearest'
                },
                plugins: {
                    datalabels: {
                        formatter: (value: number, context: any) => {
                            const ds = <IChartDataSets>context.dataset;
                            const formatter = ds.formatter || globalFormatter;
                            return formatter ? formatter(value) : value;
                        }
                    }
                }
            }
        };

        return merge(commonBarConfig, baseConfig);
    }

    private getBarConfig(baseConfig: IChartConfiguration) {
        const commonBarAndWaterfallConfig = this.getCommonBarAndWaterfallConfig(baseConfig);
        const commonBarConfig = this.getCommonBarConfig(baseConfig);
        const commonVerticalBarConfig = this.getCommonVerticalBarAndWaterfallConfig(baseConfig);
        return merge(commonBarAndWaterfallConfig, commonBarConfig, commonVerticalBarConfig);
    }

    private getHorizontalBarConfig(baseConfig: IChartConfiguration) {
        const commonBarAndWaterfallConfig = this.getCommonBarAndWaterfallConfig(baseConfig);
        const commonBarConfig = this.getCommonBarConfig(baseConfig);
        const commonHorizontalBarConfig = this.getCommonHorizontalBarAndWaterfallConfig(baseConfig);
        return merge(commonBarAndWaterfallConfig, commonBarConfig, commonHorizontalBarConfig);
    }

    private getCommonWaterfallConfig(baseConfig: IChartConfiguration) {
        const globalFormatter = baseConfig.options.formatter;

        const barConfig = this.getCommonBarAndWaterfallConfig(baseConfig);

        const waterfallConfig = {
            options: {
                tooltips: {
                    displayColors: baseConfig.options.tooltips && baseConfig.options.tooltips.displayColors || false,
                    callbacks: {
                        label: function (tooltipItem: ChartTooltipItem, data: IChartData) {
                            const config: IWaterfallChartConfiguration = (<any> this)._chart.config;
                            const ds = data.datasets[tooltipItem.datasetIndex];
                            const dsData = <number[]>ds.data;
                            const index = tooltipItem.index;
                            let value = dsData[index];
                            if (isNullOrUndefined(value)) return value;
                            if (index > 0 && (!config.options.showAbsoluteValues || config.options.showAbsoluteValues.indexOf(index) === -1)) {
                                // calc delta
                                let i = index - 1, previousValue = 0;
                                do {
                                    if (dsData[i] !== undefined && dsData[i] !== null) {
                                        previousValue = dsData[i];
                                        break;
                                    }
                                } while (i-- >= 0)
                                value = dsData[index] - previousValue;
                            }
                            const formatter = ds.formatter || globalFormatter;
                            const formattedValue = formatter ? formatter(value) : value;
                            return data.datasets.length > 1 && ds.label ? `${ ds.label }: ${ formattedValue }` : formattedValue;
                        }
                    }
                },
                legend: {
                    display: baseConfig.options.legend && baseConfig.options.legend.display || false,
                },
                plugins: {
                    datalabels: {
                        formatter: (value: number, context: any) => {
                            const config = context.chart.config;
                            const ds = <IChartDataSets>context.dataset;
                            const data = context.dataset.data;
                            const index = context.dataIndex;
                            if (index > 0 && (!config.options.showAbsoluteValues || config.options.showAbsoluteValues.indexOf(index) === -1)) {
                                // calc deltas
                                value = data[index] - data[index - 1];
                            }
                            const formatter = ds.formatter || globalFormatter;
                            return formatter ? formatter(value) : value;
                        }
                    }
                }
            }
        };

        const config = merge(barConfig, waterfallConfig);

        if (config.data.datasets) {
            config.data.datasets.forEach((ds: any, i: number) => {
                const colors: any[] = ds.data.map((d: any, index: number) => {
                    const isAbsoluteValue = index === 0 || config.options.showAbsoluteValues && config.options.showAbsoluteValues.indexOf(index) !== -1;
                    return Chart.helpers.color(defaultColors[isAbsoluteValue ? 0 : 5]);
                });

                const dataSetDefaults = {
                    backgroundColor: colors.map(c => c.alpha(0.2).rgbString()),
                    borderColor: colors.map(c => c.alpha(1).rgbString()),
                };

                extendMissingProperties(ds, dataSetDefaults);
            })
        }

        return config;
    }

    private getWaterfallConfig(baseConfig: IChartConfiguration) {
        const commonWaterfallConfig = this.getCommonWaterfallConfig(baseConfig);
        const commonVerticalBarConfig = this.getCommonVerticalBarAndWaterfallConfig(baseConfig);
        return merge(commonWaterfallConfig, commonVerticalBarConfig);
    }

    private getHorizontalWaterfallConfig(baseConfig: IChartConfiguration) {
        const commonWaterfallConfig = this.getCommonWaterfallConfig(baseConfig);
        const commonHorizontalBarConfig = this.getCommonHorizontalBarAndWaterfallConfig(baseConfig);
        return merge(commonWaterfallConfig, commonHorizontalBarConfig);
    }

    private getPieConfig(baseConfig: IChartConfiguration) {
        const globalFormatter = baseConfig.options.formatter;

        const pieDefaults = {
            options: {
                layout: {
                    padding: {
                        top: 0,
                        right: 10,
                        bottom: 0,
                        left: 10
                    }
                },
                legend: {
                    position: 'bottom',
                },
                tooltips: {
                    mode: 'nearest',
                    intersect: true,
                    callbacks: {
                        label: function (tooltipItem: ChartTooltipItem, data: IChartData) {
                            const ds = data.datasets[tooltipItem.datasetIndex];
                            const value = <number> ds.data[tooltipItem.index];
                            const label = data.labels[tooltipItem.index];
                            const formatter = ds.formatter || globalFormatter;
                            const formattedValue = formatter ? formatter(value) : value;
                            return `${ label }: ${ formattedValue }`;
                        }
                    }
                },
                plugins: {
                    datalabels: {
                        color: 'white',
                        display: function (context: any) {
                            return false;
                        },
                        font: {
                            weight: 'bold'
                        },
                        formatter: (value: number, context: any) => {
                            const ds = <IChartDataSets>context.dataset;
                            const formatter = ds.formatter || globalFormatter;
                            return formatter ? formatter(value) : value;
                        }
                    }
                }
            }
        };

        const config: IChartConfiguration = merge(pieDefaults, baseConfig);

        if (config.data.datasets) {
            config.data.datasets.forEach((ds, i) => {
                const color = Chart.helpers.color(defaultColors[i % defaultColors.length]);

                const dataSetDefaults = {
                    backgroundColor: defaultColors.map(c => Chart.helpers.color(c).alpha(1).rgbString()),
                };

                extendMissingProperties(ds, dataSetDefaults);
            })
        }

        return config;
    }

    getRightPanelCharts() {
        return this.rightPanelCharts;
    }

    setRightPanelCharts(charts: IChartConfiguration[]) {
        this.rightPanelCharts = null;
        this.addRightPanelCharts(...charts);
    }

    addRightPanelCharts(...charts: IChartConfiguration[]) {
        if (!this.rightPanelCharts) this.rightPanelCharts = [];
        this.rightPanelCharts.push(...charts);
        this.$rootScope.$broadcast(CHART_EVENT.rightPanelChartsChanged, this.rightPanelCharts);
    }
}

register.service('chartService', ChartService);