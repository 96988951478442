'use strict';

export const CHART_TYPE = {
    line: "line",
    bar: "bar",
    horizontalBar: "horizontalBar",
    waterfall: "waterfall",
    horizontalWaterfall: "horizontalWaterfall",
    pie: "pie"
};

export const CHART_AXIS_TYPE = {
    linear: 'linear',
    category: 'category'
};

export const CHART_COLOR = {
    blue: 'rgb(54, 162, 235)',
    red: 'rgb(255, 99, 132)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    purple: 'rgb(153, 102, 255)',
    orange: 'rgb(255, 159, 64)',
    grey: 'rgb(201, 203, 207)',
    olive: 'RGB(128, 128, 0)',
    maroon: 'RGB(128, 0, 0)',
    teal: 'RGB(0, 128, 128)',
    weat: 'RGB(245, 222, 179)',
    salmon: 'RGB(250, 128, 114)',
    indigo: 'RGB(75, 0, 130)',
    lightpink: 'RGB(255, 182, 193)',
    lavender: 'RGB(230, 230, 250)',
    yellowgreen: 'RGB(154, 205, 50)',
    khaki: 'RGB(240, 230, 140)',
};