'use strict';

import {
    register, ISystemorphEntity, 
    IDimensionNameFilter
} from "@systemorph/web";
import {
    BaseIndependentDimensionAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyControllerAs,
    defaultAutocompleteFormEntityPropertyTempplate, FormEntityService,
    IFormEntityPropertyScope
} from "@systemorph/form-entity-web";
import { IAutocompleteBoxItem, IUtilsService } from "@systemorph/ui-web";
import { IFilterService, IPromise } from "angular";

register.directive('processDocumentationStatusFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: ProcessDocumentationStatusFormEntityPropertyController
    };
});

class ProcessDocumentationStatusFormEntityPropertyController extends BaseIndependentDimensionAutocompleteFormEntityPropertyController {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                utilsService: IUtilsService,
                $filter: IFilterService) {
        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            return utilsService.getTypeQuery($scope.propertyLayout.propertyTypeName).then(query => {
                return query.orderBy("it.Order").filter('it.Scope == "ProcessDocumentation"').toArray(true).then(
                    (values: ISystemorphEntity[]) => {
                        const valuesWithEmptyOntion: ISystemorphEntity[] =  [<any>{ DisplayName: "" }].concat(values);

                        return valuesWithEmptyOntion.map(x => ({value: x, displayName: $filter<IDimensionNameFilter>("dimensionName")(x)}));
                    });
                });
        }         
        super($scope, formEntityService, getAutocompleteFunc, true);
    }
}
