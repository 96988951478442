'use strict';

import { ReportColumnDisplay } from './reportColumnDisplay';
import { IReportGridColumnDef, IReportGridOptions, IReportGridService, IReportGridCellData } from '../reportGrid.api';
import { ReportGridController } from '../reportGridDirective';
import { register } from '@systemorph/web';
import { IReportService, IReportModelRow } from '../../../report';

export class ReportNumberColumnDisplay extends ReportColumnDisplay {
    /*@ngInject*/
    constructor(colDef: IReportGridColumnDef,
                dataGridOptions: IReportGridOptions,
                reportGrid: ReportGridController,
                protected reportService: IReportService,
                protected $interpolate: ng.IInterpolateService,
                protected reportGridService: IReportGridService) {
        super(colDef, dataGridOptions, reportGrid);
    }

    getDisplayValue(row: IReportModelRow, cellData: any) {
        if (row.RowDefinition && row.RowDefinition.RowType === "Title") return;

        var value = this.getValue(row);

        const formatExpression = this.colDef.columnNode.column.Format || row.RowDefinition && row.RowDefinition.Format;

        const format = formatExpression
            ? this.reportGrid.reportModel.parser.interpolateExpression(formatExpression, this.colDef.columnNode, row)
            : null;

        return this.reportService.formatNumber(value, format);
    }

    getCellClasses(entity: any, cellData: IReportGridCellData, isEditable: boolean): string {
        const classes: string[] = ['report-number'];

        const baseClasses = super.getCellClasses(entity, cellData, isEditable);
        if (baseClasses) classes.push(baseClasses);

        return classes.join(' ');
    }

    getTemplate(): string {
        const displayValuePath = this.getDisplayValuePath();
        const cellDataPath = this.getCellDataPath();
        return `
            <span>
                <span ng-bind="${displayValuePath}"></span>
                <i ng-class="['trend', 'fa', {'fa-caret-up': ${cellDataPath}.parameters.Trend == 'Up', 'fa-caret-down': ${cellDataPath}.parameters.Trend == 'Down'}]"></i>
            </span>`;
    }

    getDisplayWidth(displayValue: any, cellData: IReportGridCellData): number {
        const textWidth = displayValue ? this.getTextWidth(displayValue) : 0;
        return cellData.parameters.Trend ? textWidth + 10 : textWidth;
    }

    setValue(row: IReportModelRow, value: any): any {
        value = value !== null && value !== undefined ? Number(value) : null;
        return super.setValue(row, value);
    }

    getValidators(entity: any, colDef: IReportGridColumnDef): string[] {
        return ['is-decimal'];
    }

    getCsvExportValue(entity: any) {
        const value: string = this.getValue(entity);
        return ((value !== undefined && value !== null) ? value.toString() : '').replace(/\s/g, '');
    }
}

register.factory("reportNumberColumnDisplay", function() {
    return ReportNumberColumnDisplay;
});