'use strict';

import {register, IPropertyLayout, isPromise} from "@systemorph/web";
import {
    DependencyPropertyChangedHandler,
    IDependencyPropertyChangedArgs, IFormEntityContextProviderModel,
    IFormEntityDependencyPropertyChangedFeedback,
    IFormEntityProviderModel,
    InitializeExternalPropertyHandler
} from "./formEntity.api";
import {IHttpService, IPromise, IQService, IRootScopeService, IScope} from 'angular';
import {IStateParamsService, IStateService} from 'angular-ui-router';
import {Dictionary, reduce, lowerFirst, upperFirst, chain} from 'lodash';
import {FormEntityProvider} from './formEntityProvider';
import * as moment from "moment";
import {FORM_ENTITY_EVENT} from './formEntity.events';

export class FormEntityService {
    private providers: Dictionary<IPromise<FormEntityProvider>> = {};
    externalPropertyInitializers: Dictionary<InitializeExternalPropertyHandler[]> = {};

    constructor(private $http: IHttpService,
                private $q: IQService,
                private $rootScope: IRootScopeService,
                private $state: IStateService,
                private $stateParams: IStateParamsService) {
    }

    private loadProvider(name: string) {
        const params = { name };
        return this.$http.get<IFormEntityProviderModel>(`/api/formEntity/provider`, { params })
            .then(result => {
                return new FormEntityProvider(result.data)
            });
    }

    getProvider(name: string) {
        if (!this.providers[name]) {
            this.providers[name] = this.loadProvider(name);
        }

        return this.providers[name];
    }

    getProvidersByContext(context: string) {
        const params = {
            context,
        };

        return this.$http.get<IFormEntityContextProviderModel[]>(`/api/formEntity/providersByContext`, { params })
            .then(result => result.data);
    }

    selectProviderByContext(context: string, contextArgs: any) {
        const params = {
            context,
            ...contextArgs,
            $context: context
        };

        return this.$http.get<string>(`/api/formEntity/selectProviderByContext`, { params })
            .then(result => result.data);
    }

    selectProviderByState(stateName: string, stateParams: any, scope: string) {
        const params = {
            stateName,
            ...stateParams,
            scope,
            $stateName: stateName
        };

        return this.$http.get<string>(`/api/formEntity/selectProviderByState`, { params })
            .then(result => result.data);
    }

    stateParamsToFormEntity(provider: FormEntityProvider, stateParamPrefix: string, properties: string[] = null) {
        const updatedProperties: string[] = [];

        provider.getEnabledProperties()
            .filter(pl => properties ? properties.includes(pl.systemName)
                : (provider.isExternalProperty(pl) || provider.isVisibleProperty(pl)))
            .forEach(pl => {
                const stateParamName = this.getStateParamName(pl, stateParamPrefix);
                const stateParamValue = this.$stateParams[stateParamName];
                if (stateParamValue !== undefined) {
                    const value = stateParamValue ? this.stateParamToPropertyValue(pl, stateParamValue) : undefined;
                    provider.setValue(pl, value);
                    updatedProperties.push(pl.systemName);
                }
            });

        return updatedProperties;
    }

    formEntityToStateParams(provider: FormEntityProvider, stateParamPrefix: string) {
        const params = reduce<IPropertyLayout, Dictionary<any>>(provider.getEnabledProperties(), (result, pl) => {
            const value: any = provider.getValue(pl);
            const stateParamName = this.getStateParamName(pl, stateParamPrefix);
            const stateParamValue = provider.isExternalProperty(pl) || provider.isVisibleProperty(pl)
                    ? this.propertyValueToStateParam(pl, value) : null;
            result[stateParamName] = stateParamValue;
            return result;
        }, {});

        this.$state.go(this.$state.current.name, params, {notify: false});
    }

    onInitializeExternalProperties(scope: string, handler: InitializeExternalPropertyHandler) {
        if (!this.externalPropertyInitializers[scope]) {
            this.externalPropertyInitializers[scope] = [];
        }

        this.externalPropertyInitializers[scope].push(handler);
    }

    initializeExternalProperties(scope: string, directiveScope: IScope, provider: FormEntityProvider) {
        const handlers = this.externalPropertyInitializers[scope];

        if (!handlers) {
            return this.$q.when();
        }

        const waitPromises: IPromise<any>[] = [];

        handlers.forEach(handler => handler(directiveScope, provider, waitPromises));

        return this.$q.all(waitPromises);
    }

    notifyFormEntityUpdated(scope: string, provider: FormEntityProvider, newValues: Dictionary<any>) {
        this.$rootScope.$broadcast(FORM_ENTITY_EVENT.updated, scope, provider, newValues);
    }

    private getStateParamName(propertyLayout: IPropertyLayout, stateParamPrefix: string) {
        return (stateParamPrefix || '') + propertyLayout.systemName;
    }

    private propertyValueToStateParam(propertyLayout: IPropertyLayout, value: any) {
        if (propertyLayout.propertyTypeName === 'Boolean') {
            if (value === true || value === false)
                return value.toString();
        }
        // todo: fix propertyTypeName for nullable DateTime properties in platform and remove this workaround
        else if (propertyLayout.displayDirective === 'date-time-property') {
            if (value)
                return moment(value).format();
        }
        else {
            if (value !== undefined && value !== null)
                return value.toString();
        }
    }

    private stateParamToPropertyValue(propertyLayout: IPropertyLayout, stateParamValue: string) {
        if (propertyLayout.propertyTypeName === 'Boolean') {
            if (stateParamValue === 'true') return true;
            if (stateParamValue === 'false') return false;
        }
        else if (propertyLayout.propertyTypeName.match(/^Int\d+/)) {
            const value = Number(stateParamValue);
            if (isNaN(value)) return null;
            return value;
        }
        // todo: fix propertyTypeName for nullable DateTime properties in platform and remove this workaround
        else if (propertyLayout.displayDirective === 'date-time-property') {
            const value = moment(stateParamValue);
            return value.isValid() ? value.toDate() : null;
        }

        return stateParamValue;
    }

    onDependencyPropertyChanged(propertyLayout: IPropertyLayout, handler: DependencyPropertyChangedHandler) {
        return this.$rootScope.$on(FORM_ENTITY_EVENT.dependencyPropertyChanged, (event, args: IDependencyPropertyChangedArgs) => {
            if (args.provider.layout.propertyLayouts.includes(propertyLayout) && args.propertiesToRefresh.includes(propertyLayout)) {
                const ret = handler(args.changes);

                const feedbackPromise = !isPromise(ret)
                    ? this.$q.when(<IFormEntityDependencyPropertyChangedFeedback>ret)
                    : <IPromise<IFormEntityDependencyPropertyChangedFeedback>>ret;

                args.feedbackPromises.push(feedbackPromise);
            }
        });
    }

    private getHiddenConditionalProperties(provider: FormEntityProvider, properties: string[]) {
        const data = {
            providerName: provider.name,
            formEntity: provider.getFilterObject(),
            properties: properties.map(upperFirst)
        };

        return this.$http.post<string[]>(`/api/formEntity/getHiddenConditionalProperties`, data)
            .then(result => result.data.map(lowerFirst));
    }

    refreshVisibleProperties(provider: FormEntityProvider, conditionalProperties: string[]) {
        return this.getHiddenConditionalProperties(provider, conditionalProperties)
            .then(hiddenProperties => {
                const conditionalPropertyVisibility = chain(conditionalProperties)
                    .keyBy()
                    .mapValues(p => !hiddenProperties.includes(p))
                    .value();

                provider.refreshVisibleProperties(conditionalPropertyVisibility);
            });
    }
}

register.service('formEntityService', FormEntityService);