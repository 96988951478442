'use strict';

import { register, IUserService, IMenuItemScope, IUserInfoResult} from "@systemorph/web";
import { IReportStateParams } from "@systemorph/reporting-web";
import { FormEntityRegistry } from "@systemorph/form-entity-web";
import { ProcessDocumentationContextPanelController } from './processDocumentationContextPanelController';
import { IProcessDocumentationService, IProcessDocumentationQAndA } from '../processDocumentation.api';

register.directive("processDocumentationContextPanelQAndAs", () => {
    return {
        replace: true,
        restrict: 'E',
        template: require('./processDocumentationContextPanelQAndAs.html'),
        controllerAs: "ctrl",
        controller: ProcessDocumentationContextPanelQAndAsController
    }
});

export class ProcessDocumentationContextPanelQAndAsController extends ProcessDocumentationContextPanelController {
    potentialResponder: IUserInfoResult;

    constructor($scope: IMenuItemScope,
                $q: ng.IQService,
                $timeout: ng.ITimeoutService,
                $state: ng.ui.IStateService,
                $stateParams: IReportStateParams,
                formEntityRegistry: FormEntityRegistry,
                userService: IUserService,
                blockUI: ng.blockUI.IBlockUIService,
                processDocumentationService: IProcessDocumentationService) {

        super($scope,
            $q,
            $timeout,
            $state,
            $stateParams,
            formEntityRegistry,
            blockUI,
            processDocumentationService,
            "ProcessDocumentationQAndA",
            processDocumentationService.categories.qAndAs);

        userService.getCurrentUserInfo().then((userInfo: IUserInfoResult) => {
            this.potentialResponder = userInfo;
        });
    }

    isResponseEmpty(item: IProcessDocumentationQAndA): boolean {
        return this.processDocumentationService.isResponseEmpty(item);
    }

    canAnswerQuestion(item: IProcessDocumentationQAndA): boolean {
        return this.processDocumentationService.canAnswerQuestion(item, this.potentialResponder.id);
    }

    answerQuestion(item: IProcessDocumentationQAndA): ng.IPromise<any> {
        return this.processDocumentationService.answerQuestion(item, this.typeName, this.potentialResponder.id, this.reportType);
    }
}