'use strict';

import {IPropertyOfEntityScope, COMMON_EVENT, IPropertyLayout, ANGULAR_EVENT} from "@systemorph/web";
import { IFormEntity } from '../formEntity.api';
import { FORM_ENTITY_EVENT } from '../formEntity.events';

export interface IFormEntityPropertyScope extends IPropertyOfEntityScope {
    entity: IFormEntity;
}

export class BaseFormEntityPropertyController<TValue> {
    protected propertyLayout: IPropertyLayout;
    protected entity: IFormEntity;

    constructor(protected $scope: IFormEntityPropertyScope) {
        this.propertyLayout = $scope.propertyLayout;
        this.entity = $scope.entity;

        this.$scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            this.emitDestroyedEvent();
        });
    }

    protected emitInitializedEvent() {
        this.$scope.$emit(FORM_ENTITY_EVENT.propertyInitialized, this.propertyLayout, this.getValue());
    }

    protected emitDestroyedEvent() {
        this.$scope.$emit(FORM_ENTITY_EVENT.propertyDestroyed, this.propertyLayout, this.getValue());
    }

    protected getValue(): TValue {
        return <TValue>this.entity[this.propertyLayout.systemName];
    }

    protected setValue(newValue: TValue, notify = true): void {
        const oldValue: TValue = this.entity[this.propertyLayout.systemName];
        this.entity[this.propertyLayout.systemName] = newValue;
        if (notify && oldValue != newValue)
            this.$scope.$emit(COMMON_EVENT.propertyChanged, this.entity, this.propertyLayout, newValue, oldValue);
    }
}
