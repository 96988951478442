'use strict';

import { COMMON_EVENT } from "@systemorph/web";
import { IScope, ITimeoutService, blockUI } from "angular";

export abstract class PositionController {
    isReady: boolean;

    constructor(protected state: string,
                protected storage: string,
                protected positionName: string,
                protected editableTypeName: string,
                protected languagePath: string[],
                protected $scope: IScope,
                protected $timeout: ITimeoutService,
                protected blockUI: blockUI.IBlockUIService) {
        blockUI.start('Loading...');

        require.ensure(['../positionEditor'], require => {
            require('../positionEditor');

            $timeout(() => {
                this.isReady = true;
                blockUI.stop();
            });
        });

        $scope.$on(COMMON_EVENT.dataVersionChanged, (event) => {
            this.isReady = false;

            $timeout(() => {
                this.isReady = true;
            });
        });
    }
}