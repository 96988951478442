'use strict';

import { /*do not remove*/ IPromise, IScope } from 'angular';
import { IProcessDocumentationItemGrouping, IProcessDocumentationService, IProcessDocumentationItem } from '../processDocumentation.api';

export class ProcessDocumentationItemGroupController {
    // bindings
    searchText: string;
    typeName: string;
    group: IProcessDocumentationItemGrouping;

    constructor(protected $scope: IScope,
                protected processDocumentationService: IProcessDocumentationService) {
    }

    isEditable(item: IProcessDocumentationItem) {
        return this.processDocumentationService.canEdit(item);
    }

    isDeletable(item: IProcessDocumentationItem) {
        return this.processDocumentationService.canDelete(item);
    }

    delete(item: IProcessDocumentationItem) {
        return this.processDocumentationService.delete(item);
    }

    edit(item: IProcessDocumentationItem) {
        return this.processDocumentationService.edit(item, this.typeName);
    }
}