'use strict';

import { register } from '@systemorph/web';
import { AC_COMMON_EVENT } from '@systemorph/access-control-web';
import { IReportingNodesAndHierarchyModel } from "../../accessControlByReportingNode.api";
import { IScope } from 'angular';

register.directive('reportingNodeAsScopeSelector', () => {
    return {
        replace: true,
        scope: true,
        template: require('./reportingNodeAsScopeSelector.html'),
        controller: ReportingNodeAsScopeSelectorController,
        controllerAs: 'rnssCtrl',
        bindToController: {
            scopeIds: '='
        }
    }
});

export class ReportingNodeAsScopeSelectorController {
    //binded
    scopeIds: string[];

    hierarchyAndReportingNodesModel: IReportingNodesAndHierarchyModel;
    isDirtyReportingNodes: boolean;

    get isOnlyLegacyNodeSelected(): boolean {
        return this.hierarchyAndReportingNodesModel && this.hierarchyAndReportingNodesModel.selectedIds.length > 0 && this.hierarchyAndReportingNodesModel.selectedIds.every(x => !x);
    }

    constructor(private $scope: IScope) {
        this.hierarchyAndReportingNodesModel = {
            hierarchy: null,
            selectedIds: []
        };
        this.isDirtyReportingNodes = false;

        this.$scope.$on(AC_COMMON_EVENT.dirtifyScopeSelector, () => {
            this.isDirtyReportingNodes = true;
        });
    }

    reportingNodeSelectionChanged() {
        this.isDirtyReportingNodes = true;
        this.scopeIds = this.hierarchyAndReportingNodesModel.selectedIds.filter(x => !!x);
    }
}