'use strict';

import { register, COMMON_EVENT } from "@systemorph/web";
import {FormEntityService, FORM_ENTITY_EVENT, FormEntityProvider} from "@systemorph/form-entity-web";
import { PROCESS_DOCUMENTATION_EVENT } from '../processDocumentation.events';
import { IProcessDocumentationExportModel, IProcessDocumentationItem, IProcessDocumentationItemGrouping, IProcessDocumentationService, IProcessDocumentationFormEntityUpdatedProperties } from '../processDocumentation.api';

register.directive("processDocumentationItemsOverview", () => {
    return {
        replace: true,
        restrict: 'E',
        template: require('./processDocumentationItemsOverview.html'),
        controllerAs: "pdioCtrl",
        bindToController: {
            header: "=",
            isExpanded: "=",
            typeName: "=",
            groupedItemsDirective: "=",
            groupProperty: "=",
            searchText: "="
        },
        scope: true,
        controller: ProcessDocumentationItemsOverviewController
    }
});

class ProcessDocumentationItemsOverviewController {
    //bindings
    typeName: string;
    header: string;
    isExpanded: boolean;
    groupedItemsDirective: string;
    groupProperty: string;
    searchText: string;

    layoutCssClass: string;
    blockName: string;

    canBeAdded: boolean;
    items: IProcessDocumentationItem[];
    groups: IProcessDocumentationItemGrouping[];

    constructor(protected  $scope: ng.IScope,
                protected  $q: ng.IQService,
                protected  $timeout: ng.ITimeoutService,
                protected  formEntityService: FormEntityService,
                protected  blockUI: ng.blockUI.IBlockUIService,
                protected  processDocumentationService: IProcessDocumentationService) {

        this.layoutCssClass = this.typeName.replace(/([A-Z])/g, (match: string, p1: string, index: number) => { return (index !== 0 ? "-" : "") + match.toLowerCase(); });
        this.blockName = `${this.typeName.replace(/\W/g, "")}BlockName`;

        this.reload();

        this.$scope.$on(FORM_ENTITY_EVENT.updated, (event: ng.IAngularEvent, formEntityScope: string, provider: FormEntityProvider, newValues: IProcessDocumentationFormEntityUpdatedProperties) => {
            if (!formEntityScope) {
                const relevantProperties = provider.getMainPropertyNames();
                if (relevantProperties.some(p => p in newValues)) {
                    //timeout is needed to allow subscribers of this event to clear the caches
                    this.$timeout(() => {
                        this.reload();
                    });
                } else {
                    if (!(Object.keys(newValues).length === 1 && "category" in newValues)) {
                        this.applyFilteringAndGrouping();
                    }
                }
            }
        });

        this.$scope.$on(PROCESS_DOCUMENTATION_EVENT.updateProcessDocumentationItemsOverview, (event: ng.IAngularEvent) => {
            if (this.items) {
                this.applyFilteringAndGrouping();
            }
        });

        this.$scope.$on(PROCESS_DOCUMENTATION_EVENT.collectExportProcessDocumentationItems, (event: ng.IAngularEvent, exportModels: IProcessDocumentationExportModel[]) => {
            var idsNotFilterd = (this.groups || []).map(g => (g.items || []).map(i => i.Id)).reduce((ret: string[], c: string[]) => ret.concat(c), []);
            exportModels.push({
                typeName: this.typeName,
                ids: idsNotFilterd.filter((id: string, index: number) => idsNotFilterd.indexOf(id) === index)
            });
        });

        this.$scope.$on(COMMON_EVENT.commitDataToServer, (e: ng.IAngularEvent) => {
            //timeout is needed to allow subscribers of this event to clear the caches
            this.$timeout(() => {
                this.reload();
            });
        });
        this.$scope.$on(COMMON_EVENT.persistenceContextChanged, (e: ng.IAngularEvent) => {
            //timeout is needed to allow subscribers of this event to clear the caches
            this.$timeout(() => {
                this.reload();
            });
        });
        this.$scope.$on(COMMON_EVENT.dataVersionChanged, (e: ng.IAngularEvent) => {
            //timeout is needed to allow subscribers of this event to clear the caches
            this.$timeout(() => {
                this.reload();
            });
        });
    }

    private reload(): ng.IPromise<IProcessDocumentationItemGrouping[]> {
        this.blockUI.instances.get(this.blockName).start("Loading...");
        return this.$q.all([
            this.processDocumentationService.getProcessDocumentationItems(this.typeName),
            this.processDocumentationService.canCreate(this.typeName)
        ]).then((results: any[]) => {

            this.items = results[0];
            this.canBeAdded = results[1];
            return this.applyFilteringAndGrouping().finally(() => {
                setTimeout(() => { this.$scope.$digest(); }, 0);

                this.blockUI.instances.get(this.blockName).stop();
            });
        });
    }

    applyFilteringAndGrouping(): ng.IPromise<IProcessDocumentationItemGrouping[]> {
        return this.processDocumentationService.search(this.searchText, this.typeName, this.items)
            .then((filterdItems: IProcessDocumentationItem[]) => {
                return this.processDocumentationService.groupByProperty(this.typeName, this.groupProperty, filterdItems).then((groups: IProcessDocumentationItemGrouping[]) => {
                        this.groups = groups;
                        return groups;
                    });
            });
    }

    create(): ng.IPromise<any> {
        return this.processDocumentationService.openProcessDocumentationCreationDialog(this.typeName);
    }

    toggleExpand(group: IProcessDocumentationItemGrouping): void {
        this.processDocumentationService.toggleGroupExpand(group);
    }
}