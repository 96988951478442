'use strict';

import { register } from "@systemorph/web";
import * as angular from 'angular';
import { ProcessDocumentationItemGroupController } from './processDocumentationItemGroupController';
import { IProcessDocumentationService } from '../processDocumentation.api';

class ProcessDocumentationCommentGroupController extends ProcessDocumentationItemGroupController {
    constructor($scope: angular.IScope,
                processDocumentationService: IProcessDocumentationService) {

        super($scope, processDocumentationService);
    }
}

register.directive("processDocumentationCommentGroup", () => {
    return {
        template: require('./processDocumentationCommentGroup.html'),
        controllerAs: "ctrl",
        controller: ProcessDocumentationCommentGroupController,
        bindToController: {
            searchText: '@',
            typeName: '@',
            group: '='
        }
    }
});
