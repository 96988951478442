'use strict';

import { IRootScopeService, equals, IAngularEvent, IScope, IQService, IHttpService, blockUI } from 'angular';
import { DataGridController, IChartService, DATA_GRID_EVENT, IChartConfiguration } from '@systemorph/ui-web';
import { IFormEntity, FormEntityProvider, FORM_ENTITY_EVENT, FormEntityRegistry, FORM_ENTITY, FormEntityController } from "@systemorph/form-entity-web";
import { COMMON_EVENT, register, STATE, setPageTitle, ANGULAR_EVENT } from '@systemorph/web';
import { Dictionary } from 'lodash';

import {
    IReportModelSelection,
    IReportModel,
    IReportStateParams,
    IReportService,
    IReportModelColumnNode,
    IReportModelRow,
} from '../report.api';
import { IReportChartConfiguration, IReportChartService } from '../charts';
import { IReportGridOptions, IReportGridService, IBasicFormEntity, REPORT_GRID_EVENT } from '../../components';
import { REPORT_CONTROLLER_EVENT, REPORT_EVENT } from '../report.events';
import { REPORT_MODEL_RELOAD, REPORT_STATE } from '../report.constants';


const unitNames: any = {
    1000: 'Thousands',
    1000000: 'Millions',
    1000000000: 'Billions'
};

export class ReportController {
    protected reportType: string;

    protected dataGrid: DataGridController;

    reportSelection: IReportModelSelection;
    private previousCharts: IReportChartConfiguration[];
    reportModel: IReportModel;
    options: IReportGridOptions;
    isReady: boolean;
    title: string;
    errorMessage: string;

    protected formEntityCtrl: FormEntityController;
    protected formEntityProvider: FormEntityProvider;

    /*@ngInject*/
    constructor(protected $scope: IScope,
        protected $rootScope: IRootScopeService,
        protected $q: IQService,
        protected $http: IHttpService,
        protected $stateParams: IReportStateParams,
        protected formEntityRegistry: FormEntityRegistry,
        protected reportGridService: IReportGridService,
        protected reportService: IReportService,
        protected chartService: IChartService,
        protected reportChartService: IReportChartService,
        protected blockUI: blockUI.IBlockUIService) {
        this.reportType = $stateParams.reportType;

        this.initialize()
            .finally(() => this.isReady = true);
    }

    protected isOutdated() {
        return this.formEntityCtrl && this.formEntityCtrl.formEntityPropertiesCtrl.pendingChanges;
    }

    protected initialize() {
        return this.formEntityRegistry.get().then(entry => {
            this.formEntityCtrl = entry.formEntityController;
            this.formEntityProvider = entry.provider;

            const formEntity = <IBasicFormEntity>entry.provider.formEntity;

            if (formEntity.reportingNode) {
                this.$scope.$on(FORM_ENTITY_EVENT.updated, (event: IAngularEvent, formEntityScope: string, provider: FormEntityProvider, newValues: Dictionary<any>) => {
                    if (!formEntityScope) {
                        this.onFormEntityUpdated(provider.formEntity, newValues);
                    }
                });

                this.$rootScope.$on(REPORT_EVENT.refreshReportModel, (event) => {
                    this.refreshReportModelAndMenus();
                });

                this.$scope.$on(DATA_GRID_EVENT.onInitialized, (event: IAngularEvent, scope: IScope, dataGrid: DataGridController) => this.dataGrid = dataGrid);

                this.$scope.$on(REPORT_GRID_EVENT.comparisonTypeSelected, (event: IAngularEvent, type: string) => this.onComparisonTypeChanged(type));

                formEntity.comparisonType = null;// TODO: investigate either we keep this whred logic.

                this.options = this.getOptions();

                this.$scope.$on(COMMON_EVENT.dataVersionChanged, (event: IAngularEvent) => {
                    this.refreshReportModelAndMenus();
                });

                this.$scope.$on(REPORT_GRID_EVENT.selectionChanged, (event: IAngularEvent, selection: IReportModelSelection) => {
                    const isSelectedColumnChanged = !equals(this.reportSelection.selectedColumns, selection.selectedColumns);
                    const isSelectedRowChanged = !equals(this.reportSelection.selectedRows, selection.selectedRows);
                    const isSelectedCellChanged = !equals(this.reportSelection.selectedCell, selection.selectedCell);

                    this.reportSelection = selection;

                    if (isSelectedRowChanged || isSelectedColumnChanged || isSelectedCellChanged) {
                        this.refreshCharts();
                        this.$rootScope.$broadcast(REPORT_CONTROLLER_EVENT.selectionChanged, this.reportSelection);
                    }
                });

                this.$scope.$on(ANGULAR_EVENT.stateChangeStart, event => {
                    if (!event.defaultPrevented) {
                        this.reportService.reportControllerStore.reset();
                    }
                });

                this.resetSelection();

                const setUnitsPromise = this.formEntityRegistry.getFormEntity().then((formEntity: IBasicFormEntity) => {
                    this.reportService.units = formEntity.units;
                });

                return this.$q
                    .all([
                        this.refreshReportModelAndMenus(),
                        setUnitsPromise
                    ])
                    .then(() => {
                        this.reportService.reportControllerStore.set(this);
                    });
            }
            else {
                this.errorMessage = "No reporting nodes available.";
            }
        });
    }

    protected refreshCharts(forceRefresh = false) {
        let charts: IChartConfiguration[] = [];
        try {
            if (this.reportModel.Rows && this.reportModel.Rows.length > 0)
                charts = this.getCharts();
        }
        catch (e) {
            console.error(`Failed to setup charts: ${ e }`);
        }
        if (!equals(this.previousCharts, charts) || forceRefresh) {
            this.previousCharts = charts;
            this.chartService.setRightPanelCharts(charts);
        }
    }

    protected getCharts() {
        const chartConfigs = this.reportChartService.getReportModelCharts(this.reportModel, this.reportSelection);
        chartConfigs.forEach(config => {
            if (!config.options.units && (!config.options.format || config.options.format.indexOf('%') == -1))
                config.options.units = unitNames[this.reportService.units];
        });
        return chartConfigs;
    }

    protected onFormEntityUpdated(formEntity: IFormEntity, newValues: Dictionary<any>) {
        return this.formEntityRegistry.getProvider().then(provider => {
            const skipInOdataQueryProps = provider.layout.propertyLayouts
                .filter(pl => pl.editDirectiveParameters[FORM_ENTITY.skipInOdataQueryKey])
                .map(pl => pl.systemName);

            const noReload = Object.keys(newValues).every(k => skipInOdataQueryProps.indexOf(k) != -1);

            if (newValues['units'])
                this.reportService.units = newValues['units'];

            if (noReload) {
                const refreshNumbers = !!newValues['units'];
                if (refreshNumbers) {
                    this.refreshCharts(true);
                    if (this.dataGrid) {
                        this.block();
                        setTimeout(() => {
                            this.dataGrid.refreshDisplayValues()
                                .then(() => {
                                    this.$rootScope.$broadcast(REPORT_CONTROLLER_EVENT.reportGridUpdated, false);
                                })
                                .finally(() => {
                                    this.unblock();
                                });
                        });
                    }
                }
            }
            else {
                return this.refreshReportModelAndMenus();
            }
        });
    }

    private onComparisonTypeChanged(comparisonType: string) {
        this.refreshReportModelAndMenus();
    }

    refreshReportModelAndMenus() {
        this.block();

        return this.formEntityRegistry.getFormEntity().then((formEntity: IBasicFormEntity) => {
            if (!formEntity.year) {
                this.reportModel = null;
                return;
            }
            return this.$q.all([this.getReportModel(), this.getMenus()])
                .then((results: any[]) => {
                    this.reportModel = results[0];
                    this.resetSelection();
                    this.options.menus = results[1];
                    formEntity.comparisonType = this.reportModel.ComparisonType;
                    this.setTitle();
                    this.refreshCharts();
                    this.$rootScope.$broadcast(REPORT_CONTROLLER_EVENT.reportGridUpdated, true);
                });
        }).finally(() => {
            this.unblock();
        });
    }

    protected getOptions() {
        return <IReportGridOptions> {
            saveReportValue: (reportType, row, columnNode, properties, reloadReportModel) => this.saveReportValue(reportType, row, columnNode, properties, reloadReportModel)
        };
    }

    protected getFilterObject() {
        return this.formEntityRegistry.getProvider()
            .then(provider => provider.getFilterObject());
    }

    protected getReportModel() {
        return this.reportService.loadReportModel()
            .then(reportModel => {
                return reportModel;
            });
    }

    protected saveReportValue(reportType: string, row: IReportModelRow, columnNode: IReportModelColumnNode, properties: string[], reloadReportModel: string) {
        const coordinates = this.reportModel.parser.getValueCoordinates(row, columnNode, properties);

        this.block();
        return this.getSaveParams(row, columnNode)
            .then(params => {
                params.reportType = this.reportType;
                return this.$http.post<any>(`/api/ReportModel/update`, coordinates, { params })
                    .then(response => {
                        if (reloadReportModel === REPORT_MODEL_RELOAD.full) return this.refreshReportModelAndMenus().then(() => response.data);
                        return response.data
                    });
            })
            .finally(() => {
                this.unblock();
            });
    }

    protected getSaveParams(row: IReportModelRow, columnNode: IReportModelColumnNode) {
        return this.getFilterObject()
            .then(params => {
                params.ReportType = this.reportType;
                return params;
            });
    }

    private getMenus() {
        return this.getFilterObject()
            .then(params => {
                return this.reportGridService.getReportGridMenus(null, this.reportType, params);
            });
    }

    protected setTitle() {
        this.title = this.reportModel.DisplayName;
        setPageTitle(this.$scope, this.title);
    }

    private resetSelection() {
        this.reportSelection = {
            selectedColumns: [],
            selectedRows: []
        };
    }

    protected block() {
        this.blockUI.start("Loading...");
    }

    protected unblock() {
        this.blockUI.stop();
    }
}

register.state({
    name: REPORT_STATE.report,
    parent: STATE.defaultLayout,
    url: '/report/:reportType?menu',
    //"menu" param is needed to identify the report that occurs more than once in the menu
    template: require('./report.html'),
    controllerAs: 'report',
    controller: ReportController,
    suppressReloadMessage: true,
    reloadOnSearch: false
});
