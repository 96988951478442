'use strict';

import {register, ANGULAR_EVENT, DOM_EVENT} from "@systemorph/web";
import * as angular from 'angular';
import { IDataGridCellScope, IDataGridRow, IDataGridColumn } from '../dataGrid.api';
import {uniq} from 'lodash';

interface IUiGridMultiselectFilterScope extends IDataGridCellScope {

}

interface IMultiselectFilterOption {
    value: any;
    displayName: string;
    isSelected?: boolean;
}

const popoverTemplateUrl = 'components/datagrid/filters/datagridMultiselectFilter.html';

register.directive('datagridMultiselectFilter', ($templateCache: ng.ITemplateCacheService) => {
    $templateCache.put(popoverTemplateUrl, `
        <div ng-init="ctrl.onOpen()">
            <div ng-if="ctrl.hasSelectAll" class="select-all">
                <label ng-click="ctrl.onSelectAll()">
                    <input type="checkbox" ng-model="ctrl.selectAll">
                    <span>Select all</span>
                </label>
                <div ng-if="ctrl.hasSelectAll" class="divider"></div>
            </div>
            <ul>
                <li ng-repeat="option in ctrl.options">
                    <label ng-click="ctrl.onSelectOption()">
                        <input type="checkbox" ng-model="option.isSelected">
                        {{option.displayName}}
                    </label>
                </li>
            </ul>
        </div>
    `);

    return {
        scope: true,
        template: `
            <div class="datagrid-multiselect-filter" 
                 uib-popover-template="'${popoverTemplateUrl}'"
                 popover-trigger='click' 
                 popover-placement='auto bottom-right' 
                 popover-append-to-body='true'
                 popover-class="datagrid-multiselect-filter-popover {{ctrl.popoverClass}}"
                 popover-is-open="ctrl.isOpen">
                    <span class="fa fa-filter"></span>
                    <span class="filter-tick">V</span>
            </div>`,
        controller: DataGridMultiselectFilterController,
        controllerAs: 'ctrl'
    };
});

class DataGridMultiselectFilterController {
    options: IMultiselectFilterOption[];
    isDirty: boolean;
    colFilter: any;

    hasSelectAll: boolean;
    selectAll: boolean;
    isOpen: boolean;
    popoverClass: string;

    constructor(private $scope: IUiGridMultiselectFilterScope,
                private $timeout: angular.ITimeoutService,
                private rowSearcher: any,
                private $document: angular.IDocumentService,
                private gridUtil: any) {

        this.popoverClass = `datagrid-multiselect-filter-popover-${$scope.$id}`;

        this.setupEvents();

        this.colFilter = $scope.col.filters[0];

        this.colFilter.condition = (filterValues: any[], cellValue: any, row: IDataGridRow, col: IDataGridColumn) => {
            const value = col.colDef.columnDisplay.getValue(row.entity.entity);
            return filterValues.indexOf(value) !== -1;
        }
    }

    private setupEvents() {
        const self = this;

        const mousedownHandler = (e: JQueryEventObject) => {
            if (this.isOpen && !self.isMyEvent(e)) {
                self.close();
                self.$timeout();
            }
        };

        const mousewheelHandler = (e: JQueryEventObject) => {
            if (this.isOpen && !self.isMyEvent(e)) {
                self.close(false);
                self.$timeout();
            }
        };

        this.$document.on(DOM_EVENT.mousedown, mousedownHandler);
        this.$document.on(DOM_EVENT.mousewheel, mousewheelHandler);

        this.$scope.$on(ANGULAR_EVENT.scopeDestroy, () => {
            this.$document.off(DOM_EVENT.mousedown, mousedownHandler);
            this.$document.off(DOM_EVENT.mousewheel, mousewheelHandler);
        });
    }

    private isMyEvent(e: JQueryEventObject) {
        let parent = e.target;

        while (parent = parent.parentElement) {
            if ($(parent).hasClass(this.popoverClass))
                return true;
        }

        return false;
    }

    onOpen() {
        if (this.colFilter.optionsLength === undefined) {
            this.colFilter.optionsLength = this.getValues(this.$scope.col.grid.rows).length;
        }

        const filteredRows = this.getFilteredRows();

        const values = this.getValues(filteredRows).sort((a: any, b: any) => {
            if (a == null) return -1;
            if (b == null) return 1;
            if (typeof(a) == 'string') return a.localeCompare(b);
            if (typeof(a) == 'number') return a - b;
            return 0;
        });

        this.options = values.map((value: any) => {
            return {
                value,
                displayName: value === null ? 'null' : value,
                isSelected: this.colFilter.term ? this.colFilter.term.indexOf(value) !== -1 : true
            };
        });

        this.hasSelectAll = this.options.length > 1;
        this.setSelectAll();

        if (this.colFilter.optionsLength === undefined)
            this.colFilter.optionsLength = this.options.length;
    }

    apply() {
        const selected = this.options.filter(option => option.isSelected);

        this.colFilter.term = selected.length > 0 && selected.length < this.colFilter.optionsLength
            ? selected.map(v => v.value) : null;

        this.isDirty = false;
    }

    close(apply = true) {
        if (apply && this.isDirty)
            this.apply();

        this.isOpen = false;
        this.options = null;
    }

    onSelectOption() {
        this.setSelectAll();
        this.isDirty = true;
    }

    onSelectAll() {
        this.options.forEach(option => {
            option.isSelected = this.selectAll;
        });
        this.isDirty = true;
    }

    setSelectAll() {
        this.selectAll = this.options.map(option => option.isSelected).every(x => x);
    }

    private getValues(rows: IDataGridRow[]) {
        const entities = rows.map(r => r.entity.entity);
        return uniq(entities.map(entity => this.$scope.col.colDef.columnDisplay.getValue(entity)));
    }

    // returns rows filtered by other columns
    // inspired by ui-grid's rowSearcher.search method
    private getFilteredRows() {
        const grid = this.$scope.col.grid;
        const rows = grid.rows;
        const columns = this.$scope.col.grid.columns.filter(c => c !== this.$scope.col);

        const filterData: any = [];

        for (let i = 0; i < columns.length; i++) {
            const col = columns[i];
            if (typeof(col.filters) !== 'undefined' && this.hasTerm(col.filters)) {
                filterData.push({ col: col, filters: this.rowSearcher.setupFilters(col.filters) });
            }
        }

        if (filterData.length > 0) {
            return rows.filter(row => {
                for (var j = 0; j < filterData.length; j++){
                    if (!this.rowSearcher.searchColumn(grid, row, filterData[j].col, filterData[j].filters))
                        return false
                }

                return true;
            });
        }

        return rows;
    }

    private hasTerm(filters: any[]) {
        var hasTerm = false;

        filters.forEach(filter => {
            if (!this.gridUtil.isNullOrUndefined(filter.term) && filter.term !== '' || filter.noTerm) {
                hasTerm = true;
            }
        });

        return hasTerm;
    }
}
