'use strict';

import {register } from "@systemorph/web";

register.directive('isDecimal', () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ngModelController: ng.INgModelController) {
            ngModelController.$validators['isDecimal'] = (value: string) => {
                if (!value || !value.toString().trim()) return true;
                return /^-?\d+[,.]?(\d+)?$/.test(value);
            };

            ngModelController.$parsers.unshift((value: string) => {
                return value ? value.toString().replace(/,/g, '.') : null;
            });
        }
    };
});
