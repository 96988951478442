'use strict';

import { ITypeLayout, ISystemorphEntity, IPropertyLayout } from '@systemorph/web';
import {FormEntityProvider} from './formEntityProvider';
import {IPromise, IScope} from 'angular';
import {FormEntityController} from './formEntityDirective';
import {Dictionary} from 'lodash';

export interface IFormEntityScope extends IScope {
    formEntityCtrl: FormEntityController;
}

export interface IFormEntityProviderModel {
    name: string;
    formEntity: IFormEntity;
    layout: ITypeLayout;
    conditionalProperties: string[];
}

export interface IFormEntityContextProviderModel {
    name: string;
    displayName: string;
    isDefault: boolean;
}

export interface IFormEntity extends ISystemorphEntity {
    __provider: FormEntityProvider;
}

export interface IFormEntityDependencyPropertyChangedFeedback {
    propertyLayout: IPropertyLayout;
    newValue: any;
    oldValue: any;
    // is added or removed conditional property
    isAdded?: boolean;
    isRemoved?: boolean;
}

export interface IDependencyPropertyChangedArgs {
    provider: FormEntityProvider,
    changes: Dictionary<IFormEntityDependencyPropertyChangedFeedback>;
    feedbackPromises: IPromise<IFormEntityDependencyPropertyChangedFeedback>[];
    propertiesToRefresh: IPropertyLayout[];
}

export type InitializeExternalPropertyHandler
    = (formEntityScope: IScope, provider: FormEntityProvider, waitPromises: IPromise<any>[]) => void;

export type DependencyPropertyChangedHandler = (changes: Dictionary<IFormEntityDependencyPropertyChangedFeedback>)
    => IFormEntityDependencyPropertyChangedFeedback | IPromise<IFormEntityDependencyPropertyChangedFeedback>;

export interface IDependencyCycleArgs {
    affectedProperties: IPropertyLayout[];
    feedback: IFormEntityDependencyPropertyChangedFeedback[];
    changes: Dictionary<IFormEntityDependencyPropertyChangedFeedback>;
    checkedConditionalProperties: string[];
    isInitializing: boolean;
    isConditionalPropertiesCycle: boolean;
}