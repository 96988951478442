import {IPropertyLayout, PROPERTY_TYPE_META, ITypeLayout, arrayToDictionary} from '@systemorph/web';
import {IFormEntity, IFormEntityProviderModel} from './formEntity.api';
import {FORM_ENTITY} from './formEntity.constants';
import {lowerFirst, Dictionary, flatten, camelCase, keys, pickBy, difference, remove, isEmpty, uniq, chain} from 'lodash';

export class FormEntityProvider {
    name: string;
    formEntity: IFormEntity;
    layout: ITypeLayout;

    propertyLayoutDict: Dictionary<IPropertyLayout>;
    visibleProperties: string[];
    conditionalProperties: string[];

    constructor(private providerModel: IFormEntityProviderModel) {
        this.name = providerModel.name;
        this.formEntity = providerModel.formEntity;
        this.layout = providerModel.layout;
        this.conditionalProperties = providerModel.conditionalProperties.map(lowerFirst);

        this.layout.propertyLayouts.forEach((pl: IPropertyLayout) => {
            pl.systemName = lowerFirst(pl.systemName);
        });

        this.initVisibleProperties();

        this.propertyLayoutDict = arrayToDictionary(this.providerModel.layout.propertyLayouts, p => p.systemName);

        // ---tmp
        this.formEntity.getStateParamsOrCurrentValue = (pl: IPropertyLayout) => this.getValue(pl);
    }

    isDeferredApply = () => this.layout.editDirectiveParameters[FORM_ENTITY.deferredApplyKey];

    // legacy, should be revised
    getFilterObject(propertyNames?: string[]): any {
        const ret: any = {};

        this.getEnabledProperties()
            .filter(pl => !pl.editDirectiveParameters[FORM_ENTITY.skipInOdataQueryKey])
            .filter(pl => this.isExternalProperty(pl) || this.isVisibleProperty(pl))
            .forEach(pl => {
                if (propertyNames &&
                    !propertyNames.some(pname => {
                        const name = pname.toLowerCase();
                        const candidateName = pl.systemName.toLowerCase();

                        return name === candidateName || name + "id" === candidateName;
                    })) {
                    return;
                }

                var value: any = this.formEntity[pl.systemName];
                if (!!value) {
                    if (pl.propertyTypeMeta === PROPERTY_TYPE_META.dimension) {
                        ret[`${pl.systemName}Id`] = value.Id;
                    } else {
                        ret[pl.systemName] = value;
                    }
                }
            });

        ret[FORM_ENTITY.providerKey] = this.name;

        return ret;
    }

    getFilterObjectMainPropertiesOnly() {
        return this.getFilterObject(this.getMainPropertyNames());
    }

    getMainPropertyNames() {
        return this.layout.propertyLayouts
            .filter(pl => pl.editDirectiveParameters[FORM_ENTITY.mainParameterKey])
            .map(pl => pl.systemName);
    }

    getValue(propertyLayout: IPropertyLayout) {
        return propertyLayout.propertyTypeMeta === PROPERTY_TYPE_META.dimension
            ? this.formEntity[propertyLayout.systemName] && this.formEntity[propertyLayout.systemName].SystemName
            : this.formEntity[propertyLayout.systemName];
    }

    setValue(property: string | IPropertyLayout, value: any) {
        const propertyLayout = typeof(property) == 'string' ? this.propertyLayoutDict[property] : property;

        this.formEntity[propertyLayout.systemName]
            = propertyLayout.propertyTypeMeta === PROPERTY_TYPE_META.dimension ? { SystemName: value} : value;
    }

    getValues() {
        const result: Dictionary<any> = {};

        this.layout.propertyLayouts
            .filter(pl => pl.isVisible && pl.isSimple)
            .forEach(pl => {
                result[pl.systemName] = this.getValue(pl);
            });

        return result;
    }

    setValues(values: Dictionary<any>) {
        const propertyNames = Object.keys(values);

        this.layout.propertyLayouts.filter(pl => propertyNames.indexOf(pl.systemName) !== -1)
            .forEach(pl => {
                this.setValue(pl, values[pl.systemName]);
            });
    }

    getEnabledProperties() {
        return this.layout.propertyLayouts
            .filter(pl => pl.isVisible && pl.isEditable && pl.isSimple);
    }

    getInternalProperties() {
        return this.getEnabledProperties()
            .filter(pl => !this.isExternalProperty(pl));
    }

    getConditionalProperties() {
        return this.getInternalProperties()
            .filter(pl => this.isConditionalProperty(pl));
    }

    getExternalProperties() {
        return this.getEnabledProperties()
            .filter(pl => this.isExternalProperty(pl));
    }

    isVisibleProperty(propertyLayout: IPropertyLayout) {
        return this.visibleProperties.includes(propertyLayout.systemName);
    }

    isExternalProperty(propertyLayout: IPropertyLayout) {
        return propertyLayout.editDirectiveParameters[FORM_ENTITY.isExternalPropertyKey];
    }

    getDependencies(propertyLayout: IPropertyLayout, recursive: boolean): IPropertyLayout[] {
        const dependenciesParameter: string[] =
            propertyLayout.editDirectiveParameters[FORM_ENTITY.dependenciesKey];

        if (!dependenciesParameter) {
            return [];
        }

        const directDependencies = dependenciesParameter.map(d => this.propertyLayoutDict[camelCase(d)]);

        if (!recursive) {
            return directDependencies;
        }

        const indirectDependencies = flatten(directDependencies.map(d => this.getDependencies(d, true)));

        return directDependencies.concat(indirectDependencies);
    }

    getDependentProperties(propertyLayout: IPropertyLayout, recursive: boolean) {
        return this.getEnabledProperties()
            .filter(p => {
                return this.getDependencies(p, recursive)
                    .includes(propertyLayout)
            });
    }

    getAffectedProperties(changedProperties: IPropertyLayout[]) {
        const dependentProperties = changedProperties.map(pl => this.getDependentProperties(pl, true));

        return chain([changedProperties, ...dependentProperties])
            .flatten()
            .uniq()
            .value();
    }

    refreshVisibleProperties(conditionalPropertyVisibility: Dictionary<boolean>) {
        const propertiesToRemove = keys(pickBy(conditionalPropertyVisibility, x => !x));
        const propertiesToAdd = keys(pickBy(conditionalPropertyVisibility, x => x));

        remove(this.visibleProperties, p => propertiesToRemove.includes(p));
        this.visibleProperties.push(...propertiesToAdd);
    }

    private initVisibleProperties() {
        this.visibleProperties = this.getInternalProperties()
            .filter(pl => !this.isConditionalProperty(pl))
            .map(pl => pl.systemName);
    }

    private isConditionalProperty(propertyLayout: IPropertyLayout) {
        return this.conditionalProperties.includes(propertyLayout.systemName);
    }
}