'use strict';

import { register, IColumnFilterDirectiveService, IGridFilterOptions, IColumnFilterScope } from '@systemorph/web';

class PlainStringFilterService implements IColumnFilterDirectiveService {
    getIcon = (): string => {
        return "V";
    };

    getOdataPredicate = (filterOptions: IGridFilterOptions): string => {
        return this.getOdataPredicateAfterEdit(filterOptions);
    };

    getOdataPredicateAfterEdit = (filterOptions: IGridFilterOptions): string => {
        var value = filterOptions.fieldValues[0];
        var valueStr = (value) ? "'" + value.replace(/[\[\]']+/g, '') + "'" : 'null';
        var predicate = filterOptions.propertyLayout.systemName + " eq " + valueStr;
        return predicate;
    };

    getJaydataPredicate = (filterOptions: IGridFilterOptions): string => {
        var value = filterOptions.fieldValues[0];
        var valueStr = (value) ? "'" + value.replace(/[\[\]']+/g, '') + "'" : 'null';
        return "it." + filterOptions.propertyLayout.systemName + " == " + valueStr;
    };
}

register.service('plainStringFilterService', () => {
    return new PlainStringFilterService();
});

register.directive("plainStringFilter", () => {
    return {
        restrict: 'AE',
        replace: true,
        scope: false,
        template: `<div><search-term></<search-term></div>`
    }
});