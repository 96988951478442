'use strict';

import { register, getOrAddCachedValue } from "@systemorph/web";
import { FormEntityRegistry } from "@systemorph/form-entity-web";
import { IReportHierarchyItem, IReportHierarchyService } from './report.api';
import { IHttpService } from 'angular';

class ReportHierarchyService implements IReportHierarchyService {
    private cache: ng.ICacheObject;
    /*@ngInject*/
    constructor(private $http: IHttpService,
        private $q: ng.IQService,
        private formEntityRegistry: FormEntityRegistry,
        $cacheFactory: ng.ICacheFactoryService) {
        this.cache = $cacheFactory("ReportHierarchyService");
    }

    getReportsHierarchy(): ng.IPromise<IReportHierarchyItem[]> {
        var cacheKey = "ReportsHierarchy";

        return getOrAddCachedValue(this.cache, cacheKey, () => {
            return this.$http.get<IReportHierarchyItem[]>('/api/reportUtils/reportHierarchy')
                .then(result => result.data);
        });
    }

    getEditableReportsHierarchy(): ng.IPromise<IReportHierarchyItem[]> {
        return this.$q.all([this.formEntityRegistry.getFilterObjectMainPropertiesOnly()]).then((formEntityResults: any[]) => {
            var filterObject: any = formEntityResults[0];

            var cacheKey = "EditableReportsHierarchy" + Object.keys(filterObject).map((k: string) => `${k}:${filterObject[k] || ''}`).join("_");

            return getOrAddCachedValue(this.cache, cacheKey, () => {
                return this.$http.get<IReportHierarchyItem[]>('/api/reportUtils/editableReportHierarchy', { params: filterObject })
                    .then(result => result.data);
            });
        });
    }
}

register.service("reportHierarchyService", ReportHierarchyService);