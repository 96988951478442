'use strict';

import { isObject, isNumber, merge, IAugmentedJQuery } from 'angular';
import { register } from "@systemorph/web";
import { IDataGridCellParams, BaseCellController, IChartService, CHART_AXIS_TYPE } from "@systemorph/ui-web";
import { ReportColumnDisplay } from './reportColumnDisplay';
import { IReportGridColumnDef, IReportGridOptions, IReportGridCellScope, IReportGridDataGridScope } from '../reportGrid.api';
import { ReportGridController } from '../reportGridDirective';
import { IReportColumnChartRules, IReportService, IReportChartService } from '../../../report';

export class ChartColumnDisplay extends ReportColumnDisplay {
    /*@ngInject*/
    constructor(colDef: IReportGridColumnDef,
        dataGridOptions: IReportGridOptions,
                reportGrid: ReportGridController) {
        super(colDef, dataGridOptions, reportGrid);

        colDef.exporterSuppressExport = true;
    }

    getCellClasses(entity: any, cellData: any, isEditable: boolean): string {
        const classes = super.getCellClasses(entity, cellData, isEditable);
        return `${ classes } cell-chart`;
    }

    getCellData(entity: any) {
        const cellChartEnabled = this.reportGrid.reportModel.parser.evaluateExpression<boolean>(
            this.colDef.columnNode.column.Chart.cellChartCondition,
            this.colDef.columnNode,
            entity);

        return {
            cellChartEnabled
        }
    }

    getTemplate(): string {
        return `<div ng-if="${ this.getCellDataPath() }.cellChartEnabled" report-grid-cell-chart></div>`;
    }
}

register.factory("chartColumnDisplay", () => ChartColumnDisplay);

register.directive("reportGridCellChart", function () {
    return {
        restrict: "AE",
        replace: true,
        template: `
            <div class="cell-chart-container">
                <canvas></canvas>
            </div>
        `,
        controller: CellChartController,
        controllerAs: 'chart'
    };
});

export interface ICellChartScope extends IReportGridCellScope {
    chart: CellChartController;
}

export class CellChartController extends BaseCellController<ChartColumnDisplay, IDataGridCellParams, any, any, IReportGridColumnDef, ICellChartScope> {
    private reportGrid: ReportGridController;
    private chart: Chart;
    private canvas: IAugmentedJQuery;
    private colChartRules: IReportColumnChartRules;

    constructor($scope: ICellChartScope,
        $element: ng.IAugmentedJQuery,
        private chartService: IChartService,
        private reportService: IReportService,
        private reportChartService: IReportChartService) {
        super($scope);

        this.reportGrid = (<IReportGridDataGridScope> $scope.grid.appScope).reportGrid;
        this.canvas = $element.find('canvas');
        this.colChartRules = this.scope.col.colDef.columnNode.column.Chart;

        this.scope.$watch(() => this.scope.row, () => {
            this.renderCellChart();
        });
    }

    private renderCellChart() {
        if (this.chart) this.chart.destroy();
        this.chart = this.chartService.renderChart(this.canvas, this.getCellChartConfig());
    }

    private getCellChartConfig() {
        const config = this.getChartConfig();

        merge(config, {
            options: {
                title: {
                    display: false
                },
                animation: false,
                maintainAspectRatio: false,
                layout: {
                    padding: 2
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false,
                },
                scales: {
                    yAxes: [{
                        display: false,
                        ticks: {}
                    }],
                    xAxes: [{
                        display: false,
                        ticks: {}
                    }]
                }
            },
            plugins: null
        });

        // set min/max values explicitly to get rid of scale paddings
        const axes = [...config.options.scales.xAxes, ...config.options.scales.yAxes];
        axes.forEach(axis => {
            if (axis.type === CHART_AXIS_TYPE.linear) {
                if (config.data.datasets) {
                    const dataValueKey = config.options.scales.xAxes.indexOf(axis) != -1 ? 'x' : 'y';
                    const datas = config.data.datasets.map(d => d.data).filter(d => !!d);
                    const values = [].concat(...datas).map<number>(v => isObject(v) ? (<any> v)[dataValueKey] : v)
                        .filter(v => isNumber(v));
                    if (values.length) {
                        let min = Math.min(...values);
                        let max = Math.max(...values);
                        if (min == max) {
                            min = min - min * 0.01;
                            max = max + max * 0.01;
                        }
                        axis.ticks.min = min;
                        axis.ticks.max = max;
                    }
                    axis.ticks.suggestedMin = null;
                    axis.ticks.suggestedMax = null;
                }
            }
        });

        if (config.data && config.data.datasets) {
            config.data.datasets.forEach(ds => {
                merge(ds, {
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    lineTension: 0
                })
            });
        }

        return config;
    }

    private getChartConfig() {
        if (!this.colChartRules.cellChart)
            throw 'No cell chart configuration found';

        const cell = {
            row: this.scope.row.entity.entity,
            columnNode: this.scope.col.colDef.columnNode
        };

        return this.reportChartService.processChartConfiguration(this.colChartRules.cellChart, this.reportGrid.reportModel, false, this.reportGrid.selection, cell);
    }
}