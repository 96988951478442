import {
    IReportModel,
    IReportModelColumnNode,
    IReportModelRow,
    IReportModelColumnGroup,
    IReportModelGroupNode,
    IReportModelColumnDefinition,
    IReportModelNode,
} from "./report.api";
import {
    arraySum,
    filterCollection,
    isNullOrUndefined,
    normalizeComponentName, parseIniString
} from "./helpers";
import { IPredicate } from "@systemorph/ui-web/app/utils/utils.api";
import { DATA_GRID_COLUMN_TYPE } from "@systemorph/ui-web/app/components/dataGrid/dataGrid.constants";
import { ReportBindingContext } from "./reportBindingContext";
import { evalExpression } from "@systemorph/ui-web/app/utils/eval";
import { IReportGridValueCoordinates } from "../components/reportGrid/reportGrid.api";
import { REPORT_GRID_COLUMN_DISPLAY } from "../components/reportGrid/reportGrid.constants";
import { REPORT_MODEL_NODE_TYPE, REPORT_MODEL_ROW_TYPE, SPECIAL_NUMBER } from "./report.constants";

export class ReportModelParser {
    private defaultColumnValueExpression = 'entity[column.columnDefinition.SystemName]';

    private columnTree: IReportModelNode[];
    private columnNodes: IReportModelColumnNode[];
    private context: ReportBindingContext;
    private groupNodes: IReportModelGroupNode[];

    constructor(private reportModel: IReportModel, private args: any) {
        this.init();
    }

    private stripTags(str: string) {
        return str.replace(/\<br\s*\/?\>/ig, ' ');
    }

    private init() {
        this.reportModel.args = this.args;

        if (!this.reportModel.ColumnDefinitions)
            this.reportModel.ColumnDefinitions = [];

        if (!this.reportModel.Rows)
            this.reportModel.Rows = [];

        // filling missing group levels
        if (this.reportModel.Groups) {
            this.reportModel.Groups.forEach(g => {
                g.DisplayNameHtml = g.DisplayName;
                if (g.DisplayName) {
                    g.DisplayName = this.stripTags(g.DisplayName);
                }

                if (isNullOrUndefined(g.Level)) g.Level = 0;
            });
        }

        if (!this.reportModel.Layout) this.reportModel.Layout = [0];

        if (this.reportModel.ColumnDefinitions) {
            this.normalizeColumns(this.reportModel.ColumnDefinitions);
            this.autoDetectColumnTypeByValue();
            this.setAuditTrend(this.reportModel.ColumnDefinitions);
        }

        this.parseCharts();

        this.reportModel.context = this.getContext();
    }

    private normalizeColumns(columns: IReportModelColumnDefinition[]) {
        columns.forEach(column => {
            column.DisplayNameHtml = column.DisplayName;

            // auto-detecting row headers
            if (column.Value === 'RowDefinition.DisplayName' || column.Value == 'row.rowDefinition.DisplayName') {
                column.ColumnType = DATA_GRID_COLUMN_TYPE.rowHeader;
            }

            if (column.DisplayName) {
                column.DisplayName = this.stripTags(column.DisplayName);
            }

            if (column.Display) {
                column.Display = normalizeComponentName(column.Display, "ColumnDisplay");
            }

            column.Parameters = typeof(column.Parameters) == 'string'
                ? parseIniString(<string>column.Parameters) : column.Parameters || {};

            column.EditorParameters = typeof(column.EditorParameters) == 'string'
                ? parseIniString(<string>column.EditorParameters) : column.EditorParameters || {};
        });
    }

    private setAuditTrend(columns: IReportModelColumnDefinition[]) {
        columns.forEach(column => {
            if (this.reportModel.args && this.reportModel.args.auditType && column.ColumnType === DATA_GRID_COLUMN_TYPE.number)
                column.Parameters.Trend = "value > 0 ? 'Up' : value < 0 ? 'Down' : undefined";
        });
    }

    private getGroupTree(groups: IReportModelColumnGroup[], level = 0, parent?: IReportModelGroupNode): IReportModelGroupNode[] {
        var sortKey = 0;

        const levelGroups = groups.filter(g => (g.Level || 0) === level);

        if (!levelGroups.length) return null;

        return levelGroups
            .filter(g => !g.Parents || (parent && g.Parents.indexOf(parent.group.SystemName) != -1))
            .map(group => {
                const path = parent ? [...parent.path, group] : [group];
                const groupNode = <IReportModelGroupNode> {
                    type: REPORT_MODEL_NODE_TYPE.group,
                    systemName: path.map(g => g.SystemName).join('__'),
                    path,
                    group,
                    parent,
                    children: null,
                    leafsCount: null,
                    sortKeysByLevel: parent ? [...parent.sortKeysByLevel, sortKey] : [sortKey]
                };
                groupNode.children = this.getGroupTree(groups, level + 1, groupNode);
                groupNode.isLeafComparisonGroup = group.IsComparisonGroup
                    && (!groupNode.children || groupNode.children.every((g: IReportModelGroupNode) => !g.group.IsComparisonGroup));
                groupNode.leafsCount = groupNode.children ? arraySum(groupNode.children.map(n => n.leafsCount)) : 0;
                sortKey++;
                return groupNode;
            });
    }

    private getColumnTree(groupFilter?: IPredicate<IReportModelColumnGroup>, columnFilter?: IPredicate<IReportModelColumnDefinition>): IReportModelNode[] {
        if (!this.columnTree) {
            const groups = this.reportModel.Groups && (groupFilter ? this.reportModel.Groups.filter(groupFilter) : this.reportModel.Groups);
            const columns = columnFilter ? this.reportModel.ColumnDefinitions.filter(columnFilter) : this.reportModel.ColumnDefinitions;
            const tree: IReportModelNode[] = [];
            const hasComparisonGroups = groups && groups.some(g => g.IsComparisonGroup);

            if (groups) {
                const groupTree = this.getGroupTree(groups);
                const leafGroupNodes = this.collectNodes<IReportModelGroupNode>(groupTree, n => !n.children);
                const groupedColumns = columns
                    .filter(column => column.ColumnType !== DATA_GRID_COLUMN_TYPE.rowHeader && (hasComparisonGroups || column.IsGrouped));

                leafGroupNodes.forEach(leafGroupNode => {
                    leafGroupNode.children = this.createColumnNodes(leafGroupNode, groupedColumns);
                    let parent = leafGroupNode;
                    while (parent) {
                        parent.leafsCount += leafGroupNode.children.length;
                        parent = parent.parent;
                    }
                });

                tree.push(...groupTree);
            }

            const rootColumns = groups
                ? columns.filter(column => column.ColumnType === DATA_GRID_COLUMN_TYPE.rowHeader || (!hasComparisonGroups && !column.IsGrouped))
                : columns;

            tree.splice(0, 0, ...this.createColumnNodes(null, rootColumns));

            this.columnTree = tree;
        }

        return this.columnTree;
    }

    private createColumnNodes(parent: IReportModelGroupNode, columns: IReportModelColumnDefinition[]): IReportModelColumnNode[] {
        let sortKey = 0;

        return columns
            .map(column => {
                const displayNameParts = this.getColumnDisplayNameParts(parent, column);
                const displayNameHtmlParts = this.getColumnDisplayNameParts(parent, column, true);
                const columnNode = <IReportModelColumnNode> {
                    type: REPORT_MODEL_NODE_TYPE.column,
                    systemName: (parent ? [...parent.path.map(g => g.SystemName), column.SystemName] : [column.SystemName]).join('__'),
                    displayName: displayNameParts.join(' '),
                    displayNameHtml: displayNameHtmlParts.join('<br>'),
                    column,
                    parent,
                    sortKeysByLevel: parent ? [...parent.sortKeysByLevel, sortKey] : [sortKey],
                    leafsCount: 1
                };
                sortKey++;
                return columnNode;
            });
    }

    collectNodes<T extends IReportModelNode>(tree: IReportModelNode[], filter?: IPredicate<IReportModelNode>) {
        let nodes: T[] = [];
        tree.forEach(n => {
            if (!filter || filter(n))
                nodes.push(<T> n);

            if (n.children)
                nodes.push(...this.collectNodes<T>(n.children, filter));
        });
        return nodes;
    }

    private getColumnDisplayNameParts(parent: IReportModelGroupNode, column: IReportModelColumnDefinition, html = false) {
        const layout = this.reportModel.Layout || [0];
        const displayNameParts = html ? (parent ? [...parent.path.map(g => g.DisplayNameHtml), column.DisplayNameHtml] : [column.DisplayNameHtml])
            : (parent ? [...parent.path.map(g => g.DisplayName), column.DisplayName] : [column.DisplayName]);
        const orderedParts = layout.map(l => displayNameParts[l]).filter(n => !!n);
        let parts = orderedParts;

        if (this.reportModel.ComparisonType && column.ColumnType !== DATA_GRID_COLUMN_TYPE.rowHeader) {
            const comparisonLabel = [orderedParts[orderedParts.length - 1], orderedParts[orderedParts.length - 2]]
                .filter(x => !!x)
                .join(' ');
            parts = [...orderedParts.slice(0, orderedParts.length - 2), comparisonLabel];
        }

        return parts;
    }

    filterRows(args: any[]) {
        if (!args.length) return this.reportModel.Rows;
        return filterCollection(this.reportModel.Rows, row => row.RowDefinition.SystemName, args);
    }

    filterColumnNodes(columnNodes: IReportModelColumnNode[], args: any[]) {
        if (!args.length) return columnNodes;
        if (typeof (args[0]) == 'function') return columnNodes.filter(args[0]);
        return filterCollection(columnNodes, columnNode => columnNode.column.SystemName, args);
    }

    getCellValue(columnNode: IReportModelColumnNode, row: IReportModelRow): any {
        return this.evaluateExpression(columnNode.column.Value || this.defaultColumnValueExpression, columnNode, row, false);
    }

    setCellValue(columnNode: IReportModelColumnNode, row: IReportModelRow, value: any) {
        if (columnNode && !row || !columnNode && row)
            throw 'Failed to get cell context - row or column is missing in arguments.';

        const expression = columnNode.column.Value || this.defaultColumnValueExpression;

        this.evaluateExpression(expression, columnNode, row, false, false, value);
    }

    evaluateExpression<T>(expression: string, columnNode?: IReportModelColumnNode, row?: IReportModelRow, evalCellValue = true, silent = true, assignValue?: any) {
        if (typeof (expression) !== 'string')
            throw 'Wrong argument type, string expression expected.';

        if (columnNode && !row || !columnNode && row)
            throw 'Failed to get cell context - row or column is missing in arguments.';

        if (columnNode)
            this.getContext().setCell(columnNode, row, evalCellValue);

        const evalArgs: any[] = [expression, this.getContext(), silent];

        // done this way to make it possible to pass undefined as assignValue
        if (arguments.length == 6)
            evalArgs.push(assignValue);

        return evalExpression.apply(null, evalArgs);
    }

    // 'abc{{myvar + 1}}def{{entity.prop1}}gij' => 'abc' + (myvar + 1) + 'def' + (entity.prop1) + 'gij'
    interpolateExpression(expression: string, columnNode?: IReportModelColumnNode, row?: IReportModelRow) {
        const expr = "'" + expression.replace(/\{\{(.+?)\}\}/g, this.interpolationMatchReplacer) + "'";
        return this.evaluateExpression<string>(expr, columnNode, row, true);
    }

    private interpolationMatchReplacer(match: string, expression: string) {
        return `' + ((${ expression }) || '') + '`;
    }

    // check if the two column nodes are instances of the same column in different comparison contexts
    areSameColumnInstances(c1: IReportModelColumnNode, c2: IReportModelColumnNode) {
        if (c1.column.SystemName !== c2.column.SystemName) return false;
        if (c1.parent && !c2.parent || !c1.parent && c2.parent) return false;
        if (!c1.parent) return true;
        return c1.parent.path
            .filter(g => !g.IsComparisonGroup)
            .every(g => g.SystemName === c2.parent.path[g.Level].SystemName);
    }

    getValueCoordinates(row: IReportModelRow, columnNode: IReportModelColumnNode, properties?: string[]) {
        const column = columnNode.column;
        const groupNode = columnNode.parent;

        const coordinates = <IReportGridValueCoordinates> {
            group: groupNode ? groupNode.group.SystemName : null, // is this needed?, entity seems to be enough
            column: column.SystemName,
            row: row.RowDefinition.SystemName,
            entity: this.getEntity(row, groupNode),
            properties: properties || [column.SystemName],
        };

        return coordinates;
    }

    getEntity(row: IReportModelRow, groupNode?: IReportModelGroupNode) {
        return row.RowDefinition.RowType !== REPORT_MODEL_ROW_TYPE.title && groupNode
            ? groupNode.path.reduce((groupInstance, group) => groupInstance && groupInstance[group.SystemName], row.Row)
            : row.Row;
    }

    getComparisonGroupByColumnNode(columnNode: IReportModelColumnNode) {
        let parent = columnNode.parent;
        while (parent && !parent.group.IsComparisonGroup) {
            parent = parent.parent;
        }
        return parent;
    }

    private autoDetectColumnTypeByValue() {
        if (this.reportModel.Rows && this.reportModel.Rows.length > 0) {
            this.getColumnNodes().forEach(columnNode => {
                if (columnNode.column.ColumnType) {
                    return;
                }

                for (let i = 0; i < this.reportModel.Rows.length; i++) {
                    const row = this.reportModel.Rows[i];

                    if (row.RowDefinition.RowType === REPORT_MODEL_ROW_TYPE.title) {
                        continue;
                    }

                    const value = this.getCellValue(columnNode, row);

                    if (isNullOrUndefined(value)) {
                        continue;
                    }

                    if (typeof(value) == 'number'
                        || value === SPECIAL_NUMBER.nan
                        || value === SPECIAL_NUMBER.negativeInfinity
                        || value === SPECIAL_NUMBER.infinity) {
                        columnNode.column.ColumnType = DATA_GRID_COLUMN_TYPE.number;
                    }

                    if (value === true || value === false) {
                        columnNode.column.ColumnType = DATA_GRID_COLUMN_TYPE.boolean;
                    }

                    if (columnNode.column.ColumnType) {
                        break;
                    }
                }
            });
        }
    }

    private parseCharts() {
        try {
            if (this.reportModel.Chart) {
                const reportModelChart: any = this.reportModel.Chart;
                if (reportModelChart.default || reportModelChart.columnSelection || reportModelChart.rowSelection) {
                    this.reportModel.Chart = reportModelChart;
                    if (reportModelChart.default
                        && reportModelChart.default.column === undefined
                        && reportModelChart.default.row === undefined
                        && reportModelChart.default.chart === undefined) {
                        reportModelChart.default = {
                            chart: reportModelChart.default
                        };
                    }
                }
                else {
                    this.reportModel.Chart = {
                        default: {
                            chart: reportModelChart
                        }
                    };
                }
            }

            if (this.reportModel.ColumnDefinitions) {
                this.reportModel.ColumnDefinitions.forEach(c => {
                    if (c.Chart) {
                        const colChart: any = c.Chart;
                        if (colChart.columnSelection || colChart.cellChart) {
                            c.Chart = colChart;
                        }
                        else {
                            c.Chart = {
                                columnSelection: c.ColumnType === REPORT_GRID_COLUMN_DISPLAY.chart ? undefined : colChart,
                                cellChart: c.ColumnType === REPORT_GRID_COLUMN_DISPLAY.chart ? colChart : undefined
                            };
                        }
                    }
                });
            }

            if (this.reportModel.Rows) {
                this.reportModel.Rows.forEach(r => {
                    if (r.RowDefinition.Chart)
                        r.RowDefinition.Chart = r.RowDefinition.Chart;
                });
            }
        }
        catch (e) {
            console.error(`Failed to parse chart definitions: ${ e }.`);
        }
    }

    private parseJson<T>(json: string) {
        return evalExpression<T>(`(${ json })`);
    }

    getColumnNodes() {
        if (!this.columnNodes) {
            const columnNodes = this.collectNodes<IReportModelColumnNode>(this.getColumnTree(), n => n.type == REPORT_MODEL_NODE_TYPE.column);

            const grouped = columnNodes.filter(c => c.column.IsGrouped)
                .sort((a, b) => this.columnNodeSortFunction(a, b, this.reportModel.Layout));

            const notGrouped = columnNodes.filter(c => !c.column.IsGrouped)
                .sort((a, b) => this.columnNodeSortFunction(a, b, this.reportModel.Layout));

            // filtering based on the parent restrictions
            this.columnNodes = [...notGrouped, ...grouped].filter(n => {
                return !n.parent || this.getParentGroupNodes(n).every(n => !n.group.Parents || (n.parent && n.group.Parents.indexOf(n.parent.group.SystemName) !== -1));
            });
        }

        return this.columnNodes;
    }

    getGroupNodes() {
        if (!this.groupNodes) {
            const groupNodes = this.collectNodes<IReportModelGroupNode>(this.getColumnTree(), n => n.type == REPORT_MODEL_NODE_TYPE.group);

            // filtering based on the parent restrictions
            this.groupNodes = groupNodes.filter(n => {
                return !n.parent || [n, ...this.getParentGroupNodes(n)]
                    .every(n => !n.group.Parents || (n.parent && n.group.Parents.indexOf(n.parent.group.SystemName) !== -1));
            });
        }

        return this.groupNodes;
    }

    private getParentGroupNodes(node: IReportModelNode): IReportModelGroupNode[] {
        return node.parent ? [node.parent, ...this.getParentGroupNodes(node.parent)] : [];
    }

    private columnNodeSortFunction(a: IReportModelColumnNode, b: IReportModelColumnNode, levelsOrder: number[], levelIndex = 0): number {
        if (levelIndex === levelsOrder.length) throw 'Column tree has a wrong structure or wrong sort keys assigned';
        const result = a.sortKeysByLevel[levelsOrder[levelIndex]] - b.sortKeysByLevel[levelsOrder[levelIndex]];
        return result === 0 ? this.columnNodeSortFunction(a, b, levelsOrder, levelIndex + 1) : result;
    }

    getContext() {
        if (!this.context) {
            this.context = new ReportBindingContext(this.reportModel, this);
        }

        return this.context;
    }

    getComparisonGroups() {
        return this.collectNodes<IReportModelGroupNode>(this.getColumnTree(), node => node.type == REPORT_MODEL_NODE_TYPE.group && (<IReportModelGroupNode> node).isLeafComparisonGroup);
    }

    getData() {
        const result: any[] = [];

        const rows = this.reportModel.Rows;

        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            const resultRow: any = {};
            const rowStyles = row.RowDefinition.Style ? row.RowDefinition.Style.split(/\s+/) : null;

            const columnNodes = this.getColumnNodes();

            for (let j = 0; j < columnNodes.length; j++) {
                const columnNode = columnNodes[j];

                if (row.RowDefinition.RowType === REPORT_MODEL_ROW_TYPE.title) {
                    resultRow[columnNode.systemName] = {
                        value: j == 0 ? row.Row : null,
                        style: [['title']]
                    };
                    continue;
                }

                const style = [[this.reportModel.ReportType]];

                if (rowStyles) style.push(rowStyles);

                const colStyle = columnNode.column.Style
                    ? this.interpolateExpression(columnNode.column.Style, columnNode, row).split(/\s+/)
                    : [];

                if (columnNode.column.ColumnType == DATA_GRID_COLUMN_TYPE.rowHeader)
                    colStyle.push('row-header');

                style.push(colStyle);

                const formatExpression = columnNode.column.Format || row.RowDefinition && row.RowDefinition.Format;

                const format = formatExpression
                    ? this.interpolateExpression(formatExpression, columnNode, row)
                    : null;

                resultRow[columnNode.systemName] = {
                    value: this.getCellValue(columnNode, row),
                    style: style.map(el => el.map(s => s && s.trim()).filter(s => s))
                        .filter(s => s.length),
                    format
                };
            }

            result.push(resultRow);
        }

        return result;
    }

    getColumns() {
        return this.getColumnNodes().map(c => {
            return {
                systemName: c.systemName,
                displayName: c.displayName,
                groupPath: c.parent ? c.parent.path.map(g => g.SystemName) : null,
                columnSystemName: c.column.SystemName,
                columnType: c.column.ColumnType || null
            };
        });
    }

    getValue(rowArg: any, columnArg: any, groupPath?: string[]) {
        const columnNode = this.getColumnNode(columnArg, groupPath);
        const row = this.getRow(rowArg);
        return this.getCellValue(columnNode, row);
    }

    // columnArg - systemName or index
    getColumnNode(columnArg: any, groupPath: string[], relativeToComparisonContext = false) {
        const groupNodes = groupPath
            ? this.getColumnNodes().filter(n => n.parent && n.parent.path.filter(g => !relativeToComparisonContext || !g.IsComparisonGroup).map(g => g.SystemName).every((g, i) => g == groupPath[i]))
            : this.columnNodes;

        const columnNode = typeof (columnArg) == 'string'
            ? groupNodes.filter(n => n.column.SystemName === columnArg)[0]
            : groupNodes[columnArg];

        if (!columnNode) throw `Column ${ columnArg } not found`;

        return columnNode;
    }

    // rowArg - systemName or index
    getRow(rowArg: any) {
        const row = typeof (rowArg) == 'string'
            ? this.reportModel.Rows.filter(r => r.RowDefinition.SystemName === rowArg)[0]
            : this.reportModel.Rows[rowArg];

        if (!row) throw `Row ${ rowArg } not found`;

        return row;
    }
}