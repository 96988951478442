'use strict';

import { register, USER_PIC_SIZE } from "@systemorph/web";
import {
    IBusinessProcessService, IProcessModel, 
    IProcessStateModel, IProcessPromotableModel, 
    IProcessHistoryModel, IProcessDataType, ISelectableProcessModel
} from "../businessProcess.api";
import { sum } from 'lodash';
import { BUSINESS_PROCESS_EVENT } from './businessProcessDetails.events';
import { isEmpty } from 'lodash';
import { IScope, ITemplateCacheService } from "angular";

const historyTemplateKey = require.resolve('./processTrackerHistoryPopover.html');
const motionTemplateKey = require.resolve('./businessProcessMotionPopover.html');
register.directive("grandParentProcessTracker", ($templateCache: ITemplateCacheService) => {
    $templateCache.put(historyTemplateKey, require('./processTrackerHistoryPopover.html'));
    $templateCache.put(motionTemplateKey, require('./businessProcessMotionPopover.html'));

    return {
        restrict: 'E',
        replace: true,
        scope: true,
        template: require('./grandParentProcessTracker.html'),
        bindToController: {
            process: '=',
            masterProcessName: '=',
            disablePromotion: '=',
            stateFilter: '=?',
            isSelectable: '=?',
            externalSelectChanged: '&?selectChanged'
        },
        controllerAs: "ctrl",
        controller: GrandParentProcessTrackerController
    }
});

class GrandParentProcessTrackerController {
    //bindings
    process: IProcessModel;
    masterProcessName: string;
    disablePromotion: boolean;
    isSelectable: boolean;
    stateFilter: string;
    externalSelectChanged: () => void;

    //models
    childSubProcessTrackers: IProcessModel[]; // direct children
    allChildSubProcessTrackers: IProcessModel[]; // all children
    parentSubProcessTrackers: IProcessModel[];

    isAllCollapsed: boolean;
    isAllExpanded: boolean;

    isPromoteOpen: boolean;
    isDemoteOpen: boolean;
    motionModels: IProcessPromotableModel[];
    isLoadingPromotes: boolean;

    isHistoryOpen: boolean;
    historyModel: IProcessHistoryModel;
    userImageSize: string;

    subProcessesCount: number;
    processStates: IProcessStateModel[];
    loadingStates: boolean;
    dataTypes: IProcessDataType[];

    historyPopoverTemplateUrl = historyTemplateKey;
    motionPopoverTemplateUrl = motionTemplateKey;

    constructor($scope: IScope, private businessProcessService: IBusinessProcessService) {

        this.isPromoteOpen = false;
        this.isDemoteOpen = false;
        this.motionModels = null;

        this.userImageSize = USER_PIC_SIZE.small;
        this.isHistoryOpen = false;
        this.historyModel = null;

        $scope.$on(BUSINESS_PROCESS_EVENT.stateFilterUpdated, () => {
            this.refreshChildrenAndParents();
            this.refreshExpand();
            this.refreshProcessStates();
        });

        this.refreshChildrenAndParents();
        this.refreshExpand();
        this.refreshProcessStates();
    }

    private refreshProcessStates() {
        this.loadingStates = true;
        return this.businessProcessService.getProcessStateCachedAndCalculateCounts(this.masterProcessName, this.process)
            .then(states => {
                this.subProcessesCount = sum(states.map(s => s.count));
                this.processStates = states.filter(s => this.stateFilter == null || this.stateFilter === 'all' || s.systemName === this.stateFilter);
            }).finally(() => {
                this.loadingStates = false;
            });
    }

    toggleProcessExpanded() {
        if (!this.isSelectable)
            this.process.isExpanded = !this.process.isExpanded;
    }

    expandAll(): void {
        this.parentSubProcessTrackers.forEach(st => { st.isExpanded = true });
        this.refreshExpand();
    }

    collapseAll(): void {
        this.parentSubProcessTrackers.forEach(st => { st.isExpanded = false });
        this.refreshExpand();
    }

    selectDataType(dataType: IProcessDataType) {
        this.businessProcessService.flattenProcess(this.process)
            .filter(p => isEmpty(p.subProcessModels) && (dataType.systemName === 'All' || p.systemName === dataType.systemName))
            .forEach(p => {
                p.isSelected = dataType.isSelected;
            });

        if (dataType.systemName === 'All')
            this.dataTypes.forEach(t => t.isSelected = dataType.isSelected);
        else
            this.setAllIsSelected();

        if (this.externalSelectChanged) {
            this.externalSelectChanged();
        }
    }

    onSelectChanged(process: ISelectableProcessModel) {
        this.dataTypes.filter(t => t.systemName === process.systemName)[0].isSelected
            = this.allChildSubProcessTrackers.filter(p => p.systemName === process.systemName).every(t => t.isSelected);
        this.setAllIsSelected();

        if (this.externalSelectChanged) {
            this.externalSelectChanged();
        }
    }

    private setAllIsSelected() {
        if (this.dataTypes.length > 1)
            this.dataTypes[0].isSelected = this.dataTypes.filter(t => t.systemName !== 'All').every(t => t.isSelected);
    }

    private refreshChildrenAndParents() {
        this.childSubProcessTrackers = (this.process.subProcessModelsFiltered || []).filter(p => isEmpty(p.subProcessModels));
        this.parentSubProcessTrackers = (this.process.subProcessModelsFiltered || []).filter(p => !isEmpty(p.subProcessModels));
        this.allChildSubProcessTrackers = this.businessProcessService.flattenProcess(this.process).filter(p => isEmpty(p.subProcessModels));
        this.dataTypes = this.businessProcessService.getProcessDataTypes(this.process);
        if (this.dataTypes.length > 1) {
            this.dataTypes.splice(0, 0, { systemName: 'All', displayName: 'All', isSelected: false });
        }
    }

    private refreshExpand(): void {
        this.isAllExpanded = this.parentSubProcessTrackers.every(st => st.isExpanded);
        this.isAllCollapsed = this.parentSubProcessTrackers.every(st => !st.isExpanded);
    }

    onExpandChanged() {
        this.refreshExpand();
    }

    openHistory() {
        this.historyModel = null;
        this.isHistoryOpen = true;
        this.businessProcessService.getProcessTrackerHistory(this.masterProcessName, this.process).then(historyModel => {
            this.historyModel = historyModel;
        });
    }

    openPromote(): void {
        this.motionModels = null;
        this.isPromoteOpen = true;
        this.isLoadingPromotes = true;

        this.businessProcessService.getProcessTrackerPromote(this.process.processTrackerId, this.masterProcessName)
            .then((ret: IProcessPromotableModel[]) => {
                // todo: get rid of 'all', use null or undefined instead
                this.motionModels = ret.filter(i => this.stateFilter == null || this.stateFilter === 'all' || i.fromSystemName === this.stateFilter);
            })
            .finally(() => {
                this.isLoadingPromotes = false;
            });
    }

    startMotion(motionModel: IProcessPromotableModel): void {
        this.isPromoteOpen = false;
        this.isDemoteOpen = false;
        this.businessProcessService.startMotion(this.process, this.masterProcessName, motionModel);
    }
}