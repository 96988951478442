'use strict';
import { register, IGridFilterOptions, ISystemorphEntity, IHierarchicalOptionsService, ICheckboxEntity, IHierarchicalEntity } from '@systemorph/web';
import { SearchCheckboxOptionService } from "@systemorph/web/app/entityGrid/gridActionMenus/filters/searchCheckboxOptionDirective";

register.decorator('searchCheckboxOptionService', ($delegate: SearchCheckboxOptionService) => {

    let parentGetOdataPredicateAfterEdit = $delegate.getOdataPredicateAfterEdit;

    $delegate.getOdataPredicateAfterEdit = (filterOptions: IGridFilterOptions): string => {
        return (isSimpleList(filterOptions))
            ? getSimpleList(filterOptions)
            : parentGetOdataPredicateAfterEdit(filterOptions)
    }

    let isSimpleList = (filterOptions: IGridFilterOptions): boolean => {
        const { allOptions, fieldValues, propertyLayout } = filterOptions;

        if (allOptions.length === fieldValues.length) {
            return false;
        }

        var allNotBlank = allOptions.filter((o: ISystemorphEntity) => { return o.Id !== "blank"; });
        var fieldValuesNotBlank = fieldValues.filter((id: string) => { return id !== 'blank'; });
        const { isRequired, isHierarchy } = propertyLayout.filterDirectiveParameters;

        if (!isRequired && allNotBlank.length === fieldValuesNotBlank.length) {
            return false;
        }

        if (isHierarchy) {
            return false;
        }

        return true;
    }

    let getSimpleList = (filterOptions: IGridFilterOptions) => {
        const { allOptions, fieldValues, propertyLayout } = filterOptions;

        const fieldName = (filterOptions.fieldName) ? filterOptions.fieldName : "Id";
        const propName = `${propertyLayout.systemName}.${fieldName}`;


        if (fieldValues.length > allOptions.length / 2) {
            let notInValues = allOptions.map((o: ISystemorphEntity) => o.Id).filter((id: string) => fieldValues.indexOf(id) == -1);

            return notInValues.map((id: any) => clause(propName, id, false)).join(' and ');
        }
        else {
            return fieldValues.map((id) => clause(propName, id, true)).join(' or ');
        }
    }

    let clause = (propName: string, id: string, isEqual: boolean): string => {
        var optionIdStr = (id && id !== "blank") ? "'" + id.replace(/[\[\]']+/g, '') + "'" : 'null';
        return `${propName} ${(isEqual) ? 'eq' : 'ne'} ${optionIdStr}`;
    }

    return $delegate;
});

register.decorator('hierarchicalOptionsService', ($delegate: IHierarchicalOptionsService) => {

    let parentInitListFromQuery = $delegate.initListFromQuery;

    $delegate.initListFromQuery = (parent: ICheckboxEntity, selectedOptions: any[], excludeOptions: string[], fieldName: string, isSetSelectAll: boolean): void => {
        if (isSimpleListAndExcludes(parent, selectedOptions, excludeOptions, isSetSelectAll)) {
            checkAllNotExcludes(<ICheckboxEntity[]>parent.children, excludeOptions, fieldName);
        } else {
            parentInitListFromQuery.call($delegate, parent, selectedOptions, excludeOptions, fieldName, isSetSelectAll);
        }
    }

    let isSimpleListAndExcludes = (parent: ICheckboxEntity, selectedOptions: any[], excludeOptions: string[], isSetSelectAll: boolean): boolean => {
        if (!parent.parent) { // top level item
            if (parent.children && parent.children.filter((o: ICheckboxEntity) => o.children && o.children.length > 0).length == 0) { // simple list
                return (selectedOptions.length == 0 && excludeOptions.length > 0 && !isSetSelectAll);
            }
        }
        return false;
    }

    let checkAllNotExcludes = (options: ICheckboxEntity[], excludeOptions: string[], fieldName: string): void => {
        options.forEach((o: ICheckboxEntity) => {
            o.checked = (o.entity[fieldName] != '-1' && excludeOptions.indexOf(o.entity[fieldName]) == -1);
        });
    }

    return $delegate;
});

