'use strict';

import { IScope } from 'angular';
import { FormEntityRegistry } from "@systemorph/form-entity-web";
import { ICurrentProcessModel, IBusinessProcessService } from '@systemorph/business-processes-web';
import { Dictionary } from 'lodash';

export const BALOISE_FORM_ENTITY = {
    alwaysInUrlKey: 'alwaysInUrl'
}

export interface IBaloiseBusinessProcessService extends IBusinessProcessService {
    formEntityRegistry: FormEntityRegistry;
    getFormEntityValues(process: ICurrentProcessModel): Dictionary<any>;
    $rootScope: IScope;
}