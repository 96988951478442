'use strict';

import {register, IMenuItemScope} from "@systemorph/web";

register.directive('reportToolbarMessageReportGridMenuItem', () => {
    return {
        replace: true,
        restrict: 'E',
        template: `<p ng-bind-html="message" ng-repeat="message in item.parameters"></p>`,
        controller: ($scope: IMenuItemScope, $element: ng.IAugmentedJQuery) => {
            $element.closest('.menu-item-container').addClass("report-toolbar-message");
        }
    };
});

