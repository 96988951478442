'use strict';

import { register } from "@systemorph/web";
import { IAutocompleteBoxItem } from '@systemorph/ui-web';
import {
    BaseAutocompleteFormEntityPropertyController,
    defaultAutocompleteFormEntityPropertyTempplate,
    defaultAutocompleteFormEntityPropertyControllerAs,
    IFormEntityPropertyScope, FormEntityService
} from "@systemorph/form-entity-web";
import { IReportingNodeTreeModel } from "../../../reportingWeb.api";
import { IPromise, IQService } from "angular";

register.directive('netOfShareFormEntityProperty', () => {
    return {
        restrict: 'E',
        scope: true,
        replace: true,
        template: defaultAutocompleteFormEntityPropertyTempplate,
        controllerAs: defaultAutocompleteFormEntityPropertyControllerAs,
        controller: NetOfShareFormEntityPropertyController
    };
});

class NetOfShareFormEntityPropertyController extends BaseAutocompleteFormEntityPropertyController<boolean, boolean> {
    constructor($scope: IFormEntityPropertyScope,
                formEntityService: FormEntityService,
                $q: IQService) {
        const autocomplete: string[] = $scope.entity[$scope.propertyLayout.systemName + "Autocomplete"];

        const getAutocompleteFunc = (): IPromise<IAutocompleteBoxItem[]> => {
            //TODO there is no specification how netOfShare can depends on reportingNode. Find out it from content team.
            const reportingNode: IReportingNodeTreeModel = $scope.entity["reportingNode"];
            const ret: IAutocompleteBoxItem[] = autocomplete.map(x => {
                const item: IAutocompleteBoxItem = {
                    displayName: x,
                    value: x === "Gross" ? false : true
                }
                return item;
            });

            return $q.when(ret);
        };

        super($scope, formEntityService, getAutocompleteFunc, false);
    }

    protected getUrlOrOldValue(): boolean {
        const baseValue: string | boolean = this.entity.getStateParamsOrCurrentValue(this.propertyLayout);
        if (typeof baseValue === "boolean"){
            return <boolean>baseValue;
        }

        return baseValue === "true";
    }

    protected getIndexFromAutocomplete(value: boolean, autocomplete: IAutocompleteBoxItem[]): number {
        return autocomplete.findIndex(x => x.value === value);
    }
}