'use strict';
import { IScope } from 'angular';
import { IReportingNodeService, IReportingNodeTreeModel, RestrictNodesController } from "@systemorph/reporting-web";
import { ITreeItemModel, TreeUtils, IUtilsService } from "@systemorph/ui-web";
import { register, ISystemorphEntity } from "@systemorph/web";

interface ParentChildName {
    parent: string,
    child: string
};

register.decorator('restrictNodesDirective', ($delegate: any) => {
    const directive = $delegate[0];
    directive.controller = RestrictNodesWithDisabledController;
    return $delegate;
});


export class RestrictNodesWithDisabledController extends RestrictNodesController {
    notAvailableNodesWithParents: ParentChildName[];

    /*@ngInject*/
    constructor($scope: IScope, protected reportingNodeService: IReportingNodeService, protected utilsService: IUtilsService) {

        super($scope, reportingNodeService);
        this.reportingNodeService.getReportingNodeTreesCached(this.restrictions.hierarchy)
            .then(reportingNodeTrees => {
                this.getIntercompanyNodes().then(notAvailableNodes => {
                    this.notAvailableNodesWithParents = this.getNotAvailableParents(notAvailableNodes, reportingNodeTrees);
                });
            });
    }

    private getIntercompanyNodes() {
        return this.utilsService.getTypeQuery('IntercompanyNode').then(query => {
            return query.toArray(true).then(
                (values: ISystemorphEntity[]) => {
                    return values.map(x => x.SystemName);
                });
        });
    }

    private getNotAvailableParents(notAvailableNodes: string[], reportingNodeTrees: IReportingNodeTreeModel[]): ParentChildName[] {

        const flattenNodes = TreeUtils.flattenTreeNodes(reportingNodeTrees, i => i.children);
        const nodes: ParentChildName[] = [];
        return flattenNodes.reduce<ParentChildName[]>(
            (nodes: ParentChildName[], node: IReportingNodeTreeModel) => {
                if (node.children) {
                        node.children
                            .filter((child: IReportingNodeTreeModel) => notAvailableNodes.indexOf(child.systemName) > -1)
                            .forEach((child: IReportingNodeTreeModel) => {
                                nodes.push({ parent: node.systemName, child: child.systemName});
                            });
                }

                return nodes;
            },
            nodes);
    }

    private isAvailable(nodeSystemName: string, restrictNodes: string[], notAvailableNodes: ParentChildName[]) {
        const isNotAvailableParent = notAvailableNodes.filter((node: ParentChildName) => node.child == nodeSystemName).map((node: ParentChildName) => node.parent);

        // 1) node is available if it is not in notAvailableNodes list
        // 2) node is available if it's parent is in restrictNodes
        if (isNotAvailableParent.length > 0 && restrictNodes != null && restrictNodes.indexOf(isNotAvailableParent[0]) == -1) {
            return false;
        }
        // 3) node is not available in any other case
        return true;
    }

    updateRestrictions(): () => void {
        var allItems = TreeUtils.flattenTreeNodes(this.treeItems, i => i.children);
        var selectedItems = allItems.filter(i => i.isSelected);

        this.restrictions.restrictToNodeNames = allItems.length == selectedItems.length ? null : selectedItems.map(n => n.data.systemName);

        if (!selectedItems.length) {
            this.errorMessage = "Select reporting nodes from the list";
        }
        return;
    }

    onItemSelected(item?: ITreeItemModel): void {
        super.onItemSelected();

        const itemsFlatten = TreeUtils.flattenTreeNodes(item.children, i => (<any>i).children);
        itemsFlatten.forEach((i: ITreeItemModel) => {
            i.isSelectable = this.isAvailable(i.data.systemName, this.restrictions.restrictToNodeNames, this.notAvailableNodesWithParents);
        });
    }
}