'use strict';

export const REPORT_EVENT = {
    reportModelUpdated: 'reportModelUpdated',
    refreshReportModel: 'report.refreshReportModel'
};

export const REPORT_CONTROLLER_EVENT = {
    selectionChanged: 'reportController.selectionChanged',
    reportGridUpdated: 'reportController.reportGridUpdated'
};