'use strict';

import {register} from "@systemorph/web";
import {BaseColumnDisplay} from "@systemorph/ui-web";
import { IReportColumnDisplay, IReportGridColumnDef, IReportGridOptions } from '../reportGrid.api';
import { ReportGridController } from '../reportGridDirective';

export class ReportColumnDisplay extends BaseColumnDisplay implements IReportColumnDisplay {
    protected colDef: IReportGridColumnDef;
    protected reportGrid: ReportGridController;
    /*@ngInject*/
    constructor(colDef: IReportGridColumnDef, dataGridOptions: IReportGridOptions, reportGrid: ReportGridController) {
        super(colDef, dataGridOptions);
        this.colDef = colDef;
        this.reportGrid = reportGrid;
    }
}

register.factory("reportColumnDisplay", function() {
    return ReportColumnDisplay;
});