'use strict';

import { register } from "@systemorph/web";
import { IProcessModel, IBusinessProcessService } from '../businessProcess.api';
import { isEmpty } from 'lodash';
import { IPromise, blockUI, IScope, IQService } from "angular";

register.directive("businessProcessDetails", () => {
    return {
        restrict: 'E',
        replace: true,
        bindToController: {
            processId: '=',
            processName: '='
        },
        template: `<div>
                        <header ng-if="!ctrl.processId" class="p-header">
                            <div class="alert alert-danger">
                                <p>Process id is not set.</p>
                            </div>
                        </header>
                        <div ng-if="ctrl.model">
                            <render directive="{{ctrl.directive}}" 
                                    process-model="ctrl.model" 
                                    master-process-name="ctrl.processName" 
                                    disable-promotion="ctrl.disablePromotion">
                            </render>
                        </div>
                   </div>`,
        controllerAs: "ctrl",
        controller: BusinessProcessDetailsCotroller
    }
});

class BusinessProcessDetailsCotroller {
    //bindings
    processId: string;
    processName: string;

    //models
    model: IProcessModel;
    directive: string;
    disablePromotion: boolean;

    constructor($scope: IScope,
                private $q: IQService,
                private blockUI: blockUI.IBlockUIService,
                private businessProcessService: IBusinessProcessService) {

        this.reloadAndRender();

        $scope.$watchGroup([() => this.processId, () => this.processName], (newVals: any[], oldVals: any[]) => {
            if ((newVals || []).some((el: any, index: number) => el !== oldVals[index]))
                this.reloadAndRender();
        });
    }

    private reloadAndRender(): IPromise<any> {
        if (!this.processId)
            return;

        this.blockUI.start("Loading...");
        // to trigger rerender
        this.model = null;
        return this.$q.all([this.businessProcessService.getActiveProcessInfo(), this.businessProcessService.getProcess(this.processId, true)])
                .then(([activeProcessInfo, processModel]) => {
                    this.model = processModel;
                    this.directive = isEmpty(processModel.subProcessModels)
                                        ? "simple-business-process-details"
                                        : "hierarchical-business-process-details";
                    // promotion is allowed only for current process
                    this.disablePromotion = activeProcessInfo.process == null || activeProcessInfo.process.processId !== processModel.businessProcessId;
                }).finally(() => {
                    this.blockUI.stop();
                });
    }
}