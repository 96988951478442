import {wordWrap} from "../tools";
import { IChartConfiguration } from '../charts.api';

export const multilineTitlePlugin = {
    beforeInit: function(chart: any) {
        if (chart.titleBlock) {
            const originalFit = chart.titleBlock.fit;
            chart.titleBlock.fit = function() {
                const maxWidth: number = this.maxWidth;
                const config = <IChartConfiguration>chart.config;
                if (config.options.title && config.options.title.text) {
                    if (!config.options.title.originalText)
                        config.options.title.originalText = <string>config.options.title.text;

                    config.options.title.text = <any>wordWrap(config.options.title.originalText, chart.titleBlock.ctx, maxWidth);
                }

                originalFit.call(this);
            }
        }
    }
};
